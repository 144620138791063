import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';

import { FiCopy } from "react-icons/fi";
import { RiTwitterXFill, RiWhatsappFill } from "react-icons/ri";
import { FaFacebook, FaLinkedin, FaTelegram } from "react-icons/fa";

import Error from "../Error";
import { PreviewHeader } from "../PreviewHeader/PreviewHeader";
import { PreviewFooter } from "../PreviewFooter/PreviewFooter";
import NotificationMessage from "../../Components/NotificationMessage";

import './previewPublication.css'

export const PreviewPublication = (props) => {
    props.setActivePage("Preview Publication");
    const { id } = useParams();
    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id
    const [tempData, setTempData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchDataLoading, setIsFetchDataLoading] = useState(false);
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const [status, setStatus] = useState('');
    const getImageDimensions = async (file) => {
        return new Promise((resolve, reject) => {
            const i = new Image();
            i.onload = () => resolve({ w: i.width, h: i.height });
            i.onerror = (err) => reject(err); // Handle load errors
            i.src = file;
        });
    };

    const fetchData = async () => {
        setIsFetchDataLoading(true)
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/pages/previewData/${id}?userId=${user_id}`
            );
            if (response?.status === 200) {
                setTempData(response?.data?.result);
                setIsFetchDataLoading(false)
            }
        } catch (error) {
            console.error("Error fetching newsletter data:", error);
            setIsFetchDataLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            const options = { month: 'short', day: '2-digit', year: 'numeric' };
            return new Intl.DateTimeFormat('en-US', options).format(date);
        }
    };
    const handleBack = () => {
        window.close();
    };
    const handleSend = async () => {
        if (tempData?.title !== '' && tempData?.subTitle !== '' && tempData?.categories?.length > 0 && tempData?.featured_image?.cropedImage !== "" && tempData?.description !== "") {
            const updatedData = { ...tempData, status: "Published" };
            setIsLoading(true);
            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/pages/updatepublication/${id}`,
                    updatedData,
                )

                if (res.status === 200) {
                    setIsLoading(false);
                    if(res?.data?.result?.status === "Published") {
                        if( tempData.status === 'Published' ) {
                            window.location.href = `https://reactdashboard.codeptsolutions.com/dashboard/publication/${res?.data?.result?._id}`;
                        } else {
                            window.location.href = `https://reactdashboard.codeptsolutions.com/dashboard/publication/${res?.data?.result?._id}?status=published`;
                        }                        
                    }
                    setStatus('Published')
                }
            } catch (error) {
                console.error("Failed to send data:", error);
                setIsLoading(false);
            }
        } else {
            const errors = [];
            
            if (!tempData?.title) {
                errors.push("Title is required.");
            }
            if (!tempData?.subTitle) {
                errors.push("Subtitle is required.");
            }
            if (!tempData?.categories || tempData.categories.length === 0) {
                errors.push("Category is required.");
            }
            if (!tempData?.featured_image?.cropedImage) {
                errors.push("Featured image is required.");
            }
            if (!tempData?.description) {
                errors.push("Description is required.");
            }
            if (errors.length > 0) {
                setErrorMessage(errors);
                setIsErrorMessage(true);
                return;
            }
        }
    }

    useEffect(() => {
        const galleryContainers = document.querySelectorAll('[data-type="galleryBlock"]');

        galleryContainers.forEach(async (container) => {
            const isFullLayout = (container.getAttribute('data-layout') === 'full') ? true : false;

            const imagesData = JSON.parse(container.getAttribute('data-urls'));

            const rows = [];
            const noOfImages = imagesData.length;

            let row = 0;

            const maxImagesInRow = (idx) => {
                if (isFullLayout) {
                    const remainder = imagesData.length % 3;
                    if (remainder === 0) return idx % 3 === 0;
                    if (remainder === 1) return idx > 3 ? idx % 3 === 2 : idx % 3 === 0;
                    if (remainder === 2) return idx > 3 ? (imagesData.length - idx + 1) % 3 === 0 : idx % 3 === 0;
                }
                return idx % 2 === 0;
            };

            for (const [idx, image] of imagesData.entries()) {
                if (maxImagesInRow(idx)) row++;
                if (!rows[row]) rows[row] = [];


                try {
                    const dims = await getImageDimensions(image.src);
                    const aspectRatio = (dims.w || 1) / (dims.h || 1);

                    image.style = `${aspectRatio} 1 0%`;
                } catch (error) {
                    console.error(`Failed to load dimensions for image ${image.src}:`, error);
                }

                rows[row].push(image);
            }

            const newLayout = document.createElement('div');
            newLayout.className = 'gallery-layout';

            rows.forEach((imageRow) => {
                const rowElement = document.createElement('div');
                rowElement.className = 'gallery-row';

                imageRow.forEach((image) => {
                    const imgWrapper = document.createElement('div');
                    imgWrapper.className = 'gallery-image-wrapper';
                    imgWrapper.style.flex = image?.style || '1 1 0%';

                    const imgElement = document.createElement('img');
                    imgElement.src = image.src;
                    imgElement.alt = image?.alt || 'Gallery Image';
                    imgElement.className = 'gallery-image';

                    imgWrapper.appendChild(imgElement);
                    rowElement.appendChild(imgWrapper);

                });

                newLayout.appendChild(rowElement);
            });

            const galleryPreview = container.querySelector('.gallery-preview');

            galleryPreview.innerHTML = newLayout.innerHTML;

        });
    }, [tempData]);


    // Slider 
    useEffect(() => {
        const logoSliderBlock = document.querySelectorAll('[data-type="logoSliderBlock"]');
        logoSliderBlock.forEach((container) => {
    
            const swiperSliderLists = container.querySelectorAll('.swiper-slider-list');
            
            swiperSliderLists.forEach((swiperSliderList) => {
                // Check if the swiper-slider-list has already been duplicated
                if (swiperSliderList.hasAttribute('data-duplicated')) return;
                console.log({swiperSliderList:swiperSliderList})
                const swiperItems = swiperSliderList.querySelectorAll('.swiper-items');
                swiperItems.forEach((swiperItemsList) => {
                    const swiperSlideItems = swiperItemsList.querySelectorAll('.swiper-slide-item');
                    const swiperSlideItemsArray = Array.from(swiperSlideItems);
    
                    const numberOfSlides = Math.floor(20 / swiperSlideItemsArray.length);
    
                    // Clone swiper-slide-items into each swiper-items
                    for (let i = 0; i < numberOfSlides; i++) {
                        swiperSlideItemsArray.forEach((item) => {
                            const clone = item.cloneNode(true);
                            swiperItemsList.appendChild(clone);
                        });
                    }
                });
    
                // Clone the entire swiper-slider-list div
                const cloneSliderList = swiperSliderList.cloneNode(true);
    
                // Append the cloned swiper-slider-list directly to the parent of the original swiper-slider-list
                swiperSliderList.parentNode.appendChild(cloneSliderList);
    
                // Set the flag to indicate that the swiper-slider-list has been duplicated
                swiperSliderList.setAttribute('data-duplicated', 'true');
            });
        });
    }, [tempData]);
    return (
        <>
            {
                isFetchDataLoading ?
                    <div className="loader-container preview-loader">
                        <div className="loader"></div>
                    </div> :
                    <>
                        {
                            tempData?.user_id === user_id ?
                                <>
                                    :
                                    <>
                                        <Helmet>
                                            <title>Publication Preview | Dashboard | Persone</title>
                                        </Helmet>
                                        <div className={`preview-newsletter__header ${tempData?.theme === "light" ? 'light-view' : 'dark-view'} ${tempData?.theme}`}>
                                            <p>You are currently viewing a preview of your publication.</p>
                                            <div className={`preview-buttons`}>
                                                <div className={`newpageRightButtons newpageRightButtonsPreview ${tempData?.theme === "light" ? 'light-newpageRightButtons' : 'dark-newpageRightButtons'}`} onClick={() => {
                                                    handleSend()
                                                }}>
                                                    {
                                                        ((tempData?.currentStatus === 'Published') || (status === 'Published')) ?
                                                            <>
                                                                {
                                                                    isLoading ?
                                                                        <div className="updatebutton-loader updatepreviewbutton-loader update-loader">
                                                                            <div className="ring-loader"></div>
                                                                        </div> :
                                                                        <button className={`update-button button ${tempData?.theme === "light" ? 'light-btn' : 'dark-btn'}`}>Update</button>
                                                                }
                                                            </> :
                                                            <>
                                                                {
                                                                    isLoading ?
                                                                        <div className="updatebutton-loader publish-loader">
                                                                            <div className="ring-loader"></div>
                                                                        </div> :
                                                                        <button className="button">{tempData?.theme === 'light' ? <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M8.86659 14C9.34773 14 9.68854 13.5857 9.9358 12.9442L14.3129 1.51026C14.4332 1.20286 14.5 0.928878 14.5 0.701671C14.5 0.267303 14.2327 0 13.7983 0C13.5711 0 13.2971 0.0668258 12.9897 0.187112L1.4957 4.59093C0.934368 4.80477 0.5 5.14558 0.5 5.63341C0.5 6.24821 0.96778 6.45537 1.60931 6.64916L5.2179 7.74511C5.64558 7.87876 5.88616 7.86539 6.17351 7.59809L13.5043 0.748449C13.5912 0.668258 13.6914 0.681623 13.7582 0.741766C13.8251 0.808592 13.8317 0.908831 13.7516 0.995704L6.92864 8.35322C6.66802 8.62721 6.64797 8.85442 6.77494 9.30215L7.83747 12.8305C8.03795 13.5055 8.24511 14 8.86659 14Z" fill="white" />
                                                                        </svg> : <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M8.86659 14C9.34773 14 9.68854 13.5857 9.9358 12.9442L14.3129 1.51026C14.4332 1.20286 14.5 0.928878 14.5 0.701671C14.5 0.267303 14.2327 0 13.7983 0C13.5711 0 13.2971 0.0668258 12.9897 0.187112L1.4957 4.59093C0.934368 4.80477 0.5 5.14558 0.5 5.63341C0.5 6.24821 0.96778 6.45537 1.60931 6.64916L5.2179 7.74511C5.64558 7.87876 5.88616 7.86539 6.17351 7.59809L13.5043 0.748449C13.5912 0.668258 13.6914 0.681623 13.7582 0.741766C13.8251 0.808592 13.8317 0.908831 13.7516 0.995704L6.92864 8.35322C6.66802 8.62721 6.64797 8.85442 6.77494 9.30215L7.83747 12.8305C8.03795 13.5055 8.24511 14 8.86659 14Z" fill="black" />
                                                                        </svg>}Publish</button>
                                                                }
                                                            </>

                                                    }

                                                </div>
                                                <div className="preview-edit">
                                                    <button onClick={() => { handleBack() }}>
                                                        Back to edit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <PreviewHeader profilePicture={tempData?.profilePicture} displayName={tempData?.displayName} theme={tempData?.theme} name="publication-header" />
                                        <main className={`main singleArticle ${tempData?.theme}`}>
                                            <div id="hero-and-body">
                                                <section id={`singlearticle`} className={`singleArticle`}>
                                                    <div className={`hero-wrap heroWrap`}>
                                                        <div className={`page_singlearticle hero-col heroCol`}>
                                                            <div className={`hero-image heroImage`}>
                                                                <img
                                                                    src={tempData?.pageImage}
                                                                    alt="Contact page image"
                                                                    fill={true}
                                                                    quality={100}
                                                                    priority
                                                                />
                                                                <div className='overlay'></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>

                                                <section id="article-detail" className={`articleDetail`}>
                                                    <p className="text-white datacaption">{tempData?.image_caption}</p>
                                                    <div className={`articleContainer`}>
                                                        <div className={`articleCard`}>
                                                            <div className='publication-content'>
                                                                <h2 className='publication-title text-white'>
                                                                    {tempData.title}
                                                                </h2>
                                                                <p className='publication-paragraph text-white'>
                                                                    {tempData.subTitle}
                                                                </p>
                                                            </div>
                                                            <div className='publicationContent'>
                                                                <div className='publicationAvatar'>
                                                                    <div className='publicationImg'>
                                                                        <img
                                                                            src={tempData?.profilePicture}
                                                                            width={75}
                                                                            height={75}
                                                                            quality={100}
                                                                            priority
                                                                        />
                                                                    </div>
                                                                    <p className={`publication-name-date text-white`} >
                                                                        <span className='publication-name' style={{ fontWeight: '600' }}>By: <span className='publisher-name' style={{ fontWeight: '600' }}>{tempData?.displayName}</span></span>
                                                                        <span className='publication-date' style={{ fontWeight: '600' }}>{formatDate(tempData?.createdAt ? tempData?.createdAt : "")}</span>
                                                                    </p>
                                                                </div>
                                                                <div className='publicationSocial'>
                                                                    <ul className='socialIcon'>
                                                                        <li className='Copylink' >
                                                                            <div className='copyIcon'><FiCopy /></div>
                                                                            <div className='copyLinkText'>Copy link</div>
                                                                        </li>
                                                                        <li> <FaFacebook />  </li>
                                                                        <li> <RiTwitterXFill />  </li>
                                                                        <li> <FaLinkedin />  </li>
                                                                        <li> <RiWhatsappFill /> </li>
                                                                        <li> <FaTelegram />  </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="article">
                                                                <div className={`articleContent article-min-container`}>
                                                                    {parse(tempData?.pagePreviewData ? tempData?.pagePreviewData : "")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                            {isErrorMessage && (
                                                <NotificationMessage openNotify={isErrorMessage} setOpenNotify={setIsErrorMessage} type={'error'} title={errorMessage.join(", ")} />
                                            )}
                                        </main>
                                        <PreviewFooter profilePicture={tempData?.profilePicture} displayName={tempData?.displayName} theme={tempData?.theme} />

                                    </>

                                </> :
                                <>
                                    {
                                        !isFetchDataLoading &&
                                        <Error menuDisabled={true} />
                                    }
                                </>
                        }

                    </>

            }
        </>
    )
}
