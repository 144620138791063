import { NodeViewWrapper } from '@tiptap/react';
import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import axios from 'axios';

const UrlPasteBlock = ({ node, editor, getPos }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isVideoReady, setIsVideoReady] = useState(false);
  const embedRef = useRef(null);
  const URL = node?.attrs?.url || '';

  let embeddedType = 'other';

  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  const iframePattern = /^<iframe.*?src="(.*?)".*?<\/iframe>/;  // Modified to match if the URL starts with <iframe>

  // First check if the URL contains <iframe>
  const isIframe = iframePattern.test(URL);

  if (isIframe) {
    embeddedType = 'iframe';
  } else if (URL?.trim()) {
    const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/).+$/;
    const vimeoPattern = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/[^/]+\/videos\/|)(\d+)(?:\/)?(?:\?.*)?$/;
    const spotifyPattern = /^(https?:\/\/)?(open\.)?spotify\.com\/(track|album|playlist|artist|episode|show)\/.+$/;
    const soundCloudPattern = /^(https?:\/\/)?(soundcloud\.com|snd\.sc)\/.+$/;
    const twitterPattern = /^(https?:\/\/)?(www\.)?twitter\.com\/.+\/status\/.+$/;
    const twitterXPattern = /^(https?:\/\/)?(www\.)?x\.com\/.+\/status\/.+$/;

    if (youtubePattern.test(URL) && urlPattern.test(URL)) {
      embeddedType = 'youtube';
    } else if (vimeoPattern.test(URL) && urlPattern.test(URL)) {
      embeddedType = 'vimeo';
    } else if (spotifyPattern.test(URL) && urlPattern.test(URL)) {
      embeddedType = 'spotify';
    } else if (soundCloudPattern.test(URL) && urlPattern.test(URL)) {
      embeddedType = 'soundCloud';
    } else if (twitterPattern.test(URL) || twitterXPattern.test(URL)) {
      embeddedType = 'twitter';
    }
  }

  const handleVideoReady = () => {
    setIsVideoReady(true);
  };

  useEffect(() => {
    if (embeddedType === "other") {
      const url = node?.attrs?.url?.trim();
      getSiteData(url);
    }
    if (embeddedType === "youtube") {
      const url = node?.attrs?.url
      editor.chain().focus().setYouTubeBlock({videourl: url,caption: '',}).run();
    }
    if (embeddedType === "vimeo") {
      const url = node?.attrs?.url
      editor.chain().focus().setVimeoBlock({ videourl: url, caption: '' }).run();
    }

    if (embeddedType === 'spotify') {
      setIsLoading(true);
      const url = node?.attrs?.url;
      const urlSegments = url.split('/');
      const spotifyType = urlSegments[3];
      const spotifyId = urlSegments.pop().split('?')[0];
      if (spotifyId) {
        const spotifysrc = `https://open.spotify.com/embed/${url.split('/')[3]}/${spotifyId}?utm_source=generator&theme=1`;
        editor.chain().focus().setSpotifyBlock({ spotifyUrl: spotifysrc, spotifyType }).run();
      }
      setIsLoading(false);
    }

    if (embeddedType === 'soundCloud') {
      setIsLoading(true);
      const url = node?.attrs?.url;
      const soundCloudSrc= `https://w.soundcloud.com/player/?url=${url}&color=%231ED760&auto_play=true&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=false`
      editor.chain().focus().setSoundCloudBlock({ soundCloudUrl:soundCloudSrc }).run();
      setIsLoading(false);
    }
    

    console.log({isLoading, embeddedType})
    if (embeddedType === 'twitter') {
      setIsLoading(true);
      const twitterUrl = node?.attrs?.url;

      embedTweeterBlock(twitterUrl);
    }

    if (embeddedType === 'iframe' && embedRef.current) {
      setIsLoading(true);
      embedRef.current.innerHTML = URL; // Set the iframe HTML directly
      setIsLoading(false);
    }
  }, [embeddedType]);

  const getSiteData = async (url) => {
    try {
      setIsLoading(true);
      const urlData = { url };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/publicationurl/link`,
        urlData
      );

      if (response?.data?.previewData) {
        editor.chain().focus().setBookmarkBlock({
          title: response.data.previewData?.title || '',
          description: response.data.previewData?.description || '',
          imageUrl: response.data.previewData?.imageUrl || '',
          siteTitle: response.data.previewData?.siteTitle || '',
          favicon: response.data.previewData?.faviconSrc || '',
          url: node.attrs.url,
        }).run();
      }
    } catch (error) {
      console.error('Failed to fetch URL data:', error);
      setErrorMessage("No data found");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCaptionClick = () => {
        const nodePosition = getPos(); 
        editor.commands.setNodeSelection(nodePosition);
  };

  const embedTweeterBlock = async (twitterUrl) => {
    setIsLoading(true);
    try {
      if (twitterUrl) {
        const twitterData = await embedTweet(twitterUrl);
        console.log({twitterUrl, twitterData})
        editor.chain().focus().setTwitterBlock({ twitterUrl: twitterUrl, twitterData: twitterData }).run();
        setErrorMessage('');
      }
    } catch (error) {
      setErrorMessage("An error occurred while loading the tweet.");
    } finally {
      setIsLoading(false);
    }
  }

  const embedTweet = async (twitterUrl) => {    
    try {
      if (twitterUrl) {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/pages/scrapeTweet`,
          { tweetUrl: twitterUrl }
        );
        if (res?.status === 200) {
          const twitterData = res.data.data; 

          return twitterData;
        }
      }
    } catch (error) {
      setErrorMessage("An error occurred while loading the tweet.");
    }
  }

  return (
    <NodeViewWrapper data-type={embeddedType} style={{margin:"20px 0"}}>
      {(embeddedType === 'youtube' || embeddedType === 'vimeo') &&
        <div className='video-wrapper youtube-video'>
          {!isVideoReady &&
            <div contentEditable={false} className="skeleton-video-wrapper"><div className="skeleton-video"></div></div>
          }
          <div contentEditable={false} className={isVideoReady ? 'player-wrapper player-ready' : 'player-wrapper'}>
            <ReactPlayer
              className='react-player'
              url={URL}
              width='100%'
              height='100%'
              controls
              onReady={handleVideoReady}
            />
          </div>
          <div className='video-title' contentEditable={false}>
            <p className={`video-caption`} contentEditable={true} 
            onClick={handleCaptionClick}></p>
          </div>
        </div>
      }
      {
        (embeddedType === 'spotify' || embeddedType === "soundCloud" || embeddedType === "iframe") &&
        <div className={`${embeddedType}-node-wrapper ${embeddedType}-block`} data-type={embeddedType}>
          <div className={`${embeddedType}Embed`} ref={embedRef} />
        </div>
      }
      {embeddedType === 'twitter' && 
        <div data-type="twitter" className="twitter-node-wrapper" data-drag-handle>
          <label>
            <div className='urlInput'>
              {isLoading ? (
                <div className='linkbutton-loader loader-buttonHeight'>
                  <div className='ring-loader'></div>
                </div>
              ) : (
                <>
                  {errorMessage && <p className="error-message">{errorMessage}</p>}
                </>
              )}
            </div>
          </label>
        </div>
      }
      { (embeddedType === 'other') &&
        <div data-type='bookmark' className={`bookmark-node-wrapper bookmark-block ${isLoading && 'is-loading'}`} data-drag-handle>
          <label>
            <div className='urlInput'>
              {isLoading ? (
                <div className='linkbutton-loader loader-buttonHeight'>
                  <div className='ring-loader'></div>
                </div>
              ) : (
                <>
                  {errorMessage && <p className="error-message">{errorMessage}</p>}
                </>
              )}
            </div>
          </label>
        </div>
      }
    </NodeViewWrapper>
  );
};

export default UrlPasteBlock;
