import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import ReactPlayer from 'react-player/lazy'

import Loader from "../../Components/Loader/Loader";

import link from "../../images/Settings/link.svg";
import fileYoutubeIcon from "../../images/icon-youtube.svg";
import fileVimeoIcon from "../../images/icon-vimeo.svg";
import fileVideoIcon from "../../images/icon-video.svg";
import filmIcon from "../../images/film-icon.svg";
import closeIcon from "../../images/x-close.svg";
import uploadCloud from "../../images/upload-cloud.svg";


function VideoPublication(props) {

    const { PubID, dbPageTitle, editorContent, shareUrl, validateTitle, validateEditorContent, setValidateTitle, setValidateEditorContent, setdbPageTitle, setEditorContent, setShareUrl, setIsHasImage, setIsImageFromLink, selectedVideoFileData, setShowUploadMediaPopup, setUploadMediaPopupFor, setUploadMediaPopupTab, setPublicationVideo, setPublicationCheckVideoLink, isImageFromLink, billingDetails, setShowUpgradeModel, publicationVideoValidate, setPublicationVideoValidate, youtubeLinkValidate, setYoutubeLinkValidate, setVimeoLinkValidate, vimeoLinkValidate, setContentChange } = props;


    const [isOpenBox, setIsOpenBox] = useState({
        'YouTubeBox': false,
        'VimeoBox': false,
        'VideoBox': false,
    });

    const [selectedBox, setSelectedBox] = useState();

    const YouTubevideoTitleRef = useRef(null);
    const YouTubevideoDescRef = useRef(null);
    const VimeovideoTitleRef = useRef(null);
    const VimeovideoDescRef = useRef(null);
    const uploadedvideoTitleRef = useRef(null);
    const uploadedvideoDescRef = useRef(null);

    const [videoData, setVideoData] = useState(false);
    const [isVideoLoad, setIsVideoLoad] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isValidURL, setIsValidURL] = useState('');
    const [isVideoReady, setIsVideoReady] = useState(false);
    const [isSavedVideo, setIsisSavedVideo] = useState(PubID !== undefined ? true : false)

    const [videoUrl, setVideoUrl] = useState((shareUrl !== undefined) ? shareUrl : '');
    const [videoTitle, setVideoTitle] = useState(dbPageTitle);
    const [videoDescription, setVideoDescription] = useState(editorContent);

    const [YouTubeUrl, setYouTubeUrl] = useState('');
    const [YouTubeTitle, setYouTubeTitle] = useState(dbPageTitle);
    const [YouTubeDescription, setYouTubeDescription] = useState(editorContent);

    const [VimeoUrl, setVimeoUrl] = useState('');
    const [VimeoTitle, setVimeoTitle] = useState(dbPageTitle);
    const [VimeoDescription, setVimeoDescription] = useState(editorContent);

    const [uploadedVideoName, setUploadedVideoName] = useState('');
    const [uploadedVideoSize, setUploadedVideoSize] = useState(0);
    const [uploadedVideo, setUploadedVideo] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const [isVideoDragging, setIsVideoDragging] = useState(false);
    const [loadingVideo, setLoadingVideo] = useState(false);
    const [isPublicationVideo, setIsPublicationVideo] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    useEffect(() => {
        const handleResize = () => {


            let videoTitleRef = '';
            let videoDescRef = '';

            let newSelectedBox = selectedBox;

            if (PubID !== undefined) {
                const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/).+$/;
                const vimeoPattern = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)$/;

                if (youtubePattern.test(videoUrl)) {
                    newSelectedBox = 'YouTubeBox';
                } else if (vimeoPattern.test(videoUrl)) {
                    newSelectedBox = 'VimeoBox';
                } else {
                    newSelectedBox = 'VideoBox';
                }
            }

            if (newSelectedBox === 'YouTubeBox') {
                videoTitleRef = YouTubevideoTitleRef;
                videoDescRef = YouTubevideoDescRef;
            }

            if (newSelectedBox === 'VimeoBox') {
                videoTitleRef = VimeovideoTitleRef;
                videoDescRef = VimeovideoDescRef;
            }

            if (newSelectedBox === 'VideoBox') {
                videoTitleRef = uploadedvideoTitleRef;
                videoDescRef = uploadedvideoDescRef;
            }

            if (isValidURL && isVideoLoaded && (videoData || PubID !== undefined) && videoTitleRef.current) {
                videoTitleRef.current.style.height = '40px';
                videoTitleRef.current.style.height = videoTitleRef.current.scrollHeight + 2 + 'px';
            }

            if (isValidURL && isVideoLoaded && (videoData || PubID !== undefined) && videoDescRef.current) {
                videoDescRef.current.style.height = '40px';
                var extraSpace = 2;

                if (videoDescRef.current.scrollHeight > 80) {
                    extraSpace = 10;
                }

                videoDescRef.current.style.height = videoDescRef.current.scrollHeight + extraSpace + 'px';
            }

            if (isSavedVideo === true) {
                setIsisSavedVideo(false)
            }
        };

        handleResize();

        // Attach event listener for window resize
        window.addEventListener('resize', handleResize);


        // Cleanup: Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [videoTitle, videoDescription, videoData, isVideoLoaded, isValidURL]);

    useEffect(() => {
        if (selectedVideoFileData) {
            setUploadedVideoName(selectedVideoFileData.name);
            setUploadedVideoSize(selectedVideoFileData.size);

            setUploadedVideo(selectedVideoFileData.url);
            setPublicationVideo(null);
            setVideoUrl(selectedVideoFileData.url);
            setShareUrl(selectedVideoFileData.url);
            setIsPublicationVideo(true);
        }
    }, [selectedVideoFileData]);

    useEffect(() => {
        const addUrlData = async () => {

            try {
                if (!isSavedVideo) {
                    setVideoTitle('');
                    setVideoDescription('');
                }

                if (videoUrl != '') {
                    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
                    setIsValidURL(urlPattern.test(videoUrl));


                    const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/).+$/;
                    const vimeoPattern = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)$/;

                    let newSelectedBox = selectedBox;

                    if (PubID !== undefined) {
                        if (youtubePattern.test(videoUrl)) {
                            newSelectedBox = 'YouTubeBox';
                        } else if (vimeoPattern.test(videoUrl)) {
                            newSelectedBox = 'VimeoBox';
                        } else {
                            newSelectedBox = 'VideoBox';
                        }
                        setSelectedBox(newSelectedBox);
                    }

                    if (newSelectedBox === 'YouTubeBox') {
                        setIsValidURL(youtubePattern.test(videoUrl));
                    }

                    if (newSelectedBox === 'VimeoBox') {
                        setIsValidURL(vimeoPattern.test(videoUrl));
                    }


                    if (urlPattern.test(videoUrl)) {
                        setIsVideoLoad(true);
                        setIsVideoLoaded(false);

                        const id = await extractVideoId(videoUrl);
                        if (id) {
                            getVideoDetails(id);
                        } else {
                            if (selectedVideoFileData) {
                                setVideoTitle(dbPageTitle);
                                setVideoDescription(editorContent);
                            } else {
                                setVideoTitle('');
                                setVideoDescription('');
                            }
                        }

                        setIsVideoLoad(false);
                        setIsVideoLoaded(true);
                    }
                }
            } catch (error) {
                console.log({ error: error, message: error.message })
            }
        }
        addUrlData();
    }, [videoUrl]);


    const handleVideoTitleInput = (e) => {
        setContentChange(true)

        if (isSavedVideo === false) {
            e.target.style.height = '40px';
            e.target.style.height = e.target.scrollHeight + 2 + 'px';

            setVideoTitle(e.target.value);
            setdbPageTitle(e.target.value);

            if (selectedBox === 'YouTubeBox') {
                setYouTubeTitle(e.target.value)
            }

            if (selectedBox === 'VimeoBox') {
                setVimeoTitle(e.target.value)
            }

            if (e.target.value.trim().length > 0) {
                setValidateTitle(false);
            }
        }
    }

    const handleVideoTitleBlur = (e) => {
        const newtitleVal = e.target.value.trim();
        if (newtitleVal.length == 0) {
            setVideoTitle(videoData.title);
            setdbPageTitle(videoData.title);

            if (selectedBox === 'YouTubeBox') {
                setYouTubeTitle(videoData.title)
            }

            if (selectedBox === 'VimeoBox') {
                setVimeoTitle(videoData.title)
            }
        }
    }

    const handleVideoDescInput = (e) => {
        if (isSavedVideo === false) {
            e.target.style.height = '40px';

            var extraSpace = 2;

            if (e.target.scrollHeight > 80) {
                extraSpace = 20;
            }

            e.target.style.height = e.target.scrollHeight + extraSpace + 'px';

            setVideoDescription(e.target.value);
            setEditorContent(e.target.value);

            if (selectedBox === 'YouTubeBox') {
                setYouTubeDescription(e.target.value)
            }

            if (selectedBox === 'VimeoBox') {
                setVimeoDescription(e.target.value)
            }

            if (e.target.value.trim().length > 0) {
                setValidateEditorContent(false);
            }
        }
    }

    const handleVideokDescBlur = (e) => {
        const newtitleVal = e.target.value.trim();
        if (newtitleVal.length == 0) {
            setVideoDescription(videoData.description);
            setEditorContent(videoData.description);

            if (selectedBox === 'YouTubeBox') {
                setYouTubeDescription(videoData.description)
            }

            if (selectedBox === 'VimeoBox') {
                setVimeoDescription(videoData.description)
            }
        }
    }

    const selectVideoBox = async (boxType) => {
        setIsOpenBox((prevState) => ({
            ...Object.fromEntries(Object.keys(prevState).map((key) => [key, key === boxType ? !prevState[key] : false])),
        }));

        if (selectedBox !== boxType) {
            const boxes = document.querySelectorAll(`.video-box-item .contentDrop`);
            boxes.forEach((box) => {
                if (box.classList.contains('expanded')) {
                    box.classList.remove('expanded');
                }
            });

            if (boxType == 'VideoBox') {
                setIsHasImage(false);
                setIsImageFromLink(false);
                // setPublicationCheckVideoLink({
                //     checkBoxStatus: false,
                //     imageUrl: ""
                // })
            } else {
                setIsHasImage(true);
                setIsImageFromLink(true);
            }
            setVideoUrl('');
            setVideoTitle('');
            setVideoDescription('');

            setdbPageTitle('');
            setEditorContent('');
            setShareUrl('')

            setIsValidURL(true);
            setIsVideoLoad(false);
            setIsVideoLoaded(false);

            setTimeout(() => {
                setSelectedBox(boxType);
            }, 100);
        }
    };

    const handleVideoUrlChange = async (event) => {
        const videoLink = event.target.value.trim();
        setVideoUrl(videoLink);
        setShareUrl(videoLink)
        setIsValidURL(true);

        if (selectedBox === 'YouTubeBox') {
            setYouTubeUrl(videoLink);
            setContentChange(true)
        }

        if (selectedBox === 'VimeoBox') {
            setVimeoUrl(videoLink);
            setContentChange(true)
        }

        setIsVideoReady(false);
        setIsVideoLoad(false);
        setIsVideoLoaded(false);
    };

    const extractVideoId = (videoUrl) => {
        let regExp = '';

        let newSelectedBox = selectedBox;

        if (PubID !== undefined) {
            const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/).+$/;
            const vimeoPattern = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)$/;

            if (youtubePattern.test(videoUrl)) {
                newSelectedBox = 'YouTubeBox';
            }

            if (vimeoPattern.test(videoUrl)) {
                newSelectedBox = 'VimeoBox';
            }
        }

        if (newSelectedBox === 'YouTubeBox') {
            regExp = /(?:\?v=|&v=|youtu\.be\/)([^&\n?#]+)/;
        }
        if (newSelectedBox === 'VimeoBox') {
            regExp = /\/(\d+)$/;
        }

        const match = videoUrl.match(regExp);
        return match ? match[1] : null;

    };

    const handleVideoDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) {
            setIsVideoDragging(true);
        }
    }

    const handleVideoDragLeave = (e) => {
        e.preventDefault();
        setIsVideoDragging(false);
    };

    const handleVideoDrop = (e) => {
        e.preventDefault();
        setContentChange(true)

        setIsVideoDragging(false);
        setIsPublicationVideo(false);

        const file = e.dataTransfer.files[0];

        setLoadingVideo(true);

        if (timeoutId) {
            // Clear the previous timeout if it exists
            clearTimeout(timeoutId);
        }

        if (file) {
            if (validateVideoType(file) && validateVideoSize(file)) {
                setUploadedVideoName(file.name);
                setUploadedVideoSize(file.size);
                // Handle file upload logic here
                const newTimeoutId = setTimeout(() => {
                    setUploadedVideo(file);
                    setPublicationVideo(file);
                    setIsPublicationVideo(true);
                    setLoadingVideo(false);
                }, 4000);

                // Save the timeout ID for later reference
                setTimeoutId(newTimeoutId);

                const videoObjectURL = URL.createObjectURL(file);
                setVideoUrl(videoObjectURL);
                setShareUrl(videoObjectURL);

            } else {
                setLoadingVideo(false);
                setIsPublicationVideo(true);
            }
        } else {
            setLoadingVideo(false);
            setIsPublicationVideo(false);
        }

    }

    const handleVideoUpload = (e) => {
        const file = e.target.files[0];
        setContentChange(true)

        setLoadingVideo(true);
        setIsPublicationVideo(false);

        if (timeoutId) {
            // Clear the previous timeout if it exists
            clearTimeout(timeoutId);
        }

        if (file) {
            if (validateVideoType(file) && validateVideoSize(file)) {
                setUploadedVideoName(file.name);
                setUploadedVideoSize(file.size);
                setPublicationVideoValidate(false)
                // Handle file upload logic here
                const newTimeoutId = setTimeout(() => {
                    setUploadedVideo(file);
                    setPublicationVideo(file);
                    setIsPublicationVideo(true);
                    setLoadingVideo(false);
                }, 4000);

                // Save the timeout ID for later reference
                setTimeoutId(newTimeoutId);

                const videoObjectURL = URL.createObjectURL(file);
                setVideoUrl(videoObjectURL);
                setShareUrl(videoObjectURL);

            } else {
                setLoadingVideo(false);
                setIsPublicationVideo(true);
            }
        } else {
            setLoadingVideo(false);
            setIsPublicationVideo(false);
        }
    }

    const validateVideoType = (file) => {
        const allowedTypes = [
            'video/mp4',
            'video/quicktime',
            'video/x-msvideo',
            'video/x-ms-wmv',
            'video/vnd.dlna.mpeg-tts',
            'video/webm',
            'video/x-flv',
        ];
        return allowedTypes.includes(file.type);
    };

    const validateVideoSize = (file) => {
        const maxSize = 250 * 1024 * 1024; // 250MB in bytes
        return file.size <= maxSize;
    };


    function humanReadableFileSize(bytes) {
        if (bytes === 0) return '0 B';

        const k = 1024;
        const sizes = ['B', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
    };

    const handleBoxClick = () => {
        setShowUploadMediaPopup(true);
        setUploadMediaPopupFor('video-file');
        setUploadMediaPopupTab('upload');
    };

    const handleVideoLibraryClick = (e) => {
        e.stopPropagation();
        setShowUploadMediaPopup(true);
        setUploadMediaPopupFor('video-file');
        setUploadMediaPopupTab('media');
    };

    const handleCancelUpload = () => {
        // Clear the timeout if the close button is clicked
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        setLoadingVideo(false);
    };

    const getVideoDetails = async (videoId) => {
        if (selectedBox === 'YouTubeBox') {
            try {
                const API_KEY = 'AIzaSyBTB6ekrqQ9XLfkrHE9SmIHb6hMbtIxMZ4';
                const response = await axios.get(
                    `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${API_KEY}`
                );

                if (response.data.items.length > 0) {
                    const videoDetails = response.data.items[0].snippet;

                    if (isSavedVideo) {
                        videoDetails.title = dbPageTitle;
                        videoDetails.description = editorContent;
                    }

                    setVideoData(videoDetails)
                    setdbPageTitle(videoDetails.title);
                    setEditorContent(videoDetails.description);

                    setVideoTitle(videoDetails.title);
                    setVideoDescription(videoDetails.description);

                    setYouTubeTitle(videoDetails.title);
                    setYouTubeDescription(videoDetails.description);
                    if (isImageFromLink) {
                        setPublicationCheckVideoLink({
                            checkBoxStatus: true,
                            imageUrl: videoDetails?.thumbnails?.maxres?.url
                        })
                        setPublicationVideoValidate(false)
                        setYoutubeLinkValidate(false)
                        setVimeoLinkValidate(false)
                    } else {
                        setPublicationCheckVideoLink({
                            checkBoxStatus: false,
                            imageUrl: ""
                        })
                    }

                } else {
                    setdbPageTitle('');
                    setEditorContent('');
                    setVideoData(false);

                    setVideoTitle('');
                    setVideoDescription('');

                    setYouTubeTitle('');
                    setYouTubeDescription('');
                }
            } catch (error) {
                setdbPageTitle('');
                setEditorContent('');
                setVideoData(false);

                setVideoTitle('');
                setVideoDescription('');

                setYouTubeTitle('');
                setYouTubeDescription('');

                console.error('Error fetching video details:', error);
            }
        }

        if (selectedBox === 'VimeoBox') {
            try {
                const ACCESS_TOKEN = '435db997f412e55399fd332847bbdb57';

                const response = await axios.get(`https://api.vimeo.com/videos/${videoId}`, {
                    headers: {
                        Authorization: `Bearer ${ACCESS_TOKEN}`,
                    },
                });

                if (response.data) {
                    const videoDetails = response.data;

                    videoDetails.title = videoDetails.name;

                    if (isSavedVideo) {
                        videoDetails.title = dbPageTitle;
                        videoDetails.description = editorContent;
                    }

                    setVideoData(videoDetails)
                    setdbPageTitle(videoDetails.name);
                    setEditorContent(videoDetails.description);

                    setVideoTitle(videoDetails.name);
                    setVideoDescription(videoDetails.description);

                    setVimeoTitle(videoDetails.name);
                    setVimeoDescription(videoDetails.description);
                    if (isImageFromLink) {
                        setPublicationCheckVideoLink({
                            checkBoxStatus: true,
                            imageUrl: videoDetails?.pictures?.base_link
                        })
                        setPublicationVideoValidate(false)
                    } else {
                        setPublicationCheckVideoLink({
                            checkBoxStatus: false,
                            imageUrl: ""
                        })
                    }
                }
            } catch (error) {
                setdbPageTitle('');
                setEditorContent('');
                setVideoData(false);

                setVideoTitle('');
                setVideoDescription('');

                setVimeoTitle('');
                setVimeoDescription('');

                console.error('Error fetching video details:', error);
            }
        }
    };

    const handleVideoReady = () => {
        // Set loading to false once the video is ready to play
        setIsVideoReady(true);
    };

    return (
        <>
            <div className="newpageLeftRow textInfoBlock">
                <h2>Display a Video</h2>

                <p>
                    Provide a direct link to your content on YouTube or Vimeo, allowing visitors to watch it  on your web profile. Alternatively, for an exclusive viewing experience, upload your own video straight from your computer.
                </p>
                <p>
                    The featured video will be a centerpiece on your profile, offering an engaging multimedia experience that enriches your narrative and complements the existing content.
                </p>
            </div>

            <div className="newpageLeftRow">
                <div className="video-box-group">
                    <div className="video-box-item youtube-box-check">
                        <label>
                            <input
                                type="radio"
                                value="youtube"
                                name="videotype"
                                onClick={() =>{ selectVideoBox('YouTubeBox'); setYoutubeLinkValidate(false)}}
                                checked={selectedBox === 'YouTubeBox'}
                                onChange={() => { }}
                            />
                            <img className="icon-social-small" src={fileYoutubeIcon} alt="Youtube Video Box" />
                            <p>
                                YouTube video
                                <span>Link a video from YouTube by sharing its URL.</span>
                            </p>
                        </label>
                        <div className={`contentDrop ${selectedBox === 'YouTubeBox' ? 'expanded' : ''}`}>
                            <div className="newpageLeftRow newpageCard linkDataBox linkBox newpagefile">
                                <div className="linkData">
                                    <div className="adressInputBlock urlInput">
                                        <img className="adressLink adressLinkLeftInput" src={link} alt="Link" />
                                        <input
                                            className="urlInputLeft"
                                            type="text"
                                            placeholder="Paste the URL of your YouTube video..."
                                            value={videoUrl}
                                            onChange={handleVideoUrlChange}
                                        />
                                    </div>
                                    {selectedBox === 'YouTubeBox' && youtubeLinkValidate &&  videoUrl === '' &&
                                        <p className="validateError">Please paste a YouTube URL</p>
                                    }

                                    {selectedBox === 'YouTubeBox' && !isValidURL && videoUrl !== '' &&
                                        <p className="validateError">Please enter a YouTube video URL to share</p>
                                    }

                                    {
                                        isValidURL && isVideoLoad &&
                                        <div className="skeleton-video-wrapper"><div className="skeleton-video"></div></div>
                                    }

                                    {
                                        isValidURL && isVideoLoaded &&
                                        <>
                                            {
                                                !isVideoReady &&
                                                <div className="skeleton-video-wrapper"><div className="skeleton-video"></div></div>

                                            }
                                            <div className={(isVideoReady) ? 'player-wrapper player-ready' : 'player-wrapper'}>
                                                <ReactPlayer
                                                    className='react-player'
                                                    url={videoUrl}
                                                    width='100%'
                                                    height='100%'
                                                    controls
                                                    onReady={handleVideoReady}
                                                />
                                            </div>
                                            <p className="label" >Video title</p>
                                            <textarea id="linkDataTitle" className="linkDataTitle" placeholder="Enter a title" ref={YouTubevideoTitleRef} onChange={(e) => handleVideoTitleInput(e)} onBlur={(e) => handleVideoTitleBlur(e)} value={videoTitle}></textarea>
                                            {validateTitle && (
                                                <p className="validateError">Please enter a title</p>
                                            )}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="video-box-item vimeo-box-check">
                        <label>
                            <input
                                type="radio"
                                value="vimeo"
                                name="videotype"
                                onClick={() => {selectVideoBox('VimeoBox'); setVimeoLinkValidate(false)}}
                                checked={selectedBox === 'VimeoBox'}
                                onChange={() => { }}
                            />
                            <img className="icon-social-small" src={fileVimeoIcon} alt="Vimeo Video Box" />
                            <p>
                                Vimeo video
                                <span>Link a video from Vimeo by sharing its URL.</span>
                            </p>
                        </label>
                        <div className={`contentDrop ${selectedBox === 'VimeoBox' ? 'expanded' : ''}`}>
                            <div className="newpageLeftRow newpageCard linkDataBox linkBox newpagefile">
                                <div className="linkData">
                                    <div className="adressInputBlock urlInput">
                                        <img className="adressLink adressLinkLeftInput" src={link} alt="Vimeo Link" />
                                        <input
                                            className="urlInputLeft"
                                            type="text"
                                            placeholder="Paste the URL of your Vimeo video..."
                                            value={videoUrl}
                                            onChange={handleVideoUrlChange}
                                        />
                                    </div>
                                    {selectedBox === 'VimeoBox' && vimeoLinkValidate && videoUrl === '' &&
                                        <p className="validateError">Please paste a Vimeo URL</p>
                                    }
                                    {selectedBox === 'VimeoBox' && !isValidURL && videoUrl !== '' &&
                                        <p className="validateError">Please enter a Vimeo video URL to share</p>
                                    }

                                    {
                                        isValidURL && isVideoLoad &&
                                        <div className="skeleton-video-wrapper"><div className="skeleton-video"></div></div>
                                    }

                                    {
                                        isValidURL && isVideoLoaded &&
                                        <>
                                            {
                                                !isVideoReady &&
                                                <div className="skeleton-video-wrapper"><div className="skeleton-video"></div></div>

                                            }
                                            <div className={(isVideoReady) ? 'player-wrapper player-ready' : 'player-wrapper'}>
                                                <ReactPlayer
                                                    className='react-player'
                                                    url={videoUrl}
                                                    width='100%'
                                                    height='100%'
                                                    controls
                                                    onReady={handleVideoReady}
                                                />
                                            </div>

                                            <p className="label" >Video title</p>
                                            <textarea id="linkDataTitle" className="linkDataTitle" placeholder="Enter a title" ref={VimeovideoTitleRef} onChange={(e) => handleVideoTitleInput(e)} onBlur={(e) => handleVideoTitleBlur(e)} value={videoTitle}></textarea>
                                            {validateTitle && (
                                                <p className="validateError">Please enter a title</p>
                                            )}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="video-box-item uploded-video-box-check">
                        <label>
                            <input
                                type="radio"
                                value="video"
                                name="videotype"
                                onClick={() => {
                                    if (billingDetails?.subscriptions?.plan === "free_plan") {
                                        setShowUpgradeModel(true)
                                    } else {
                                        selectVideoBox('VideoBox'); 
                                        setPublicationCheckVideoLink({
                                            checkBoxStatus: false,
                                            imageUrl: ""
                                        })
                                        setPublicationVideoValidate(false)
                                        setValidateTitle(false)
                                    }
                            }
                            }
                                checked={selectedBox === 'VideoBox'}
                                onChange={() => { }}
                            />
                            <img className="icon-social-small" src={fileVideoIcon} alt="Uploaded Video Box" />
                            
                            {
                                billingDetails?.subscriptions?.plan === "free_plan" ?
                                    <div className="upgrade-box" onClick={() => {setShowUpgradeModel(true)}}>
                                        <p>
                                            Upload video
                                            <span>Upload a new video directly from your computer.</span>
                                        </p>
                                        <button className="primary-button minwidthAuto button" onClick={() => {setShowUpgradeModel(true)}} >
                                            Upgrade
                                        </button>
                                    </div>
                                    :
                                    <div className="upgrade-box">
                                        <p>
                                            Upload video
                                            <span>Upload a new video directly from your computer.</span>
                                        </p>
                                    </div>
                            }

                        </label>
                        <div className={`contentDrop ${selectedBox === 'VideoBox' ? 'expanded' : ''}`}>
                            <div className="newpageLeftRow newpageCard linkDataBox linkBox newpagefile">
                                <div className="linkData">
                                    <p className="label" >Video title</p>
                                    <textarea id="linkDataTitle" className="linkDataTitle" placeholder="Enter a title" ref={uploadedvideoTitleRef} onChange={(e) => handleVideoTitleInput(e)} onBlur={(e) => handleVideoTitleBlur(e)} value={videoTitle}></textarea>
                                    {validateTitle && (
                                        <p className="validateError">Please enter a title</p>
                                    )}
                                </div>
                            </div>

                            <div className="newpageLeftRow textBlock">
                                <div className={isVideoDragging ? `draging fileUploadBox` : `fileUploadBox`}
                                    onDragOver={handleVideoDragOver}
                                    onDragLeave={handleVideoDragLeave}
                                    onDrop={handleVideoDrop}
                                >
                                    <input
                                        type="file"
                                        id="vidoe-publication"
                                        onChange={handleVideoUpload}
                                        accept=".mp4, .mov, .avi, .wmv, .avchd, .webm, .flv"
                                        hidden
                                    />

                                    {loadingVideo === true ? (
                                        <div className="uploadFileBlock preloadFile">
                                            <div className="uploadFileBlockDetails">
                                                <div className="fileUploadedCover_wrapp">
                                                    <img
                                                        className="fileUploadedCover_Img"
                                                        src={filmIcon}
                                                        alt="FeaturedImage"
                                                    />
                                                </div>

                                                <p className="uploaded-file-name">
                                                    {uploadedVideoName}
                                                </p>
                                                <p className="uploaded-file-size">
                                                    {humanReadableFileSize(uploadedVideoSize)}
                                                </p>
                                            </div>
                                            <div className="featureImg-loader loadingCloseWrap">
                                                <Loader />
                                                <div className="loadingclose" onClick={handleCancelUpload}>
                                                    <img src={closeIcon} alt="icon-close" />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="uploadFileBlock" onClick={handleBoxClick} >
                                            {isPublicationVideo === true ? (
                                                <div className="featureImgMiddleContent fileUploadedCover">
                                                    <div className="fileUploadedCover_wrapp">
                                                        <img
                                                            className="fileUploadedCover_Img"
                                                            src={filmIcon}
                                                            alt="FeaturedImage"
                                                        />
                                                    </div>
                                                    <p className="uploaded-file-name">
                                                        {uploadedVideoName}
                                                    </p>
                                                    <p className="uploaded-file-size">
                                                        {humanReadableFileSize(uploadedVideoSize)}
                                                    </p>
                                                    <p className="newPageImageUploadText">
                                                        <span>Upload</span> or <span onClick={handleVideoLibraryClick}>Open Video Library</span> to replace
                                                        <br />
                                                        MP4, MOV, AVI, WMV, AVCHD, WebM, FLV (max. 128MB)
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="featureImgMiddleContent">
                                                    <img
                                                        className="fileUploadCover_Img"
                                                        src={uploadCloud}
                                                        alt="FeaturedImage"
                                                    />
                                                    <p className="newPageImageUploadText">
                                                        <span>Upload</span> or <span onClick={handleVideoLibraryClick}>Open Video Library</span>
                                                        <br />
                                                        MP4, MOV, AVI, WMV, AVCHD, WebM, FLV (max. 128MB)
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {videoUrl !== '' && isPublicationVideo === true &&
                                <div className="newpageLeftRow textBlock">
                                    {
                                        !isVideoReady &&
                                        <div className="skeleton-video-wrapper"><div className="skeleton-video"></div></div>

                                    }
                                    <div className={(isVideoReady) ? 'player-wrapper player-ready' : 'player-wrapper'}>
                                        <ReactPlayer
                                            className='react-player'
                                            url={videoUrl}
                                            width='100%'
                                            height='100%'
                                            controls
                                            onReady={handleVideoReady}
                                        />
                                    </div>
                                </div>
                            }
                            {selectedBox === 'VideoBox' && publicationVideoValidate && videoUrl === '' &&
                                <p className="validateError">Please choose a video to share</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VideoPublication;