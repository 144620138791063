import { useEffect, useState } from 'react';


import { formatNumber, formattedDate } from '../utils/functions';
import currencies from '../utils/currency.json'

import '.././App.css';
import GoogleAdsense from '../images/Settings/adSense.png'
import GoogleAdsenseBig from '../images/Settings/GoogleAdsenseBig.png'
import up from '../images/up-image.svg'
import down from '../images/down-image.svg'
function MonetizationAnalyticsBoxes({stripeBalance, aggregateData}) {
    const [totalPayoutRate, setTotalPayoutRate] = useState(0)
    useEffect(() => {
        setTotalPayoutRate(aggregateData?.totalPayout?.change || 0)
    }, [aggregateData]);
    return (
        <div className="dashboardBoxes dashboard-wrap monetizationBox">
            <div className='dashboardUserbox dashboardUserboxFlex'>
                <span>
                    <p className='dashUserStatus'>Total earnings</p>
                    <h2>{currencies[stripeBalance?.currency?.toUpperCase()]?.symbol_native || '$'}{(formatNumber(stripeBalance.totalEarnings > 0 ? stripeBalance.totalEarnings/100 : 0))} {stripeBalance?.currency?.toUpperCase() || 'USD'}</h2>
                    
                        <span className={`graphPercent ${totalPayoutRate !== 0 && (totalPayoutRate >= 0? 'greenPercent' : 'redPercent') }`}>
                            {totalPayoutRate !== 0 ? (
                            totalPayoutRate >= 0 ? (
                            <img src={up} alt="Up" className="svg-icon" />
                            ) : (
                            <img src={down} alt="Down" className="svg-icon" />
                            )) : (
                            "〰"
                            )}
                            {Math.abs(totalPayoutRate)}%
                        </span>
                    
                </span>
                {/* <TotalEarningChart /> */}
            </div>

            <div className='dashboardUserbox'>
                <p className='dashUserStatus'>Total balance</p> 
                <h2>{currencies[stripeBalance?.currency?.toUpperCase()]?.symbol_native || '$'}{(formatNumber(stripeBalance.totalBalance > 0 ? stripeBalance.totalBalance/100 : 0))} {stripeBalance?.currency?.toUpperCase() || 'USD'}</h2>
            </div>

            <div className='dashboardUserbox'>
                <p className='dashUserStatus'>Future payouts</p>
                <h2>{currencies[stripeBalance?.currency?.toUpperCase()]?.symbol_native || '$'}{(formatNumber(stripeBalance.futureBalance > 0 ? stripeBalance.futureBalance/100 : 0))} {stripeBalance?.currency?.toUpperCase() || 'USD'}</h2>
                {(stripeBalance.futureBalance > 0 && stripeBalance.expectedDate) && 
                    <span className='graphPercent payoutDate'>
                        {formattedDate(stripeBalance.expectedDate)}
                    </span>
                }
            </div>
            <div className='dashboardUserbox dashboardGoogleAdSenseBox'>
                <div>
                    <p className='dashUserStatus'>Connect your Google AdSense account to start earning revenue from ads</p>
                    {/* <p className='dashUserStatus'>Login in to your Google AdSense account to view your ad monetization performance</p> */}
                    <div className='google-adSense button'>
                        <img src={GoogleAdsense} alt="Google AdSense" className='stripeSize' />
                        <p className='senseText'>Connect Google AdSense</p>
                        {/* <p className='senseText'>Log in to Google AdSense</p> */}
                    </div>
                </div>
                <div>
                    <img src={GoogleAdsenseBig} alt="Google AdSense" className='googleAdSenseBig' />
                </div>
            </div>
        </div>
    );
}

export default MonetizationAnalyticsBoxes;
