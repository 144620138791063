import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import close from '../images/Settings/close.svg'

function DeleteAccountModal({ isOpen, onClose,stripeData }) {
    const [password, setPassword] = useState("");
    const [deleteModal, setdeleteModal] = useState(true);
    const [validatePassword, setValidatePassword] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    if (!isOpen) {
        return null;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(isLoading || ( stripeData?.details_submitted === true && stripeData?.stripeAccountid !== "" )) {
            return; 
        }

        try {
            if (password.length > 0) {
                setIsLoading(true);
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/removeaccount/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    { password: password }
                );
                setdeleteModal(false);
                document.body.classList.remove('hidden-message');
                localStorage.removeItem("userinfo");
                localStorage.removeItem("isCompleted");
                localStorage.removeItem("isInvited");
                localStorage.removeItem("userProfileSlug");
                sessionStorage.removeItem("userId");
                Cookies.remove('rememberMe');  
                window.location.href = "/sign-in";
            } else {
                setValidatePassword(true)
            }
            setIsLoading(false);
            setPassword("");
        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                setError(true);
            } else {
                console.log(error, "error");
            }
            return false;
        }
    }


    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (e.target.value.length > 0) {
            setValidatePassword(false);
            setError(false);
        } else {
            setValidatePassword(true);
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            {deleteModal && (<div className='welcomeModal'>
                <div className="popupTop popupTopClose closePlan">
                        <img src={close} onClick={onClose} alt="Icon" />
                    </div>
                <h3 className='popupTitle'>Delete your account</h3>
                <p className='popupDesc'>We're sorry to hear that you're considering deleting your account. <br /> <br />
                    Please be aware that deleting your account is a permanent action and cannot be undone. All your profile, data, settings, and preferences will be irreversibly removed from our system. If you are sure about this decision, proceed with caution. Should you ever wish to return in the future, you would need to start anew.
                </p>

                <div className='welcomeForm'>
                    <label className='welcomeLabel'>Password</label>
                    <input
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                        className="welcomeInput"
                        autoComplete="new-password"
                        aria-autocomplete="none"
                        autoCorrect="off"
                        autoCapitalize="off"
                        autoFocus={true}
                        value={password}
                        disabled={(stripeData?.details_submitted === true && stripeData?.stripeAccountid !== "") ? true : false}
                        onChange={handlePasswordChange} />
                    {validatePassword === true ? (
                        <p className="validateError">The field is not filled</p>
                    ) : (
                        ""
                    )}
                    {error === true ? (
                        <p className="validateError">Incorrect password, please try again</p>
                    ) : (
                        ""
                    )}
                </div>
                {
                    (stripeData?.details_submitted === true && stripeData?.stripeAccountid !== "") && <p className="validateError">Before deleting your account, you need to disconnect your Stripe account</p>
                }
                <div className="welcomeModalbtn">
                    <button className="cancel secondary-button button" type="button" onClick={onClose}>Cancel</button>
                    <button className={`savePicture primary-button button ${(stripeData?.details_submitted === true && stripeData?.stripeAccountid !== "") ? `welcomeModalbtnDisabled` :""}`} type="submit" disabled={(stripeData?.details_submitted === true && stripeData?.stripeAccountid !== "") ? true : false}>
                        {isLoading ?
                            <div className="settingPage-loader">
                                <div className="ring-loader"></div>
                            </div>
                            : "Delete"}</button>
                </div>
            </div>)}
        </form>
    )
}
export default DeleteAccountModal