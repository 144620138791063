import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import Dashboard from './Pages/Dashboard';
import Register from './Pages/Register';
import ErrorPage from './Pages/Error';
import Login from './Pages/Login';
import ForgotPassword from './Pages/ForgotPassword';
import NewPass from './Pages/NewPass';
import RegisterNotification from './Pages/RegisterNotification';
import './App.css';

function App() {
  
  // Retrieving user info and completion status from localStorage

  const isCompleted = localStorage.getItem("isCompleted") ? JSON.parse(localStorage.getItem("isCompleted")) : true;
  const rememberMe = Cookies.get('rememberMe') === 'true';
  return (
    <section className='board' >
      <BrowserRouter>
        <Routes>
        <Route path="/" element={ rememberMe ? ( isCompleted ? <Dashboard /> : <RegisterNotification /> ) :  ( <Navigate to="/sign-in" /> ) } />
          {/* Route for '/' */}
          <Route path="/*" element={ rememberMe ? ( isCompleted ? <Dashboard /> : <RegisterNotification /> ) : ( <Navigate to="/sign-in" /> ) } />
          {/* Route for dashboard sub-pages */}
          <Route path="/dashboard/*" element={ rememberMe ? ( isCompleted ? <Dashboard /> : <RegisterNotification /> ) : ( <Navigate to="/sign-in" /> ) } />
          {/* Route for forgot password */}
          <Route path="/forgot-password" element={ rememberMe ? ( isCompleted ? <Navigate to="/dashboard" /> : <RegisterNotification /> ) : ( <ForgotPassword /> ) } />
          {/* Route for setting new password */}
          <Route path="/setnewpassword" element={ rememberMe ? ( isCompleted ? <Navigate to="/dashboard" /> : <RegisterNotification /> ) : ( <NewPass /> ) } />
          {/* Route for registration pages */}
          <Route path="/sign-up/*" element={ rememberMe ? ( isCompleted ? <Navigate to="/dashboard" /> : <RegisterNotification /> ) : ( <Register /> ) } />
          {/* Route for login */}
          <Route path="/sign-in" element={ rememberMe ? ( isCompleted ? <Navigate to="/dashboard" /> : <Login /> ) : ( <Login /> ) } />
          {/* Route for any other undefined paths */}
          <Route path="*" element={isCompleted ? <ErrorPage /> : <RegisterNotification />} />
        </Routes>
      </BrowserRouter>
    </section >
  );
}

export default App;