import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useState } from 'react';
import axios from 'axios';

const TwitterNodeView = ({ node, editor }) => {
  const [twitterUrl, setTwitterUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    setTwitterUrl(e.target.value.trim());
    setErrorMessage('');
  };

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter' && twitterUrl !== '') {
      const twitterPattern = /^(https?:\/\/)?(www\.)?twitter\.com\/.+\/status\/.+$/;
      const twitterXPattern = /^(https?:\/\/)?(www\.)?x\.com\/.+\/status\/.+$/;

      if (twitterPattern.test(twitterUrl) || twitterXPattern.test(twitterUrl)) {
        const twitterData = await embedTweet();
        editor.chain().focus().setTwitterBlock({ twitterUrl: twitterUrl, twitterData: twitterData }).run();
        setErrorMessage('');
      } else {
        setErrorMessage('Please enter a valid URL');
      }
    }
  };

  const embedTweet = async () => {
    setIsLoading(true)
    
    try {
      if (twitterUrl) {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/pages/scrapeTweet`,
          { tweetUrl: twitterUrl }
        );
        if (res?.status === 200) {
          const twitterData = res.data.data; 

          return twitterData;
        }
      }
    } catch (error) {
      setErrorMessage("An error occurred while loading the tweet.");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <NodeViewWrapper>
      <div data-type="twitter" className="twitter-node-wrapper" data-drag-handle>
        <label>
          <div className='urlInput'>
            {isLoading ? (
              <div className='linkbutton-loader loader-buttonHeight'>
                <div className='ring-loader'></div>
              </div>
            ) : (
              <>
                <svg width='20' height='20' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <rect width='14' height='14' rx='7' fill='#0A0A0A' />
                  <path d='M7.58666 6.53049L9.8801 3.92188H9.3367L7.3444 6.18643L5.75432 3.92188H3.91992L6.32498 7.34658L3.91992 10.0819H4.46332L6.56595 7.68987L8.24553 10.0819H10.0799L7.58666 6.53049ZM6.84215 7.37664L6.59809 7.03541L4.65929 4.32273H5.49406L7.0592 6.51276L7.30223 6.854L9.33644 9.70055H8.50167L6.84215 7.37664Z' fill='white' />
                </svg>
                <input
                  className="urlInputLeft"
                  type="text"
                  placeholder="Paste X (formerly Twitter) URL to add embedded content"
                  value={twitterUrl}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                />
                {
                  errorMessage &&
                  <p className="error-message">{errorMessage}</p>
                }
              </>)
            }
          </div>
        </label>
      </div>
    </NodeViewWrapper>
  );
};

export const TwitterEmbeds = Node.create({
  name: 'twitter',

  isolating: true,
  defining: true,
  group: 'block',
  draggable: true,
  selectable: true,
  inline: false,

  addAttributes() {
    return {
      twitterUrl: { default: '' },
      twitterData: { default: {} },
    };
  },

  parseHTML() {
    return [
        {
            tag: `div[data-type="${this.name}"]`,
        },
    ];
  },

    renderHTML({ HTMLAttributes }) {
      return [
        'div',
        { 'data-type': this.name, ...HTMLAttributes, class: 'twitter-block' },
      ];
    },

    addCommands() {
        return {
            setTwitterEmbeds: (options) => ({ commands }) =>
                commands.insertContent(`<div data-type="${this.name}" data-twitter-url="${options?.twitterUrl}" data-twitter-data="${options?.twitterData}"></div>`),
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(TwitterNodeView);
    },
});

