import React, { useEffect, useState } from "react";
import axios from 'axios';

import NoResults from "./NoResults";
import '.././App.css';

import { Link } from "react-router-dom";

import { formatNumber } from "../utils/functions";

import defaulPicture from '.././images/defaulPicture.svg'
import Copy from '.././images/icons-copy.svg'
import Facebook from '.././images/icon-facebook.svg'
import Twitter from '.././images/icon-twitter.svg'
import Linkdin from '.././images/icon-linkedin.svg'
import WhatsApp from '.././images/icon-whatsapp.svg'
import up from '../images/up-image.svg'
import down from '../images/down-image.svg'

function foramteDate(date) {
    var newDate = new Date(date);

    var options = { year: 'numeric', month: 'short', day: 'numeric' };

    return newDate.toLocaleDateString('en-US', options);
}

function LatesInfoBox({ openRate, latestNewsLatterDeliveredCount, latestNewsLatterOpenedCount, latestPublicationPageviews, latestPublicationVisitors }) {

    const [latestPublication, setLatestPublication] = useState();
    const [latestNewsLatter, setLatestNewsLatter] = useState();
    const [tooltipVisible, setTooltipVisible] = useState(false); 

    const profileSlug = localStorage.getItem('userProfileSlug')
    const baseUrl = `https://jourmal-next-front.vercel.app/${profileSlug}/`;
    const [urlInput, setURLInput] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
              const latestInfoResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/getLatestInfo/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
              );

              setLatestPublication( latestInfoResponse.data.result.latestPublication );
              setLatestNewsLatter( latestInfoResponse.data.result.latestNewsletter );
              setURLInput(`${baseUrl}${latestInfoResponse.data.result.latestPublication?.url_address || latestInfoResponse.data.result.latestPublication?.title }`)
              
            } catch (error) {
              console.log(error, "error");
            }
          }

        fetchData();
    }, []);
    
    const handleCopyUrl = () => {
        if (navigator?.clipboard && navigator?.clipboard?.writeText) {
            // Use clipboard API if available
            navigator.clipboard.writeText(urlInput)
                .then(() => {
                    setTooltipVisible(true);
                    setTimeout(() => setTooltipVisible(false), 2000);
                })
                .catch((err) => console.error("Failed to copy text to clipboard:", err));
        } else {
            // Fallback for unsupported devices/browsers
            const tempInput = document.createElement("input");
            tempInput.value = urlInput;
            document.body.appendChild(tempInput);
            tempInput.select();
            tempInput.setSelectionRange(0, 99999); // For mobile compatibility
            document.execCommand("copy");
            document.body.removeChild(tempInput);
    
            setTooltipVisible(true);
            setTimeout(() => setTooltipVisible(false), 2000);
        }
    };

    const handleFacebookShare = () => {
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlInput)}`;
        window.open(facebookShareUrl, '_blank');
    }

    const handleTwitterShare = () => {
        const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(urlInput)}&text=${encodeURIComponent("Check this out!")}`;
        window.open(twitterShareUrl, "_blank");
    };

    const handleLinkedInShare = () => {
        const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(urlInput)}`;
        window.open(linkedInShareUrl, "_blank");
    };

    const handleWhatsAppShare = () => {
        const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(urlInput)}`;
        window.open(whatsappShareUrl, "_blank");
    };

    return (
        <>
            <div className="LatesInfo">                      
                <div className="LatesBox">
                
                    {latestPublication ?
                        <>
                            <div className="LatesInfoBox">
                                <p className='homePageInfoboxTitle'>Latest publication</p>
                                <div className="LatesInfoNotification">
                                    <div className="LatesInfoImg">
                                        <img src={latestPublication?.featured_image || defaulPicture} alt={latestPublication?.title || 'Feature Image'} />
                                    </div>
                                    <div className="LatesInfoContent">
                                        <p className='LatesInfoTitel'>
                                            <Link className='editTitle' to={`/dashboard/publication/${latestPublication?._id}`}>{latestPublication?.title}</Link>
                                        </p>
                                        <p className="LatesInfoDate">{foramteDate(latestPublication?.createdAt)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="analyticsButtons">
                                <div className="graphButton greenPercent">
                                    <p className="analyticsButtonTitle">
                                        Visitors
                                    </p>
                                    <div>
                                        <p className="graphNumber">{formatNumber(latestPublicationVisitors ? latestPublicationVisitors?.value : 0)}</p>
                                        <span className={`graphPercent ${(latestPublicationVisitors?.change !== undefined && latestPublicationVisitors?.change !== 0) && (latestPublicationVisitors?.change >= 0 ? 'greenPercent' : 'redPercent')}`}>
                                            {latestPublicationVisitors?.change !== undefined && latestPublicationVisitors?.change !== 0 ? (
                                                latestPublicationVisitors?.change >= 0 ? (
                                                    <img src={up} alt="Up" className="svg-icon" />
                                                ) : (
                                                    <img src={down} alt="Down" className="svg-icon" />
                                                )
                                            ) : (
                                                "〰"
                                            )}
                                            
                                            {latestPublicationVisitors ? Math.abs(latestPublicationVisitors?.change) : 0}%
                                        </span>

                                    {/* </span> */}
                                </div>
                            </div>
                            <div className="graphButton">
                                <p className="analyticsButtonTitle">Pageviews</p>
                                <div>
                                    <p className="graphNumber">{formatNumber(latestPublicationPageviews ? latestPublicationPageviews?.value : 0)}</p>
                                    <span className={`graphPercent ${(latestPublicationPageviews?.change !== undefined && latestPublicationPageviews?.change !== 0) && (latestPublicationPageviews?.change >= 0 ? 'greenPercent' : 'redPercent')}`}>
                                    { latestPublicationPageviews?.change !== undefined && latestPublicationPageviews?.change !== 0 ? (
                                                latestPublicationPageviews?.change >= 0 ? (
                                                    <img src={up} alt="Up" className="svg-icon" />
                                                ) : (
                                                    <img src={down} alt="Down" className="svg-icon" />
                                                )
                                            ) : (
                                                "〰"
                                            )}
                                        {latestPublicationPageviews ? Math.abs(latestPublicationPageviews?.change) : 0}%
                                    </span>
                                </div>
                            </div>
                            <div className="graphButton graphShareButton">
                                <p className="analyticsButtonTitle">Share:</p>
                                <div>
                                    <div className="LatesInfoIcon">
                                        <span><img src={Copy} alt="copyLogo" onClick={handleCopyUrl} className="copy-icon" /></span>
                                        <span><img src={Facebook} alt="facebookLogo" onClick={handleFacebookShare} className="facebook-icon" /></span>
                                        <span><img src={Twitter} alt="twitterLogo" onClick={handleTwitterShare} className="twitter-icon" /></span>
                                        <span><img src={Linkdin} alt="LinkdinLogo" onClick={handleLinkedInShare} className="linkedin-icon" /></span>
                                        <span><img src={WhatsApp} alt="whatsAppLogo" onClick={handleWhatsAppShare} className="whatsapp-icon" /></span>
                                        {tooltipVisible && <div className="text-tooltip">URL Copied!</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                </>
                :
                <>
                    <NoResults type="publication" name="publication" status="all" hideIcon={false} />
                </>
                    }
                </div>

                <div className="LatesBox newslatterBox">
                    { latestNewsLatter ? 
                        <>
                            <div className="LatesInfoBox">
                                <p className='homePageInfoboxTitle'>Latest newsletter</p>
                                <div className="LatesInfoNotification">
                                    <div className="LatesInfoImg">
                                    <img src={latestPublication?.featured_image || defaulPicture} alt={latestNewsLatter?.subject || 'Feature Image'} />
                                    </div>
                                    <div className="LatesInfoContent">
                                        <p className='LatesInfoTitel'>
                                            <Link className='editTitle' to={`/dashboard/newsletter/${latestNewsLatter?._id}`}>{latestNewsLatter?.subject}</Link>
                                        </p>
                                        <p className="LatesInfoDate">{foramteDate(latestNewsLatter?.createdAt)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="analyticsButtons">
                                <div className="graphButton greenPercent">
                                    <p className="analyticsButtonTitle">Recipients</p>
                                    <div>
                                        <p className="graphNumber">{formatNumber(latestNewsLatterDeliveredCount ? latestNewsLatterDeliveredCount : 0)}</p>
                                    </div>
                                </div>
                                <div className="graphButton">
                                    <p className="analyticsButtonTitle">Visitors</p>
                                    <div>
                                        <p className="graphNumber">{formatNumber(latestNewsLatterOpenedCount ? latestNewsLatterOpenedCount : 0)}</p>
                                    </div>
                                </div>
                                <div className="graphButton">
                                    <p className="analyticsButtonTitle">Open rate</p>
                                    <div>
                                        <p className="graphNumber">{openRate ? openRate : 0}%</p>
                                    </div>
                                </div>

                            </div>
                        </>
                        :
                        <>
                            <NoResults type="newsletter" name="Newsletters" status="all" hideIcon={false} />
                        </>
                    }                    
                </div>
            </div>
        </>
    );
}

export default LatesInfoBox;
