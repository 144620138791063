
function NegativeBalancePopup ({paymentData}){
    return(
        <>
            <div className='negativeBalance' >
                <h3 className='popupTitle balanceTitle'>Payment for negative balance</h3>
                <p className='popupDesc balanceText'> Your stripe account currently has a negative balance. To proceed with deleting your account, you    need to first settle the outstanding balance.</p>
                <p className='popupDesc balanceText'>Please make a payment of <span>${paymentData?.result?.amount} USD</span> to bring your balance to zero. Once your balance is cleared, we will be able to process the account deletion. </p>
                <a  href={paymentData?.result?.url} target="_blank" className="loginButton balanceBtn">
                    Pay Now
                </a>
            </div>        
        </>
    )
}

export default NegativeBalancePopup ;