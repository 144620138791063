
import React, { useEffect, useState } from "react";
import {  useLocation } from 'react-router-dom';
import axios from "axios";
import { Helmet } from 'react-helmet';

import RegisterNotification from './RegisterNotification';
import Step1 from '../Components/Register/Step1';
import Step2 from '../Components/Register/Step2';
import Step3 from '../Components/Register/Step3';
import Step4 from '../Components/Register/Step4';
import Step5 from '../Components/Register/Step5';
import Step6 from '../Components/Register/Step6';
import Done from '../Components/Register/Done';
import BgFormAnimate from './BgFormAnimate';
import InvitedUSerFirstStep from "../Components/Register/InvitedUSerFirstStep";


import '../App.css';

function Register(props) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const email = searchParams.has("email") ? searchParams.get("email") : '';
    const invitedFrom = searchParams.has("invitedFrom") ? searchParams.get("invitedFrom") : '';
    const isInvited = searchParams.has("email") ? true : false;

    const [activeStep, setActiveStep] = useState(searchParams.get("isInvited") ? 2 : 1)
    const [isLoaded, setIsLoaded] = useState(false);
    const [tokenVerify, setTokenVerify] = useState(searchParams.get("isInvited")? true : false);
    const [isCompleted, setIsCompleted] = useState((localStorage.getItem("isCompleted")) ? JSON.parse(localStorage.getItem("isCompleted")) : true);
    const invited = (localStorage.getItem("isInvited")) ? JSON.parse(localStorage.getItem("isInvited")) : false;

    const [showInvitation, setShowInvitation] = useState(false);

    const [statusReg, setStatusReg] = useState([
        {
            step: 1,
            status: 'active',
            data: []
        },
        {
            step: 2,
            status: 'inActive',
            data: []
        },
        {
            step: 3,
            status: 'inActive',
            data: []
        },
        {
            step: 4,
            status: 'inActive',
            data: []
        },
        {
            step: 5,
            status: 'inActive',
            data: []
        },
        {
            step: 6,
            status: 'inActive',
            data: []
        },
        {
            step: 7,
            status: 'inActive',
            data: []
        }
    ])

    useEffect(() => {
        if (activeStep > 0 && statusReg[activeStep - 1].status !== 'active') {
            statusReg[activeStep - 1].status = 'active'
        }
        setStatusReg(statusReg)

    }, [activeStep])

    useEffect(() => {
        // token verify function
        let userData = {};
        if (isInvited) {
            setShowInvitation(true)
        }

        if(searchParams.has("isInvited")) {
            setActiveStep(2);
        }

        if (token) {
            const tokenVerify = () => {
                axios.put(`${process.env.REACT_APP_API_URL}/user/verifytoken?token=${token}`)
                    .then((response) => {
                        if (response.status == 200) {
                            setIsLoaded(true);
                            setTokenVerify(true);
                            setActiveStep(2);
                            userData = response.data.userMeta;

                            localStorage.setItem("userId", userData.user_id);

                            setStatusReg(prevStatusReg => {
                                const newData = [...prevStatusReg]; // Create a copy of the array

                                const newDataItem2 = { ...newData[1] }; // Create a copy of the object to be updated
                                newDataItem2.data[0] = { displayName: userData.displayName, firstName: userData.firstName }; // Update the data at the specified index
                                newData[1] = newDataItem2; // Update the array with the modified object

                                const newDataItem3 = { ...newData[2] }; // Create a copy of the object to be updated
                                newDataItem3.data[0] = { email: userData.email }; // Update the data at the specified index
                                newData[2] = newDataItem3; // Update the array with the modified object

                                return newData; // Set the updated array as the new state
                            });
                        }
                    })
                    .catch((err) => {
                        setIsLoaded(true);
                        console.log(err.response)
                    });
            };
            tokenVerify();
        } else {
            setIsLoaded(true);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Sign Up | Persone</title>
            </Helmet>
            {
                !isLoaded ?
                    <BgFormAnimate />
                    :
                    (
                        !isCompleted && !tokenVerify && !invited ?
                            <RegisterNotification />
                            :
                            <>

                                {
                                    activeStep == 1 &&
                                    <BgFormAnimate />
                                }

                                <div className={' form-design  register-wrapp registertion-step-' + activeStep}>
                                    <div className="register">
                                        <div className="register-header">
                                            {(activeStep !== 1) &&
                                                <div className='regStatus_mobile'>
                                                    <div className='regStatus_block'>
                                                        <div className={(activeStep === 2) ? 'regStatus_pointActive' : ''}>

                                                        </div>
                                                        <div className={(activeStep === 3) ? 'regStatus_pointActive' : ''}>

                                                        </div>
                                                        <div className={(activeStep === 4) ? 'regStatus_pointActive' : ''}>

                                                        </div>
                                                        <div className={(activeStep === 5) ? 'regStatus_pointActive' : ''}>

                                                        </div>
                                                        <div className={(activeStep === 6) ? 'regStatus_pointActive' : ''}>

                                                        </div>
                                                        <div className={(activeStep === 7) ? 'regStatus_pointActive' : ''}>

                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                (activeStep === 1) ? ( !showInvitation ? ( <Step1 step={setActiveStep} addData={setStatusReg} activeStep={activeStep} data={statusReg} setIsCompleted={setIsCompleted} email={email} isInvited={isInvited} /> ) : ( <InvitedUSerFirstStep setShowInvitation={setShowInvitation} invitedFrom={invitedFrom} /> ) ):
                                                    (activeStep === 2) ? <Step2 step={setActiveStep} addData={setStatusReg} activeStep={activeStep} data={statusReg} /> :
                                                        (activeStep === 3) ? <Step3 step={setActiveStep} addData={setStatusReg} activeStep={activeStep} data={statusReg} /> :
                                                            (activeStep === 4) ? <Step4 step={setActiveStep} addData={setStatusReg} activeStep={activeStep} data={statusReg} /> :
                                                                (activeStep === 5) ? <Step5 step={setActiveStep} addData={setStatusReg} activeStep={activeStep} data={statusReg} /> :
                                                                     (activeStep === 6) ? <Step6 step={setActiveStep} addData={setStatusReg} activeStep={activeStep} data={statusReg} /> :
                                                                        (activeStep === 7) ? <Done step={setActiveStep} addData={setStatusReg} activeStep={activeStep} data={statusReg} setIsCompleted={setIsCompleted} /> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                    )

            }
        </>
    );
}

export default Register;