import React, { useEffect, useRef, useState } from 'react'
import axios from "axios";
import close from '../../images/Settings/close.svg'
function DeleteSubscriberModel(props) {
    const { isOpen, onClose, subsciberIds, hasPaidSubscription, fetchData, setShowNotification, setShowMultiNotify, setSelectedSubscriberLength, setSelectedItems} = props;
    const inputRef = useRef(null);
    const modelRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [validatePassword, setValidatePassword] = useState(false);
    const [userPassword, setUserPassword] = useState('');
    const [readOnly, setReadOnly] = useState(true);
    const [error, setError] = useState(false);
    const [errorMassage, setErrorMassage] = useState('');

    const modelTitle = (subsciberIds.length > 1) ? 'Delete Subscibers' : 'Delete Subsciber';
    let modelDesc = '';

    if (hasPaidSubscription) {
        modelDesc = (subsciberIds.length > 1) 
            ? 'Are you sure you want to delete these subscribers? This action cannot be undone. The subscribers will receive refunds for the remaining subscription period.' 
            : 'Are you sure you want to delete this subscriber? This action cannot be undone. The subscriber will receive a refund for the remaining subscription period.';
    } else {
        modelDesc = (subsciberIds.length > 1) 
            ? 'Are you sure you want to delete these subscribers? This action cannot be undone.' 
            : 'Are you sure you want to delete this subscriber? This action cannot be undone.';
    }
    

    useEffect(() => {    
        const handleKeyDown = (e) => {
            const deleteMediaElement = document.getElementById('deleteMedia');
            if (e?.key === 'Enter' && isOpen && deleteMediaElement?.children?.length > 0) {
                deleteSubscriber();
            } else if(e?.key === "Escape"){
                onClose();
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            await deleteSubscriber();
        }
    };

    const deleteSubscriber = async () => {
        try {
            setIsLoading(true);
            let num = subsciberIds.length;

            await Promise.all(
                subsciberIds?.map(async (subsciberId) => {
                    await axios.post(
                        `${process.env.REACT_APP_API_URL}/subscriber/deletesubscriber/${subsciberId}`,
                        {
                            isLast: (num > 1) ? false : true,
                            password: userPassword 
                        },
                    );
                    num = num - 1
                })
            );

            await fetchData();
            if(subsciberIds.length > 1){
                setShowMultiNotify(true)
            }else{
                setShowNotification(true)
            }
            if(setSelectedSubscriberLength) {
                setSelectedSubscriberLength(subsciberIds.length)
            }            
            onClose();
            setIsLoading(false);
            setSelectedItems([])            

        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                setError(true);
            } else {
                setError(true);
                setErrorMassage(error?.response?.data?.error);
            }
        }
    }

    return (
        <>
        <div className='welcomeModal deleteCategoryPage' ref={modelRef} id="deleteMedia">
            <div className="popupTop popupTopClose closePlan">
              <img src={close} onClick={e => { onClose() }} alt="Icon" />
            </div>
            <h3 className='popupTitle'>{modelTitle}</h3>
            <p className='popupDesc'>{modelDesc}</p>

            <div className="btnGroupLink" id='deleteMedia'>
                <button className="secondary-button button" type="button" onClick={onClose}>Cancel</button>
                <button className="primary-button button" type="button" onClick={() => deleteSubscriber()} onKeyPress={(e) => handleKeyPress(e)}>
                    {isLoading ?
                        <div className="settingPage-loader">
                            <div className="ring-loader"></div>
                        </div>
                        : "Delete"}
                </button>
            </div>
        </div>
        </>
    )
}

export default DeleteSubscriberModel