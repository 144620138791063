import React from 'react';
import DataTable from 'react-data-table-component';

import NoResults from '../NoResults';
import NoResultsSubscriber from '../NoResultsSubscriber';
import Pagination from '../Pagination';

import down from '../../images/down_arrow.svg'
import top from '../../images/arrow_top.svg'

export const DataTableList = (props) => {
    const CustomPagination = () => {
        return (
            <Pagination 
                totalPage={props?.paginationTotalRows} // Adjust according to your rows per page
                currentPage={props?.currentPage} 
                setCurrentPage={props?.onChangePage} 
            />
        );
    }
    return (
        <DataTable
            columns={props?.columns}
            data={props?.data}
            selectableRows={props?.selectableRows}
            selectableRowDisabled={props?.selectableRowDisabled}
            onSelectedRowsChange={props?.onSelectedRowsChange}
            onSort={props?.onSort} // Ensure onSort is passed properly
            progressPending={props?.progressPending}
            progressComponent={props?.progressComponent}
            pagination={props?.paginationTotalRows > 1 ? true : false}
            paginationComponent={CustomPagination}
            paginationServer
            onChangePage={props?.onChangePage}
            paginationTotalRows={props?.paginationTotalRows}
            customStyles={props?.customStyles}
            selectableRowsComponent={props?.selectableRowsComponent}
            selectAllRows={props?.selectAllRows}
            onSelectAllChange={props?.onSelectAllChange}
            clearSelectedRows={props?.clearSelectedRows}
            sortIcon={(sortDirection) => {
                // Customize icons based on sort direction using custom images
                if (sortDirection === 'asc') {
                  return <img src={top} alt="Ascending Sort" style={{ width: '16px', height: '16px' }} />;
                } else {
                  return <img src={down} alt="Descending Sort" style={{ width: '16px', height: '16px' }} />;
                }
            }}
            noDataComponent={ props?.type === 'subscribers' ? <NoResultsSubscriber type={props?.status} /> : <NoResults type={props?.type} name={props?.name} status={props?.status} onclickhandle={props?.onclickhandle ? props?.onclickhandle : null}/> }
        />
    );
}