import { NodeViewWrapper } from '@tiptap/react'

const BookmarkBlockView = (props) => {
    const { editor, getPos, node } = props

    console.log({editor, getPos, node})

    return (
        <NodeViewWrapper>
            <div contentEditable={false} className='link-content'>
                <a href={node.attrs.url} target='_blank'>
                    <div className="textBlock linkBox">
                        <div className='linkImageSection'>
                            <img className="previewImage" src={node.attrs.imageUrl} alt={node.attrs.title} />
                        </div>
                        <div className="linkData">
                            <div>
                                <p className="linkDataTitle">{node.attrs.title}</p>
                                <span className="linkDataDiscripation">{node.attrs.description}</span>
                            </div>
                            {node.attrs.siteTitle &&
                                <div className='siteData'>
                                    {node.attrs.favicon &&
                                        <img src={node.attrs.favicon} alt={node.attrs.siteTitle} />
                                    }
                                    <p>{node.attrs.siteTitle}</p>
                                </div>
                            }                           
                        </div>
                    </div>
                </a>
                
            </div>
        </NodeViewWrapper>
    )
}
export default BookmarkBlockView;