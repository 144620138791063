import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import DeleteSubscriberModel from '../../Components/Modal/DeleteSubscriberModel';
import AddSubscriberModel from '../../Components/Modal/AddSubscriberModel';
import NotificationMessage from '../../Components/NotificationMessage';
import { SubscriberHeader } from './SubscriberHeader';
import { DataTableList } from '../../Components/DataTable/DataTable';

import '../../App.css';

import more from '../../images/more.svg'
import SubscriberActionDropdown from '../../Components/SubscriberActionDropdown';


function AllSubscriber(props) {
    props.setActivePage('All Subscribers');
    const isFreePlan = props?.billingDetails?.subscriptions?.plan === "free_plan" ? true : false;

    // State declarations
    const [activeFilter, setActiveFilter] = useState('');
    const [pages, setPages] = useState([]);
    const [searchInput, setSearchInput] = useState('');  
    const [itemToDelete, setItemToDelete] = useState('');
    const [originalData, setOriginalData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPlan, setSelectedPlan] = useState([])
    const [planSelect, setPlanSelect] = useState(false)
    const [allSelected, setAllSelected] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [selectedSubscriberLength, setSelectedSubscriberLength] = useState(0)
    const [hasPaidSubscription, setHasPaidSubscription] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [showMultiNotify, setShowMultiNotify] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [existEmail, setExistEmail] = useState([])
    const [validateEmail, setValidateMail] = useState("")
    const [isAddSubscriberLoading, setAddSubscriberIsLoading] = useState(false);
    const [subscriberNotify, setSubscriberNotify] = useState(false)
    const [multiSubscriberNotify, setMultiSubscriberNotify] = useState(false)
    const [newMails, setNewMails] = useState([])
    const [selectedCategoryPlan, setSelectedCategoryPlan] = useState({ value: 'freePlan', label: 'Free subscription' });
    const [verifySubscriberNotify, setVerifySubscriberNotify] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [activateEdit, setActivateEdit] = useState(false)
    const [columnWidths, setColumnWidths] = useState([]);

    // Fetch subscriber data from the API
    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id

    const planList = ["Free Plan" , `${props?.userMetaData?.subsPlans?.planOne?.planNameOne} (Monthly)` ,`${props?.userMetaData?.subsPlans?.planOne?.planNameOne} (Yearly)`, "Trial Plan"];

    
    useEffect(() => {
        fetchData();
    }, [user_id, currentPage, searchInput, activeFilter,selectedPlan]);

    useEffect(() => {
        searchPage();
    }, [searchInput, originalData, activeFilter]);

    useEffect(() => {
        setCurrentPage(1);
    }, [activeFilter,selectedPlan]);

    const fetchData = async () => {
        const search = searchInput.toLowerCase();
        setIsLoading(false)
        let filter = [];
        if (selectedPlan.includes("Free Plan")) {
            filter.push("freeSubscribers");
        }
        if (selectedPlan.includes(`${props?.userMetaData?.subsPlans?.planOne?.planNameOne} (Monthly)`)) {
            filter.push("monthlySubscribers");
        }
        if (selectedPlan.includes(`${props?.userMetaData?.subsPlans?.planOne?.planNameOne} (Yearly)`)) {
            filter.push("yearlySubscribers");
        }
        if (selectedPlan.includes("Trial Plan")) {
            filter.push("allTrialSubscribers");
        }

        
        const filterQuery = filter.join(",");
        try {
            setIsLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscriber/list/${user_id}?page=${currentPage}&filter=${filterQuery}&search=${search}&planName=`);
            setOriginalData(response?.data?.result || []);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error(error, "error");
        }
    };

    function searchPage() {
        const input = searchInput.toLowerCase();
        const filteredData = input === '' 
          ? originalData 
          : originalData.filter(element => element?.email?.toLowerCase().startsWith(input));
        setPages(filteredData);
    }

    useEffect(() => {
        var id = itemToDelete;
        var result = [];
        pages?.forEach(element => {
            if (element.id === id) {
                return false
            } else {
                result.push(element)
            }
        });
        setPages(result)

    }, [itemToDelete])


    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (e.target != null && showDeleteModel && (e.target.classList.contains('overlay'))) {
                setShowDeleteModel(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showDeleteModel]);

    const closeDeleteModel = () => {
        setShowDeleteModel(false);
        document.body.classList.remove('hidden-message');
    }

    const handleDelete = () => {
        if (selectedRows.length > 0) {
            setShowDeleteModel(true);
            document.body.classList.add('hidden-message');
        }else {
            setShowDeleteModel(false);
            document.body.classList.remove('hidden-message');
        }
    }

    const handleSelectAll = () => {
        if (allSelected) {
            setSelectedItems([]);
            setHasPaidSubscription(false);
        } else {
            const allItemIds = pages.map((item) => item._id).filter((itemId) => itemId);
            setSelectedItems(allItemIds);
    
            const hasPlanOne = pages.some((item) => item.plan === 'planOne');
            setHasPaidSubscription(hasPlanOne);
        }
        setAllSelected(!allSelected);
    };

    const [showSubcriberPopup,setShowSubcriberPopup] = useState(false);

    function handleOpenAddSubcriberPopup() {
        setShowSubcriberPopup(true)
        setErrorMessage("")
        setExistEmail([])
        setValidateMail("")
        setAddSubscriberIsLoading(false)
        setSelectedCategoryPlan({ value: 'freePlan', label: 'Free subscription' })
    }

    const [openPopupIndex, setOpenPopupIndex] = useState(null);

    const togglePopup = (index) => {
        if (index === openPopupIndex) {
            setOpenPopupIndex(null);
        } else {
            setOpenPopupIndex(index);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openPopupIndex &&  (event.target.closest('.pagesTableAction') === null && event.target.closest('.dropdown') === null) && !document.body.classList.contains('hidden-message')) {
                togglePopup(null) // Close the dropdown
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openPopupIndex,togglePopup]);

    useEffect(() => {
        if (originalData.length > 0) {
            calculateColumnWidths();
        }
    }, [originalData]);
    

    function formateDate(date) {
        var createdAtDate = new Date(date);

        var options = { year: 'numeric', month: 'short', day: 'numeric' };

        var formattedDate = createdAtDate.toLocaleDateString('en-US', options);
        return formattedDate
    }

    const calculateColumnWidths = () => {
        const tempWidths = originalData?.reduce((acc, row) => {
            Object.keys(row).forEach((key) => {
                const dataValue = row[key].toString();
                const title = row[key];

                // Compare title length with data content length and take the max

                let maxContentLength = 0;

                if (key === 'plan') {
                    let planText = 'Free Plan';

                    if (row['planName'] !== undefined && row['planName'] !== '') {
                        planText = row['planName'];
                    }

                    if( row['plan'] === "planOne" ) {
                        if( row['interval'] === "year" ) {
                            maxContentLength = Math.max(planText.length + 3, title.length);
                        } else {
                            maxContentLength = Math.max(planText.length + 4, title.length);
                        }
                    } else {
                        maxContentLength = Math.max(planText.length, title.length);
                    }
                } else {
                    maxContentLength = Math.max(dataValue.length, title.length);
                }

                if (!acc[key] || maxContentLength > acc[key]) {
                    acc[key] = maxContentLength;
                }
            });
            return acc;
        }, {});
        if(tempWidths !== undefined){
            const widthMap = Object.keys(tempWidths).reduce((acc, key) => {
                if (key === 'createdAt' || key === 'updatedAt') {
                } else {
                    acc[key] = `${tempWidths[key] * 10 + 40}px`;
                }
                return acc;
            }, {});
            setColumnWidths(widthMap); // Set the calculated column widths
        }    
    };

    const handleMasterCheckboxChange = () => {
        if (isAllSelected) {
            setSelectedRows([]);
        } else {
            setSelectedRows(originalData.map((row) => row._id)); // Select all rows
        }
        setIsAllSelected(!isAllSelected); // Toggle master checkbox
    };

    function updateActivateEdit(activateEdit) {
        setActivateEdit(activateEdit)
    }

    const customTitleSort = (rowA, rowB) => {
        const titleA = rowA?.email.toString().toLowerCase();
        const titleB = rowB?.email.toString().toLowerCase();
    
        // Natural sort comparison for mixed numbers and letters
        return titleA.localeCompare(titleB, undefined, { numeric: true, sensitivity: 'base' });
    };

    const columns = [
        {
            name: 'Email',
            selector: (row) => row?.email,
            cell: (row) => {
                return (
                    <span className='emailLink'>{row?.email}</span>
                )
            },
            sortable: true,
            minWidth: columnWidths['email'] && parseInt(columnWidths['email']) > 300 ? '300px' : columnWidths['email'],
            sortFunction: customTitleSort,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "20px", paddingBottom: "20px", fontSize: "14px" },
        },
        {
            name: 'Status',
            selector: (row) => (row.emailVerification === true && row?.trialData?.isTrial !== true ) ? 'Subscribed': (row.emailVerification === true && row?.trialData?.isTrial === true ) ? 'Trial' : 'Pending',
            cell: (row) => {
                return (
                    <p className={`verifyStatus ${(row.emailVerification === true && row?.trialData?.isTrial !== true) ? 'verifySubscribe' : (row.emailVerification === true && row?.trialData?.isTrial === true) ? 'verifyTrial' : 'verifyPending'}`}>{(row.emailVerification === true && row?.trialData?.isTrial !== true ) ? 'Subscribed': (row.emailVerification === true && row?.trialData?.isTrial === true ) ? 'Trial' : 'Pending'}</p>
                )
            },
            width: columnWidths['status'] || '130px',
            style: { width: columnWidths['status'] || '130px', paddingLeft: 0, paddingRight: "16px", paddingTop: "20px", paddingBottom: "20px", fontSize: "14px" },
            sortable: true,
        },
        {
            name: 'Plan',
            selector: row => `${row?.planName || 'Free Plan'} ${((row?.plan === "planOne" && row?.interval === "month" && row?.trialData?.isTrial !== true) && `(Monthly)`) } ${((row?.plan === "planOne" && row?.interval === "year" && row?.trialData?.isTrial !== true) && `(Yearly)`) }`,
            cell: (row) => {
                return (
                    <p><span className={`${row?.plan !== 'freePlan' ? `pagePaidPlanLabel` : 'pageFreePlanLabel'} verifyStatus ${row?.interval}`}>{row?.planName || 'Free Plan'} {((row?.plan === "planOne" && row?.interval === "month" && row?.trialData?.isTrial !== true) && `(Monthly)`) } {((row?.plan === "planOne" && row?.interval === "year" && row?.trialData?.isTrial !== true) && `(Yearly)`) }</span></p>
                )
            },
            sortable: true,
            paddingLeft: 0,
            width: columnWidths['plan'] || '130px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "20px", paddingBottom: "20px", minWidth: columnWidths['plan'] || '130px', fontSize: "14px" },
        },
        {
            name: 'Date',
            selector: row => formateDate(row?.createdAt),
            sortable: true,
            paddingLeft: 0,
            width: '100px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "20px", paddingBottom: "20px", minWidth: '100px', fontSize: "14px" },
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <div className='pagesTableAction publicationAction' onClick={() => togglePopup(row)}>
                        <img src={more} alt="More" />
                    </div>
                    { (openPopupIndex?._id === row?._id) &&
                        <SubscriberActionDropdown togglePopup={togglePopup} data={row} fetchData={fetchData} setShowNotification={setShowNotification} />
                    }
                </>
            ),
            justifyContent: 'flex-end',
            allowOverflow: true,
            button: true,
            // minWidth: "55px",
            paddingRight: '16px',
            width: '80px',
            style: { textAlign: 'right', minWidth: '80px', paddingLeft: 0, paddingRight: "16px", paddingTop: "20px", paddingBottom: "20px" }, // Aligning to the right
            headerStyle: { textAlign: 'right' },
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };

    return (
        <section className='dashboard subs'>
            <Helmet>
                <title>All Subscribers | Dashboard | Persone</title>
            </Helmet>
            <div className="container">
                <div className="dashboardTop">
                    <div className="dashboardTopText subscriberTopText">
                        <div className='dashboardTopLeft'>
                            <p>All Subscribers</p>
                            <p className="pageSubTitle">Manage your subscribers to track your audience and build stronger connections.</p>
                        </div>
                        <SubscriberHeader searchInput={searchInput} setSearchInput={setSearchInput} setPlanSelect={setPlanSelect} planSelect={planSelect} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} planList={planList} handleDelete={handleDelete} handleOpenAddSubcriberPopup={handleOpenAddSubcriberPopup}/>
                    </div>
                </div>

                <div className="pagesTableWrapper subscribers-list">
                    <div className="pagesTable dataTable-list">
                        <DataTableList
                            columns={columns}
                            data={originalData}
                            onSelectedRowsChange={({ selectedRows }) => {
                                const selectedIds = selectedRows.map((row) => row._id);
                                setSelectedRows(selectedIds);
                                setIsAllSelected(selectedIds.length === fetchData.length);
                            }}
                            progressPending={isLoading}
                            progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                            onChangePage={setCurrentPage}
                            paginationTotalRows={totalPage}
                            currentPage={currentPage}
                            customStyles={customStyles}
                            selectableRowsComponent="input"
                            selectAllRows={isAllSelected}
                            onSelectAllChange={handleMasterCheckboxChange}
                            type="subscribers" 
                            status="all"
                            selectableRows={true}
                        />
                    </div>
                </div>
                {showDeleteModel &&
                    <>
                        <div className="overlay"></div>
                        <DeleteSubscriberModel isOpen={showDeleteModel} onClose={closeDeleteModel} subsciberIds={selectedRows} hasPaidSubscription={hasPaidSubscription} fetchData={fetchData} setShowNotification={setShowNotification} setShowMultiNotify={setShowMultiNotify} setSelectedItems={setSelectedRows} setSelectedSubscriberLength={setSelectedSubscriberLength}/>
                    </>
                }
                {showSubcriberPopup &&
                    <>
                        <div className="overlay"></div>
                        <AddSubscriberModel setShowSubcriberPopup={setShowSubcriberPopup} showSubcriberPopup={showSubcriberPopup} errorMessage={errorMessage} setErrorMessage={setErrorMessage} existEmail={existEmail} setExistEmail={setExistEmail} validateEmail={validateEmail} setValidateMail={setValidateMail} isAddSubscriberLoading={isAddSubscriberLoading} setAddSubscriberIsLoading={setAddSubscriberIsLoading} userMetaData={props?.userMetaData} setSubscriberNotify={setSubscriberNotify} setMultiSubscriberNotify={setMultiSubscriberNotify} setNewMails={setNewMails} newMails={newMails} setSelectedCategory={setSelectedCategoryPlan} setSelectedCategoryPlan={setSelectedCategoryPlan } selectedCategory={selectedCategoryPlan} setVerifySubscriberNotify={setVerifySubscriberNotify}/>
                    </>
                }
                {subscriberNotify && (
                    <NotificationMessage  openNotify={subscriberNotify} setOpenNotify={setSubscriberNotify} type={'success'} title={`Invitation to subscribe sent to ${newMails[0]} `} />
                )}
                {multiSubscriberNotify && (
                    <NotificationMessage  openNotify={multiSubscriberNotify} setOpenNotify={setMultiSubscriberNotify} type={'success'} title={`Invitation to subscribe sent to ${newMails[0]} and ${newMails.length - 1} more`} />
                )}
                {showNotification && (
                    <NotificationMessage  openNotify={showNotification} setOpenNotify={setShowNotification} type={'success'} title={`A subscriber was successfully deleted!`} />
                )}
                {showMultiNotify && (
                    <NotificationMessage  openNotify={showMultiNotify} setOpenNotify={setShowMultiNotify} type={'success'} title={`${selectedSubscriberLength} subscribers were successfully deleted!`} />
                )}
                {(verifySubscriberNotify) && (
                    <NotificationMessage  openNotify={verifySubscriberNotify} setOpenNotify={setVerifySubscriberNotify} type={'success'} title={`${(newMails?.length === 1) ? `A subscriber was successfully added!`: `${newMails?.length} subscribers were successfully added!`}`} />
                )}
            </div>
        </section>
    )
}

export default AllSubscriber
