import axios from "axios";
import { useState } from "react";
import close from '../../images/Settings/close.svg'
function DomainDeleteModel({setShowPopup, setDomainName, setDomainVerifictionToken, setDomainVerified, setDomainAdded, setDisConnectedNotification}) { 
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(isLoading) {
            return; 
        }

        try {
            // if (password.length > 0) {
                setIsLoading(true);
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/removedomain/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    { password: "" }
                );                
                setShowPopup(false);
                setDisConnectedNotification(true)
                setDomainName('');
                setDomainVerifictionToken('');
                setDomainVerified(false);
                setDomainAdded(false);
            // } else {
            //     setValidatePassword(true)
            // }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            return false;
        }
    }
    
    return (
        <form onSubmit={handleSubmit}>
            <div className='welcomeModal domainDeleteModal'>
            <div className="popupTop popupTopClose closePlan">
              <img src={close} onClick={e => { setShowPopup(false); }} alt="Icon" />
            </div>
                <h3 className='popupTitle'>Delete Domain</h3>

                <p className='popupDesc'>
                    Are you sure you want to remove your custom domain? Removing your custom domain will:
                </p>
                <ul>
                    <li>Traffic to your custom domain will be redirected to your default site URL.</li>
                    <li>Any established SEO rankings linked to this domain may be affected.</li>
                    <li>Existing links to this domain will no longer function directly.</li>
                </ul>
                <p className='popupDesc' style={{ marginBottom: 0 }}>
                    If you proceed, your domain setup will be cleared from our system. You can reconfigure it anytime by adding the domain again.
                </p>

                <div className="btnGroupLink">
                    <button className="secondary-button" type="button" onClick={() => setShowPopup(false)}>Cancel</button>
                    <button className="primary-button " type="submit">
                        {isLoading ?
                            <div className="settingPage-loader">
                                <div className="ring-loader"></div>
                            </div>
                            : "Delete"}
                    </button>
                </div>
            </div>
        </form>
    )
}

export default DomainDeleteModel