import React, { useEffect, useRef, useState } from 'react'
import axios from "axios";
import close from '../../images/Settings/close.svg'
function DeleteCategoryModel(props) {
  const { isOpen, onClose, categoryIds, updateCategoriesData, categoriesData, setShowNotification, setShowMultiNotify, setSelectedItems, setSelectedCatsLength, setToggleSelect, toggleSelect} = props;

  const modelTitle = (categoryIds.length > 1) ? 'Delete Categories' : 'Delete Category';
  const modelDesc = (categoryIds.length > 1) ? 'Are you sure you want to delete these categories? This action cannot be undone.' : 'Are you sure you want to delete this category? This action cannot be undone.';

  const modelRef = useRef(null);
  const catDropdownRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectCategory, setSelectCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('Select Category');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (selectCategory && catDropdownRef.current && !catDropdownRef.current.contains(e.target)) {
        setSelectCategory(false);
      }

      if (modelRef.current && !modelRef.current.contains(e.target)) {
        onClose('cancel')
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [selectCategory]);

  useEffect(() => {
    if (isOpen) {
      const handleKeyDown = (e) => {
        const deleteMediaElement = document.getElementById('deleteMedia');
        if (e?.key === 'Enter' && isOpen && deleteMediaElement?.children?.length > 0) {
          deleteCategory()
        } else if (e?.key === "Escape") {
          onClose('cancel');

        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isOpen]);
  if (!isOpen) {
    return null;
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      deleteCategory();
    }
  };

  const deleteCategory = async () => {
    setIsLoading(true);

    if (selectedCategoryId != '') {
      const catData = await updateCategoriesData(categoryIds, selectedCategoryId, 'move');

      const newCategoryData = {
        newslatter_count: catData.newslatter_count,
        page_count: catData.page_count,
        publication_count: catData.publication_count,
        newslatter_ids: catData.newslatter_ids,
        page_ids: catData.page_ids,
        publication_ids: catData.publication_ids
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/categories/updatecategory/${selectedCategoryId}`,
        newCategoryData,
      );
    }
    let response;
    
    await Promise.all(
      categoryIds?.map(async (categoryId) => {
        response = await axios.delete(`${process.env.REACT_APP_API_URL}/categories/remove/${categoryId}`);
      })
    );  
    
    updateCategoriesData(categoryIds, '', 'delete');

    if(response?.status === 200){
      if(categoryIds.length > 1){
        setShowMultiNotify(true)
      }else{
        setShowNotification(true)
      }
      if(setSelectedCatsLength) {
        setSelectedCatsLength(categoryIds.length);
      }
    }
    setIsLoading(false);
    if(setSelectedItems) {
      setSelectedItems([]);
    }    
    if(setToggleSelect) {
      setToggleSelect(!toggleSelect)
    }
    
    onClose();
  }

  return (
    <>
      {isOpen && (
        <div className='welcomeModal deleteCategoryPage' ref={modelRef} id="deleteMedia">
          <div className="popupTop popupTopClose closePlan">
              <img src={close} onClick={e => { onClose() }} alt="Icon" />
          </div>
          <h3 className='popupTitle'>{modelTitle}</h3>
          <p className='popupDesc'>{modelDesc}</p>

          {
            categoriesData.length > categoryIds.length &&
            <div className='movetocat-wrapp'>
              <div className="" >
                <p className="newPageRightTitle iconDropWrap">
                  <span>
                    Move to (Optional)
                  </span>
                </p>
                <div className={`contentDrop`}>
                  <div>

                    <div className="select planTypeSelect">
                      <div
                        className="selectMain"
                        onClick={(e) => { setSelectCategory(!selectCategory); }}
                        // onFocus={(e) => { setSelectCategory(true); }}
                        onKeyPress={(e) => { handleKeyPress(e) }}
                      >
                        <p>{selectedCategory}</p>
                      </div>
                      {selectCategory && (
                        <div className="selectListOption selectPlanOption" ref={catDropdownRef}>
                          <div className="selectOption" key="000" onClick={(e) => { setSelectedCategory('Select Category'); setSelectCategory(false); }}>
                            <p>Select Category</p>
                          </div>
                          {
                            categoriesData?.map((item) => {
                              return (
                                !categoryIds.includes(item._id) && <div
                                  className="selectOption"
                                  key={item._id}
                                  onClick={(e) => { setSelectedCategoryId(item._id); setSelectedCategory(item.name); setSelectCategory(false); }}
                                >
                                  <p>{item.name}</p>
                                </div>
                              )
                            })
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="btnGroupLink">
            <button className="secondary-button button" type="button" onClick={() => { onClose('cancel'); }} >Cancel</button>
            <button className="primary-button button" type="button" onClick={deleteCategory} >
              {isLoading ?
                <div className="updatebutton-loader ">
                  <div className="ring-loader"></div>
                </div>
                :
                <>
                  Delete
                </>}
            </button>
          </div>

        </div>
      )}
    </>
  )
}

export default DeleteCategoryModel