import { useState } from 'react';
import { useEffect, useRef } from "react";
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import axios from "axios";

import Home from './Home';
import MediaGallery from './MediaGallery';
import Newsletter from './Newsletter';
import NewPage from './NewPage';
import NewPublication from './NewPublication';
import Error from './Error';
import Analytics from './Analytics';
import Pages from './Pages';
import Publications from './Publications';
import NewNewsletter from './NewNewsletter';
import Categories from './Categories';
import ProfileAssistant from './ProfileAssistant';
import Settings from '../Pages/Settings';
import MonetizationAnalytics from './MonetizationAnalytics';
import Footer from '../Components/Footer';
import Menu from '../Components/Menu';
import ChangePlan from "../Components/Settings/ChangePlan";
import MenuDropdown from "../Components/MenuDropdown";
import AddNewDropdown from "../Components/AddNewDropdown";
import NotificationMessage from "../Components/NotificationMessage";
import { InvitationModel } from "../Components/Modal/InvitationModel";
import { ChangePlanSuccessModel } from "../Components/Modal/ChangePlanSuccessModel";
import { PreviewNewsletter } from "./PreviewNewsletter/PreviewNewsletter";
import { PreviewPublication } from "./PreviewPublication/PreviewPublication";
import { PreviewPage } from "./PreviewPage/PreviewPage";
import { PreviewHome } from "./PreviewHome/PreviewHome";
import { PreviewContact } from "./PreviewContact/PreviewContact";
import FreeSubscriber from "./Subscribers/FreeSubscriber";
import PaidSubscriber from "./Subscribers/PaidSubscriber";
import TrialSubscriber from "./Subscribers/TrialSubscriber";
import AllSubscriber from "./Subscribers/AllSubscriber";
import PublishedPublications from "./Publications/PublishedPublication";
import DeletedPublications from "./Publications/DeletedPublications";
import DraftsPublications from "./Publications/DraftsPublications";
import DeletedPages from "./Pages/DeletedPages";
import DraftsPages from "./Pages/DraftsPages";
import PublishedPages from "./Pages/PublishedPages";
import SentNewsletter from './Newsletter/SentNewsletters';
import DraftsNewsletter from './Newsletter/DraftsNewsletters';
import DeletedNewsletter from './Newsletter/DeletedNewsletters';

import home from '.././images/sidebar/icon-home.svg';
import plus from '.././images/plus-icon.svg'
import link from ".././images/link.svg"
import avatar from '.././images/profile-user.svg'
import down from ".././images/Settings/down.svg";
import iconDivider from ".././images/chevron-right-light.svg";

import '.././App.css';

function Dashboard() {
    const [activePage, setActivePage] = useState('Home');
    const [menuActive, setMenuActive] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [addTabActive, setAddTabActive] = useState(false)
    const [showStickyMenu, setShowStickyMenu] = useState(false);
    const [profileSlug, setProfileSlug] = useState("");
    const [avatarImage, setAvatarImage] = useState(avatar);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userData, setuserData] = useState({});
    const [isOpenInvitationPopup, setOpenInvitationPopup] = useState(false)
    const [isLoading, setIsLoading] = useState(false); 
    const [invitateTo, setInvitateTo] = useState('');
    const [invitationError, setInvitationError] = useState(false);
    const [invitationMessage, setInvitationMessage] = useState('');
    const [invitationNotification, setInvitationNotification] = useState(false);
    const [openSuccessPlanPopup, setOpenSuccessPlanPopup] = useState(false)
    const [userMetaData, setUserMetaData] = useState({})
    const [sendInvitation, setSendInvitation] = useState(false)
    const [canSendNewsletter, setCanSendNewsletter] = useState(false)
    const [isContentChange, setContentChange] = useState(false)
    const [subscriberData, setSubscriberData]= useState([])
    const [subscriberNotify, setSubscriberNotify] = useState(false);
    const stickymenu = useRef()
    const addRef = useRef()
    const currentUrl = window.location.href; 
    const navigate = useNavigate(); 
    const id = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
    
    const showConfirmationPopup = (url) => {
        const confirmLeave = window.confirm("Changes you made may not be saved. Are you sure you want to leave?");
        if (confirmLeave) {
            setContentChange(false);
            navigate(url);
        } 
    }
    const toggleMenu = () => {
        const body = document.body;
        if (menuActive) {
          body.classList.remove('hide-menu');
        } else {
          body.classList.add('hide-menu');
        }
        setMenuActive(!menuActive);
    };

    const handleMouseEnter = () => {
        if (menuActive) {
            setIsHovered(true);
        }
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const resetMenuActive = () => {
        setMenuActive(false);
        document.body.classList.remove('hide-menu');
    };

    const sidebarClasses = [
        'sidebar',
        isHovered ? 'hover-menu' : '',
    ].filter(Boolean).join(' ');

    useEffect(() => {
        setContentChange(false);
        fetchData();
    }, [activePage]);

    const [billingDetails, setBillingDetails] = useState({});
    const [showUpgradeModel, setShowUpgradeModel] = useState(false);

    const toConvertUppercase = (string) => {
        const words = string?.split(" ");

        const capitalizedWords = words?.map(
            (word) => word?.charAt(0).toUpperCase() + word?.slice(1)
        );
        const capitalizedString = capitalizedWords?.join(" ");
        return capitalizedString;
    };

    const fetchData = async () => {
        try {
            const billingDetailsResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/getBillingDetails/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )

            setBillingDetails(billingDetailsResponse?.data?.result);
            setShowUpgradeModel(false);
        } catch (error) {
            console.log(error, "error");
        }
    }
    const handleInvitateToChange = (event) => {
        setIsLoading(false);
        setInvitationError(false);
        setInvitationMessage('');
        setInvitateTo(event.target.value);
    }

    const handleInvitateToKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendInvitation();
        }
    }

    const handleSendInvitation = async () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        setIsLoading(true);

        setInvitationError(false);
        setInvitationMessage('');

        if (!emailPattern.test(invitateTo)) {
            setInvitationError(true)
            setInvitationMessage('Please enter a valid email address.');
            setIsLoading(false);
            return;
        }

        const data = {
            email: invitateTo,
            name: `${firstName}  ${lastName}`,
            invitedFrom: JSON.parse(localStorage.getItem("userinfo"))?._id
        };

        await axios.put(`${process.env.REACT_APP_API_URL}/user/sendinvitation`, data)
            .then((response) => { 
                if( response.status === 200 ) {
                    setInvitationMessage(`<b>Invite Sent!</b> Sent to ${invitateTo}`);
                    setInvitationNotification(true);
                    setOpenInvitationPopup(false);
                    setSendInvitation(true)
                } else {
                    setInvitationError(true)
                    setInvitationMessage(response.data.message);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setInvitationError(true)
                setInvitationMessage(error.messager);
                setIsLoading(false);
            });
    }

    const [messages, setMessages] = useState("");
    const [subscriberAddedNotify, setSubscriberAddedNotify] = useState(false);
    const [oldsubscriberAddedCount, setOldSubscriberCount] = useState(0);
    const [notificationMessages, setNotificationMessages] = useState([]);
    const notificationMessagesRef = useRef(notificationMessages);
    
    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id

    useEffect(() => {
        notificationMessagesRef.current = notificationMessages;
    }, [notificationMessages]);

    useEffect(() => {
        const webSocket = new WebSocket('ws://localhost:8080');
    
        webSocket.onopen = () => {
            // console.log('Connected to WebSocket server');
        };
    
        webSocket.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            if(parsedData?.author_id === user_id){
                if (parsedData?.message && ! notificationMessagesRef.current.includes(parsedData?.message)) {
                        setSubscriberAddedNotify(true);
                        setOldSubscriberCount((prevCount) => prevCount + 1);
                        setMessages(parsedData);

                        setNotificationMessages((prevMessages) => [...prevMessages, parsedData?.message]);
                }
            }
        };
    
        return () => {
            webSocket.close();
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                )
                const userData = response?.data?.result?.user;
                const userMediaData = response?.data?.result?.userMediaData;
                const userMeta = response?.data?.result?.userMeta;

                const firstName = toConvertUppercase(userData?.firstName);
                const lastName = toConvertUppercase(userData?.lastName);

                setFirstName(firstName);
                setLastName(lastName);
                setuserData(userData);
                setProfileSlug(userMeta.profileSlug);
                setUserMetaData(userMeta)
                setSubscriberData(response?.data?.subscriberData)
                if(response?.data?.subscriberCount > 0){
                    if(!subscriberAddedNotify){
                        setSubscriberNotify(true)
                    }
                }

                if(userMeta?.profileMedia?.cropedImage) {
                    setAvatarImage(userMediaData.profileMeida.cropedMedia.url)
                }

            } catch (error) {
                console.log(error, "error");
            }
        } 
        fetchData();
    }, [sendInvitation]);

    useEffect (()=>{
        const checkIfClickedOutside = (e) => {
            if (showStickyMenu && stickymenu.current && !stickymenu.current.contains(e.target)) {
                setShowStickyMenu(false);
            }
            
        }
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };

    },[showStickyMenu])
    
    useEffect (()=>{
        const checkIfClickedOutside = (e) => {
            
            if (addRef && addRef.current && !addRef.current.contains(e.target)) {
                setAddTabActive(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };

    },[addTabActive])
    
    return (
        <div className="main-wrapper">
            {
                ((activePage !== "Preview Newsletter") && (activePage !== "Preview Publication") && (activePage !== "Preview Page") && (activePage !== "Preview Home") && (activePage !== "Preview Contact")) &&
                <div
                    className={sidebarClasses}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <Menu activePage={activePage} toggleMenu={toggleMenu} menuActive={menuActive} setHover={setIsHovered} resetMenuActive={resetMenuActive} billingDetails={billingDetails} paymentMethods={billingDetails?.paymentMethods} isStipeConnected={billingDetails.isStipeConnected} updateData={fetchData} isExpired={billingDetails?.permission?.isSubscriptionExpired} planDetails={billingDetails?.subscriptions} userMetaData={userMetaData} isContentChange={isContentChange} showConfirmationPopup={showConfirmationPopup}/>
                </div>
            }
            <div className={`main-content ${((activePage === "Preview Newsletter") || (activePage === "Preview Publication") || (activePage === "Preview Page") || (activePage === "Preview Home") ||  (activePage === "Preview Contact")) ? `preview-main-content` : '' }`}>
            {
                ((activePage !== "Preview Newsletter") && (activePage !== "Preview Publication") && (activePage !== "Preview Page") && (activePage !== "Preview Home") && (activePage !== "Preview Contact")) &&
                <div className={`sticky-menu stickyBar ${menuActive ? 'fullStickyBar' : ''}`}>
                    <div className="breadcrumbs home-breadcrumbs">

                        {((activePage == "New page") || (activePage == "New Publication") || (activePage == "New Newsletter")) || (activePage === "PageHome") || (activePage === "PageContact") || (activePage === "All Publications") || (activePage === "Published Publications") || (activePage === "Draft Publications") || (activePage === "Deleted Publications") || (activePage === 'Published Pages') || (activePage === "All Pages") || (activePage === "Drafts Pages") || (activePage === "Deleted Pages") || (activePage === "All Newsletters") || (activePage === "Sent Newsletters") || (activePage === "Drafts Newsletters") || (activePage === "Deleted Newsletters") || (activePage === "All Subscribers") || (activePage === "Free Subscribers") || (activePage === "Paid Subscribers") || (activePage === 'Trial Subscribers') ? (
                            <>
                                <Link to={'/dashboard/home'} ><img src={home} className="home-icon" alt="home-icon" /></Link>

                                <img className="divider" src={iconDivider} alt="dropdown" />

                                <Link to={`${((activePage == "New page") || (activePage === "PageHome") || (activePage === "PageContact") || (activePage === "All Pages") || (activePage === 'Published Pages') || (activePage === "Drafts Pages") || (activePage === "Deleted Pages") ) ? '/dashboard/all-pages' : ((activePage == "New Publication") || (activePage === "All Publications") || (activePage === "Published Publications") || (activePage === "Draft Publications") || (activePage === "Deleted Publications") || (activePage === "Deleted Publications") ) ? "/dashboard/all-publications" : ((activePage == "New Newsletter") || (activePage === "All Newsletters") || (activePage === "Sent Newsletters") || (activePage === "Drafts Newsletters") || (activePage === "Deleted Newsletters") ) ? "/dashboard/all-newsletters" : ( (activePage === "All Subscribers") || (activePage === "Free Subscribers") || (activePage === "Paid Subscribers") || (activePage === 'Trial Subscribers')) ? '/dashboard/all-subscribers' : '' }`} >

                                    <span className="">{`${((activePage == "New page") || (activePage === "PageHome") || (activePage === "PageContact") || (activePage === "All Pages") || (activePage === "Drafts Pages") || (activePage === 'Published Pages') || (activePage === "Deleted Pages") )  ? 'Pages' : ((activePage == "New Publication") || (activePage === "All Publications") || (activePage === "Published Publications") || (activePage === "Draft Publications") || (activePage === "Deleted Publications") ) ? "Publications" : ((activePage == "New Newsletter") || (activePage === "All Newsletters") || (activePage === "Sent Newsletters") || (activePage === "Drafts Newsletters") || (activePage === "Deleted Newsletters") ) ? "Newsletters" : ((activePage === "All Subscribers") || (activePage === "Free Subscribers") || (activePage === "Paid Subscribers") || (activePage === 'Trial Subscribers') ) ? 'Subscribers' : '' }`}</span>
                                    
                                </Link>

                                <img className="divider" src={iconDivider} alt="dropdown" />
                                <Link to={`${activePage == "New page" ? '/dashboard/page' : (activePage == "New Publication") ? "/dashboard/publication" : ((activePage == "New Newsletter")) ? "/dashboard/newsletter" : ((activePage === "PageHome") || (activePage === "PageContact")) ? `/dashboard/page/${id}` : (activePage === "All Publications") ? '/dashboard/all-publications' : (activePage === "Published Publications") ? '/dashboard/published-publications' : (activePage === "Draft Publications") ? '/dashboard/drafts-publications' : (activePage === "Deleted Publications") ? '/dashboard/deleted-publications' : (activePage === 'All Pages') ? '/dashboard/all-pages' : (activePage === 'Published Pages') ? '/dashboard/published-pages' : (activePage === "Drafts Pages") ? '/dashboard/drafts-pages' : (activePage === "Deleted Pages") ? '/dashboard/deleted-pages' : (activePage === "All Newsletters") ? "/dashboard/all-newsletters" : (activePage === "Sent Newsletters") ? "/dashboard/sent-newsletters" : (activePage === "Drafts Newsletters") ? '/dashboard/drafts-newsletters' : (activePage === "Deleted Newsletters") ? '/dashboard/deleted-newsletters' : (activePage === "All Subscribers") ? '/dashboard/all-subscribers' : (activePage === "Free Subscribers") ? '/dashboard/free-subscribers' : (activePage === "Paid Subscribers") ? '/dashboard/paid-subscribers' : (activePage === 'Trial Subscribers') ? '/dashboard/trial-subscribers' : '' }`} >
                                    <span className="">{`${activePage == "New page" ? 'New Page' : (activePage == "New Publication") ? "New Publication" : (activePage == "New Newsletter") ? "New Newsletter" : (activePage === "PageHome") ? "Home" : (activePage === "PageContact") ? "Contact" : (activePage === "All Publications") ? "All Publications" : (activePage === "Published Publications") ? "Published Publications" : (activePage === "Draft Publications") ? "Draft Publications" : (activePage === "Deleted Publications") ? "Deleted Publications" : (activePage === 'All Pages') ? 'All Pages' : (activePage === 'Published Pages') ? 'Published Pages' : (activePage === "Drafts Pages") ? 'Drafts Pages' : (activePage === "Deleted Pages") ? "Deleted Pages" : (activePage === "All Newsletters") ? 'All Newsletters' : (activePage === "Sent Newsletters") ? 'Sent Newsletters' : (activePage === "Drafts Newsletters") ? 'Drafts Newsletters' : (activePage === "Deleted Newsletters") ? 'Deleted Newsletters' : (activePage === "All Subscribers") ? 'All Subscribers' : (activePage === "Free Subscribers") ? 'Free Subscribers' : (activePage === "Paid Subscribers") ? "Paid Subscribers" : (activePage === 'Trial Subscribers') ? 'Trial Subscribers' : '' }`}</span>
                                </Link>
                            </>
                        ) :
                            (<>
                                <Link to={'/dashboard/home'} ><img src={home} className="home-icon" alt="home-icon" /></Link>
                                <img className="divider" src={iconDivider} alt="dropdown" />
                                <Link to={
                                    activePage === "ProfileAssistant" ? '/dashboard/profile-assistant' :
                                        activePage === "monetization-analytics" ? '/dashboard/monetization-analytics' :
                                            activePage === "MediaGallery" ? '/dashboard/media-library' :
                                                activePage === "All Pages" ? '/dashboard/all-pages' :
                                                    activePage === "All Publications" ? '/dashboard/all-publications' :
                                                        activePage === "All Newsletters" ? '/dashboard/all-newsletters' :
                                                            activePage === "Categories" ? '/dashboard/categories' :
                                                                activePage === "All Subscribers" ? '/dashboard/all-subscribers' :
                                                                    activePage === "Contact-Analytics" ? '/dashboard/analytics' :
                                                                        activePage === "Settings" ? '/dashboard/settings' :
                                                                            activePage === "Preview Newsletter" ? '/dashboard/previewnewsletter' :
                                                                                activePage === "Preview Publication" ? '/dashboard/previewpublication' :
                                                                                    activePage === "Preview Page" ? '/dashboard/previewpage' :
                                                                                        activePage === "Preview Home" ? '/dashboard/previewhome' :
                                                                                            activePage === "Preview Contact" ? '/dashboard/previewcontact' :
                                                                                                                '/dashboard/home'
                                } ><span className="">{activePage === "ProfileAssistant" ? "Profile Assistant" : activePage === "Contact-Analytics" ? "Content & Visitors Analytics" : activePage === "monetization-analytics" ? "Monetization Analytics" : activePage === "MediaGallery" ? "Media Library" : activePage === "All Pages" ? "All Pages" : activePage === "All Publications" ? "All Publications" : activePage === "Newsletter" ? "Newsletters" : activePage}</span></Link>
                            </>)
                        }
                    </div>
                    <div className='dropdownSide'>
                        <div className="addNew addNewBtn" ref={addRef}>
                            <button className={(addTabActive === true) ? 'primary-button minwidthAuto flex button' : 'primary-button minwidthAuto flex button'} onClick={(e) => { setAddTabActive(!addTabActive); }}><img src={plus} alt="Icon" />Create</button>
                            {
                                (addTabActive === true) ? <AddNewDropdown setClose={setAddTabActive} setActivePage={setActivePage} isContentChange={isContentChange} showConfirmationPopup={showConfirmationPopup}/> : ''
                            }
                        </div>
                        <Link className='secondary-button flex button' to={`https://jourmal-next-front.vercel.app/${profileSlug}`} target="_blank">
                            <img src={link} alt="link" />
                            Web Profile
                        </Link>
                        <div className='dropDown' ref={stickymenu}>
                            <div className="avatarVisible menuDropdown" onClick={() => { setShowStickyMenu(!showStickyMenu) }} >
                                <div className='sidebar-user'>
                                    <img className='avatar' src={avatarImage} alt={{ firstName }} ref={stickymenu} />
                                </div>
                                <img className='menu-down-arrow' src={down} alt='down' />
                            </div>
                            {showStickyMenu &&
                                <MenuDropdown userData={userData} lastName={lastName} firstName={firstName} setShowStickyMenu={setShowStickyMenu} toggleMenu={toggleMenu} billingDetails={billingDetails} planDetails={billingDetails?.subscriptions} billingData={fetchData} setShowUpgradeModel={setShowUpgradeModel} setOpenInvitationPopup={setOpenInvitationPopup} setInvitateTo={setInvitateTo} setInvitationMessage={setInvitationMessage} />
                            }
                        </div>
                    </div>
                </div>
            }
                <Routes>
                    <Route path='/' element={<Home setActivePage={setActivePage} toggleMenu={toggleMenu} billingDetails={billingDetails} planDetails={billingDetails?.subscriptions} billingData={fetchData} />}></Route>
                    <Route path='/home' element={<Home setActivePage={setActivePage} toggleMenu={toggleMenu} billingDetails={billingDetails} planDetails={billingDetails?.subscriptions} billingData={fetchData} />}></Route>
                    <Route path="/settings/*" element={<Settings setActivePage={setActivePage} billingDetails={billingDetails} planDetails={billingDetails?.subscriptions} paymentMethods={billingDetails?.paymentMethods} isStipeConnected={billingDetails.isStipeConnected} updateData={fetchData} isExpired={billingDetails?.permission?.isSubscriptionExpired} />}></Route>
                    <Route path='/all-pages' element={<Pages setActivePage={setActivePage} />}></Route>
                    <Route path='/published-pages' element={<PublishedPages setActivePage={setActivePage} />}></Route>
                    <Route path='/drafts-pages' element={<DraftsPages setActivePage={setActivePage} />}></Route>
                    <Route path='/deleted-pages' element={<DeletedPages setActivePage={setActivePage} />}></Route>
                    <Route path='/all-publications' element={<Publications setActivePage={setActivePage} />}></Route>
                    <Route path='/published-publications' element={<PublishedPublications setActivePage={setActivePage} />}></Route>
                    <Route path='/drafts-publications' element={<DraftsPublications setActivePage={setActivePage} />}></Route>
                    <Route path='/deleted-publications' element={<DeletedPublications setActivePage={setActivePage} />}></Route>
                    <Route path='/all-subscribers' element={<AllSubscriber setActivePage={setActivePage} billingDetails={billingDetails} userMetaData={userMetaData} />}></Route>
                    <Route path='/media-library' element={<MediaGallery setActivePage={setActivePage} billingDetails={billingDetails} billingData={fetchData} showUpgradeModel={showUpgradeModel} setShowUpgradeModel={setShowUpgradeModel} />}></Route>
                    <Route path='/all-newsletters' element={<Newsletter setActivePage={setActivePage} />}></Route>
                    <Route path='/sent-newsletters' element={<SentNewsletter setActivePage={setActivePage} />}></Route>
                    <Route path='/drafts-newsletters' element={<DraftsNewsletter setActivePage={setActivePage} />}></Route>
                    <Route path='/deleted-newsletters' element={<DeletedNewsletter setActivePage={setActivePage} />}></Route>
                    <Route path='/new-page' element={<NewPage setActivePage={setActivePage} setShowUpgradeModel={setShowUpgradeModel} planDetails={billingDetails.subscriptions} userMetaData={userMetaData} setContentChange={setContentChange}/>}></Route>
                    <Route path='/page/:id' element={<NewPage setActivePage={setActivePage} setShowUpgradeModel={setShowUpgradeModel} planDetails={billingDetails.subscriptions} userMetaData={userMetaData} setContentChange={setContentChange}/>}></Route>
                    <Route path='/new-publication' element={<NewPublication setActivePage={setActivePage} billingDetails={billingDetails} billingData={fetchData} showUpgradeModel={showUpgradeModel} setShowUpgradeModel={setShowUpgradeModel} userMetaData={userMetaData} setContentChange={setContentChange}/>}></Route>
                    <Route path='/publication/:id' element={<NewPublication setActivePage={setActivePage} billingDetails={billingDetails} billingData={fetchData} showUpgradeModel={showUpgradeModel} setShowUpgradeModel={setShowUpgradeModel} userMetaData={userMetaData} setContentChange={setContentChange}/>}></Route>
                    <Route path='/new-newsletter' element={<NewNewsletter setActivePage={setActivePage} billingDetails={billingDetails} billingData={fetchData} showUpgradeModel={showUpgradeModel} setShowUpgradeModel={setShowUpgradeModel} setCanSendNewsletter={setCanSendNewsletter} setContentChange={setContentChange} />}></Route>
                    <Route path='/newsletter/:id' element={<NewNewsletter setActivePage={setActivePage} billingDetails={billingDetails} billingData={fetchData} showUpgradeModel={showUpgradeModel} setShowUpgradeModel={setShowUpgradeModel} setCanSendNewsletter={setCanSendNewsletter} setContentChange={setContentChange}/>}></Route>
                    <Route path='/categories/' element={<Categories setActivePage={setActivePage}/>}></Route>
                    <Route path='/content-visitors-analytics' element={<Analytics setActivePage={setActivePage} />}></Route>
                    <Route path='/monetization-analytics' element={billingDetails?.subscriptions?.plan === 'free_plan' ? (<Home setActivePage={setActivePage} toggleMenu={toggleMenu} billingDetails={billingDetails} planDetails={billingDetails?.subscriptions} billingData={fetchData} />) : (<MonetizationAnalytics setActivePage={setActivePage} />)}></Route>
                    <Route path='/profile-assistant' element={<ProfileAssistant setActivePage={setActivePage} />}></Route>
                    <Route path='*' element={<Error setActivePage={setActivePage} menuDisabled={true} />}></Route>
                    <Route path='/previewnewsletter/:id' element={<PreviewNewsletter setActivePage={setActivePage} userMetaData={userMetaData} canSendNewsletter={canSendNewsletter}/>}></Route>
                    <Route path='/previewpublication/:id' element={<PreviewPublication setActivePage={setActivePage} userMetaData={userMetaData} />}></Route>
                    <Route path='/previewpage/:id' element={<PreviewPage setActivePage={setActivePage} userMetaData={userMetaData} />}></Route>
                    <Route path='/previewhome/:id' element={<PreviewHome setActivePage={setActivePage} userMetaData={userMetaData} />}></Route>
                    <Route path='/previewcontact/:id' element={<PreviewContact setActivePage={setActivePage} userMetaData={userMetaData} />}></Route>
                    <Route path="/free-subscribers" element={<FreeSubscriber setActivePage={setActivePage} billingDetails={billingDetails} userMetaData={userMetaData} />}></Route>
                    <Route path="/paid-subscribers" element={<PaidSubscriber setActivePage={setActivePage} billingDetails={billingDetails} userMetaData={userMetaData} />}></Route>
                    <Route path="/trial-subscribers" element={<TrialSubscriber setActivePage={setActivePage} billingDetails={billingDetails} userMetaData={userMetaData} />}></Route>
                </Routes>
                {
                    (activePage !== "Preview Newsletter" &&  activePage !== "Preview Publication" && activePage !== "Preview Page" && (activePage !== "Preview Home") && (activePage !== "Preview Contact")) &&
                        <div className="container">
                            <Footer menuActive={menuActive} />
                        </div>
                }
            </div>

            {(billingDetails && billingDetails?.permission?.isSubscriptionExpired === 'yes') &&
                <ChangePlan planDetails={billingDetails.subscriptions} paymentMethods={billingDetails.paymentMethods} isStipeConnected={billingDetails.isStipeConnected} updateData={fetchData} isExpired={billingDetails.permission.isSubscriptionExpired} setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup} />
            }
            {(showUpgradeModel) &&
                <ChangePlan planDetails={billingDetails.subscriptions} paymentMethods={billingDetails.paymentMethods} isStipeConnected={billingDetails.isStipeConnected} updateData={fetchData} isExpired={billingDetails.permission.isSubscriptionExpired} setIsChangePlanOpen={setShowUpgradeModel} isChangePlanOpen={showUpgradeModel} setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup} />
            }
            {isOpenInvitationPopup && <InvitationModel setOpenInvitationPopup={setOpenInvitationPopup} isOpenInvitationPopup={isOpenInvitationPopup} handleInvitateToChange={handleInvitateToChange} handleInvitateToKeyPress={handleInvitateToKeyPress} handleSendInvitation={handleSendInvitation} invitateTo={invitateTo} isLoading={isLoading} invitationError={invitationError} invitationMessage={invitationMessage} setIsLoading={setIsLoading} setInvitateTo={setInvitateTo} setInvitationError={setInvitationError} setInvitationMessage={setInvitationMessage} userMetaData={userMetaData} />}
            {openSuccessPlanPopup &&
                <ChangePlanSuccessModel setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup} plan={billingDetails?.subscriptions?.plan} />
            }
            {invitationNotification &&
                <NotificationMessage
                    openNotify={invitationNotification}
                    setOpenNotify={setInvitationNotification}
                    type={'success'}
                    title={`Invitation sent to ${invitateTo}`} />
            }
            {(subscriberNotify) && (
                    <NotificationMessage  
                        openNotify={subscriberNotify} 
                        setOpenNotify={setSubscriberNotify} 
                        type={'success'} 
                        title={`${(subscriberData?.length > 1) ? 
                            `${subscriberData?.[0]?.email} and ${subscriberData?.length - 1}  more has subscribed to your ${subscriberData?.[0]?.planName}!`
                            : 
                            `${subscriberData?.[0]?.email} has subscribed to your ${subscriberData?.[0]?.planName}!`}`} 
                    />
                )}
                {subscriberAddedNotify && (
                    <NotificationMessage  openNotify={subscriberAddedNotify} setOpenNotify={setSubscriberAddedNotify} setOldSubscriberCount={setOldSubscriberCount} setNotificationMessages={setNotificationMessages} type={'success'} title={oldsubscriberAddedCount > 1 ? `${messages?.message} and ${oldsubscriberAddedCount - 1} more has subscribed!` : `${messages?.message} has subscribed to your ${messages?.planName}!`} name="liveNotification" />
                )}
        </div>
    );
}

export default Dashboard;