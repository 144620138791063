import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import '../.././App.css';

function Notification(props) {

    props.setActivePage('Notifications')

    const [enableNotification, setEnableNotification] = useState(false);
    const [weeklyReport, setWeeklyReport] = useState(false)
    const [monthlyReport, setMonthlyReport] = useState(false)
    const [yearlyReport, setYearlyReport] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [email, setEmail] = useState("")
    const [sendMailSubscriber, setSendMailSubscriber] = useState('Everyone');
    const [selectSubscriber, setSelectSubscriber] = useState(false)

    const subscriberRef = useRef(null)

    function clearAll() {
        setCancelLoading(true);
        fetchData();
    }

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )
            const userMeta = response?.data?.result?.userMeta;
            setEmail(userMeta?.email)
            setEnableNotification(userMeta.notification_type);
            setWeeklyReport(userMeta.notification_weekly);
            setMonthlyReport(userMeta.notification_monthly);
            setYearlyReport(userMeta.notification_yearly);
            setSendMailSubscriber(userMeta?.emailReplies || "Everyone");

            setCancelLoading(false);
        } catch (error) {
            setCancelLoading(false);
            console.log(error, "error");
        }
    }

    const addData = async () => {
        try {
            setIsLoading(true);
            const notificationData = {
                notification_type: enableNotification,
                notification_weekly: weeklyReport,
                notification_monthly: monthlyReport,
                notification_yearly: yearlyReport,
                notification_receive_mail: sendMailSubscriber,
                emailReplies: sendMailSubscriber
            }

            await axios.post(
                `${process.env.REACT_APP_API_URL}/user/changeNotification/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                notificationData,
            )
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error, "Data not add");
        }
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (selectSubscriber && subscriberRef.current && !subscriberRef.current.contains(e.target)) {
                setSelectSubscriber(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
        document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [selectSubscriber]);

    return (
        <>
        <Helmet>
            <title>Notifications | Dashboard | Persone</title>
        </Helmet>
        <div className="general setting-notifications">
            <div className="generalTop">
                <div className="generalTopLeft">
                    <p className='generalTopLeftTitle'>Notifications</p>
                    <p className='generalTopLeftDesc'>Update and manage the notifications you'd like to receive via email to stay informed about your account and activities.</p>
                </div>
            </div>

            <div className="billingContent">
                <div className="billingRow">
                    <div className="billingText">
                        <p className='settingsMenuBlockTitle'>Analytics email notifications</p>
                        <p className='generalTopLeftDesc'>Choose when you'd like to receive email updates on the performance and analytics of your personal web profile.</p>
                    </div>
                    <div className='billingTextRight'>
                        <div className="bigSwitch">
                            <div className={(enableNotification === true) ? 'switch switchToggleActive' : 'switch'} onClick={e => setEnableNotification(!enableNotification)}>
                                <div className='switchToggle'></div>
                            </div>
                        </div>
                    </div>
                </div>

                { (enableNotification === true) && 
                    <div className="billingRow">
                        <div className="billingText">
                            <p className='settingsMenuBlockTitle'>Email frequency</p>
                            <p className='generalTopLeftDesc'>Customize how often you'd like to receive analytics updates.</p>
                        </div>
                        <div className='switchBlocks'>
                            <div className="switchBlock">
                                <div className="smallSwitch">
                                    <div className={(weeklyReport === true) ? 'switch switchToggleActive' : 'switch'} onClick={e => setWeeklyReport(!weeklyReport)}>
                                        <div className='switchToggle'></div>
                                    </div>
                                </div>
                                <p>Weekly report</p>
                            </div>
                            <div className="switchBlock">
                                <div className="smallSwitch">
                                    <div className={(monthlyReport === true) ? 'switch switchToggleActive' : 'switch'} onClick={e => setMonthlyReport(!monthlyReport)}>
                                        <div className='switchToggle'></div>
                                    </div>
                                </div>
                                <p>Monthly report</p>
                            </div>
                            <div className="switchBlock">
                                <div className="smallSwitch">
                                    <div className={(yearlyReport === true) ? 'switch switchToggleActive' : 'switch'} onClick={e => setYearlyReport(!yearlyReport)}>
                                        <div className='switchToggle'></div>
                                    </div>
                                </div>
                                <p>Yearly report</p>
                            </div>
                        </div>
                    </div>
                }

                <div className="billingRow">
                    <div className="billingText billingTextNotification">
                        <p className='settingsMenuBlockTitle'>Receive email replies</p>
                        <p className='generalTopLeftDesc'>Set who can reach your via email by replying to your newsletter or by emailing {email}</p>
                    </div>
                    <div className='switchBlocks'>
                        <div className="switchBlock">

                            <div className="select timezome-selection timezome-selection-notification subscriberSelect" ref= {subscriberRef} >
                                <div className="selectMain" onClick={() => { setSelectSubscriber(!selectSubscriber); }} >
                                    <p>{sendMailSubscriber}</p>
                                </div>
                    
                                {selectSubscriber === true &&
                                    <div className="selectListOption" >
                                        <div className="selectOption" onClick={(e) => { setSendMailSubscriber("Everyone"); setSelectSubscriber(false);}}>
                                            <p>Everyone</p>
                                        </div>
                                        <div className="selectOption" onClick={(e) => { setSendMailSubscriber("All Subscribers"); setSelectSubscriber(false);}}>
                                            <p>All Subscribers</p>
                                        </div>
                                        <div className="selectOption" onClick={(e) => { setSendMailSubscriber("Paid Subscribers"); setSelectSubscriber(false);}}>
                                            <p>Paid Subscribers</p>
                                        </div>
                                        <div className="selectOption" onClick={(e) => { setSendMailSubscriber("Nobody"); setSelectSubscriber(false);}}>
                                            <p>Nobody</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="saveButtons">
                <button  className={`settingsWhite secondary-button ${cancelLoading ? 'btn_loading' : 'button'}`} onClick={e => clearAll()}>
                    {cancelLoading ?
                        <div className="settingPage-cancelLoader ">
                            <div className="ring-loader cancel"></div>
                        </div>
                        : "Cancel"}
                </button>
                <button className={`settingsBlue primary-button ${isLoading ? 'btn_loading' : 'button'}`} onClick={addData}>
                    {isLoading ?
                        <div className="settingPage-loader">
                            <div className="ring-loader"></div>
                        </div>
                        : "Save"}
                </button>
            </div>
        </div>
        </>
    );
}

export default Notification;
