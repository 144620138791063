import { useEffect, useRef, useState } from 'react';
import '../.././App.css';
import close from '../../images/Settings/close.svg';
import TwoFactorPhone from '../../Components/Settings/TwoFactorPhone';
import axios from 'axios';
import Notification from '../Notification';

function AddFactor(props) {
    const popupRef = useRef();
    const [showTwoFactorOption, setShowTwoFactorOption] = useState(props.isAddFactorOpen);
    const [showFieldPopup, setShowFieldPopup] = useState(false);
    const [selectedOption, setSelectedOption] = useState('login_mobile');
    const [isActiveField, setActiveField] = useState(false)
    let activeText = 'Active';
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (popupRef.current && !popupRef.current.contains(e.target)) {
                // setShowTwoFactorOption(false);
                props.setIsAddFactorOpen(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, []);

    const handleContinue = async () => {
        setShowTwoFactorOption(false);
        setShowFieldPopup(true);
        if (selectedOption !== 'login_mobile') {
            try {
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/twoFactorEmailOTP/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    { email: props?.status?.email }
                );
            } catch (error) {
                console.log(error)
            }
        }
    };

    const handleSelectPlan = (plan) => {
        setActiveField(true)
        setSelectedOption(plan);
    }
    const disabledTwoFactor = async () => {
        props.setIsAddFactorOpen(false)
    }
    return (
        <div className="popupContainer">
            <div className='popupOverlay'></div>
            <div className="popupBlock popupBlockPlan popupAddFactor" ref={popupRef}>
                <div className='popupBlock-wrapper'>
                    <div className="popupBlock-inner">
                        <div className="popupTop popupTopClose">
                            <img src={close} onClick={() => props.setIsAddFactorOpen(false)} alt="close icon" />
                        </div>

                        {showTwoFactorOption &&
                            <>
                                <div className="popupContent">
                                    <p className="popupTitle">Set Two Factor Authentication</p>
                                    <p className="popupDesc">Choose between receiving a verification code via SMS message or email whenever you log in.</p>
                                </div>
                                <div className="popupContent-main">
                                    <div className="popupContent-item">
                                        <div className="popupContent-inner" onClick={() => handleSelectPlan('login_mobile')}>
                                            <div className='changePlan-check'>
                                                <input type="radio" className="changePlan-checkBox" name='chooseoption' value='login_mobile' checked={selectedOption === 'login_mobile'} />
                                            </div>
                                            <div className='changePlan-subText'>
                                                <div className='active-plan'>
                                                    <h2>Login with your mobile device</h2>
                                                </div>
                                                <p className='changePlan-text'>Each time you log in, you will receive a one-time code via SMS message. Enter this code to complete the login process and
                                                    ensure your account remains protected.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        props?.status?.status === "enable" && props?.status?.method === "email" ?
                                            <div className="popupContent-main">
                                                <div className={`popupContent-item active-plan-background`}>
                                                    <div className='popupContent-inner' >
                                                        <div className='changePlan-subText'>
                                                            <div className='active-plan'>
                                                                <h2>Email Two Factor</h2>
                                                                <span className='active-sub'>{activeText}</span>
                                                            </div>
                                                            <p className='changePlan-text'>Each time you log in, you will receive a one-time code in your email. Enter this code to complete the login process and ensure your account remains protected.</p>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div> :
                                            <div className="popupContent-item">
                                                <div className="popupContent-inner" onClick={() => handleSelectPlan('login_email')}>
                                                    <div className='changePlan-check'>
                                                        <input type="radio" className="changePlan-checkBox" name='chooseoption' value='login_email' checked={selectedOption === 'login_email'} />
                                                    </div>
                                                    <div className='changePlan-subText'>
                                                        <div className='active-plan'>
                                                            <h2>Login with your email address</h2>
                                                        </div>
                                                        <p className='changePlan-text'>Each time you log in, you will receive a one-time code in your email. Enter this code to complete the login process and
                                                            ensure your account remains protected.</p>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                                <div className="popupButtons">
                                    <button className='settingsWhite billingSaveBtn secondary-button button minwidthAuto' onClick={() => { disabledTwoFactor() }}>Cancel</button>
                                    <button className='settingsBlue billingSaveBtn primary-button button minwidthAuto' onClick={handleContinue}>Continue</button>
                                </div>
                            </>
                        }

                        {showFieldPopup &&
                            <TwoFactorPhone setShowTwoFactorOption={setShowTwoFactorOption} setShowFieldPopup={setShowFieldPopup} setIsAddFactorOpen={props.setIsAddFactorOpen} selectedOption={selectedOption} isActiveField={isActiveField} setStatus={props.setStatus} status={props.status} setNotification={props.setNotification} setEditNotify={props.setEditNotify} />
                        }

                    </div>
                </div>
            </div>
            {/* showFieldPopup && (
                <TwoFactorPhone setShowTwoFactorOption={setShowTwoFactorOption} setShowFieldPopup={setShowFieldPopup} setIsAddFactorOpen={props.setIsAddFactorOpen} selectedOption={selectedOption} />
            ) */}
        </div>
    );
}

export default AddFactor;
