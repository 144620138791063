import React, { useState, useCallback, useEffect } from 'react';
import { BubbleMenu } from '@tiptap/react';
import UploadMediaPopup from '../../../../Modal/UploadMediaPopup';

const LogoSliderBubbleMenu = ({ editor, appendTo, type }) => {

    const [showUploadMediaPopup, setShowUploadMediaPopup] = useState(false);
    const [selectMediaLoading, setSelectMediaLoading] = useState(false);
    const [uploadedImages, setUploadedImages] = useState([]);

    const [editAttributes, setEditAttributes] = useState({
        href: '#',
        width: 'auto',
        layout: 'default',
        imageId: [],
        imageKeys: [],
        imageUrls: [],
        imageSlider: false,
    });


    const getNodeAttrs = useCallback(() => {
        const nodeAttrs = editor.state.selection.node?.attrs;

        if (nodeAttrs) {
            setEditAttributes((prev) => ({
                ...prev,
                href: nodeAttrs.href || '#',
                width: nodeAttrs.width || 'auto',
                layout: nodeAttrs.layout === 'null' ?  'default' : nodeAttrs.layout,
                imageUrls: nodeAttrs.imageUrls || [],
                imageId: nodeAttrs.imageId || [],
                imageKeys: nodeAttrs.imageKeys || [],
            }));
        }
    }, [editor]);

    const shouldShow = useCallback(() => {
        if (editor.isActive('logoSliderBlock')) {
            getNodeAttrs();
        }
        return editor.isActive('logoSliderBlock') && editor.isFocused;
    }, [editor]);

    useEffect(() => {
        if (uploadedImages.length > 0 ) {
            setSelectMediaLoading(false);
            setShowUploadMediaPopup(false);
            
            const imageUrlsArray = uploadedImages.map(image => image.url);
            const imageKeysArray = uploadedImages.map(image => image.key);
            const imageIdsArray = uploadedImages.map(image => image._id);
            const imageAltArray = uploadedImages.map(image => image.alt || '');
            const imageNameArray = uploadedImages.map(image => image.name);

            // Update the `editAttributes` state with new values
            setEditAttributes((prev) => {
                const updatedAttrs = {
                    ...prev,
                    imageUrls: imageUrlsArray,
                    imageKeys: imageKeysArray,
                    imageId: imageIdsArray,
                    imageAlt: imageAltArray,
                    imageName: imageNameArray
                };

                // Optionally update the editor attributes if needed
                if (editor) {
                editor.chain().focus().updateAttributes("logoSliderBlock", updatedAttrs).run();
                }

                return updatedAttrs;
            });
        }
    }, [uploadedImages, editor]);

    const setButtonAttribute = (key, value) => {
        setEditAttributes((prev) => {
            const newAttrs = { ...prev, [key]: value };
            if (editor) {
                editor.chain().focus().updateAttributes("logoSliderBlock", newAttrs).run();  // Update node attributes
            }
            return newAttrs;
        });
    };

    const deleteNode = () => {
        editor.chain().setMeta('logoSliderBlock', true).deleteSelection().run()
    }

    const editImages = () => {
        setShowUploadMediaPopup(true);
    }

    return (
        <>
            <BubbleMenu
                editor={editor}
                shouldShow={shouldShow}
                updateDelay={0}
                tippyOptions={{
                    zIndex: 99,
                    popperOptions: {
                        modifiers: [{ name: 'flip', enabled: false }],
                    },
                    appendTo: () => appendTo?.current,
                }}
            >
                <div className="bubble-toggle-menu button-box image-bubble-menu page-bubble-menu">
                    <div className='image-bubble-block'>
                        {type === "publication" &&
                            (editAttributes?.layout === "default" ?
                                <button onClick={() => setButtonAttribute("layout", "full")} className="full-display-button">
                                    <svg width="20" height="20" viewBox="0 0 15 14" stroke='currentColor' fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.75 8.16667V9.45C12.75 10.4301 12.75 10.9201 12.5593 11.2945C12.3915 11.6238 12.1238 11.8915 11.7945 12.0593C11.4201 12.25 10.9301 12.25 9.95 12.25H8.66667M6.33333 1.75H5.05C4.06991 1.75 3.57986 1.75 3.20552 1.94074C2.87623 2.10852 2.60852 2.37623 2.44074 2.70552C2.25 3.07986 2.25 3.56991 2.25 4.55V5.83333M9.25 5.25L12.75 1.75M12.75 1.75H9.25M12.75 1.75V5.25M5.75 8.75L2.25 12.25M2.25 12.25H5.75M2.25 12.25L2.25 8.75" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                                :
                                <button onClick={() => setButtonAttribute("layout", "default")} className="full-display-button">
                                    <svg width="20" height="20" viewBox="0 0 15 14" stroke='#0A0A0A' fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.75 8.16667V9.45C12.75 10.4301 12.75 10.9201 12.5593 11.2945C12.3915 11.6238 12.1238 11.8915 11.7945 12.0593C11.4201 12.25 10.9301 12.25 9.95 12.25H8.66667M6.33333 1.75H5.05C4.06991 1.75 3.57986 1.75 3.20552 1.94074C2.87623 2.10852 2.60852 2.37623 2.44074 2.70552C2.25 3.07986 2.25 3.56991 2.25 4.55V5.83333M9.25 5.25L12.75 1.75M12.75 1.75H9.25M12.75 1.75V5.25M5.75 8.75L2.25 12.25M2.25 12.25H5.75M2.25 12.25L2.25 8.75" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            )
                        }
                        
                        <button onClick={editImages}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z"></path></svg>
                        </button>
                        <button onClick={deleteNode} gallery-fileName="full-display-button">
                            <svg width="20" height="20" viewBox="0 0 15 14" stroke='currentColor' fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.33333 3.4974V3.03073C9.33333 2.37733 9.33333 2.05064 9.20617 1.80107C9.09432 1.58155 8.91584 1.40307 8.69632 1.29122C8.44676 1.16406 8.12006 1.16406 7.46667 1.16406H6.53333C5.87994 1.16406 5.55324 1.16406 5.30368 1.29122C5.08416 1.40307 4.90568 1.58155 4.79383 1.80107C4.66667 2.05064 4.66667 2.37733 4.66667 3.03073V3.4974M5.83333 6.70573V9.6224M8.16667 6.70573V9.6224M1.75 3.4974H12.25M11.0833 3.4974V10.0307C11.0833 11.0108 11.0833 11.5009 10.8926 11.8752C10.7248 12.2045 10.4571 12.4722 10.1278 12.64C9.75347 12.8307 9.26342 12.8307 8.28333 12.8307H5.71667C4.73657 12.8307 4.24653 12.8307 3.87218 12.64C3.5429 12.4722 3.27518 12.2045 3.10741 11.8752C2.91667 11.5009 2.91667 11.0108 2.91667 10.0307V3.4974" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>

                    </div>
                </div>
            </BubbleMenu>
            { showUploadMediaPopup && (
                <div className='node-logoSlider'>
                    <UploadMediaPopup
                        showUploadMediaPopup={showUploadMediaPopup} 
                        setShowUploadMediaPopup={setShowUploadMediaPopup}
                        setSelectMediaLoading={setSelectMediaLoading}
                        selectMediaLoading={selectMediaLoading}
                        setSelectedProfileImage={setUploadedImages}
                        uploadMediaPopupFor='images-file'
                        uploadMediaPopupTab="media"
                        selectedMedia= {editAttributes.imageId}
                    />
                </div>
            )}
        </>
    );
};

export default LogoSliderBubbleMenu;
