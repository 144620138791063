import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

import Menu from '../Components/Menu';
import Footer from '../Components/Footer';
import ErrorImg from '../images/ErrorImg.png';

import '../App.css';

function Error(props) {

    props?.setActivePage?.("Error");

    let navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>404 Page Not Found | Dashboard | Persone</title>
            </Helmet>
            {
                (props?.menuDisabled !== true) ? <Menu /> : ''
            }
            <section className='dashboard error-page'>
                <div className='erorBlock'>
                    <img src={ErrorImg} alt="ErrorImg" />
                    <p className='errorTitle'>Something wen’t wrong :(</p>
                    <p className='errorSubtitle'>Oops! Something went wrong. We're facing a few issues right now. Please bear with us and try again later. Thanks for your patience!</p>
                    <div className='erorButton'>
                        <button onClick={() => navigate(-1)} className='erorButton_left secondary-button minwidthAuto'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                            Back</button>
                        <Link to={'/'}><button className='erorButton_right primary-button'>
                            Take Me Home
                            <svg
                                width="16" height="16" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 6.5H14.3333M14.3333 6.5L9.33333 1.5M14.3333 6.5L9.33333 11.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button></Link>
                    </div>
                </div>
            </section>

            {
                (window.location.href.indexOf('/dashboard') !== -1)
                    ? ''
                    : <div className="footer_error">
                        <Footer />
                    </div>

            }
        </>
    );
}

export default Error;