import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactFlagsSelect from "react-flags-select";

import "../.././App.css";
import login from '../../images/persone-logo.png'

function Step1(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(props.email);
  const [countryCode, setSelectedCountry] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [matchPassword, setMatchPassword] = useState(true);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const [validateName, setValidateName] = useState(false);
  const [validateLastName, setValidateLastName] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);
  const [countryValidate, setCountryValidate] = useState(false);
  const [validatePassword, setValidatePassword] = useState(false);
  const [validateRePass, setValidateRePass] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [readOnlyPassword, setReadOnlyPassword] = useState(true);
  props.step(1);

  const emailRef = useRef(null);
  const history = useNavigate();
  let timezoneData = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  let timeZone1 = timezoneData.substring(timezoneData.lastIndexOf('/') + 1)
  var timeZone = manipulateString(timeZone1);

  function manipulateString(inputString) {

    let slashIndex = inputString.indexOf('/');
    let valueAfterSlash = slashIndex !== -1 ? inputString.slice(slashIndex + 1) : inputString;
    let modifiedString = valueAfterSlash.replace(/-/g, ' ');

    return modifiedString;
  }

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [isUppercase, setIsUppercase] = useState(false);
  const [isLowercase, setIsLowercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [isMinLength, setIsMinLength] = useState(false);

  const [box, setBox] = useState(false);

  const fulfilledRequirementsCount = [
    isUppercase,
    isLowercase,
    hasSpecialChar,
    hasNumber,
  ].filter(Boolean).length;

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (newPassword.length > 0) {
      setValidatePassword(false);
    }
    setIsUppercase(/[A-Z]/.test(newPassword));
    setIsLowercase(/[a-z]/.test(newPassword));
    setHasNumber(/[0-9]/.test(newPassword));
    setHasSpecialChar(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword));
    setIsMinLength(newPassword.length >= 8);
  };

  useEffect(() => {
    if (password.length > 0) {
      if (password.length >= 8 && (hasNumber && hasSpecialChar && isLowercase && isUppercase)) {
        setBox(false);
      } else {
        setBox(true);
      }
    }
  }, [password, isUppercase, isLowercase, hasNumber, hasSpecialChar]);

  const [invited, setInvited] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isInvited = searchParams.get("invited");

  useEffect(() => {
    if (isInvited) {
      // Prevent the input from being modified
      const handleKeyDown = (e) => {
        e.preventDefault();
      };

      const handlePaste = (e) => {
        e.preventDefault();
      };

      const handleInput = (e) => {
        e.target.value = email;
      };

      const inputElement = emailRef.current;
      inputElement.addEventListener('keydown', handleKeyDown);
      inputElement.addEventListener('paste', handlePaste);
      inputElement.addEventListener('input', handleInput);

      return () => {
        inputElement.removeEventListener('keydown', handleKeyDown);
        inputElement.removeEventListener('paste', handlePaste);
        inputElement.removeEventListener('input', handleInput);
      };
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (firstName.length > 0) {
      setValidateName(false);
    } else {
      setValidateName(true);
    }

    if (lastName.length > 0) {
      setValidateLastName(false);
    } else {
      setValidateLastName(true);
    }

    if (email.length > 0) {
      setValidateEmail(false);
    } else {
      setValidateEmail(true)
    }
    if (emailPattern.test(email)) {
      setValidateEmail(false);
    } else {
      setValidateEmail(true)
    }
    if (countryCode.length > 0) {
      setCountryValidate(false);
    } else{
      setCountryValidate(true);
    }

    if (password.length >= 8) {
      setValidatePassword(false);
    } else {
      setValidatePassword(true);
    }

    if (rePassword.length > 0) {
      setValidateRePass(false);
    } else {
      setValidateRePass(true);
    }

    if (rePassword !== password) {
      setMatchPassword(false);
    } else {
      setMatchPassword(true);
    }

    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      emailPattern.test(email) &&
      password.length >= 8 &&
      countryCode.length > 0 &&
      rePassword === password &&
      isCheckboxChecked === true
    ) {
      try {
        setIsLoading(true);
        const registerData = {
          timeZone: timeZone,
          username: email,
          password: password,
          confirmPassword: rePassword,
          user_metadata: {
            firstname: firstName,
            lastname: lastName,
          },
          country:countryCode,
          invited: invited
        };
        
        let response = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/register`,
          registerData
        );
        const result = response.data.result._id;
        sessionStorage.setItem("userId", result);

        const loginData = { username: email, password: password };
        let responses = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/login?notSendMail=${true}`,
          loginData
        );
        localStorage.setItem("userinfo", JSON.stringify(responses.data));
        localStorage.setItem("isCompleted", "false");

        if(isInvited) {
          localStorage.setItem("isInvited", "true");
        } else {
          localStorage.setItem("isInvited", "false");
        }
        setToken(responses.data.token);

        var copy = [...props.data]

        copy[1].status = 'done'
        props.addData(copy)
        props.step(3)
        props.setIsCompleted(false)
        if( response.data?.redirect ) {
          const redirectUrl = new URL(response.data?.redirect);
          const path = redirectUrl.origin + redirectUrl.pathname + redirectUrl.search;

          history(path, { replace: true });
          window.location.href = path;
        }
        setIsLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 409) {
          setEmailError(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.log(error, "error");
        }
        return false;
      }
    } else {
      isCheckboxChecked === false && setCheckboxError(true);
    }
  };

  const [profileImageSrc, setprofileImageSrc] = useState('')

  useEffect(() => {
    var data = props.data[1].data[0]

    var profileData = props.data[4]?.data[0] || ''

    if (profileData) {
      setprofileImageSrc(profileData.profileImageSrc);
    }
    if( isInvited ) {
      setInvited(true)
    }
  }, [])

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <>
      {/* <BgFormAnimate/> */}
      <form onSubmit={handleSubmit} onKeyPress={(e) => { handleKeyPress(e) }}>

        <div className="step">
          <div className="step_content">
            <a href='https://jourmal-next-front.vercel.app/' target="_blank">
                <img className='person-logo' src={login} alt="Login" />
            </a>
            <h1 className="title new-register-title">Create a new account</h1>
            <p className="Subtitle new-register-subtitle">Please enter your details</p>

            <div className="user_name">
              <div className="user_nameBlock">
                <p>First name</p>
                <input
                  autoFocus={true}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    if (e.target.value.length > 0) {
                      setValidateName(false);
                    }
                  }}
                  placeholder="Enter first name"
                  className={
                    validateName === true ? "first_name inputError" : "first_name"
                  }
                  type="text"
                />
                {validateName === true && (
                  <p className="validateError">Please enter your first name</p>
                )}
              </div>
              <div className="user_nameBlock">
                <p>Last name</p>
                <input
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    if (e.target.value.length > 0) {
                      setValidateLastName(false);
                    }
                  }}
                  placeholder="Enter last name"
                  className={
                    validateLastName === true
                      ? "last_name inputError"
                      : "last_name"
                  }
                  type="text"
                />
                {validateLastName === true && (
                  <p className="validateError">Please enter your last name</p>
                )}
              </div>
            </div>

            <div className="user_name">
              <div className="user_email user_nameBlock">
                <p>Email address</p>
                <input value={email} onChange={(e) => { setEmail(e.target.value); setEmailError(false); if (e.target.value.length > 0) { setValidateEmail(false); } }}
                  placeholder="Enter email address"
                  className={ validateEmail === true ? "last_name inputError" : "last_name" }
                  type="text"
                  ref={emailRef}
                  readOnly={readOnly} onFocus={ () => setReadOnly(false) } onBlur={ () => setReadOnly(true) }
                  style={{
                    pointerEvents: isInvited ? 'none' : 'auto',
                    cursor: isInvited ? 'default' : 'text',
                    backgroundColor: isInvited ? '#f9fafb' : '#ffffff',
                  }}
                />
                {validateEmail === true && (
                  <p className="validateError">Please enter a valid email address</p>
                )}
                {emailError === true && (
                  <p className="validateError">This email is already taken</p>
                )}
              </div>

              <div className='user_email user-country user_nameBlock'>
                <p>Country or region</p>
                <ReactFlagsSelect
                  selected={countryCode}
                  searchable
                  searchPlaceholder="Search a country..."
                  onSelect={(code) => {
                    setSelectedCountry(code);
                    setCountryValidate(false);
                  }}
                  className={
                    countryValidate 
                      ? 'countryDropdown inputError register-countrybox no-country-selected' 
                      : countryCode === "" 
                        ? 'countryDropdown register-countrybox no-country-selected' 
                        : 'countryDropdown register-countrybox'
                  }
                />
                {countryValidate && <p className="validateError">Please select your country or region</p>}              
            </div>
          </div>

          <div className="user_password">
            <div className="user_email">
              <p>Password</p>
              <input
                value={password}
                onChange={handlePasswordChange}
                placeholder="Your password"
                className={
                  validatePassword === true
                    ? "last_name inputError"
                    : "last_name"
                }
                readOnly={readOnlyPassword} onFocus={ () => setReadOnlyPassword(false) } onBlur={ () => setReadOnlyPassword(true) }
                type="password"
              />
              {validatePassword === true && (
                <p className="validateError">Please choose a valid password</p>
              )}
              {box && (
                <div className="requirements-box">
                  <p>Your password must contain:</p>
                  <ul className="requirement-list">
                    <li className={isMinLength ? "fulfilled" : ""}>
                      <p>At least 8 characters</p>
                    </li>
                    <li
                      className={
                        fulfilledRequirementsCount >= 4 ? "fulfilled" : ""
                      }
                    >
                      <p>And the Following:</p>
                      <ul>
                        <li className={isLowercase ? "fulfilled" : ""}>
                          <p>Lower case letters (a - z)</p>
                        </li>
                        <li className={isUppercase ? "fulfilled" : ""}>
                          <p>Upper case letters (A - Z)</p>
                        </li>
                        <li className={hasNumber ? "fulfilled" : ""}>
                          <p>Numbers (0 - 9)</p>
                        </li>
                        <li className={hasSpecialChar ? "fulfilled" : ""}>
                          <p>Special characters (e.g. !@#$%^&*)</p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="user_email">
            <p>Re-enter password</p>
            <input
              value={rePassword}
              onChange={(e) => {
                setRePassword(e.target.value);
                if (e.target.value.length > 0) {
                  setValidateRePass(false);
                  setMatchPassword(true);
                }
              }}
              placeholder="Re-enter password"
              className={
                validateRePass === true ? "last_name inputError" : "last_name"
              }
              type="password"
            />

            {validateRePass === true && (
              <p className="validateError">Please re-enter your password</p>
            )}
            {!matchPassword && (
              <p className="validateError">The passwords don't match</p>
            )}
          </div>

          <div className="stepCheckbox_block">
            <input
              className="stepCheckbox"
              id="agreement"
              type="checkbox"
              name="accept_terms"
              value="true"
              checked={isCheckboxChecked}
              onChange={(e) => {
                setIsCheckboxChecked(e.target.checked);
                setCheckboxError(false);
              }}
            />
            <label for="agreement">
              I agree to Persone's <span className='login-footer-link'>Terms of Service</span> & <span className='login-footer-link'>Privacy Policy</span>
            </label>
          </div>
          {checkboxError === true && (
            <p className="checkboxValidateError">
              Please accept the terms of service and privacy policy
            </p>
          )}

          <div className="regButtons regButtonsHoverEffect">
            <button
              type="submit"
              className={`btn_toStep btn-hide create-button primary-button button ${isLoading ? 'btn_loading' : ''}`}
            >
              {isLoading ?
                <div className="registerLoader">
                  <div className="ring-loader stepOne-loader"></div>
                </div>
                : "Create a new account"}
              <svg
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6.5H14.3333M14.3333 6.5L9.33333 1.5M14.3333 6.5L9.33333 11.5"
                  stroke="white"
                  stroke-width="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      </form >
    </>
  );
}

export default Step1;
