import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';

import close from '../../images/Settings/close.svg'

export const ChangePlanSuccessModel = (props) => {    
    const popupRef = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (props?.openSuccessPlanPopup && props?.setOpenSuccessPlanPopup && popupRef?.current && !popupRef?.current.contains(e.target)) {
                props?.setOpenSuccessPlanPopup(false)
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [props?.openSuccessPlanPopup]);

    const handleButtonClick = (e) => {
        if(props?.plan !== "personal_assistant_plan") {
            e.preventDefault()
            props?.setOpenSuccessPlanPopup(false);
        }
    }

    return (
        <div>
            <div className='popupOverlay'></div>
            <div className='welcomeModal inviteModal successModal' ref={popupRef}>
                <div className='popupBlock-wrapper successplanChange'>
                    <div className="popupTop popupTopClose closePlan">
                        <img src={close} onClick={e => { props?.setOpenSuccessPlanPopup(false) }} alt="Icon" />
                    </div>
                    <h3 className='popupTitle'>Welcome to the {props?.plan === "unlimited_plan" ? 'Unlimited Plan' : 'Personal Assistant Plan'}!</h3>
                    <p className='popupDesc'>You now have access to all features including:</p>
                    <div className='step_content'>
                        <li className="dropdownMenu menuItem marginStart" >

                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='invite-mail-icon'>
                                <path d="M14.4286 9.07295H9.79805M14.4286 12.7774H9.79805M14.4286 5.36851H9.79805M6.55667 1.66406L6.55667 18.3341M5.44533 1.66406H13.2247C14.7807 1.66406 15.5587 1.66406 16.153 1.96688C16.6758 2.23325 17.1008 2.65828 17.3672 3.18106C17.67 3.77538 17.67 4.55338 17.67 6.1094V13.8887C17.67 15.4447 17.67 16.2227 17.3672 16.8171C17.1008 17.3398 16.6758 17.7649 16.153 18.0312C15.5587 18.3341 14.7807 18.3341 13.2247 18.3341H5.44533C3.88932 18.3341 3.11131 18.3341 2.517 18.0312C1.99422 17.7649 1.56919 17.3398 1.30282 16.8171C1 16.2227 1 15.4447 1 13.8887V6.1094C1 4.55338 1 3.77538 1.30282 3.18106C1.56919 2.65828 1.99422 2.23325 2.517 1.96688C3.11131 1.66406 3.88932 1.66406 5.44533 1.66406Z" stroke="#0A2540" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <span className='popupDesc'>Unlimited Pages</span>
                        </li>
                        <li className="dropdownMenu menuItem" >

                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='invite-mail-icon'>
                                <path d="M16.9523 17.8996H10.054M1 18.3307L5.78506 16.4903C6.09112 16.3726 6.24415 16.3137 6.38732 16.2369C6.5145 16.1686 6.63573 16.0898 6.74976 16.0013C6.87814 15.9017 6.99408 15.7858 7.22595 15.5539L16.9523 5.82755C17.9048 4.8751 17.9048 3.33086 16.9523 2.3784C15.9999 1.42595 14.4556 1.42595 13.5032 2.3784L3.77681 12.1048C3.54493 12.3366 3.429 12.4526 3.32937 12.5809C3.24089 12.695 3.1621 12.8162 3.09383 12.9434C3.01698 13.0866 2.95812 13.2396 2.84041 13.5457L1 18.3307ZM1 18.3307L2.77469 13.7166C2.90168 13.3864 2.96518 13.2213 3.07409 13.1457C3.16927 13.0796 3.28705 13.0546 3.40086 13.0763C3.5311 13.1012 3.65617 13.2263 3.90632 13.4764L5.85431 15.4244C6.10445 15.6746 6.22953 15.7996 6.2544 15.9299C6.27613 16.0437 6.25114 16.1615 6.18505 16.2566C6.10943 16.3656 5.94434 16.4291 5.61416 16.556L1 18.3307Z" stroke="#0A2540" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>


                            <span className='popupDesc'>Unlimited Publications</span>
                        </li>
                        <li className="dropdownMenu menuItem" >

                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='invite-mail-icon'>
                                <path d="M8.04048 11.2936L17.1417 2.19241M8.15106 11.5779L10.4291 17.4356C10.6297 17.9517 10.7301 18.2097 10.8747 18.285C11 18.3503 11.1493 18.3504 11.2747 18.2853C11.4194 18.2101 11.52 17.9522 11.7213 17.4364L17.4337 2.79846C17.6154 2.33284 17.7062 2.10003 17.6565 1.95127C17.6134 1.82207 17.512 1.72069 17.3828 1.67753C17.234 1.62783 17.0012 1.71869 16.5356 1.90039L1.89766 7.61276C1.38186 7.81405 1.12396 7.91469 1.0488 8.05936C0.983649 8.18477 0.983737 8.33407 1.04904 8.45941C1.12437 8.60399 1.38238 8.70433 1.89842 8.90501L7.75611 11.183C7.86086 11.2237 7.91324 11.2441 7.95734 11.2756C7.99643 11.3034 8.03061 11.3376 8.0585 11.3767C8.08996 11.4208 8.11032 11.4732 8.15106 11.5779Z" stroke="#0A2540" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>


                            <span className='popupDesc'>Unlimited Newsletters</span>
                        </li>
                        <li className="dropdownMenu menuItem" >

                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='invite-mail-icon'>
                                <path d="M11.8355 7.49856L6.8345 12.4996M6.8345 7.49856L11.8355 12.4996M17.67 9.99906C17.67 14.6024 13.9383 18.3341 9.335 18.3341C4.73171 18.3341 1 14.6024 1 9.99906C1 5.39577 4.73171 1.66406 9.335 1.66406C13.9383 1.66406 17.67 5.39577 17.67 9.99906Z" stroke="#0A2540" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>


                            <span className='popupDesc'>No Ads & “Built with” Banner</span>
                        </li>
                        <li className="dropdownMenu menuItem" >

                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='invite-mail-icon'>
                                <path d="M6.41775 12.2217C6.41775 13.2958 7.28848 14.1666 8.36258 14.1666H10.1685C11.3193 14.1666 12.2523 13.2336 12.2523 12.0828C12.2523 10.932 11.3193 9.99906 10.1685 9.99906H8.5015C7.35068 9.99906 6.41775 9.06614 6.41775 7.91531C6.41775 6.76449 7.35068 5.83156 8.5015 5.83156H10.3074C11.3815 5.83156 12.2523 6.70229 12.2523 7.7764M9.335 4.58131V5.83156M9.335 14.1666V15.4168M17.67 9.99906C17.67 14.6024 13.9383 18.3341 9.335 18.3341C4.73171 18.3341 1 14.6024 1 9.99906C1 5.39577 4.73171 1.66406 9.335 1.66406C13.9383 1.66406 17.67 5.39577 17.67 9.99906Z" stroke="#0A2540" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>


                            <span className='popupDesc'>Paid Subscription</span>
                        </li>
                        <li className="dropdownMenu menuItem" >

                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" className='invite-mail-icon'>
                                <path d="M12.6667 9.27083L9.33333 5.9375M9.33333 5.9375L6 9.27083M9.33333 5.9375V13.6042C9.33333 14.7631 9.33333 15.3425 9.7921 15.9913C10.0969 16.4224 10.9745 16.9544 11.4977 17.0253C12.2851 17.132 12.5841 16.976 13.1821 16.6641C15.8472 15.2738 17.6667 12.4848 17.6667 9.27083C17.6667 4.66846 13.9357 0.9375 9.33333 0.9375C4.73096 0.9375 1 4.66846 1 9.27083C1 12.3553 2.67583 15.0484 5.16667 16.4893" stroke="#0A2540" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>


                            <span className='popupDesc'>Upload Files & Videos</span>
                        </li>
                        <li className="dropdownMenu menuItem paddinglast" >

                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='invite-mail-icon'>
                                <path d="M3.08375 18.3341V14.1666M3.08375 5.83156V1.66406M1 3.74781H5.1675M1 16.2503H5.1675M10.1685 2.49756L8.72306 6.2557C8.48801 6.86685 8.37048 7.17242 8.18771 7.42945C8.02573 7.65726 7.8267 7.85629 7.59889 8.01827C7.34186 8.20104 7.03628 8.31857 6.42514 8.55363L2.667 9.99906L6.42514 11.4445C7.03628 11.6796 7.34186 11.7971 7.59889 11.9798C7.8267 12.1418 8.02573 12.3409 8.18771 12.5687C8.37048 12.8257 8.48801 13.1313 8.72306 13.7424L10.1685 17.5006L11.6139 13.7424C11.849 13.1313 11.9665 12.8257 12.1493 12.5687C12.3113 12.3409 12.5103 12.1418 12.7381 11.9798C12.9951 11.7971 13.3007 11.6796 13.9119 11.4445L17.67 9.99906L13.9119 8.55363C13.3007 8.31857 12.9951 8.20104 12.7381 8.01827C12.5103 7.85629 12.3113 7.65726 12.1493 7.42945C11.9665 7.17242 11.849 6.86685 11.6139 6.2557L10.1685 2.49756Z" stroke="#0A2540" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <span className='popupDesc'>Write with AI</span>
                        </li>

                        { props?.plan === "personal_assistant_plan" &&
                            <li className="dropdownMenu menuItem paddinglast" >
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className='invite-mail-icon'>
                                    <path d="M17 14.3333V9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9V14.3333M3.22222 17C1.99492 17 1 16.0051 1 14.7778V13C1 11.7727 1.99492 10.7778 3.22222 10.7778C4.44952 10.7778 5.44444 11.7727 5.44444 13V14.7778C5.44444 16.0051 4.44952 17 3.22222 17ZM14.7778 17C13.5505 17 12.5556 16.0051 12.5556 14.7778V13C12.5556 11.7727 13.5505 10.7778 14.7778 10.7778C16.0051 10.7778 17 11.7727 17 13V14.7778C17 16.0051 16.0051 17 14.7778 17Z" stroke="#0A2540" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className='popupDesc'>Personal Assistant</span>
                            </li>
                        }


                    </div>
                    { props?.plan === "personal_assistant_plan" &&
                        <p className='popupDesc personal-assistant-text'>Now it’s time to fill in the details for the communication with your personal assistants.</p>
                    }
                    <div className="popupButtons">
                        <Link className='settingsBlue billingSaveBtn verifyLoader invitation-model' to={ ((props?.plan === "personal_assistant_plan")) ? "/dashboard/profile-assistant" : '#'} onClick={e => { handleButtonClick(e) }}>
                            Let's start
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
