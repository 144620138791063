import axios from "axios";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import back from '../images/right-long-arrow.svg'

import '../css/loginPage.css'

function CheckEmail(props) {

  const userSendmail = () => {
    const data = {
      email: props?.email,
    };
    axios
      .put(`${process.env.REACT_APP_API_URL}/user/emailsend`, data)
      .then((response) => { 
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Helmet>
          <title>Forgot Password? | Persone</title>
      </Helmet>
      <div className="loginWindow checkEmail">
        <p className="loginTitle login-page-title">Please check your email</p>
        <p className="loginSubtitle">
          A password reset link has been sent to: <br/ ><span>{props?.email}</span>
        </p>
        <p className="loginBottom login-resend-link" onClick={userSendmail}>
          Didn't receive the email? Check your spam or junk folder, ensure the email address is correct, or try resending the link.<Link>Click to resend.</Link>
        </p>
        <p className="loginBottom resetBottom resetForgotPassword">
          <Link to={"/sign-in"}>
            <img src={back} alt="Back" className="back-arrow"/>
            Back to Sign In
          </Link>
        </p>
      </div>
    </>
  );
}

export default CheckEmail;