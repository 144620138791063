import React, { useState } from 'react';
import faceSmile from "../../images/face-smile.svg";
import axios from 'axios';


import close from '../../images/Settings/close.svg'
function BasicPlanModal() {

    const [basicPlanModal, setBasicPlanModal] = useState(true);

    const handleClose = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/user/changestatus/${JSON.parse(localStorage.getItem("userinfo"))?._id}`, {
                status: "active"
            })
            setBasicPlanModal(false);
            document.body.classList.remove('hidden-message');
        } catch (error) {
            if (error?.response?.status == 400) {
                console.log(error);
            }
        }
    }

    return (
        <>
            {basicPlanModal && (<div className='welcomeModal planModal'>
                        <div className="popupTop popupTopClose closePlan">
                                    <img src={close} onClick={e => { setBasicPlanModal(false); }} alt="Icon" />
                                </div>
                <div className='welcomeFace'>
                    <img
                        src={faceSmile}
                        alt="faceSmile-icon" />
                </div>
                <h3 className='popupTitle'>Your account is now active!</h3>
                <p className='popupDesc'>We've restored you to your previous billing plan, and your web profile is live once again. It's now visible to your audience and visitors, so you can continue to present who you are & what you do.<br /><br />
                    We're thrilled to have you with us again.</p>
               
                <div className="btnGroupLink btnGroupWidth">
                    <button className="primary-button button" type="button" onClick={handleClose}>Finish</button>
                </div>
            </div>)}
        </>
    )
}

export default BasicPlanModal