import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '.././App.css';
import clear from '.././images/delete.svg'
import edit from '.././images/pen.svg'
import SingleDeletePage from './DeleteModal/SingleDeletePage';

function PageActionDropdown(props) {
  let index = props.index;
  let type = props.type;
  let togglePopup = props.togglePopup;

  const [isPopupOpenDeleteAccount, setIsPopupOpenDeleteAccount] = useState(false);
  const [areDropdownsOpen, setAreDropdownsOpen] = useState(false);
  const [isEditDeleteDropdownOpen, setIsEditDeleteDropdownOpen] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState('');

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (e.target != null && isPopupOpenDeleteAccount && (e.target.classList.contains('welcomeOverlay'))) {
        setIsPopupOpenDeleteAccount(false);        
        document.body.classList.remove('hidden-message');
        togglePopup();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isPopupOpenDeleteAccount]);


  const openPopup = (newStatus) => {
    if (newStatus === 'Published' || newStatus === 'Draft' || newStatus === 'Deleted') {
      setAreDropdownsOpen(true);
      setIsEditDeleteDropdownOpen(false);
    } else {
      setAreDropdownsOpen(false);
      setIsEditDeleteDropdownOpen(false);
    }
    props?.changeStatus(newStatus);
    const dropdown = document.querySelector('.dropdown');
    dropdown.style.display = 'none';
  };

  const handleDelete = () => {
    setIsPopupOpenDeleteAccount(true)
    document.body.classList.add('hidden-message')
    const dropdown = document.querySelector('.dropdown');
    dropdown.style.display = 'none';
  }

  const handleRestore = async () => {
    // document.body.classList.add('hidden-message')
    const dropdown = document.querySelector('.dropdown');
    dropdown.style.display = 'none';

    let updateAPI = `${process.env.REACT_APP_API_URL}/${type}/update/${index}`;

    if (type === 'page') {
        updateAPI = `${process.env.REACT_APP_API_URL}/pages/updatepage/${index}`;
    }
    if (type === 'publication') {
        updateAPI = `${process.env.REACT_APP_API_URL}/pages/updatepublication/${index}`;
    }
    if (type === 'newsletter') {
      updateAPI= `${process.env.REACT_APP_API_URL}/newsLetter/updatestatus/${index}`
    }
    
    const response = await axios.post(
        updateAPI,
        {
            status: props?.data?.previous_status,
            previous_status: props?.data?.previous_status
        }
    );

    if (response) {
        props?.updateOriginalData((prevData) => {
            const updatedData = prevData.filter((item) => item._id !== index);
            return updatedData;
        });
        if (props?.setPages) {
            props?.setPages((prevPages) => prevPages.filter((item) => item._id !== index));
        }
    }
}


  const closeAccountDeletedPopup = () => {
    setIsPopupOpenDeleteAccount(false)
    document.body.classList.remove('hidden-message');
  }

  return (
    <>
      <div className='dropdown addDropdown dropdown-field'>
        <ul>
          {props?.data?.status === "Deleted" ?
            (
              <>
                <li onClick={() => handleRestore()}>
                  <a href="#">
                    Restore
                  </a>
                </li>
                <li onClick={() => {
                  handleDelete();
                }}>
                  <a href="#">
                    Delete
                  </a>
                </li>
              </>
            ) : (
              <>
                {areDropdownsOpen && (
                  <>
                    <li>
                      <Link to={'/dashboard/' + type + '/' + index}>
                        <img src={edit} alt="Icon" />
                        Edit
                      </Link>
                    </li>
                    <li onClick={() => {
                      handleDelete();
                    }}>
                      <a href="#">
                        <img src={clear} alt="Icon" />
                        Delete
                      </a>
                    </li>
                  </>
                )}

                {!areDropdownsOpen && (
                  <>
                    {
                      (type == 'home' || type == 'contact') &&
                        <li>
                            <Link to={'/dashboard/page/' + index}>
                              <img src={edit} alt="Icon" />
                              Edit
                            </Link>
                        </li>
                    }
                    {
                      type !== 'home' && type !== 'contact' &&
                        <>
                          <li>
                            <Link to={'/dashboard/' + type + '/' + index}>
                              <img src={edit} alt="Icon" />
                              Edit
                            </Link>
                          </li>
                          <li onClick={() => {
                            handleDelete();
                          }}>
                            <a href="#">
                              <img src={clear} alt="Icon" />
                              Delete
                            </a>
                          </li>
                        </>
                    }
                  </>
                )}

              </>
            )}
        </ul>
      </div>

      {isPopupOpenDeleteAccount && <div className="welcomeOverlay"></div>}
      <SingleDeletePage isOpen={isPopupOpenDeleteAccount} onClose={closeAccountDeletedPopup} status={deleteStatus} data={props?.data} setPages={props?.setPages} type={props?.type} setItemToDelete={props?.setItemToDelete} index={props?.data?._id} updateOriginalData={props?.updateOriginalData} setShowNotification={props?.setShowNotification} action="action"/>

    </>
  );
}

export default PageActionDropdown;
