import React, { useState, useEffect, useCallback, useRef } from "react";
import { BubbleMenu } from "@tiptap/react";
import axios from 'axios';

import icons from "../../iconsList.json";

const PublicationBubbleMenu = ({ editor, appendTo }) => {
  const toggleRef = useRef(null); 
  
  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [categories, setCategories] = useState([])
  const [selectedCategorie, setSelectedCategorie] = useState([])
  const [editAttributes, setEditAttributes] = useState({
    title: "publications",
    layout: "grid",
    categories: []
  });

  useEffect(() => {
    if (editor && editor.isActive("publications")) {
      const attrs = editor.getAttributes("publications");
      setEditAttributes((prev) => ({
        ...prev,
        ...attrs,
        layout: attrs.layout || "grid",
        categories: attrs.categories || []
      }));
    }
  }, [editor]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        toggleRef.current && 
        !toggleRef.current.contains(event.target)
      ) {
        setIsOpen(false); // Close dropdown if the click is outside
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const shouldShow = useCallback(() => {
    if (editor.isActive("publications")) {
      const defaultcategories = editor.state.selection.node?.attrs?.categories;
      let categoriesArray = [];

    // Check if defaultcategories exists and determine the categoriesArray
    if (defaultcategories) {
      // If defaultcategories is a single category, wrap it in an array
      if (Array.isArray(defaultcategories)) {
        categoriesArray = defaultcategories; // If it's already an array, use it directly
      } else if (typeof defaultcategories === "string") {
        // If it's a string, check if it contains a comma
        categoriesArray = defaultcategories.includes(',')
          ? defaultcategories.split(',').map(category => category.trim()) // Split into array
          : [defaultcategories]; // Wrap single category in an array
      }
    }
    
    setSelectedCategorie(categoriesArray);
      setButtonAttribute("categories", defaultcategories);
      const defaultcount = editor.state.selection.node?.attrs?.count;
      setCount(defaultcount);
      setButtonAttribute("count", defaultcount);
    }
    return editor.isActive("publications") && editor.isFocused;
  }, [editor]);

  const setButtonAttribute = (key, value) => {
    setEditAttributes((prev) => {
      const newAttrs = { ...prev, [key]: value };
      return newAttrs;
    });
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  
  const handleCount = (count) => {
    setCount(count);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/categories/all/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
      )
      setCategories(response.data.result)
    } catch (error) {
      console.log(error, "error");
    }
  }
  const handleApplyChange = () => {
    if (editor) {
      editor.chain().focus().updateAttributes("publications", editAttributes).run();
    }
  }

  

  useEffect(() => {
    fetchData();
  }, []);

  const allCategories = categories.map(item => item.name).filter(name => name !== undefined);
  const deleteCategories = (category) => {
    const updatedCategories = selectedCategorie.filter((item) => item !== category);
    setSelectedCategorie(updatedCategories);
    setButtonAttribute("categories", updatedCategories);
  };
  return (
    <BubbleMenu
      editor={editor}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        zIndex: 99,
        popperOptions: {
          modifiers: [{ name: "flip", enabled: false }],
        },
        appendTo: () => appendTo?.current,
        onHidden: () => setIsOpen(false)
      }}
    >
      <div className="bubble-toggle-menu button-box">
        <div className="categories-box" ref={toggleRef}>
          <label className="menu-title">Categories</label>
          <div className="categories-button" onClick={toggleDropdown}>
            <div className="select-categories">Select Categories</div>
            <div
              className="menu-icon"
              dangerouslySetInnerHTML={{ __html: icons["selectDown"] }}
            />
          </div>
            {(selectedCategorie && selectedCategorie?.length > 0) &&
              <div className="categories">
                <>
                  {
                    selectedCategorie?.map((item) => {
                      return (
                        <div className="selected-categorie">
                          <p>{item}</p>
                          <svg width="20" height="20" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => deleteCategories(item)}>
                            <path d="M7 6.72234L9.72234 4L10.5 4.77766L7.77766 7.5L10.5 10.2223L9.72234 11L7 8.27766L4.27766 11L3.5 10.2223L6.22234 7.5L3.5 4.77766L4.27766 4L7 6.72234Z" fill="#344054" />
                          </svg>
                        </div>
                      )
                    })
                  }
                </>
              </div>
            }
          {isOpen && <div className="content-type-menu categories-menu">
            {allCategories.map((item) => {
              const isSelected = selectedCategorie.includes(item); // Check if the category is already selected
              return (
                <div className="selectedcat" key={item} onClick={() => {
                  if (isSelected) {
                    // If the category is selected, remove it from the selectedCategorie array
                    const updatedCategories = selectedCategorie.filter((cat) => cat !== item);
                    setSelectedCategorie(updatedCategories);
                    setButtonAttribute("categories", updatedCategories); // Update the editAttributes with the new categories
                  } else {
                    // If the category is not selected, add it to the selectedCategorie array
                    const updatedCategories = [...selectedCategorie, item];
                    setSelectedCategorie(updatedCategories);
                    setButtonAttribute("categories", updatedCategories); // Update the editAttributes with the new categories
                  }
                }}>
                  <input className="categoriesSelect" type="checkbox" checked={isSelected} />
                  <p className="categories">
                    {item}
                  </p>
                </div>
              );
            })}

          </div>}
        </div>
        <div className="alignment-box">
          <label className="menu-title layout-title">Layout</label>
          <div className="alignment-buttons layout">
            <button
              className={`toolbar-button ${editAttributes.layout === "list" ? "active" : ""}`}
              onClick={() => setButtonAttribute("layout", "list")}
            >
              <div dangerouslySetInnerHTML={{ __html: icons["ListLayout"] }} />
            </button>
            <button className={`toolbar-button ${editAttributes.layout === "grid" ? "active" : ""}`}
              onClick={() => setButtonAttribute("layout", "grid")}
            >
              <div dangerouslySetInnerHTML={{ __html: icons["GridLayout"] }} />
            </button>

          </div>
        </div>
        <div className="publication-count">
          <div className="count-title">
            <label>Publication Count</label>
          </div>
          <input
            id="publication-count" className="publication-count-input" type="number"
            placeholder="Enter publication count"
            min={0} // Set minimum value to 0
            value={count}
            onChange={(e) => {
              const newCount = e.target.value;
              handleCount(newCount);
              setButtonAttribute("count", newCount);
            }}
          />
          <p className="field-info">Type 0 to display all publications</p>
        </div>

        <div className='button-element publication-submit-button'>
          <button className="button" onClick={handleApplyChange}>Apply</button>
        </div>

        

      </div>
    </BubbleMenu>
  );
};

export default PublicationBubbleMenu;
