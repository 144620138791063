import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { useState, useEffect } from 'react';

const getImageDimensions = async (file) => {
    return new Promise(function (resolved, rejected) {
        var i = new Image()
        i.onload = function () {
            resolved({ w: i.width, h: i.height })
        };
        i.src = file
    })
}


const GalleryImage = ({ image, onDelete }) => {

    const [dimensions, setDimensions] = useState(null);
    const [imageStyle, setImageStyle] = useState({ flex: `1 1 0%` });


    useEffect(() => {
        // Fetch image dimensions when component mounts
        const fetchDimensions = async () => {
            try {
                const dims = await getImageDimensions(image);

                const aspectRatio = (dims.w || 1) / (dims.h || 1);
                setImageStyle({ flex: `${aspectRatio} 1 0%` })

                setDimensions(dims);
            } catch (error) {
                console.error('Failed to load image dimensions', error);
            }
        };

        fetchDimensions();
    }, [image]);
    return (
        <div className='gallery-image' style={imageStyle}>
            <img src={image} alt="Uploaded" />
            <div className='delete-item-block' onClick={() => onDelete(image)}>
                <div className='delete-item'>
                    <button className='delete-button'>
                        <svg width="16" height="16" viewBox="0 0 15 14" stroke='currentColor' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.33333 3.4974V3.03073C9.33333 2.37733 9.33333 2.05064 9.20617 1.80107C9.09432 1.58155 8.91584 1.40307 8.69632 1.29122C8.44676 1.16406 8.12006 1.16406 7.46667 1.16406H6.53333C5.87994 1.16406 5.55324 1.16406 5.30368 1.29122C5.08416 1.40307 4.90568 1.58155 4.79383 1.80107C4.66667 2.05064 4.66667 2.37733 4.66667 3.03073V3.4974M5.83333 6.70573V9.6224M8.16667 6.70573V9.6224M1.75 3.4974H12.25M11.0833 3.4974V10.0307C11.0833 11.0108 11.0833 11.5009 10.8926 11.8752C10.7248 12.2045 10.4571 12.4722 10.1278 12.64C9.75347 12.8307 9.26342 12.8307 8.28333 12.8307H5.71667C4.73657 12.8307 4.24653 12.8307 3.87218 12.64C3.5429 12.4722 3.27518 12.2045 3.10741 11.8752C2.91667 11.5009 2.91667 11.0108 2.91667 10.0307V3.4974" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className='layout-wrapper'></div>
        </div>
    )
}

const GalleryImages = ({ images, setImages, editor, isFullDisplay }) => {
    const rows = [];
    const noOfImages = images?.length;

    let row = 0;


    const deleteImage = (imageToDelete) => {
        const updatedImages = images.filter((image) => image !== imageToDelete);
        setImages(updatedImages);

        // Update the node attributes to reflect the change in imageUrls
        editor.commands.updateAttributes('galleryBlock', {
            imageUrls: updatedImages
        });

        // Call deleteNode if only one image remains
        if (updatedImages.length === 0) {
            const chain = editor.chain();
            chain.setGalleryUpload();
            chain.run();
        }
    };

    const maxImagesInRow = function (idx) {
        let createNew = false;



        if (noOfImages > 1 && (noOfImages % 3 === 0) && (idx % 3 === 0)) {
            createNew = true;
        }

        if (noOfImages > 1 && (noOfImages % 3 === 1)) {
            if (idx > 3) {
                if (idx % 3 === 2) {
                    createNew = true;
                }
            } else {
                if ((idx % 3 === 0)) {
                    createNew = true;
                }
            }

        }

        if (noOfImages > 1 && (noOfImages % 3 === 2)) {
            if (idx > 3) {
                if ((noOfImages - idx + 1) % 3 === 0) {
                    createNew = true;
                }
            } else {
                if ((idx % 3 === 0)) {
                    createNew = true;
                }
            }

        }

        return createNew;
    };

    if (isFullDisplay == 'full') {
        images.forEach((image, idx) => {
            // start a new display row if necessary
            if (maxImagesInRow(idx)) {
                row = row + 1;
            }

            if (!rows[row]) {
                rows[row] = [];
            }

            rows[row].push(image);
        })
    } else {
        // Split images into rows of 2
        for (let i = 0; i < noOfImages; i += 2) {
            rows[row] = images.slice(i, i + 2);  // Create a row with up to 2 images
            row++;
        }
    }

    return (
        <>
            {rows?.map((row, rowIndex) => (
                <div key={rowIndex} className="image-row">
                    {row?.map((item, idx) => (
                        <GalleryImage key={idx} image={item} onDelete={deleteImage} />
                    ))}
                </div>
            ))}
        </>
    );
};


const GalleryBlockView = (props) => {
    const { editor, getPos, node } = props;

    const isFullDisplay = node.attrs.layout || "default";
    const images = node.attrs.imageUrls || [];
    const [image, setImages] = useState(images); // Initial images
    const [errorMessage, setErrorMessages] = useState()
    useEffect(() => {
        if (node.attrs.imageUrls) {
            setImages(images)
            if (node.attrs.imageUrls.length > 12) {
                setErrorMessages("You can upload up to 12 images.")
            } else {
                setErrorMessages("")
            }
        }
    }, [node.attrs.imageUrls])
    const handleCaptionChange = (e) => {
        const caption = e.target.innerText; 
        editor.commands.updateAttributes(node.type.name, {
          ...node.attrs, 
          caption:caption, 
        });
      };
      const handleCaptionClick = () => {
        const nodePosition = getPos(); 
        editor.commands.setNodeSelection(nodePosition);
    };
    return (
        <NodeViewWrapper data-type='image'>
            <div className='image-block gallery-block' >
                {node.attrs.imageUrls.length > 0 ? (
                    <>
                        <div 
                            className={`image-preview gallery-preview image-width-${isFullDisplay}`} >
                            <div className='image-preview-cover'>
                                <GalleryImages images={image} setImages={setImages} editor={editor} isFullDisplay={isFullDisplay} />
                            </div>
                            <div className='video-title' contentEditable={false}>
                                <p
                                    className="video-caption"
                                    contentEditable={true}
                                    onBlur={handleCaptionChange} // Triggered when the user finishes editing
                                    suppressContentEditableWarning={true} // Prevent warning about contentEditable
                                    onClick={handleCaptionClick}
                                >{node.attrs.caption}</p>
                            </div>
                        </div>

                    </>
                ) : (
                    <p>No image available</p>
                )}
                <div className='layout-wrapper'></div>
            </div>
            {
                (errorMessage) &&
                <p className="error-message" >{errorMessage}</p>
            }
        </NodeViewWrapper>
    );
};

export const GalleryBlock = Node.create({
    name: 'galleryBlock',

    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
            imageUrls: {
                default: [],
            },
            caption: {
                default: '',
            },
            layout: {
                default: 'default',
            },
            imageKeys: {
                default: []
            },
            imageId: {
                default: []
            },
            imageAlt: {
                default: []
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
                getAttrs: (dom) => {
                    const images = Array.from(dom.querySelectorAll('img'));
                    const imageUrls = images.map(img => img.getAttribute('src'));
                    const imageKeys = images.map(img => img.getAttribute('data-key') || '');
                    const imageId = images.map(img => img.getAttribute('data-id') || '');
                    const imageAlt = images.map(img => img.getAttribute('alt') || '');
                    const caption = dom.querySelector('.video-caption')?.innerText || '';
                    return {
                        imageUrls: imageUrls,
                        layout: dom.getAttribute('data-layout') || 'default',
                        imageKeys: imageKeys,
                        imageId: imageId,
                        imageAlt: imageAlt,
                        caption: caption,
                    };
                },
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        const { imageUrls = [], caption = '', layout = 'default', imageKeys = [], imageId = [], imageAlt = [] } = HTMLAttributes;

        return [
            'div',
            {
                'data-type': this.name,
                'data-caption': caption,
                'data-layout': layout,
                class: 'gallery-block gallery-block-view',
            },
            ['div', { class: `gallery-preview image-width-${layout}` },
                ...imageUrls.map((imageUrl, index) => (

                    ['img', {
                        src: imageUrl,
                        alt: imageAlt[index],
                        'data-key': imageKeys[index],
                        'data-id': imageId[index],
                    }]

                ))
            ],
            caption ? 
            ['div', { class: 'video-title' },
                ['p', { class: 'video-caption' }, caption]
            ]
            : ''
        ];
    },


    addCommands() {
        return {
            setGalleryBlock:
                (options) =>
                    ({ commands }) => {
                        return commands.insertContent({
                            type: this.name,
                            attrs: {
                                imageUrls: options?.imageUrls || '',
                                caption: options?.caption || '',
                                layout: options?.layout || '',
                                imageKeys: options?.imageKeys || '',
                                imageId: options?.imageId || '',
                                imageAlt: options?.imageAlt || '',
                            },
                        });
                    },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(GalleryBlockView);
    },
});
