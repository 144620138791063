import { useState } from 'react';

import more from '.././images/more.svg'
import DeleteSubscriberModel from './Modal/DeleteSubscriberModel';

const SubscriberActionDropdown = (props) => {

    let data = props.data

    const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);

    const handleDelete = () => {
        setIsOpenDeletePopup(true);
        document.body.classList.add('hidden-message')
        const dropdown = document.querySelector('.dropdown');
        dropdown.style.display = 'none';
    }

    const closeDeletePopup = () => {
        console.log('come closeDeletePopup')
        setIsOpenDeletePopup(false)
        document.body.classList.remove('hidden-message');
        props.togglePopup(null)
    }

    return (
        <>
        <div className='dropdown addDropdown dropdown-field'>
            <ul>
                <li onClick={() => { handleDelete(); }}><a href="#">Delete</a></li>
            </ul>
        </div>

        {isOpenDeletePopup && 
            <>
                <div className="welcomeOverlay"></div>
                <DeleteSubscriberModel isOpen={isOpenDeletePopup} onClose={closeDeletePopup} subsciberIds={[data._id]} fetchData={props.fetchData} setShowNotification={props?.setShowNotification}/>
            </>
        }
        </>
    )

}

export default SubscriberActionDropdown;