import noResults from '.././images/noResults.svg'

import '.././App.css';

function NoResultsSubscriber(props) {

    const descptions = {
        all: {
            title: "No subscribers yet",
            desctription: "It looks like you don’t have any subscribers yet. Start attracting them by offering valuable content and engaging with your audience.",
        },
        free: {
            title: "No free subscribers yet",
            desctription: "It looks like you don’t have any free subscribers yet. Start attracting them by offering valuable content and engaging with your audience.",
        },
        paid: {
            title: "No paid subscribers yet",
            desctription: "It looks like you don’t have any paid subscribers yet. Start attracting them by offering valuable content and engaging with your audience.",
        },
        trial: {
            title: "No trial subscribers yet",
            desctription: "It looks like you don’t have any trial subscribers yet. Start attracting them by offering valuable content and engaging with your audience.",
        }
    }

    let title = `No subscribers yet`;
    let descption= "It looks like you don’t have any subscribers yet. Start attracting them by offering valuable content and engaging with your audience.";

    if (descptions[props?.status]) {
        title = descptions[props?.status].title;
        descption = descptions[props?.status].desctription;

    }

    return (
        <div className='NoResults'>
            <div className="noResultsBody">
                <img src={noResults} alt="No results" />
                <p className="noResTitle">{title}</p>
                <p className='noResDesc'>{descption}</p>
            </div>
        </div>
    );
}

export default NoResultsSubscriber;
