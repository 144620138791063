import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import '../.././App.css';
import AdSense from '../../images/Settings/adSense.png';
import Stripe from '../../images/Settings/stripe2.png';
import PlusIcon from '../../images/plus.svg';
import deleteIcon from '../../images/delete.svg'
import downloadIcon from '../../images/download.svg'
import { MonetizationModal } from '../../Components/Modal/monetizationModal';
import { Link } from 'react-router-dom';
import dateFormatter from '../../utils/dateFormatter';

import currencies from '../../utils/currency.json'
import NotificationMessage from '../../Components/NotificationMessage';
import { DataTableList } from '../../Components/DataTable/DataTable';
import Color from '@tiptap/extension-color';

export default function Monetization(props) {

    const refAction = useRef();
    
    props.setActivePage("Monetization");
    const [monetization, setMonetization] = useState({
        displayAd: false,
        googleAd: "Disconnect",
        StripeConnected: false,
        StripeConnectedTo: '',
        subsTxt: "",
        subsCancelTxt: "",
        planName: "Free Plan",
        planPrice: "",
        planDescription: "",
        planSuccessText: "",
        planNameOne: "",
        pricePerMonthOne: "",
        pricePerYearOne: "",
        descriptionOne: "",
        successTextOne: "",
        deleteReq: false,
    })

    const [isLoading, setIsLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [isPlanOpen, setPlanOneOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState();
    const [isDeletePlan, setDeletePlan] = useState(false)
    const [isPriceChanged, setIsPriceChanged] = useState(false)
    const [isPriceChangeOpen, setIsPriceChangeOPen] = useState(false)
    const [isHasPlanOne, setIsHasPlanOne] = useState(false)
    const [isDisconnectStripe, setDisconnectStripe] = useState(false)
    const [shouldCreateNew, isShouldCreateNew] = useState(false)

    const [isSripeLoading, setIsStripeLoading] = useState(false);
    const [stipeConnectError, setStipeConnectError] = useState('');
    const [stripeable, setStripeable] = useState(false);

    const [stripeLoginUrl, setStripeLoginUrl] = useState('');
    const [showPopup, setShowopup] = useState(true);

    const [invoices, setInvoices] = useState([])
    const [payouts, setPayuts] = useState([])
    const [activeTab, setActiveTab] = useState('Transactions')
    const [isActionOpen, setIsActionOpen] = useState(false)
    const [notification, setNotification] = useState(false);
    const [stripeConnectNotify, setStripeConnectNotify] = useState(false);
    const [stripeDisConnectNotify, setStripeDisConnectNotify] = useState(false);
    const [googleConnect, setGoogleConnect] = useState(false);
    const [googleDisConnect, setGoogleDisConnect] = useState(false);
    const [columnWidths, setColumnWidths] = useState([]);
    

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (refAction.current && !refAction.current.contains(e.target) && (e.target.classList.contains('dropdown'))) {
                // setActivateEdit(false);
                setIsActionOpen(false)
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, []);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )
            const userMeta = response?.data?.result?.userMeta;
            setStripeable(response?.data?.result?.canConnectStipe);
            setStripeLoginUrl(response?.data?.result?.stripeLoginUrl || '#')
            setShowopup(response?.data?.result?.hasPaidSubscribers)

            setMonetization({
                displayAd: userMeta?.googleAds?.displayAd || false,
                googleAd: userMeta?.googleAds?.googleAd || "Disconnect",
                StripeConnected: userMeta?.stripeAccountData?.details_submitted || false,
                StripeConnectedTo: userMeta?.stripeAccountData?.details_submitted ? (userMeta?.stripeAccountData?.email || '') : '',
                subsTxt: userMeta?.subsTxt || "Receive regular updates and insights directly to your email, keeping you informed and engaged.",
                subsCancelTxt: userMeta?.subsCancelTxt || "I’m sorry to see you go! If you decide to cancel your subscription, you will no longer receive regular updates and insights directly to your email.",
                planName: userMeta?.subsPlans?.freePlan?.planName || "Free Plan",
                planPrice: userMeta?.subsPlans?.freePlan?.planPrice || 0,
                planDescription: userMeta?.subsPlans?.freePlan?.planDescription || "",
                planSuccessText: userMeta?.subsPlans?.freePlan?.planSuccessText || "",
                planNameOne: userMeta?.subsPlans?.planOne?.planNameOne || "",
                pricePerMonthOne: userMeta?.subsPlans?.planOne?.pricePerMonthOne || 0,
                pricePerYearOne: userMeta?.subsPlans?.planOne?.pricePerYearOne || 0,
                descriptionOne: userMeta?.subsPlans?.planOne?.descriptionOne || "",
                successTextOne: userMeta?.subsPlans?.planOne?.successTextOne || "",
                deleteReq: userMeta?.stripeAccountData?.deleteReq || false
            });

            if (userMeta?.subsPlans?.planOne?.planNameOne) {
                setPlanOneOpen(true);
                setIsHasPlanOne(true);
                setIsPriceChanged(false)
            }

            const invoicesData = await axios.get(
                `${process.env.REACT_APP_API_URL}/subscriber/getInvoices/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )

            setInvoices(invoicesData?.data?.result?.invoices || [])
            const payoutsData = invoicesData?.data?.result?.invoices.filter(item => item.type === "payout") || []
            setPayuts(payoutsData)

            setCancelLoading(false)
        } catch (error) {
            setCancelLoading(false);
            console.log(error, "error");
        }
    }

    const columns = [
        {
            name: 'Date',
            selector: (row) => {
                return (
                    dateFormatter(row.date, 'date', '', false, true)
                )
            },
        },
        {
            name: 'Type',
            selector: (row) => {
                return (
                    row?.type || '-'
                )
            },
            style: {  paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Description',
            selector: (row) => {
                return (
                    row?.description || '-'
                )
            },
            paddingLeft: 0,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px"},
        },
        {
            name: 'Amount',
            selector: (row) => {
                return (
                    row.amount < 0 ? `- ${currencies[row?.currency?.toUpperCase()]?.symbol_native || '$'}${Math.abs(row.amount)} ${row?.currency?.toUpperCase() || 'USD'}` : `${currencies[row?.currency?.toUpperCase()]?.symbol_native || '$'}${row.amount} ${row?.currency?.toUpperCase() || 'USD'}`
                )
            },
            paddingLeft: 0,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Destination',
            selector: (row) => {
                return (
                    row?.destination || '-'
                )
            },
            paddingLeft: 0,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Status',
            selector: (row) => {
                return (
                    row?.status === 'paid' ? "Paid" : row?.status === 'succeeded'  ? "Succeeded" : "-"
                )
            },
            justifyContent: 'flex-end',
            allowOverflow: true,
            button: true,
            width: "70px",
            paddingRight: '16px',
            style: { textAlign: 'right', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", width: "70px", }, // Aligning to the right
            headerStyle: { textAlign: 'right' },
        },
        {
            name: 'Receipt',
            selector: (row) => {
                return (
                    <Link to={row.receipt_url} target="_blank" rel="noopener noreferrer">
                        <img src={downloadIcon} alt="downloadIcon" />
                    </Link>
                )
            },
            headerStyle: { justifyContent: 'flex-end' },
            maxWidth: '50px',
            justifyContent: 'flex-end',
            textAlign: 'right',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", maxWidth: '50px', fontSize: "14px", justifyContent: 'flex-end' },
        },
    ]


    const PayoutColumns = [
        {
            name: 'Date',
            selector: (row) => {
                return (
                    dateFormatter(row.date, 'date', '', false, true)
                )
            },
        },
        {
            name: 'Type',
            selector: (row) => {
                return (
                    row?.type || '-'
                )
            },
            style: {  paddingLeft: 0, paddingRight: "16px",  fontSize: "14px"},
        },
        {
            name: 'Description',
            selector: (row) => {
                return (
                    row?.description || '-'
                )
            },
            paddingLeft: 0,
            style: { paddingLeft: 0, paddingRight: "16px",  fontSize: "14px"},
        },
        {
            name: 'Amount',
            selector: (row) => {
                return (
                    row?.amount < 0 ? `- ${currencies[row?.currency?.toUpperCase()]?.symbol_native || '$'}${Math.abs(row.amount)} ${row?.currency?.toUpperCase() || 'USD'}` : `${currencies[row?.currency?.toUpperCase()]?.symbol_native || '$'}${row.amount} ${row?.currency?.toUpperCase() || 'USD'}`
                )
            },
            paddingLeft: 0,
            style: { paddingLeft: 0, paddingRight: "16px",  fontSize: "14px" },
        },
        {
            name: 'Destination',
            selector: (row) => {
                return (
                    row?.destination || '-'
                )
            },
            paddingLeft: 0,
            style: { paddingLeft: 0, paddingRight: "16px",  fontSize: "14px" },
        },
        {
            name: 'Status',
            selector: (row) => {
                return (
                    row?.status === 'paid' ? "Paid" : row?.status === 'succeeded'  ? "Succeeded" : "-"
                )
            },
            justifyContent: 'flex-end',
            allowOverflow: true,
            button: true,
            width: "70px",
            paddingRight: '0',
            style: { textAlign: 'right', paddingLeft: 0, paddingRight: "0",  width: "70px", }, // Aligning to the right
            headerStyle: { textAlign: 'right' },
        },
    ]

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };
    
    const handleChange = (e) => {
        setMonetization((prevMonetization) => ({ ...prevMonetization, [e.target.name]: e.target.value }));
        setErrorMessage((prevErrorMessage) => ({ ...prevErrorMessage, [e.target.name]: '' }));
    };

    const handlePriceChange = (e) => {
        if (isHasPlanOne) {
            setIsPriceChanged(true);
        }

        setMonetization((prevMonetization) => ({ ...prevMonetization, [e.target.name]: e.target.value }));
        setErrorMessage((prevErrorMessage) => ({ ...prevErrorMessage, [e.target.name]: '' }));
    }

    const handleSubmit = async () => {
        const errors = {
            planName: "",
            planPrice: "",
            subsTxt: '',
            subsCancelTxt: "",
            planDescription: "",
            planSuccessText: "",
            planNameOne: "",
            pricePerMonthOne: "",
            pricePerYearOne: "",
            descriptionOne: "",
            successTextOne: "",
        };
        if (monetization.planName === '') {
            errors.planName = "Please enter a free plan name";
        }
        if (monetization.subsTxt === "") {
            errors.subsTxt = "Please enter a general text about the subscription";
        }
        if (monetization.subsCancelTxt === "") {
            errors.subsCancelTxt = "Please enter a cancellation text";
        }
        if (monetization?.planDescription === "") {
            errors.planDescription = "Please enter a description about the free plan";
        }
        if (monetization.planSuccessText === "") {
            errors.planSuccessText = "Please enter a success text";
        }
        if (isPlanOpen == true) {
            if (!monetization.planNameOne) {
                errors.planNameOne = "Please enter a paid plan name";
            }
            if (!monetization.pricePerMonthOne || isNaN(monetization.pricePerMonthOne)) {
                errors.pricePerMonthOne = "Please enter a price for the monthly subscription";
            }
            if (!monetization.pricePerYearOne || isNaN(monetization.pricePerYearOne)) {
                errors.pricePerYearOne = "Please enter a price for the yearly subscription";
            }
            if (!monetization.descriptionOne) {
                errors.descriptionOne = "Please enter a description about the paid plan";
            }
            if (!monetization.successTextOne) {
                errors.successTextOne = "Pleas enter a success text";
            }
        }
        const hasErrors = Object.values(errors).some(error => error !== "");
        if (hasErrors) {
            setErrorMessage(errors);
        } else {
            if (isPriceChanged && showPopup) {
                setIsPriceChangeOPen(true)
            } else {
                await handleSaveData();
                setNotification(true)
            }            
        }
    }

    const handleSaveData = async () => {        
        try {
            setIsLoading(true);
            
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/updateMonetization/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                {
                    googleAds: {
                        displayAd: monetization?.displayAd,
                        googleAd: monetization?.googleAd || "Disconnect"
                    },
                    stripeConnected: monetization?.StripeConnected ||false,
                    subsTxt: monetization?.subsTxt || "",
                    subsCancelTxt: monetization?.subsCancelTxt || "",
                    subsPlans: {
                        freePlan: {
                            planName: monetization?.planName || "Free Plan",
                            planPrice: Number(monetization?.planPrice) || 0,
                            planDescription: monetization?.planDescription || "",
                            planSuccessText: monetization?.planSuccessText || "",
                        },
                        planOne: {
                            planNameOne: (isPlanOpen) ? (monetization?.planNameOne || "") : "",
                            pricePerMonthOne: (isPlanOpen) ? (Number(monetization?.pricePerMonthOne) || 0) : "",
                            pricePerYearOne: (isPlanOpen) ? (Number(monetization?.pricePerYearOne) || 0) : "",
                            descriptionOne: (isPlanOpen) ? (monetization?.descriptionOne || "") : "",
                            successTextOne: (isPlanOpen) ? (monetization?.successTextOne || "") : "",
                        }
                    }
                }
            )
            if (response?.status === 200) {
                fetchData()
                setIsPriceChangeOPen(false)
            }
            setIsLoading(false);
            setIsPriceChanged(false);

        } catch (error) {
            setIsLoading(false);
            console.log(error, "error");
        }
    }

    const handleDeletePlan = (plan) => {        
        if(! showPopup) {
            setMonetization(prevState => {
                const { planNameOne, pricePerMonthOne, pricePerYearOne, descriptionOne, successTextOne, ...newState } = prevState;
                return newState;
            });
            setPlanOneOpen(false)
            setDeletePlan(false)
        } else if (plan === 'planOne') {
            setDeletePlan(true)
        }
    }

    const handleStipeConnect = async () => {
        if(monetization?.deleteReq) {
            return;
        }

        if(! showPopup && monetization?.StripeConnected) {
            setIsStripeLoading(true);

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/disconnectStripe/${JSON.parse(localStorage.getItem("userinfo"))?._id}`
            );

            setMonetization(prevState => {
                const { planNameOne, pricePerMonthOne, pricePerYearOne, descriptionOne, successTextOne, ...newState } = prevState;
                return { ...newState, deleteReq: true };
            });

            setPlanOneOpen(false);

            if(response?.data?.result?.canDisconnect) {                    
                setDisconnectStripe(false);
                isShouldCreateNew(true);  
                setStripeDisConnectNotify(true);
            }

            setIsStripeLoading(false);

            return false;            
        }

        try {
            setStipeConnectError('')
            setIsStripeLoading(true);

            if(monetization?.StripeConnected) {
                setDisconnectStripe(true);
            } else {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/createStripeAccount/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    {
                        createNew: shouldCreateNew,
                    }
                );
                if (response.data.status) {
                    const stripeAccountLinkUrl = response.data.result.stripeAccountLink.url;
                    window.open(stripeAccountLinkUrl, '_blank');
                    setStripeConnectNotify(true)
                } else {
                    setStipeConnectError(response.data.message);
                    console.error("Failed to connect to Stripe account:", response.data.message);
                }

                setDisconnectStripe(false);
            }

            setIsStripeLoading(false);
            
        } catch (error) {
            setIsStripeLoading(false);
            console.log(error, "error");
        }

    }

    const handleAgreeBtn = async (action) => {        
        try {
            if (action === 'deletePlan') {
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/deletePlan/${JSON.parse(localStorage.getItem("userinfo"))?._id}`
                );

                setMonetization(prevState => {
                    const { planNameOne, pricePerMonthOne, pricePerYearOne, descriptionOne, successTextOne, ...newState } = prevState;
                    return newState;
                });

                setPlanOneOpen(false)
                setDeletePlan(false)
            }

            if (action === 'disconnectStipe') {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/disconnectStripe/${JSON.parse(localStorage.getItem("userinfo"))?._id}`
                );

                setMonetization(prevState => {
                    const { planNameOne, pricePerMonthOne, pricePerYearOne, descriptionOne, successTextOne, ...newState } = prevState;
                    return { ...newState, deleteReq: true };
                });

                setPlanOneOpen(false);

                if(response?.data?.result?.canDisconnect) {                    
                    setDisconnectStripe(false);
                    isShouldCreateNew(true);    
                } else {
                    return 'Your account cannot be disconnected until the balance is zero. Please settle any negative balance or transfer all funds to your account. Once the balance is zero, the account will be automatically disconnected.';
                }
                return false;
            }

            if (action === 'priceChanged') {
                await handleSaveData();
            }
        } catch (error) {
            console.log({error:error, message: error.message})
        }
    }

    function clearAll() {
        setCancelLoading(true);
        fetchData();
    }

    const toggleAction = () => {
        setIsActionOpen(!isActionOpen)
    }
    
    
    return (
        <>
            <Helmet>
                <title>Monetization | Dashboard | Persone</title>
            </Helmet>
            <div className='monetization'>
                <div className="generalTop">
                    <div className="generalTopLeft">
                        <p className="generalTopLeftTitle">Monetization</p>
                        <p className="generalTopLeftDesc">
                            Update and manage your ads and subscription plans, and view all your transactions in one place.
                        </p>
                    </div>
                </div>
                <div className="generalRow">
                    <div className="generalRowDesc">
                        <p className='settingsMenuBlockTitle'>Display ads</p>
                        <p className='generalTopLeftDesc'>Enable ads on your publications to earn revenue from your content.</p>
                    </div>
                    <div className="generalBlockItems">
                        <div className = "marginRemove">
                            <div className="bigSwitch">
                                <div className={(monetization?.displayAd === true) ? 'switch switchToggleActive' : 'switch'} name="displayAd" onClick={() => setMonetization((prevMonetization) => ({ ...prevMonetization, displayAd: !monetization?.displayAd }))} value={monetization?.displayAd}>
                                    <div className='switchToggle'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    monetization?.displayAd === true &&
                    <div className="generalRow">
                        <div className="generalRowDesc">
                            <p className="settingsMenuBlockTitle">Google AdSense</p>
                            <p className="generalTopLeftDesc">Integrate your Google AdSense account to display personalized ads on your publications and earn revenue from your readers.</p>
                        </div>
                        <div className="generalBlockItems marginRemove">
                            <div>
                                <div className="connectAdSense secondary-button button" onClick={() => setMonetization((prevMonetization) => ({ ...prevMonetization, googleAd: monetization?.googleAd === 'Disconnect' ? 'Connect' : 'Disconnect' }))}>
                                    <img src={AdSense} alt="AdSense" />
                                    <p className='senseText'>{monetization?.googleAd === 'Disconnect' ? 'Connect Google AdSense' : 'Disconnect Google AdSense'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div  className={`generalRow ${monetization?.StripeConnected ? 'paddingRemoveRow' : ''}`}>
                    <div className="generalRowDesc">
                        <p className="settingsMenuBlockTitle">Connect to Stripe</p>
                        <p className="generalTopLeftDesc">Integrate your Stripe account to launch a paid newsletter and earn revenue from your subscribers.</p>
                    </div>
                    <div className="generalBlockItems marginRemove">
                        <div className='marginRemove'>
                            {stripeable ?  
                                <div className={`connectAdSense button ${monetization?.deleteReq && 'disabled'} ${monetization?.StripeConnected ? 'disconnectStipe' : 'connectStipe'}`} onClick={() => { handleStipeConnect() }}>
                                    { isSripeLoading ? 
                                        <div className="settingPage-loader">
                                            <div className="ring-loader dark"></div>
                                        </div>
                                        :
                                        <>                                    
                                            <img src={Stripe} alt="Stripe" className='stripeSize' />
                                            <p className='senseText'>{monetization?.StripeConnected ? 'Disconnect Stripe' : 'Connect Stripe'}</p>

                                        </>
                                    }
                                </div>
                                :
                                <div className="tooltip-container">
                                    <div className={`disabled connectAdSense button ${monetization?.StripeConnected ? '' : 'connectStipe'}`}>                            
                                        <img src={Stripe} alt="Stripe" className='stripeSize' />
                                        <p className='senseText'>{monetization?.StripeConnected ? 'Disconnect Stripe' : 'Connect Stripe'}</p>
                                    </div>
                                    <p className='planNote'>
                                        <span>Note: </span>
                                        Unfortunately, your country is not currently supported by Stripe. You might consider using  <a href='https://stripe.com/atlas' target='_black'>Stripe Atlas</a>, which assists in setting up a company and bank account in the US, specifically designed to integrate with Stripe. Please check periodically for updates on the supported countries by Stripe <a href='https://docs.stripe.com/connect/cross-border-payouts' target='_blank' rel="noreferrer">here</a>.
                                    </p>
                                </div>
                            }
                            {monetization?.StripeConnected && <p className='senseSubText'><span className='changeColorStripe'>Connected to:</span> <Link to={stripeLoginUrl} target="_blank" className='senseSubText'>{monetization?.StripeConnectedTo}</Link> </p>}
                        </div>
                        {monetization?.deleteReq && 
                            <p className='planNote disconnectStipeNote'>
                                <span>Note: </span>
                                Your account cannot be disconnected until the balance is zero. Please settle any negative balance or transfer all funds to your account. Once the balance is zero, the account will be automatically disconnected.
                            </p>
                        }
                        {stipeConnectError && <div className='error-message'>{stipeConnectError}</div> }
                    </div>
                </div>
                <div className=" generalTwo">
                    <div className='generalItem'>
                        <div className="generalRowDesc">
                            <p>General subscription text</p>
                            <p>Write an overview of your subscription, detailing the type of content, its value, and the frequency of updates.</p>
                        </div>
                        <div className="generalBlockItems">
                            <textarea className='generalArea' placeholder='Enter an overview of your subscription, including type, value, and frequency...' onChange={(e) => { handleChange(e) }} value={monetization?.subsTxt}  name="subsTxt"></textarea>
                            { errorMessage?.subsTxt &&
                                <div className='error-message'>{errorMessage?.subsTxt}</div>
                            }                            
                        </div>
                    </div>
                    <div className='generalItem'>
                        <div className="generalRowDesc">
                            <p>Cancel subscription text</p>
                            <p>Write a message for subscribers considering cancellation, highlighting the benefits they’ll miss and encouraging them to stay.</p>
                        </div>
                        <div className="generalBlockItems">
                            <textarea className='generalArea' placeholder='Enter a message highlighting the benefits of staying subscribed and encouraging reconsideration...' onChange={(e) => { handleChange(e) }} value={monetization?.subsCancelTxt} name="subsCancelTxt"></textarea>
                            { errorMessage?.subsCancelTxt &&
                                <div className='error-message'>{errorMessage?.subsCancelTxt}</div>
                            } 
                        </div>
                    </div>
                </div>

                {/* if  props?.billingDetails?.subscriptions?.plan is not free_plan than show below portion*/}
                {props?.billingDetails?.subscriptions?.plan !== "free_plan" &&
                    <>
                        <div className=" generalTwo generalBorder">
                            <div className='generalItem'>
                                <div className="generalRowDesc">
                                    <p>Free Plan</p>
                                    <p>Choose a name for your free plan, write a brief description of its features, and provide  success and cancellation messages for your subscribers.</p>
                                </div>
                                <div className='freeSubscription'>
                                    <div className="generalBlockItems">
                                        <div className="generalBlockInputs">
                                            <div className='monetizationPlan'>
                                                <p className="field-label">Plan name</p>
                                                <input type="text" placeholder='Enter your free plan name' name="planName" onChange={(e) => { handleChange(e) }} value={monetization?.planName} />                                                
                                                { errorMessage?.planName &&
                                                    <div className='error-message'>{errorMessage?.planName}</div>
                                                } 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="generalBlockItems">
                                        <p className="field-label">Description</p>
                                        <textarea className='generalArea' placeholder="Enter a description of your free plan, including features and limitations..." name="planDescription" onChange={(e) => { handleChange(e) }} value={monetization?.planDescription}></textarea>                                        
                                        { errorMessage?.planDescription &&
                                            <div className='error-message'>{errorMessage?.planDescription}</div>
                                        } 
                                    </div>
                                    <div className="generalBlockItems">
                                        <p className="field-label">Success text</p>
                                        <textarea className='generalArea' placeholder="Enter the message to display after a successful subscription..." name="planSuccessText" onChange={(e) => { handleChange(e) }} value={monetization?.planSuccessText}></textarea>                                        
                                        { errorMessage?.planSuccessText &&
                                            <div className='error-message'>{errorMessage?.planSuccessText}</div>
                                        }
                                    </div>
                                    <div className={`${(isPlanOpen === true || isDisconnectStripe) ? "generalBlockItemsRemove" : "generalBlockItems"}`}>
                                        <p className='planNote'>
                                            <span>Note: </span>
                                            If you change the price of an existing subscription only new subscribers will be subject to the new rate once the price change has taken effect. Your current subscribers will continue with the plan they initially subscribed to.
                                        </p>
                                    </div>
                                    <div className={`${(isPlanOpen === true || isDisconnectStripe || ! monetization?.StripeConnected || monetization?.deleteReq ) ? "generalBlockItemsRemove" : "generalBlockItems"}`}>
                                        <button className="connectAdSense addSubscription" onClick={() => { setPlanOneOpen(true) }}>
                                            <img src={PlusIcon} alt="AdSense" />
                                            <p className='senseText'>Add paid subscription</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                         {((isPlanOpen === true || monetization?.planNameOne) && monetization?.StripeConnected ) && 
                            <div className="generalTwo">
                                <div className='generalItem'>
                                    <div className='mobilescreen'>
                                        <div className="generalRowDesc">
                                            <p>Paid Plan</p>
                                            <p>Choose a name for your paid plan, write a brief description of its features, and provide  success and cancellation messages for your subscribers.</p>
                                        </div>
                                        <div className='mobile-btn' onClick={() => handleDeletePlan('planOne')}>
                                            <img src={deleteIcon} alt="deleteIcon" />
                                        </div>
                                    </div>
                                    <div className='planOne'>
                                        <div className="generalBlockItems">
                                            <div className='monetizationPlan'>
                                                <p className="field-label">Plan name</p>
                                                <input type="text" placeholder='Enter your paid plan name' name="planNameOne" onChange={(e) => { handleChange(e) }} value={monetization?.planNameOne} />
                                                { errorMessage?.planNameOne &&
                                                    <div className='error-message'>{errorMessage?.planNameOne}</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="generalBlockItems">
                                            <div className='monetizationPlan pricepermonth'>
                                                <div className='setWidth'>
                                                    <p className="field-label">Price for monthly subscription:</p>
                                                </div>
                                                <div className='setFlex'>
                                                    <input type="text" name="pricePerMonthOne" placeholder="0.00" className='firstInput' 
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*\.?\d*$/.test(value)) {
                                                            handlePriceChange(e);
                                                            }
                                                        }} value={monetization?.pricePerMonthOne} 
                                                    />
                                                    <input type="text" name="pricePerMonthOne" className='secondInput' placeholder="USD" readOnly />
                                                </div>
                                            </div>
                                            { errorMessage?.pricePerMonthOne &&
                                                <div className='error-message'>{errorMessage?.pricePerMonthOne}</div>
                                            }
                                        </div>
                                        <div className="generalBlockItems">
                                            <div className='monetizationPlan pricepermonth'>
                                                <div className='setWidth'>
                                                    <p className="field-label">Price for yearly subscription:</p>
                                                </div>
                                                <div className='setFlex'>
                                                    <input type="text" name="pricePerYearOne" placeholder="0.00" className='firstInput' 
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*\.?\d*$/.test(value)) {
                                                            handlePriceChange(e);
                                                            }
                                                        }} value={monetization?.pricePerYearOne} 
                                                    />
                                                    <input type="text" name="pricePerMonthOne" className='secondInput' placeholder="USD" readOnly />
                                                </div>
                                            </div>
                                            { errorMessage?.pricePerYearOne &&
                                                <div className='error-message'>{errorMessage?.pricePerYearOne}</div>
                                            }
                                        </div>
                                        <div className="generalBlockItems">
                                            <p className="field-label">Description</p>
                                            <textarea className='generalArea' placeholder="Enter a description of your paid plan, including features and benefits..." name="descriptionOne" onChange={(e) => { handleChange(e) }} value={monetization?.descriptionOne}></textarea>
                                            { errorMessage?.descriptionOne &&
                                                <div className='error-message'>{errorMessage?.descriptionOne}</div>
                                            }
                                        </div>
                                        <div className="generalBlockItems">
                                            <p className="field-label">Success text</p>
                                            <textarea className='generalArea' placeholder="Enter the message to display after a successful subscription..." name="successTextOne" onChange={(e) => { handleChange(e) }} value={monetization?.successTextOne}></textarea>
                                            { errorMessage?.successTextOne &&
                                                <div className='error-message'>{errorMessage?.successTextOne}</div>
                                            }
                                        </div>
                                        <div className='generalBlockItems'>
                                            <p className='planNote'>
                                                <span>Note: </span>
                                                If you change the price of an existing subscription only new subscribers will be subject to the new rate once the price change has taken effect. Your current subscribers will continue with the plan they initially subscribed to.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='deletePlan' onClick={()=>{handleDeletePlan('planOne')}}>
                                        <img src={deleteIcon} alt="deleteIcon" />
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
            <div className="saveButtons">
                <button className={`settingsWhite secondary-button ${cancelLoading ? 'btn_loading' : 'button'}`} onClick={(e) => clearAll()}>
                    {cancelLoading ?
                        <div className="settingPage-cancelLoader ">
                            <div className="ring-loader cancel"></div>
                        </div>
                        : "Cancel"}
                </button>
                <button className={`settingsBlue primary-button ${isLoading ? 'btn_loading' : 'button'}`} onClick={handleSubmit}>
                    {isLoading ?
                        <div className="settingPage-loader">
                            <div className="ring-loader"></div>
                        </div>
                        : "Save"
                    }
                </button>
            </div> 

            {invoices.length > 0 && 
                <div className="payoutSetting settingsMenu">
                    <p className={`settingMenu ${activeTab === 'Transactions' && 'active'}`} onClick={() => setActiveTab('Transactions')}>All Transactions</p>
                    {payouts.length > 0 && <p className={`settingMenu ${activeTab === 'Payouts' && 'active'}`} onClick={() => setActiveTab('Payouts')}>Payouts</p> }
                </div>
            }

            { (invoices.length > 0 && activeTab === 'Transactions') &&
                <div className="monetizationBilling dataTable-list">
                    <div className="billingTable">
                        <DataTableList
                            columns={columns}
                            data={invoices}
                            progressPending={isLoading}
                            progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                            customStyles={customStyles}
                            selectableRows={false}
                        />
                    </div>
                </div>
            }

            { (payouts.length > 0 && activeTab === 'Payouts') &&
                <div className="BillingTableWrapper monetizationBilling dataTable-list">
                    <div className="billingTable">
                        <DataTableList
                            columns={PayoutColumns}
                            data={payouts}
                            progressPending={isLoading}
                            progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                            customStyles={customStyles}
                            selectableRows={false}
                        />
                    </div>
                </div>
            }
                
            {
                ((isDeletePlan || isDisconnectStripe || isPriceChangeOpen) && showPopup) && <MonetizationModal isDeletePlan={isDeletePlan} isDisconnectStripe={isDisconnectStripe} isPriceChange={isPriceChangeOpen} setPlanOneOpen={setPlanOneOpen} setMonetization={setMonetization} monetization={monetization} handleSaveData={handleSaveData} setDeletePlan={setDeletePlan} setIsPriceChanged={setIsPriceChangeOPen} setDisconnectStripe={setDisconnectStripe} isShouldCreateNew={isShouldCreateNew} handleAgreeBtn={handleAgreeBtn} />
            }

            {notification &&
                <NotificationMessage  openNotify={notification} setOpenNotify={setNotification} type={'success'} title={`Your monetization settings has been successfully updated!`} />
            }
            {stripeConnectNotify &&
                <NotificationMessage  openNotify={stripeConnectNotify} setOpenNotify={setStripeConnectNotify} type={'success'} title={`Your Stripe account has been successfully connected`} />
            }
            {stripeDisConnectNotify &&
                <NotificationMessage  openNotify={stripeDisConnectNotify} setOpenNotify={setStripeDisConnectNotify} type={'success'} title={`Your Stripe account has been successfully disconnected`} />
            }
            {googleConnect &&
                <NotificationMessage  openNotify={googleConnect} setOpenNotify={setGoogleConnect} type={'success'} title={`Your Google Adsense account has been successfully connected`} />
            }
            {googleDisConnect &&
                <NotificationMessage  openNotify={googleDisConnect} setOpenNotify={setGoogleDisConnect} type={'success'} title={`Your Google Adsense account has been successfully disconnected`} />
            }
        </>
    )
}