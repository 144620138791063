
import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import axios from 'axios';
import Cookies from 'js-cookie';

import AddNewDropdown from './AddNewDropdown';
import '.././App.css';

/* IMAGES */
import logo from '../../src/images/persone-logo.png'
import logoText from '../../src/images/persone-logo-text.png'
import plus from '.././images/plus-icon.svg'
import sideLogout from '.././images/icon-logout.svg'
import avatar from '.././images/profile-user.svg'
import sendIcon from '../images/icon-sent.svg'
import copy from '.././images/icons-copy.svg'
import down from ".././images/Settings/down.svg"
import iconLock from ".././images/locked.svg"

import Home from '../../src/images/sidebar/icon-home.svg';
import Profile from '../../src/images/sidebar/icon-myprofile.svg';
import Pages from '../../src/images/sidebar/icon-pages.svg';
import Publication from '../../src/images/sidebar/icon-publications.svg';
import Newsletters from '../../src/images/sidebar/icon-newsletters.svg';
import Category from '../../src/images/sidebar/icon-tags.svg';
import Subscribers from '../../src/images/sidebar/icon-subscribers.svg';
import Media from '../../src/images/sidebar/icon-media.svg';
import Analiytics from '../../src/images/sidebar/icon-analytics.svg';
import ProfileAssistant from '../../src/images/sidebar/icon-profileassistant.png';
import settings from '../../src/images/sidebar/icon-settings.svg'
import ChangePlan from './Settings/ChangePlan';
import link from ".././images/link.svg"
import MenuDropdown from './MenuDropdown';
import { InvitationModel } from './Modal/InvitationModel';
import { ChangePlanSuccessModel } from './Modal/ChangePlanSuccessModel';
import Notification from './Notification';
import NotificationMessage from './NotificationMessage';


function Menu(props) {
    const navigate = useNavigate();
    const ref = useRef();
    const myNavRef = useRef();
    const refMobile = useRef();
    const hamburgerRef = useRef();
    const hamburgerMobileRef = useRef();
    const stickymenu = useRef();
    let menuRef = useRef();
    const addRef = useRef()

    const [addTabActive, setAddTabActive] = useState(false)
    const [userInfoActive, setUserInfoActive] = useState(false)
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [profileSlug, setProfileSlug] = useState("");
    const [mobileMenuActive, setMobileMenuActive] = useState(false)
    const [isLoading, setIsLoading] = useState(false); 
    const [invitateTo, setInvitateTo] = useState('');
    const [invitationError, setInvitationError] = useState(false);
    const [invitationMessage, setInvitationMessage] = useState('');
    const [invitationNotification, setInvitationNotification] = useState(false);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [isSubscriberSubMenuOpen, setIsSubscriberSubMenuOpen] = useState(false);
    const [isPublicationSubMenuOpen, setIsPublicationSubMenuOpen] = useState(false);
    const [isNewsletterSubMenuOpen, setIsNewsletterSubMenuOpen] = useState(false);
    const [isPageSubMenuOpen, setIsPagesSubMenuOpen] = useState(false);
    const [isChangePlanOpen, setIsChangePlanOpen] = useState(false);

    const [avatarImage, setAvatarImage] = useState(avatar);
    const [showStickyMenu, setShowStickyMenu] =useState(false);
    const [mobileStickyMenu, setMobileStickyMenu] = useState(false)
    const [userMetaData, setUserMetaData] = useState({})
    const [isOpenInvitationPopup, setOpenInvitationPopup] = useState(false)
    const [openNotify, setOpenNotify] = useState(false);
    const [openSuccessPlanPopup, setOpenSuccessPlanPopup] = useState(false)
    const [sendInvitation, setSendInvitation] = useState(false)
    const [tooltipVisible, setTooltipVisible] = useState(false); 

    mobileMenuActive ? disableBodyScroll(document) : enableBodyScroll(document)

    useEffect(() => {
        if (userInfoActive === true) {
            setMobileMenuActive(false)
        } else if (mobileMenuActive === true) {
            setUserInfoActive(false)
        }
    }, [mobileMenuActive, userInfoActive]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            const classListToCheck = ['menuList', 'menuRight', 'menuListItem', 'logo-hamburger-menu', 'sidebar-text-box', 'user-action', 'container'];


            // if (addTabActive && ref.current && !ref.current.contains(e.target) ) {
            //     setAddTabActive(false);
            // }
            if (addTabActive && ref.current && !ref.current.contains(e.target) && classListToCheck.some(className => e.target.classList.contains(className))) {
                setAddTabActive(false);
            }

            if ((userInfoActive) && ref.current && !ref.current.contains(e.target)) {
                // setAddTabActive(false);
                setUserInfoActive(false);
            }

            if ((addTabActive || userInfoActive) && refMobile.current && !refMobile.current.contains(e.target)) {
                setAddTabActive(false);
                setUserInfoActive(false);
            }

            // Close menu when we click outside
            if (mobileMenuActive === true && !hamburgerRef.current.contains(e.target) && !menuRef.current.contains(e.target)) {
                setMobileMenuActive(false);
                const myNav = document.getElementById("myNav");
                myNav.classList.remove("show-menu")
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [addTabActive, userInfoActive, mobileMenuActive]);

    useEffect(() => {
        const handleClickNavOutside = (event) => {
          if (myNavRef.current && 
            !myNavRef.current.contains(event.target) && 
            window.innerWidth <= 1024 && 
            props.menuActive && 
            !hamburgerMobileRef.current.contains(event.target) ) {
                props.resetMenuActive();
          }
        };
    
        // Attach event listener to the document for click events
        document.addEventListener('click', handleClickNavOutside);
    
        // Cleanup the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickNavOutside);
        };
    }, [props.menuActive, props.resetMenuActive]);


    const handleAddClass = (value) => {
        setMobileMenuActive(value)
        if (value === true) {
            const myNav = document.getElementById("myNav");
            myNav.classList.add("show-menu")
        }
        if (value === false) {
            const myNav = document.getElementById("myNav");
            myNav.classList.remove("show-menu")
        }
    }

    const [userData, setuserData] = useState({});

    const toConvertUppercase = (string) => {
        const words = string.split(" ");

        const capitalizedWords = words.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1)
        );
        const capitalizedString = capitalizedWords.join(" ");
        return capitalizedString;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}?notification=${true}`,
                )

                const fetchUserData = response?.data?.result?.user;
                const userData = response?.data?.result?.user;
                const userMeta = response?.data?.result?.userMeta;
                const userMediaData = response?.data?.result?.userMediaData;
                
                localStorage.setItem('userTimeZone', userMeta.timeZone);
                localStorage.setItem('userProfileSlug', userMeta.profileSlug);

                const firstName = toConvertUppercase(userData?.firstName);
                const lastName = toConvertUppercase(userData?.lastName);

                if(userMeta?.profileMedia?.cropedImage) {
                    setAvatarImage(userMediaData.profileMeida.cropedMedia.url)
                }

                setFirstName(firstName);
                setLastName(lastName);
                setProfileSlug(userMeta.profileSlug);
                setuserData(fetchUserData);
                setUserMetaData(userMeta)

            } catch (error) {
                console.log(error, "error");
            }
        }
        fetchData();
    }, [sendInvitation]);

    const token = localStorage.getItem("userinfo");

    useEffect(() => {
        if (!token) {
            navigate('/sign-in');
        }
    }, []);


    const erorBlocks = document.querySelectorAll(".erorBlock");

    if (erorBlocks.length > 0) {
        const menuListItems = document.querySelectorAll(".menuListItem");
        menuListItems.forEach((menuListItem) => {
            menuListItem.classList.add("error");
        });
    }

    const handleLogout = () => {
        localStorage.removeItem("userinfo");
        localStorage.removeItem("isCompleted");
        localStorage.removeItem("isInvited");
        localStorage.removeItem("userProfileSlug");
        sessionStorage.removeItem("userId");
        Cookies.remove('rememberMe');
        window.location.href = '/sign-in'; 
    }

    const handleHamburgerClick = () => {
        props.toggleMenu();
        props.setHover(false);

        setAddTabActive(false); 
        setUserInfoActive(false);
        setMobileStickyMenu(false);

        setIsSubMenuOpen(false);
        setIsSubscriberSubMenuOpen(false);
        setIsPublicationSubMenuOpen(false);
        setIsNewsletterSubMenuOpen(false);
        setIsPagesSubMenuOpen(false);
    };

    const handleAddNew = () => {
        if( props.menuActive ) {
            props.toggleMenu();
            props.setHover(false);
        }

        setAddTabActive(!addTabActive); 
        setUserInfoActive(false);
    }

    const handleMenuItemClick = (e, url) => {
        if (props?.isContentChange === true) {
            e.preventDefault();
            props?.showConfirmationPopup(url);
        } else {
            props.resetMenuActive();
        }
    };

    const handleSubMenuClick = (e) => {
        if( props?.isContentChange === true ){
            e.preventDefault();
            props?.showConfirmationPopup()
        }
        if (window.innerWidth > 1024) {
            props.resetMenuActive();
        }
        setIsSubMenuOpen(!isSubMenuOpen);
    };

    const handleSubscriberSubMenuClick = (e) => {   
        if( props?.isContentChange === true ){
            e.preventDefault();
            props?.showConfirmationPopup()
        }     
        if (window.innerWidth > 1024) {
            props.resetMenuActive();
        }
        setIsSubscriberSubMenuOpen(!isSubscriberSubMenuOpen);
    };
    const handlePublicationsSubMenuClick = (e) => {
        if( props?.isContentChange === true ){
            e.preventDefault();
            props?.showConfirmationPopup()
        }        
        if (window.innerWidth > 1024) {
            props.resetMenuActive();
        }
        setIsPublicationSubMenuOpen(!isPublicationSubMenuOpen);
    };
    const handleNewsletterSubMenuClick = (e) => {   
        if( props?.isContentChange === true ){
            e.preventDefault();
            props?.showConfirmationPopup()
        }     
        if (window.innerWidth > 1024) {
            props.resetMenuActive();
        }
        setIsNewsletterSubMenuOpen(!isNewsletterSubMenuOpen);
    };
    const handlePagesSubMenuClick = (e) => {
        if( props?.isContentChange === true ){
            e.preventDefault();
            props?.showConfirmationPopup()
        }
        if (window.innerWidth > 1024) {
            props.resetMenuActive();
        }
        setIsPagesSubMenuOpen(!isPageSubMenuOpen);
    };

    const handleInvitateToChange = (event) => {
        setIsLoading(false);
        setInvitationError(false);
        setInvitationMessage('');
        setInvitateTo(event.target.value);
    }

    const handleInvitateToKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendInvitation();
        }
    }

    const handleSendInvitation = async () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        setIsLoading(true);

        setInvitationError(false);
        setInvitationMessage('');

        if (!emailPattern.test(invitateTo)) {
            setInvitationError(true)
            setInvitationMessage('Please enter a valid email address.');
            setIsLoading(false);
            return;
        }

        const data = {
            email: invitateTo,
            name: `${firstName}  ${lastName}`,
            invitedFrom: JSON.parse(localStorage.getItem("userinfo"))?._id
        };

        await axios.put(`${process.env.REACT_APP_API_URL}/user/sendinvitation`, data)
            .then((response) => { 
                if( response.status === 200 ) {
                    setInvitationMessage(`<b>Invite Sent!</b> Sent to ${invitateTo}`);
                    setInvitationNotification(true);
                    setOpenInvitationPopup(false)
                    setSendInvitation(true)
                } else {
                    setInvitationError(true)
                    setInvitationMessage(response.data.message);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setInvitationError(true)
                setInvitationMessage(error.messager);
                setIsLoading(false);
            });
    }
    useEffect (()=>{
        const checkIfClickedOutside = (e) => {
            if (showStickyMenu && stickymenu.current && !stickymenu.current.contains(e.target)) {
                setShowStickyMenu(false);
            }
            
        }
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };

    },[showStickyMenu])  
    
    useEffect (()=>{
        const checkIfClickedOutside = (e) => {            
            if (addRef && addRef.current && !addRef.current.contains(e.target)) {
                setAddTabActive(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };

    },[addTabActive])

    return (
        <>
            <div className="logo-hamburger-menu mobile-view" ref={hamburgerMobileRef} >
                <Link className='logotype' to={'/'} onClick={(e) => {handleMenuItemClick(e,'/')}}>
                    <img src={logo} alt="Persone" className='logo-icon' />
                    <span><img src={logoText} alt="Persone" className='logo-text' /></span>
                </Link>
                <div className='mobile-menuRight'>
                    <div className="addNew" ref={addRef}>
                        <button className={(addTabActive === true) ? 'buttonActive primary-button ' : 'primary-button minwidthAuto flex'} onClick={(e) => { handleAddNew() }}><img src={plus} alt="Icon" /><span className='createButtonMenu'>Create</span></button>
                        {
                            (addTabActive === true) ? <AddNewDropdown setClose={setAddTabActive} /> : ''
                        } 
                    </div>
                    <Link className='secondary-button flex' to={`https://jourmal-next-front.vercel.app/${profileSlug}`} target="_blank">
                        <img src={link} alt="link" />
                        Web Profile
                    </Link>
                    <div className='dropDown' ref={stickymenu}>
                        <div className= "avatarVisible menuDropdown" onClick={()=>{setShowStickyMenu(!showStickyMenu)}}>
                            <div className='sidebar-user'>
                                <img className='avatar' src={avatarImage} alt={{firstName}} />
                            </div>
                            <img className='menu-down-arrow' src={down} alt='down' />
                        </div>
                        {showStickyMenu && 
                            <MenuDropdown userData={userData} lastName={lastName} firstName={firstName} setShowStickyMenu={setShowStickyMenu} showStickyMenu={showStickyMenu} toggleMenu={props?.toggleMenu} planDetails={props?.billingDetails?.subscriptions} setShowUpgradeModel={setIsChangePlanOpen} setOpenInvitationPopup={setOpenInvitationPopup} setInvitateTo={setInvitateTo} setInvitationMessage={setInvitationMessage}/>
                        }
                    </div>
                    <button className='hamburger-menu' onClick={handleHamburgerClick} >
                        <span></span>
                    </button>
                </div> 
            </div>
            <nav id='myNav' ref={myNavRef}>
                <div className="container menuWrapper">
                    <div className="logo-hamburger-menu desktop-view">
                        <Link className='logotype' to={'/'} onClick={(e) => {handleMenuItemClick(e,'/')}}>
                            <img src={logo} alt="Persone" className='logo-icon' />
                            <span><img src={logoText} alt="Persone" className='logo-text' /></span>
                        </Link>
                        <button className='hamburger-menu' onClick={handleHamburgerClick} >
                            <span></span>
                        </button>
                    </div>

                    {/* <div className="menuRight addNew" ref={ref}>
                        <button className={(addTabActive === true) ? 'buttonActive' : ''} onClick={(e) => { setAddTabActive(!addTabActive); setUserInfoActive(false) }}><img src={plus} alt="Icon" /> <span>&nbsp;&nbsp;Add new</span></button>
                        {
                            (addTabActive === true) ? <AddNewDropdown setClose={setAddTabActive} /> : ''
                        }
                    </div> */}
                    <div className='menu-wrapperBox'>
                        <ul className='menuList'>
                            <ul className='menuData' >
                                <li>
                                    <Link className={(props.activePage === 'Home') ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/home'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/home')}}>
                                        <img src={Home} className="home-icon" alt="home-icon" />
                                        <span>Home</span>
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link className={(props.activePage === '') ? 'menuListItem menuItemActive' : 'menuListItem'} to={`https://jourmal-next-front.vercel.app/${profileSlug}`} target="_blank" rel="noopener noreferrer" onClick={handleMenuItemClick}>
                                        <img src={Profile} alt="Icon" />
                                        <span>My Profile</span>
                                    </Link>
                                </li> */}
                                <li>
                                    <Link className={((props.activePage === 'Page') || (props.activePage === 'Published Pages') || (props.activePage === 'Drafts Pages') || (props.activePage === 'Deleted Pages')) ? 'menuListItem menuItemActive' : 'menuListItem'} onClick={(e) => handlePagesSubMenuClick(e)}>
                                        <img src={Pages} className="pages-icon" alt="pages-icon" />
                                        <span>Pages</span>
                                        <div className='analiytics-down-icon'>
                                            <img src={down} alt="subscriber-icon" />
                                        </div>
                                    </Link>
                                    { isPageSubMenuOpen &&
                                        <div className='analytics-submenu'>
                                                <ul className="submenu-inner">
                                                    <li>
                                                        <Link className={(props.activePage === 'Page') ? '' : 'settingMenu'} to={'/dashboard/all-pages'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/all-pages')}}>All Pages</Link>
                                                    </li>
                                                    <li>
                                                        <Link className={(props.activePage === 'Published Pages') ? '' : 'settingMenu'} to={'/dashboard/published-pages'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/published-pages')}}>Published</Link>
                                                    </li>
                                                    <li>
                                                        <Link className={(props.activePage === 'Drafts Pages') ? '' : 'settingMenu'} to={'/dashboard/drafts-pages'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/drafts-pages')}}>Drafts</Link>
                                                    </li>
                                                    <li>
                                                        <Link className={(props.activePage === 'Deleted Pages') ? '' : 'settingMenu'} to={'/dashboard/deleted-pages'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/deleted-pages')}}>Deleted</Link>      
                                                    </li>
                                                </ul>
                                            </div>
                                    }
                                </li>
                                <li>
                                    <Link className={((props.activePage === 'Publication') || (props.activePage === 'Published Publications') || (props.activePage === 'Draft Publications') || (props.activePage === 'Delete Publications')) ? 'menuListItem menuItemActive' : 'menuListItem'} onClick={(e) => {handlePublicationsSubMenuClick(e)}}>
                                        <img src={Publication} className="publication-icon" alt="publication-icon" />
                                        <span>Publications</span>
                                        <div className='analiytics-down-icon'>
                                            <img src={down} alt="subscriber-icon" />
                                        </div>
                                    </Link>
                                    { isPublicationSubMenuOpen &&
                                        <div className='analytics-submenu'>
                                            <ul className="submenu-inner">
                                                <li>
                                                    <Link className={(props.activePage === 'Publication') ? '' : 'settingMenu'} to={'/dashboard/all-publications'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/all-publications')}}>All Publications</Link>
                                                </li>
                                                <li>
                                                    <Link className={(props.activePage === 'Published Publications') ? '' : 'settingMenu'} to={'/dashboard/published-publications'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/published-publications')}}>Published</Link>
                                                </li>
                                                <li>
                                                    <Link className={(props.activePage === 'Draft Publications') ? '' : 'settingMenu'} to={'/dashboard/drafts-publications'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/drafts-publications')}}>Drafts</Link>
                                                </li>
                                                <li>
                                                    <Link className={(props.activePage === 'Delete Publications') ? '' : 'settingMenu'} to={'/dashboard/deleted-publications'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/deleted-publications')}}>Deleted</Link>      
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </li>
                                <li>
                                    <Link className={((props.activePage === 'Newsletter') || (props.activePage === 'Sent Newsletters') || (props.activePage === 'Drafts Newsletters') || (props.activePage === 'Deleted Newsletters')) ? 'menuListItem menuItemActive' : 'menuListItem'} onClick={(e) => handleNewsletterSubMenuClick(e)}>
                                        <img src={Newsletters} className="newsletters-icon" alt="newsletters-icon" />
                                        <span>Newsletters</span>
                                        <div className='analiytics-down-icon'>
                                            <img src={down} alt="subscriber-icon" />
                                        </div>
                                    </Link>
                                    { isNewsletterSubMenuOpen &&
                                        <div className='analytics-submenu'>
                                            <ul className="submenu-inner">
                                                <li>
                                                    <Link className={(props.activePage === 'Newsletter') ? '' : 'settingMenu'} to={'/dashboard/all-newsletters'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/all-newsletters')}}>All Newsletters</Link>
                                                </li>
                                                <li>
                                                    <Link className={(props.activePage === 'Sent Newsletters') ? '' : 'settingMenu'} to={'/dashboard/sent-newsletters'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/sent-newsletters')}}>Sent</Link>
                                                </li>
                                                <li>
                                                    <Link className={(props.activePage === 'Drafts Newsletters') ? '' : 'settingMenu'} to={'/dashboard/drafts-newsletters'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/drafts-newsletters')}}>Drafts</Link>
                                                </li>
                                                <li>
                                                    <Link className={(props.activePage === 'Deleted Newsletters') ? '' : 'settingMenu'} to={'/dashboard/deleted-newsletters'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/deleted-newsletters')}}>Deleted</Link>      
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </li>
                                <li>
                                    <Link className={(props.activePage === 'Categories') ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/categories'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/categories')}}>
                                        <img src={Category} className="category-icon" alt="categories-icon" />
                                        <span>Categories</span>
                                    </Link>
                                </li>
                                <li>
                                    { props?.billingDetails?.subscriptions?.plan === "free_plan" ? 
                                        <Link className={(props.activePage === 'Subscribers') ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/all-subscribers'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/all-subscribers')}}>
                                            <img src={Subscribers} className="subscribers-icon" alt="subscribers-icon" />
                                            <span>Subscribers</span>
                                        </Link>
                                        :
                                        <Link className={(props.activePage === 'Subscribers') ? 'menuListItem menuItemActive' : 'menuListItem'}  onClick={(e) => handleSubscriberSubMenuClick(e)}>
                                            <img src={Subscribers} className="subscribers-icon" alt="subscribers-icon" />
                                            <span>Subscribers</span>
                                            <div className='analiytics-down-icon'>
                                                <img src={down} alt="subscriber-icon" />
                                            </div>
                                        </Link>
                                    }
                                    
                                    {((props?.billingDetails?.subscriptions?.plan !== "free_plan") && isSubscriberSubMenuOpen) && (
                                        <div className='analytics-submenu'>
                                            <ul className="submenu-inner">
                                                <li>
                                                    <Link className={(props.activePage === 'All Subscribers') ? '' : 'settingMenu'} to={'/dashboard/all-subscribers'}  onClick={(e) => {handleMenuItemClick(e,'/dashboard/all-subscribers')}}>All Subscribers</Link>
                                                </li>
                                                <li>
                                                    <Link className={(props.activePage === 'Free Subscribers') ? '' : 'settingMenu'} to={'/dashboard/free-subscribers'}  onClick={(e) => {handleMenuItemClick(e,'/dashboard/free-subscribers')}}>Free Subscribers</Link>
                                                </li>
                                                {
                                                    (props?.userMetaData?.stripeAccountData?.stripeAccountid !== "" && props?.userMetaData?.stripeAccountData?.stripeAccountid !== undefined) &&
                                                    <>
                                                        <li>
                                                            <Link className={(props.activePage === 'Paid Subscribers') ? '' : 'settingMenu'} to={'/dashboard/paid-subscribers'}  onClick={(e) => {handleMenuItemClick(e,'/dashboard/paid-subscribers')}}>Paid Subscribers</Link>      
                                                        </li>
                                                        <li>
                                                        <Link className={(props.activePage === 'Trial Subscribers') ? '' : 'settingMenu'} to={'/dashboard/trial-subscribers'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/trial-subscribers')}}>Trial Subscribers</Link> 
                                                        </li>
                                                    </>
                                                }
                                            </ul>
                                        </div>
                                    )}
                                </li>
                                <li>
                                    <Link className={(props.activePage === 'MediaGallery') ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/media-library'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/media-library')}}>
                                        <img src={Media} className="media-icon" alt="media-icon" />
                                        <span>Media Library</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={(props.activePage === 'Analytics' || props.activePage == 'Contact-Analytics' || props.activePage == 'monetization-analytics') ? 'menuListItem menuItemActive' : 'menuListItem'} onClick={(e) => handleSubMenuClick(e)}>
                                        <img src={Analiytics} className="analiytics-icon" alt="analiytics-icon" />
                                        <span>Analytics</span>
                                        <div className='analiytics-down-icon'>
                                            <img src={down} alt="analiytics-icon" />
                                        </div>
                                    </Link>
                                    {isSubMenuOpen && (
                                        <div className='analytics-submenu'>
                                            <ul className="submenu-inner">
                                            <li>
                                                <Link className={(props.activePage == 'Contact-Analytics')? 'active-submenu' : ''} to={'/dashboard/content-visitors-analytics'}onClick={(e) => {handleMenuItemClick(e,'/dashboard/content-visitors-analytics')}}>Content & Visitors Analytics</Link>
                                            </li>
                                            <li>
                                                {(props?.billingDetails?.subscriptions?.plan === "free_plan") ? 
                                                    <>
                                                        <div className='overlayMenu' onClick={()=>{setIsChangePlanOpen(true); props.resetMenuActive();}}>
                                                            <div className='overlayLock overlayLock-menu overlayLock-submenu'>
                                                                <img className='submenu-lock'  src={iconLock} alt="lock-icon" />
                                                            </div>
                                                            <Link className={(props.activePage === 'monetization-analytics') ? '' : 'settingMenu'} onClick={handleMenuItemClick} >Monetization Analytics</Link>
                                                        </div>
                                                    
                                                    </>
                                                    :
                                                    <Link className={(props.activePage == 'monetization-analytics')? 'active-submenu ' : ''} to={'/dashboard/monetization-analytics'}onClick={(e) => {handleMenuItemClick(e,'/dashboard/monetization-analytics')}}>Monetization Analytics</Link>
                                                }
                                            </li>
                                        </ul>
                                        </div>
                                    )}
                                </li>
                                    { userMetaData?.planDetail?.plan === "personal_assistant_plan" && 
                                            <li>
                                                <Link className={(props.activePage === 'ProfileAssistant') ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/profile-assistant'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/profile-assistant')}}>
                                                    <img src={ProfileAssistant} className="analiytics-icon" alt="analiytics-icon" />
                                                    <span>Profile Assistant</span>
                                                </Link>
                                            </li>
                                    }
                                <li>
                                    <Link className={(props.activePage === 'Settings') ? 'menuListItem menuItemActive' : 'menuListItem'} to={'/dashboard/settings'} onClick={(e) => {handleMenuItemClick(e,'/dashboard/settings')}}>
                                        <img src={settings} className="analiytics-icon" alt="analiytics-icon" />
                                        <span>Settings</span>
                                    </Link>
                                </li>
                            </ul>
                        </ul>

                        <div>
                            <div className='invite-box-user web-profile-box'>
                                <div className='invite-text-box'>
                                    <h4>My personal web profile</h4>
                                    <div className="shareLink flex">
                                    <input className="" type="text" placeholder="Type an email address..." value={`https://jourmal-next-front.vercel.app/${profileSlug}`}  />
                                        <div className='shareLinkWrap'>
                                            <Link to={`https://jourmal-next-front.vercel.app/${profileSlug}`} target="_blank">
                                                <img className="send-mail-icon invitebox-icon"  src={sendIcon} alt="send mail" />
                                            </Link>
                                            <div className='shareLinkCopyBlock'>
                                                <img className='shareLinkCopy invitebox-icon' src={copy} alt="Link" onClick={e => {
                                                    navigator.clipboard.writeText(`https://jourmal-next-front.vercel.app/${profileSlug}`);
                                                    setTooltipVisible(true);
                                                    setTimeout(() => setTooltipVisible(false), 2000);
                                                }} />
                                                {tooltipVisible && <div className='text-tooltip'>URL Copied!</div> }
                                            </div>
                                        </div>
                                    </div>
                                    <p className='invite-info'>Share the link to your personal web profile on your social networks to enhance your digital presence.</p>
                                </div>
                            </div>

                            <div className='user-action' ref={ref}>
                                <div className="menuRight">
                                    <div className="userProfile">
                                        <div className={`avatarVisible ${userInfoActive === true ? 'active' : ''}`} >
                                            <div className='sidebar-user'>
                                                <img className='avatar' src={avatarImage} alt={{firstName}} />
                                            </div>
                                            <div className='sidebar-user-content'>
                                                <p className="sidebar-user-name"> {firstName}  {lastName} </p>
                                                <p className="sidebar-user-mail">{userData.email}</p>
                                            </div>
                                        </div>
                                        <div className="sidebar-logout desktop-view">
                                            <img className='icon-logout' src={sideLogout} alt="icon-logout" onClick={handleLogout} />
                                        </div>
                                        <div className="mobile-view">
                                            <img src={down} alt='down' className='menu-down-arrow' onClick={()=>{setMobileStickyMenu(!mobileStickyMenu)}} />
                                            {mobileStickyMenu && 
                                                <MenuDropdown userData={userData} lastName={lastName} firstName={firstName} setShowStickyMenu={setShowStickyMenu} showStickyMenu={showStickyMenu} toggleMenu={props?.toggleMenu} planDetails={props?.billingDetails?.subscriptions} setShowUpgradeModel={setIsChangePlanOpen} setOpenInvitationPopup={setOpenInvitationPopup} setInvitateTo={setInvitateTo} setInvitationMessage={setInvitationMessage}/>
                                            }   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>                   
                    <motion.div
                        className='mobileMenu'
                        initial={{ opacity: 1, height: 0 }}
                        animate={{
                            opacity: 1,
                            height: (mobileMenuActive === true) ? "auto" : 0,
                        }}
                        transition={{ duration: 0.3 }}
                        ref={menuRef}
                    >
                        <div className="mobileMenuContent">
                            <ul className='mobileMenuLinks'>
                                <Link to={'/dashboard/home'} onClick={e => handleAddClass(false)}>Home</Link>
                                <Link to={'/dashboard/all-pages'} onClick={e => handleAddClass(false)}>Pages</Link>
                                <Link to={'/dashboard/all-publications'} onClick={e => handleAddClass(false)}>Publications</Link>
                                <Link to={'/dashboard/all-subscribers'} onClick={e => handleAddClass(false)}>Subscribers</Link>
                                <Link to={'/dashboard/media-gallery'} onClick={e => handleAddClass(false)}>Media Library</Link>
                                <Link to={'/dashboard/all-newsletters'} onClick={e => handleAddClass(false)}>Newsletter</Link>
                                <Link to={'/dashboard/categories'} onClick={e => handleAddClass(false)}>Categories</Link>
                                <Link to={'/dashboard/content-visitors-analytics'} onClick={e => handleAddClass(false)}>Analytics</Link>
                            </ul>
                        </div>
                    </motion.div>
                </div>
            </nav>
            {isChangePlanOpen && 
                <ChangePlan isChangePlanOpen={isChangePlanOpen} setIsChangePlanOpen={setIsChangePlanOpen}  planDetails={props?.billingDetails?.subscriptions} paymentMethods={props?.paymentMethods} isStipeConnected={props?.isStipeConnected || false} updateData={props?.updateData} isExpired={props?.isExpired} setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup}/>
            }
             { isOpenInvitationPopup && 
                <InvitationModel setOpenInvitationPopup={setOpenInvitationPopup} isOpenInvitationPopup={isOpenInvitationPopup} handleInvitateToChange={handleInvitateToChange} handleInvitateToKeyPress={handleInvitateToKeyPress} handleSendInvitation={handleSendInvitation} invitateTo={invitateTo} isLoading={isLoading} invitationError={invitationError} invitationMessage={invitationMessage} setIsLoading={setIsLoading} setInvitateTo={setInvitateTo} setInvitationError={setInvitationError} setInvitationMessage={setInvitationMessage} userMetaData={userMetaData}/>
             }
             { openSuccessPlanPopup && 
                <ChangePlanSuccessModel setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup} plan={props?.billingDetails?.subscriptions?.plan}/>
            }
            { invitationNotification && 
                <NotificationMessage 
                openNotify={invitationNotification} 
                setOpenNotify={setInvitationNotification} 
                type={'success'} 
                title={`Invitation sent to ${invitateTo}`} />
            
            }
        </>
    );
}

export default Menu;
