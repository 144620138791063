import React, { useEffect, useRef, useState } from 'react'
import axios from "axios";

import close from '../../images/Settings/close.svg'

function AddCategoryPage(props) {
    const { isOpen, onClose, data, setCategory, setSelectedCategory, updateCategoriesData, setOpenNotify, setToggleSelect, toggleSelect } = props;

    const modelRef = useRef(null);
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen && inputRef.current) {
            inputRef.current.focus();
        }

        const checkIfClickedOutside = (e) => {
            if (modelRef.current && !modelRef.current.contains(e.target)) {
                onClose('cancel')
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            const handleKeyDown = (e) => {
                const deleteMediaElement = document.getElementById('deleteMedia');
                if (e?.key === 'Enter' && isOpen && deleteMediaElement?.children?.length > 0) {
                    addCategory()
                } else if (e?.key === "Escape") {
                    onClose('cancel');

                }
            };
            document.addEventListener("keydown", handleKeyDown);
            return () => {
                document.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [isOpen]);
    if (!isOpen) {
        return null;
    }

    const handleInputChange = (e) => {
        setErrorMessage('');
        setInputValue(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            addCategory();
        }
    };

    const addCategory = async () => {
        setErrorMessage('');

        var canAddNewCat = true;

        if (inputValue.trim() !== '') {
            setIsLoading(true);

            if (updateCategoriesData != undefined) {
                const result = await updateCategoriesData('', inputValue.trim(), 'add');

                if (result != true) {
                    setErrorMessage(result);
                    setIsLoading(false);
                    canAddNewCat = false;
                }

            } else {
                const categoryExists = data.some((existingCategory) =>
                    existingCategory.toLowerCase() === inputValue.toLowerCase()
                );

                if (categoryExists) {
                    // Display an error message or take appropriate action here
                    setErrorMessage("This category already exists.");
                    setIsLoading(false);
                    canAddNewCat = false;
                }
            }

            if (canAddNewCat) {

                const authorId = JSON.parse(localStorage.getItem("userinfo"))?._id;
                //make first letter capital
                const new_iv = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                const newCategoryData = {
                    author_id: authorId,
                    name: new_iv
                }

                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/categories/add`,
                    newCategoryData,
                )

                if (response.status === 200) {
                    if (updateCategoriesData != undefined) {
                        await updateCategoriesData(response.data.catData, '', 'update');
                        onClose();
                    } else {
                        setCategory((prevValues) => [...prevValues, new_iv]);
                        setSelectedCategory((prevValues) => [...prevValues, new_iv]);
                        onClose('create');
                    }

                    setOpenNotify(true)
                    setIsLoading(false);
                    setInputValue('');
                    setErrorMessage('');
                }

                if (response.status === 201) {
                    setIsLoading(false);
                    setErrorMessage(response.data.message);
                }

                if (response.status === 500) {
                    setIsLoading(false);
                }

                if (response.status === 400) {
                    setIsLoading(false);
                }

                if (response) {
                    setIsLoading(false);
                }
            }
        } else {
            // Display an error message or take appropriate action here
            setErrorMessage("Category name is required!");
            setIsLoading(false);
        }
    };


    return (
        <>
            {isOpen && (
                <div className='welcomeModal addCategoryPage' ref={modelRef} id="deleteMedia">
                    <div className="popupTop popupTopClose closePlan">
                        <img src={close} onClick={e => { onClose('cancel') }} alt="Icon" />
                    </div>
                    <h3 className='popupTitle'>New Category</h3>
                    <input id="category_name_inp" className='welcomeInput' ref={inputRef} value={inputValue} onChange={handleInputChange} onKeyPress={(e) => handleKeyPress(e)} type="text" placeholder="Category name" />

                    {errorMessage && <p className="error-message">{errorMessage}</p>}

                    <div className="btnGroupLink">
                        <button className="secondary-button button" type="button" onClick={() => { onClose('cancel'); setInputValue(''); setErrorMessage(''); }} >Cancel</button>
                        <button className="primary-button button" type="button" onClick={addCategory}>
                            {isLoading ?
                                <div className="updatebutton-loader ">
                                    <div className="ring-loader"></div>
                                </div>
                                :
                                <>
                                    Create
                                </>}
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default AddCategoryPage