import '.././App.css';
import note_icon from '.././images/note_icon.svg'
import note from '.././images/note.svg'
import { Link } from 'react-router-dom';

function AddNewDropdown(props) {
    const token = localStorage.getItem("userinfo");

    const handleClick = (name,e, url) =>{
        if(props?.isContentChange === true){
            e.preventDefault();
            props?.showConfirmationPopup(url)
        }
        props.setActivePage(name)
        props.setClose(false)
    }

    return (
        <div className='dropdown addDropdown'>
            <ul>
                <li>
                    <Link to={token ? '/dashboard/new-page' : '/sign-in'} onClick={(e)=> handleClick("New page",e,'/dashboard/new-page')}>
                        {/* <img src={note_icon} alt="Icon" /> */}
                        <p>New Page</p>
                    </Link>
                </li>
                <li>
                    <Link to={token ? '/dashboard/new-publication' : '/sign-in'} onClick={(e)=> handleClick("New Publication",e, '/dashboard/new-publication')}>
                        {/* <img src={note_icon} alt="Icon" /> */}
                        <p>New Publication</p>
                    </Link>
                </li>
                <li>
                    <Link to={token ?'/dashboard/new-newsletter': '/sign-in'} onClick={(e)=> handleClick("New Newsletter",e, '/dashboard/new-newsletter')}>
                        {/* <img src={note_icon} alt="Icon" /> */}
                        <p>New Newsletter</p>
                    </Link>
                </li>

            </ul>
        </div>
    );
}

export default AddNewDropdown;
