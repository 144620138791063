import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../.././App.css';

import AddFactor from '../../Components/Settings/AddFactor';
import axios from 'axios';
import Notification from '../../Components/Notification';
import NotificationMessage from '../../Components/NotificationMessage';

function TwoFactor(props) {

    props.setActivePage('2FA')

    const [isAddFactorOpen, setIsAddFactorOpen] = useState(false);
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState(false);
    const [editNotify, setEditNotify] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`
                );
                const userMeta = response?.data?.result?.userMeta;

                let twoFactorAuthData = userMeta?.twoFactorAuth || { status: "disabled", method: "", data: "" };

                twoFactorAuthData = {
                    ...twoFactorAuthData,
                    email: response?.data?.result?.user?.email || ''
                }

                setStatus(twoFactorAuthData);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [isAddFactorOpen]);

    const disabledTwoFactor = async() =>{
        setIsLoading(true);
        const updateData = {
            twoFactorAuth: {
                status: "disabled",
                method: "",
                data: ""
            }
        }
        await axios.post(
            `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            updateData
        );
        setStatus(prevState => ({
            ...prevState,
            status: 'disabled'
        }));

        setIsLoading(false);
    }
    
    return (
        <>
        <Helmet>
            <title>2FA | Dashboard | Persone</title>
        </Helmet>
        <div className="general twoFactor">
            <div className="generalTop">
                <div className="generalTopLeft">
                    <p className='generalTopLeftTitle'>Two Factor Authentication</p>
                    <p className='generalTopLeftDesc'>Two-Factor Authentication (2FA) adds an extra layer of security to your account by requiring a second verification step, such as a code sent to your phone, along with your password.</p>
                </div>
            </div>

            <div className="twoFactorContent">
                <div className="twoFactorRow">
                    <div className="billingText">
                        <p className='settingsMenuBlockTitle'>Enable 2FA</p>
                        <p className='twoFactorTopLeftDesc'>Enhance your account security by choosing to log in using either your mobile device or your email address for two-factor authentication.</p>
                    </div>
                    <div className="factorRowCheckbox flex">
                        <button className="secondary-button minwidthAuto button" onClick={(e) => { setIsAddFactorOpen(!isAddFactorOpen) }}>
                            { (status?.status === "enable") ? "Change 2FA" : "Add 2FA" }                            
                        </button>
                        {(status?.status === "enable")  && 
                            <button className="removeFactor_btn primary-button" onClick={(e) => { disabledTwoFactor() }}>
                                {isLoading ?
                                    <div className="settingPage-loader">
                                        <div className="ring-loader"></div>
                                    </div>
                                    :
                                    "Disable 2FA"
                                }
                            </button>
                        }
                    </div>
                </div>
            </div>

            {isAddFactorOpen &&
                <AddFactor setIsAddFactorOpen={setIsAddFactorOpen} isAddFactorOpen={isAddFactorOpen} status={status} setStatus={setStatus} setNotification={setNotification} setEditNotify={setEditNotify}/>
            }
            {notification &&
                <Notification  
                openNotify={notification} 
                setOpenNotify={setNotification} 
                type={'success'} 
                title={`2FA has been successfully added!`}
                text={"Your account is now more secure with two-factor authentication."} />
            }
            {editNotify &&
                <Notification  
                openNotify={editNotify} 
                setOpenNotify={setEditNotify} 
                type={'success'} 
                title={`2FA has been successfully edited!`}
                text={"Your two-factor authentication settings have been successfully updated."} />
            }
        </div>
        </>
    );
}

export default TwoFactor;
