import React, { useEffect, useState, useRef } from 'react'

import Chart from 'chart.js/auto'
import { getRelativePosition } from 'chart.js/helpers';


import up from '../images/up-image.svg'
import down from '../images/down-image.svg'

import dateFormatter, { formatTime } from '../utils/dateFormatter';

import ".././App.css";


function AnalyticsCharts({ apiFilter, graphData, compareData, isLoading, aggregateData, compareAggregateData, selectedPeriod, setSelectedPeriod, setSelectedDate, showCompare, setTempPeriod, setPreviousDate }) {

  const chartRef = useRef(null)

  const [uniqueVisitors, setUniqueVisitors] = useState(0);
  const [totalVisits, setTotalVisits] = useState(0)
  const [totalPageviews, setTotalPageviews] = useState(0)
  const [totalBounceRate, setTotalBounceRate] = useState(0)
  const [totalVisitDuration, setTotalVisitDuration] = useState(0)
  const [totalViewsPerVisit, setTotalViewsPerVisit] = useState(0)

  const [compareUniqueVisitors, setCompareUniqueVisitors] = useState(0);
  const [compareTotalVisits, setCompareTotalVisits] = useState(0)
  const [compareTotalPageviews, setCompareTotalPageviews] = useState(0)
  const [compareTotalBounceRate, setCompareTotalBounceRate] = useState(0)
  const [compareTotalVisitDuration, setCompareTotalVisitDuration] = useState(0)
  const [compareTotalViewsPerVisit, setCompareTotalViewsPerVisit] = useState(0)

  const [changesVisitors, setChangesVisitors] = useState(0);
  const [changesVisits, setChangesVisits] = useState(0);
  const [changesPageViews, setChangesPageViews] = useState(0);
  const [changesBounceRate, setChangesBounceRate] = useState(0);
  const [changesVisitDuration, setChangesVisitDuration] = useState(0);
  const [changesTotalViewsPerVisit, setChangesTotalViewsPerVisit] = useState(0);

  const [analyticsActive, setAnalyticsActive] = useState("visitors");

  const analyticsFilter = {
    visitors: {
      title: 'Visitors'
    },
    visits: {
      title: 'Visits'
    },
    pageviews: {
      title: 'Page views'
    },
    bounce_rate: {
      title: 'Bounce rate'
    },
    visit_duration: {
      title: 'Visit duration'
    },
    views_per_visit : {
      title: 'Views per visit'
    }
  }

  useEffect(() => {
    if(apiFilter !== '' && analyticsActive === 'views_per_visit' ) {
      // setAnalyticsActive('visitors');
    }
  },[apiFilter])

  useEffect(() => {
    setUniqueVisitors(aggregateData?.visitors?.value || 0);
    setTotalVisits(aggregateData?.visits?.value || 0);
    setTotalPageviews(aggregateData?.pageviews?.value || 0);
    setTotalBounceRate(aggregateData?.bounce_rate?.value || 0);
    setTotalVisitDuration(aggregateData?.visit_duration?.value || 0);
    setTotalViewsPerVisit(aggregateData?.views_per_visit?.value || 0);

    setChangesVisitors(aggregateData?.visitors?.change || 0);
    setChangesVisits(aggregateData?.visits?.change || 0);
    setChangesPageViews(aggregateData?.pageviews?.change || 0);
    setChangesBounceRate(aggregateData?.bounce_rate?.change || 0);
    setChangesVisitDuration(aggregateData?.visit_duration?.change || 0);
    setChangesTotalViewsPerVisit(aggregateData?.views_per_visit?.change || 0);
    
    if( showCompare ) {
      setCompareUniqueVisitors(compareAggregateData?.visitors?.value || 0);
      setCompareTotalVisits(compareAggregateData?.visits?.value || 0);
      setCompareTotalPageviews(compareAggregateData?.pageviews?.value || 0);
      setCompareTotalBounceRate(compareAggregateData?.bounce_rate?.value || 0);
      setCompareTotalVisitDuration(compareAggregateData?.visit_duration?.value || 0);
      setCompareTotalViewsPerVisit(compareAggregateData?.views_per_visit?.value || 0);
    }

  }, [aggregateData, compareAggregateData]);

  useEffect(() => {
    if (graphData) {

      const ctx = chartRef.current.getContext('2d');

      var gradient = ctx.createLinearGradient(0, 0, 0, 300);
      gradient.addColorStop(0, 'rgba(101,116,205, 0.2)');
      gradient.addColorStop(1, 'rgba(101,116,205, 0)');

      const datasets = [{
        label: analyticsFilter[analyticsActive]['title'],
        data: graphData.map(data => data[analyticsActive]),
        borderWidth: 3,
        pointHoverRadius: 4,
        fill: true,
        backgroundColor: gradient,
        borderColor: 'rgba(101,116,205)',
        pointBorderColor: "transparent",
        pointBackgroundColor: 'rgba(101,116,205)',
        pointHoverBackgroundColor: 'rgba(71, 87, 193)',
        yAxisID: 'y',
      }]

      if( showCompare ) {
        datasets.push({
          label: analyticsFilter[analyticsActive]['title'],
          data: compareData.map(data => data[analyticsActive]),
          borderWidth: 3,
          pointHoverRadius: 4,
          fill: true,
          backgroundColor: gradient,
          borderColor: 'rgba(60,70,110,0.2)',
          pointBorderColor: "transparent",
          pointBackgroundColor: 'rgba(60,70,110,0.2)',
          pointHoverBackgroundColor: 'rgba(60, 70, 110)',
          yAxisID: 'yComparison',
        })
      }

      // Dummy data
      const data = {
        labels: graphData.map(data => dateFormatter(data.date, selectedPeriod.value === 'day' ? 'hour' : 'date', '', false)),
        datasets: datasets
      };

      // Create the chart
      const myChart = new Chart(ctx, {
        type: 'line',
        data: data,
        options: {
          animation: false,
          plugins: {
            legend: { display: false },
            tooltip: {
              enabled: false,
              mode: 'index',
              intersect: false,
              position: 'average',
              external: GraphTooltip()
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          elements: { line: { tension: 0 }, point: { radius: 0 } },
          onClick: handleClick,
          scale: {
            ticks: { precision: 0, maxTicksLimit: 8 }
          },
          scales: {
            y: {
              min: 0,
              suggestedMax: calculateMaximumY(datasets),
              ticks: {
                callback: function (value, index, values) {
                  if (analyticsActive === 'visit_duration') {
                    return formatTime(value);
                  }
                  return value;
                }
              },
              border: {
                display: false
              },
              grid: {
                zeroLineColor: 'transparent',
                drawBorder: false,
              }
            },
            yComparison: {
              min: 0,
              display: false,
              suggestedMax: calculateMaximumY(datasets),
              grid: { display: false },
            },
            x: {
              grid: { display: false },
            }
          },
          interaction: {
            mode: 'index',
            intersect: false,
          }
        },
      });

      window.addEventListener('mousemove', repositionTooltip);

      function handleClick(event) {
        if (selectedPeriod.value !== 'day') {
          const canvasPosition = getRelativePosition(event, myChart);

          const dataX = myChart.scales.x.getValueForPixel(canvasPosition.x);
          
          const data = graphData[dataX]
          const compareDate = compareData[dataX];
          const formatedDate = dateFormatter(data.date, 'date', '');
          setPreviousDate(compareDate?.date || false);
          setSelectedDate(data.date);
          setSelectedPeriod({ value: "day", label: formatedDate })
          setTempPeriod({ value: "day", label: formatedDate })
        }
      }

      return () => {
        // Cleanup chart on unmount
        myChart.destroy();
      };

    }

  }, [graphData, analyticsActive]);

  const GraphTooltip = () => {

    return (context) => {
      const tooltipModel = context.tooltip;
      const offset = document.getElementById("main-graph-canvas").getBoundingClientRect();
      let tooltipEl = document.getElementById('chartjs-tooltip');

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.style.display = 'none';
        tooltipEl.style.opacity = 0;
        document.body.appendChild(tooltipEl);
      }

      if (tooltipEl && offset && window.innerWidth < 768) {
        tooltipEl.style.top = offset.y + offset.height + window.scrollY + 15 + 'px'
        tooltipEl.style.left = offset.x + 'px'
        tooltipEl.style.right = null
        tooltipEl.style.opacity = 1
      }

      if (tooltipModel.opacity === 0) {
        tooltipEl.style.display = 'none';
        return;
      }

      const data = tooltipModel.dataPoints.find((dataPoint) => dataPoint.dataset.yAxisID == "y");
      const value = data?.raw || 0
      const updatedData = graphData[data.dataIndex];
      const formatedDate = dateFormatter(updatedData?.date, 'date', '');
      const formatedTime = dateFormatter(updatedData?.date, 'hour', '');
      const date = `${formatedDate}${selectedPeriod.value === 'day' ? `, ${formatedTime}` : ''}`;

      
      const comparisonData = showCompare ? tooltipModel.dataPoints.find((dataPoint) => dataPoint.dataset.yAxisID == "yComparison") : {};
      
      const isCompareIndex = comparisonData && comparisonData.hasOwnProperty('dataIndex')

      const comparisonValue = comparisonData?.raw || 0
      const comparisonUpdatedData = showCompare && isCompareIndex ? compareData[comparisonData.dataIndex] : {};
      
      const comparisonFormatedDate = showCompare && isCompareIndex? dateFormatter(comparisonUpdatedData?.date, 'date', '') : '';
      const comparisonFormatedTime = showCompare && isCompareIndex ? dateFormatter(comparisonUpdatedData?.date, 'hour', '') : '';
      const comparisonDate = showCompare && isCompareIndex ? `${comparisonFormatedDate}${selectedPeriod.value === 'day' ? `, ${comparisonFormatedTime}` : ''}` : '';

      if (tooltipModel.body) {

        const comparisonTooltip = showCompare && isCompareIndex ? `<div class="tooltip-subtitle"><div class="tooltip-mark compare-mark"></div>${comparisonDate} <div class="tooltip-value">${(analyticsActive === 'visit_duration') ? formatTime(comparisonValue) : comparisonValue}</div></div>` : '';

        tooltipEl.innerHTML = `
          <div class="grapph-tooltip">
            <div class="tooltip-title">${data?.dataset?.label}</div>
            <div class="tooltip-subtitle"> <div class="tooltip-mark"></div>${date} <div class="tooltip-value">${(analyticsActive === 'visit_duration') ? formatTime(value) : value}</div> </div>
            ${showCompare ? comparisonTooltip : ''}
            <div class="tooltip-link ${selectedPeriod.value === 'day' && 'tooltip-hide'}">Click to view day</div>
          </div>
        `;
      }
      tooltipEl.style.display = null;
    };
  };

  const repositionTooltip = (e) => {
    const tooltipEl = document.getElementById('chartjs-tooltip');
    if (tooltipEl && window.innerWidth >= 768) {
      if (e.clientX > 0.66 * window.innerWidth) {
        tooltipEl.style.right = (window.innerWidth - e.clientX) + window.pageXOffset + 'px';
        tooltipEl.style.left = null;
      } else {
        tooltipEl.style.right = null;
        tooltipEl.style.left = e.clientX + window.pageXOffset + 'px';
      }
      tooltipEl.style.top = e.clientY + window.pageYOffset + 'px';
      tooltipEl.style.opacity = 1;
    }
  };

  const calculateMaximumY = function (dataset) {
    const yAxisValues = dataset
      .flatMap((item) => item.data)
      .map((item) => item || 0)

    if (yAxisValues) {
      return Math.max(...yAxisValues)
    } else {
      return 1
    }
  }

  console.log({apiFilter})

  return (
    <div className="dashboardGraph analyticsCharts dashboard-analytics">
      <div>
        {
          !isLoading &&
          <>
            <div className="dashboardGraphCotnent">
              <div className="analyticsButtons">
                <div className={analyticsActive === "visitors" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("visitors")} >
                  <p className="analyticsButtonTitle">Unique visitors</p>
                  <div>
                    <div className='analytics-data'>
                      <p className="analyticsNumbers">{uniqueVisitors}</p>
                      {
                        showCompare && graphData && graphData.length > 0 && 
                          <div className='analyticsDate'>{`${dateFormatter(graphData[0].date, 'date', '', false)}-${dateFormatter(graphData[graphData.length - 1].date, 'date', '', false)}`}</div>
                      }                      
                    </div>
                    {
                      showCompare && compareData && compareData.length > 0 &&
                        <div className='compare-data'>
                          <p className='analyticsNumbers compareNumbers'>{compareUniqueVisitors}</p>
                          <div className='analyticsDate compareDate'>{`${dateFormatter(compareData[0].date, 'date', '', false)}-${dateFormatter(compareData[compareData.length - 1].date, 'date', '', false)}`}</div>
                        </div>
                    }                    
                    {
                      ! showCompare &&
                        <span className={`graphPercent ${changesVisitors !== 0 && (changesVisitors >= 0 ? 'greenPercent' : 'redPercent') }`}>
                          {changesVisitors !== 0 ? (
                            changesVisitors >= 0 ? (
                              <img src={up} alt="Up" className="svg-icon" />
                            ) : (
                              <img src={down} alt="Down" className="svg-icon" />
                            )
                          ) : (
                            "〰"
                          )}
                          {Math.abs(changesVisitors)}%
                        </span>
                    }
                    
                  </div>
                </div>
                <div className={analyticsActive === "visits" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("visits")} >
                  <p className="analyticsButtonTitle">Total visits</p>
                  <div>
                    <div className='analytics-data'>
                      <p className="graphNumber">{totalVisits}</p>
                      {
                        showCompare && graphData && graphData.length > 0 && 
                          <div className='analyticsDate'>{`${dateFormatter(graphData[0].date, 'date', '', false)}-${dateFormatter(graphData[graphData.length - 1].date, 'date', '', false)}`}</div>
                      }
                    </div>
                    {
                      showCompare && compareData && compareData.length > 0 &&
                        <div className='compare-data'>
                          <p className='analyticsNumbers compareNumbers'>{compareTotalVisits}</p>
                          <div className='analyticsDate compareDate'>{`${dateFormatter(compareData[0].date, 'date', '', false)}-${dateFormatter(compareData[compareData.length - 1].date, 'date', '', false)}`}</div>
                        </div>
                    }
                    {
                      ! showCompare &&
                        <span className={`graphPercent ${changesVisits !== 0 && (changesVisits >= 0 ? 'greenPercent' : 'redPercent') }`}>
                          {changesVisits !== 0 ? (
                            changesVisits >= 0 ? (
                              <img src={up} alt="Up" className="svg-icon" />
                            ) : (
                              <img src={down} alt="Down" className="svg-icon" />
                            )
                          ) : (
                            "〰"
                          )}
                          {Math.abs(changesVisits)}%
                        </span>
                    }
                  </div>
                </div>
                <div className={analyticsActive === "pageviews" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("pageviews")} >
                  <p className="analyticsButtonTitle">Total pageviews</p>
                  <div>
                    <div className='analytics-data'>
                      <p className="graphNumber">{totalPageviews}</p>
                      {
                        showCompare && graphData && graphData.length > 0 && 
                          <div className='analyticsDate'>{`${dateFormatter(graphData[0].date, 'date', '', false)}-${dateFormatter(graphData[graphData.length - 1].date, 'date', '', false)}`}</div>
                      }
                    </div>
                    {
                      showCompare && compareData && compareData.length > 0 &&
                        <div className='compare-data'>
                          <p className='analyticsNumbers compareNumbers'>{compareTotalPageviews}</p>
                          <div className='analyticsDate compareDate'>{`${dateFormatter(compareData[0].date, 'date', '', false)}-${dateFormatter(compareData[compareData.length - 1].date, 'date', '', false)}`}</div>
                        </div>
                    }
                    {
                      ! showCompare &&
                        <span className={`graphPercent ${changesPageViews !== 0 && (changesPageViews >= 0 ? 'greenPercent' : 'redPercent') }`}>
                          {changesPageViews !== 0 ? (
                            changesPageViews >= 0 ? (
                              <img src={up} alt="Up" className="svg-icon" />
                            ) : (
                              <img src={down} alt="Down" className="svg-icon" />
                            )
                          ) : (
                            "〰"
                          )}
                          {Math.abs(changesPageViews)}%
                        </span>
                    }
                  </div>
                </div>
                { apiFilter === '' &&
                  <div className={analyticsActive === "views_per_visit" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("views_per_visit")} >
                    <p className="analyticsButtonTitle">Views per visit</p>
                    <div>
                      <div className='analytics-data'>
                        <p className="graphNumber">{totalViewsPerVisit}</p>
                        {
                          showCompare && graphData && graphData.length > 0 && 
                            <div className='analyticsDate'>{`${dateFormatter(graphData[0].date, 'date', '', false)}-${dateFormatter(graphData[graphData.length - 1].date, 'date', '', false)}`}</div>
                        }
                      </div>
                      {
                        showCompare && compareData && compareData.length > 0 &&
                          <div className='compare-data'>
                            <p className='analyticsNumbers compareNumbers'>{compareTotalViewsPerVisit}</p>
                            <div className='analyticsDate compareDate'>{`${dateFormatter(compareData[0].date, 'date', '', false)}-${dateFormatter(compareData[compareData.length - 1].date, 'date', '', false)}`}</div>
                          </div>
                      }
                      {
                        ! showCompare &&
                          <span className={`graphPercent ${changesTotalViewsPerVisit !== 0 && (changesTotalViewsPerVisit >= 0 ? 'greenPercent' : 'redPercent') }`}>
                            {changesTotalViewsPerVisit !== 0 ? (
                              changesTotalViewsPerVisit >= 0 ? (
                                <img src={up} alt="Up" className="svg-icon" />
                              ) : (
                                <img src={down} alt="Down" className="svg-icon" />
                              )
                            ) : (
                              "〰"
                            )}
                            {Math.abs(changesTotalViewsPerVisit)}%
                          </span>
                      }
                    </div>
                  </div>
                }
                <div className={analyticsActive === "bounce_rate" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("bounce_rate")} >
                  <p className="analyticsButtonTitle">Bounce rate</p>
                  <div>
                    <div className='analytics-data'>
                      <p className="graphNumber">{totalBounceRate}%</p>
                      {
                        showCompare && graphData && graphData.length > 0 && 
                          <div className='analyticsDate'>{`${dateFormatter(graphData[0].date, 'date', '', false)}-${dateFormatter(graphData[graphData.length - 1].date, 'date', '', false)}`}</div>
                      }
                    </div>
                    {
                      showCompare && compareData && compareData.length > 0 &&
                        <div className='compare-data'>
                          <p className='analyticsNumbers compareNumbers'>{compareTotalBounceRate}</p>
                          <div className='analyticsDate compareDate'>{`${dateFormatter(compareData[0].date, 'date', '', false)}-${dateFormatter(compareData[compareData.length - 1].date, 'date', '', false)}`}</div>
                        </div>
                    }
                    {
                      ! showCompare &&
                        <span className={`graphPercent ${changesBounceRate !== 0 && (changesBounceRate >= 0 ? 'greenPercent' : 'redPercent') }`}>
                          {changesBounceRate !== 0 ? (
                            changesBounceRate >= 0 ? (
                              <img src={up} alt="Up" className="svg-icon" />
                            ) : (
                              <img src={down} alt="Down" className="svg-icon" />
                            )
                          ) : (
                            "〰"
                          )}
                          {Math.abs(changesBounceRate)}%
                        </span>
                    }
                  </div>
                </div>
                <div className={analyticsActive === "visit_duration" ? "graphButton greenPercent dashboardGraphActiveButton" : "graphButton"} onClick={() => setAnalyticsActive("visit_duration")} >
                  <p className="analyticsButtonTitle">Visit duration</p>
                  <div>
                    <div className='analytics-data'>
                      <p className="graphNumber">{ formatTime(totalVisitDuration)}</p>                      
                      {
                        showCompare && graphData && graphData.length > 0 && 
                          <div className='analyticsDate'>{`${dateFormatter(graphData[0].date, 'date', '', false)}-${dateFormatter(graphData[graphData.length - 1].date, 'date', '', false)}`}</div>
                      }
                    </div>
                    {
                      showCompare && compareData && compareData.length > 0 &&
                        <div className='compare-data'>
                          <p className='analyticsNumbers compareNumbers'>{formatTime(compareTotalVisitDuration)}</p>
                          <div className='analyticsDate compareDate'>{`${dateFormatter(compareData[0].date, 'date', '', false)}-${dateFormatter(compareData[compareData.length - 1].date, 'date', '', false)}`}</div>
                        </div>
                    }
                    {
                      ! showCompare &&
                        <span className={`graphPercent ${changesVisitDuration !== 0 && (changesVisitDuration >= 0 ? 'greenPercent' : 'redPercent') }`}>
                          {changesVisitDuration !== 0 ? (
                            changesVisitDuration >= 0 ? (
                              <img src={up} alt="Up" className="svg-icon" />
                            ) : (
                              <img src={down} alt="Down" className="svg-icon" />
                            )
                          ) : (
                            "〰"
                          )}
                          {Math.abs(changesVisitDuration)}%
                        </span>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboardChart">
              <div className="visitor-grreapoh">
                <canvas id="main-graph-canvas" ref={chartRef} width="400" height="400"></canvas>
              </div>
            </div>
          </>
        }

        {isLoading &&
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        }
      </div>
    </div>

  );
}

export default AnalyticsCharts;
