import { useState } from 'react';
import '.././App.css';
import CategoriesListItem from './CategoriesListItem';

function PageActionDropdown(props) {



    return (
        <>
            {
                props.categories.length > 0 &&
                    <div className='dropdown addDropdown categoriesDropdown'>
                            <ul>
                                {
                                    props.categories.map((category, index) => (
                                        <CategoriesListItem category={category} selected={props.selected} changeCategory={props.changeCategory} />
                                    ))
                                }
                            </ul>
                    </div>
            }
        </>
    );
}

export default PageActionDropdown;
