import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const FilterPopup = ({ selectedFilter, showPopup, setShowPopup, popupRef, analyticsData, filtersData, apiFilter, fetchData }) => {

  const [filters, setFilters] = useState(apiFilter);
  const [optionsData, setOptionsData] = useState('')

  const [filterOperation1, setFilterOperation1] = useState('is');
  const [filterOperation2, setFilterOperation2] = useState('is');
  const [filterOperation3, setFilterOperation3] = useState('is');

  const [selectedOperation1, setSelectedOperation1] = useState('');
  const [selectedOperation2, setSelectedOperation2] = useState('');
  const [selectedOperation3, setSelectedOperation3] = useState('');

  const filterOperation = [
    { value: "is", label: "is" },
    { value: "is_not", label: "is not" },
  ];

  useEffect(() => {
    setOptionsData('')
    if (analyticsData.length === undefined) {
      const optionsDataObj = {
        pages: {
          popupTitle: 'Filter by Contents',
          popupContent1: {
            title: 'Pages',
            placeholder: 'Select Pages',
            options: analyticsData.topPages
            .filter(item => item?.pageType !== 'publication')
            .map(item => ({
              value: item.page,
              label: item.page
            })),
            defaultValue: filtersData?.page?.value ? analyticsData?.topPages.findIndex(item => item?.page === filtersData?.page?.value) : false
          },
          popupContent2: {
            title: 'Publications',
            placeholder: 'Select Publications',
            options: analyticsData.topPages
            .filter(item => item.pageType === 'publication')
            .map(item => ({
              value: item.page,
              label: item.page
            })),
            defaultValue: filtersData?.page?.value ? analyticsData?.topPages?.findIndex(item => item?.page === filtersData?.page?.value) : false
          }
        },
        sources: {
          popupTitle: 'Filter by Sources',
          popupContent1: {
            title: 'Sources',
            placeholder: 'Select Sources',
            options: analyticsData?.topSource.map(item => ({
              value: item.source,
              label: item.source
            })),
          },
        },
        location: {
          popupTitle: 'Filter by Locations',
          popupContent1: {
            title: 'Country',
            placeholder: 'Select Countries',
            options: analyticsData.topCountry.map(item => ({
              value: item.isoCode,
              label: item.title
            })),
            defaultValue: filtersData?.country?.value ? analyticsData?.topCountry.findIndex(item => item?.title === filtersData?.country?.value) : false
          },
        },
        devices: {
          popupTitle: 'Filter by Devices',
          popupContent1: {
            title: 'Browser',
            placeholder: 'Select Browsers',
            options: analyticsData.topBrowser.map(item => ({
              value: item.title,
              label: item.title
            })),
            defaultValue: filtersData?.browser?.value ? analyticsData?.topBrowser.findIndex(item => item?.title === filtersData?.browser?.value) : false
          },
          popupContent2: {
            title: 'Operating Systems',
            placeholder: 'Select OS',
            options: analyticsData.topOs.map(item => ({
              value: item.title,
              label: item.title
            })),
            defaultValue: filtersData?.os?.value ? analyticsData?.topOs.findIndex(item => item?.title === filtersData?.os?.value) : false
          },
          popupContent3: {
            title: 'Screen sizes',
            placeholder: 'Select Screen sizes',
            options: analyticsData.topDevice.map(item => ({
              value: item.title,
              label: item.title
            })),
            defaultValue: filtersData?.size?.value ? analyticsData?.topDevice.findIndex(item => item?.title === filtersData?.size?.value) : false
          },
        },
      }
      setOptionsData(optionsDataObj[selectedFilter.value])
      setFilterOperation1('is');
      setFilterOperation2('is');
      setFilterOperation3('is');
      setSelectedOperation1('');
      setSelectedOperation2('');
      setSelectedOperation3('');
    }

  }, [analyticsData, showPopup, selectedFilter]);


  const handleOperationChange1 = (selectedOption) => {
    setFilterOperation1(selectedOption.value)
  }
  const handleOperationChange2 = (selectedOption) => {
    setFilterOperation2(selectedOption.value)
  }
  const handleOperationChange3 = (selectedOption) => {
    setFilterOperation3(selectedOption.value)
  }

  const handleFilterChange1 = (selectedOption) => {
    setSelectedOperation1(selectedOption);
  }
  const handleFilterChange2 = (selectedOption) => {
    setSelectedOperation2(selectedOption);
  }
  const handleFilterChange3 = (selectedOption) => {
    setSelectedOperation3(selectedOption);
  }

  const handleFilterBtn = async () => {
    let filterData = filters;

    if( Array.isArray(selectedOperation1) || selectedOperation1.value ) {
      const filterValue = Array.isArray(selectedOperation1) ? selectedOperation1.map(item => item.value).join('|') : selectedOperation1.value;
      const FilterOperation = filterOperation1 === 'is' ? '==' : '!=';
      let filterTitle = filterValue;

      if( selectedFilter.value === 'pages' ) {
        if( !filters.includes('event:page') ) {
          filterData += `;event:page${FilterOperation}${filterValue}`;
        } else {
          const pattern = /;event:page(==|!=)([^;]+)/;
          filterData = filterData.replace(pattern, `;event:page${FilterOperation}${filterValue}`)
        }
  
        filtersData['page'] = { title: 'Page', value: filterValue, filterName: `;event:page${FilterOperation}${filterValue}`, operation: filterOperation1 };
      }

      if( selectedFilter.value === 'sources' ) {
        if( !filters.includes('visit:source') ) {
          filterData += `;visit:source${FilterOperation}${filterValue}`;
        } else {          
          const pattern = /;visit:source(==|!=)([^;]+)/;
          filterData = filterData.replace(pattern, `;visit:source${FilterOperation}${filterValue}`)
        }
  
        filtersData['source'] = { title: 'Source', value: filterValue, filterName: `;visit:source${FilterOperation}${filterValue}`, operation: filterOperation1 };
      }

      if( selectedFilter.value === 'location' ) {
        if( !filters.includes('visit:country') ) {
          filterData += `;visit:country${FilterOperation}${filterValue}`;
        } else {
          const pattern = /;visit:country(==|!=)([^;]+)/;
          filterData = filterData.replace(pattern, `;visit:country${FilterOperation}${filterValue}`)
        }

        filterTitle = Array.isArray(selectedOperation1) ? selectedOperation1.map(item => item.label).join('|') : selectedOperation1.label;
  
        filtersData['country'] = { title: 'Country', value: filterTitle, filterName: `;visit:country${FilterOperation}${filterValue}`, operation: filterOperation1 };
      }

      if( selectedFilter.value === 'devices' ) {
        if( !filters.includes('visit:browser') ) {
          filterData += `;visit:browser${FilterOperation}${filterValue}`;
        } else {          
          const pattern = /;visit:browser(==|!=)([^;]+)/;
          filterData = filterData.replace(pattern, `;visit:browser${FilterOperation}${filterValue}`)
        }
  
        filtersData['browser'] = { title: 'Browser', value: filterValue, filterName: `;visit:browser${FilterOperation}${filterValue}`, operation: filterOperation2 };
      }

    }

    if( Array.isArray(selectedOperation2) || selectedOperation2.value ) {
      const filterValue = Array.isArray(selectedOperation2) ? selectedOperation2.map(item => item.value).join('|') : selectedOperation2.value;
      const FilterOperation = filterOperation2 === 'is' ? '==' : '!=';
      let filterTitle = filterValue;

      if( selectedFilter.value === 'pages' ) {
        if( !filters.includes('event:page') ) {
          filterData += `;event:page${FilterOperation}${filterValue}`;
        } else {
          const pattern = /;event:page(==|!=)([^;]+)/;
          filterData = filterData.replace(pattern, `;event:page${FilterOperation}${filterValue}`)
        }
  
        filtersData['page'] = { title: 'Page', value: filterValue, filterName: `;event:page${FilterOperation}${filterValue}`, operation: filterOperation2 };
      }

      if( selectedFilter.value === 'devices' ) {
        if( !filters.includes('visit:os') ) {
          filterData += `;visit:os${FilterOperation}${filterValue}`;
        } else {
          const pattern = /;visit:os(==|!=)([^;]+)/;
          filterData = filterData.replace(pattern, `;visit:os${FilterOperation}${filterValue}`)
        }
  
        filtersData['os'] = { title: 'OS', value: filterValue, filterName: `;visit:os${FilterOperation}${filterValue}`, operation: filterOperation3 };
      }
      
    }

    if( Array.isArray(selectedOperation3) || selectedOperation3.value ) {
      const filterValue = Array.isArray(selectedOperation3) ? selectedOperation3.map(item => item.value).join('|') : selectedOperation3.value;
      const FilterOperation = filterOperation2 === 'is' ? '==' : '!=';
      let filterTitle = filterValue;

      if( selectedFilter.value === 'devices' ) {
        if( !filters.includes('visit:device') ) {
          filterData += `;visit:device${FilterOperation}${filterValue}`;
        } else {          
          const pattern = /;visit:device(==|!=)([^;]+)/;
          filterData = filterData.replace(pattern, `;visit:device${FilterOperation}${filterValue}`)
        }
  
        filtersData['size'] = { title: 'Device', value: filterValue, filterName: `;visit:device${FilterOperation}${filterValue}`, operation: filterOperation1 };
      }
    }

    setShowPopup(false)
    await fetchData(filterData, filtersData);
  }

  const colourStyles = {
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected
          ? "#f1f5f9"
          : isFocused
            ? "#f1f5f9"
            : "transparent",
        fontSize: "14px",
        cursor: "pointer",
        color: '#101828'
      };
    },
    input: (provided, state) => ({
      ...provided,
      height: "34px",
      padding: 0,
      margin: 0,
      borderRadius: "6px",
      fontSize: "14px",
      cursor: "pointer",
      paddingLeft: "0",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      color: "#101828",
    }),
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderRadius: "8px",
      width: "100%",
    }),
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none !important",
      borderRadius: "8px",
      marginRTop: "2px",
      minHeight: "100%",
      cursor: "pointer",
    }),
    container: (provided, state) => ({
      ...provided,
      paddingLeft: "0px",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      color: "#667085",
      marginRTop: "10px",
      cursor: "pointer",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: "15px",
      paddingRight: "15px",
    }),
  };
  useEffect(() => {
    if (showPopup) {
        const handleKeyDown = (e) => {
            const deleteMediaElement = document.getElementById('deleteMedia');
            if (e?.key === 'Enter' && showPopup && deleteMediaElement?.children?.length > 0) {
              // handleFilterBtn()
            } else if (e?.key === "Escape") {
              setShowPopup(false)
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }
}, [showPopup]);
  return (
    <>
      {showPopup && optionsData && (
        <div className="detailsPopup">
          <div className="detailsPopup-inner" ref={popupRef} id="deleteMedia">
            <h2>{optionsData.popupTitle}</h2>
            {optionsData?.popupContent1 &&
              <div className="popup__content">
                <div>{optionsData.popupContent1.title}</div>

                <Select
                  className="select-container homepage-select filter-selection"
                  classNamePrefix='filter-select'
                  options={filterOperation}
                  onChange={handleOperationChange1}
                  placeholder={
                    <div className="custom-placeholder">
                      <span className="selectPlaceHolder">is</span>
                    </div>
                  }
                  styles={colourStyles}
                  isSearchable={false}
                />

                <Select
                  className="select-container homepage-select filter-selection"
                  classNamePrefix='filter-select'
                  options={optionsData.popupContent1.options}
                  onChange={handleFilterChange1}
                  placeholder={
                    <div className="custom-placeholder">
                      <span className="selectPlaceHolder">{optionsData.popupContent1.placeholder}</span>
                    </div>
                  }
                  isMulti={filterOperation1 === 'is' ? true : false}
                  styles={colourStyles}
                  {...(optionsData.popupContent1.defaultValue !== false && { defaultValue: [optionsData.popupContent1.options[optionsData.popupContent1.defaultValue]] })}
                />

              </div>
            }

            {optionsData?.popupContent2 &&
              <div className="popup__content">
                <div>{optionsData.popupContent2.title}</div>

                <Select
                  className="select-container homepage-select filter-selection"
                  classNamePrefix='filter-select'
                  options={filterOperation}
                  onChange={handleOperationChange2}
                  placeholder={
                    <div className="custom-placeholder">
                      <span className="selectPlaceHolder">is</span>
                    </div>
                  }
                  styles={colourStyles}
                  isSearchable={false}
                />

                <Select
                  className="select-container homepage-select filter-selection"
                  classNamePrefix='filter-select'
                  options={optionsData.popupContent2.options}
                  onChange={handleFilterChange2}
                  placeholder={
                    <div className="custom-placeholder">
                      <span className="selectPlaceHolder">{optionsData.popupContent2.placeholder}</span>
                    </div>
                  }
                  isMulti={filterOperation2 === 'is' ? true : false}
                  styles={colourStyles}
                  {...(optionsData.popupContent2.defaultValue !== false && { defaultValue: [optionsData.popupContent2.options[optionsData.popupContent2.defaultValue]] })}
                />
              </div>
            }

            {optionsData.popupContent3 &&
              <div className="popup__content">
                <div>{optionsData.popupContent3.title}</div>

                <Select
                  className="select-container homepage-select filter-selection"
                  classNamePrefix='filter-select'
                  options={filterOperation}
                  onChange={handleOperationChange3}
                  placeholder={
                    <div className="custom-placeholder">
                      <span className="selectPlaceHolder">is</span>
                    </div>
                  }
                  styles={colourStyles}
                  isSearchable={false}
                />

                <Select
                  className="select-container homepage-select filter-selection"
                  classNamePrefix='filter-select'
                  options={optionsData.popupContent3.options}
                  onChange={handleFilterChange3}
                  placeholder={
                    <div className="custom-placeholder">
                      <span className="selectPlaceHolder">{optionsData.popupContent3.placeholder}</span>
                    </div>
                  }
                  isMulti={filterOperation3 === 'is' ? true : false}
                  styles={colourStyles}
                  {...(optionsData.popupContent3.defaultValue !== false && { defaultValue: [optionsData.popupContent3.options[optionsData.popupContent3.defaultValue]] })}
                />
              </div>
            }

            <div className="filter-bumit-btn" onClick={handleFilterBtn}>
              <button>Apply Filter</button>
            </div>

            <span className="detailsClose" onClick={() => setShowPopup(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#b8c2cc" className="popupClose">
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z">
                </path>
              </svg>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterPopup;
