import { useEffect, useState } from 'react';
import '.././App.css';

function AuthorBlock(props) {

    const [name, setName] = useState('')
    const [url, setURL] = useState('')

    useEffect(() => {
        let website = props.baseUrl;

        if(props?.customDomain?.verified && props?.customDomain?.domainName !== "") {
            website = props?.customDomain?.domainName || props.baseUrl;
        }
        setName(props?.data?.author || '')
        setURL(props?.data?.website  || '')
    }, [props?.data])


    const updateAuthorData = () => {
        props.updateAuthor(props.data,
            {
                author: name,
                website: url
            });
    };

    const handleDelete = () => {
        props.deleteAuthor(props.data);
    };
    
    const isReadOnly = props.authors[0] === props.data;
    return (
        <div className='newAuthorBlock'>
            <div className="adressInputBlock authorInput authorBlockInputs">
                <input type="text" placeholder='Full name....'  value={name} 
                    onChange={e => {setName(e.target.value);
                        const newErrors = props?.authorError || []; // Ensure it's an array
                        const errorIndex = props?.authors?.findIndex((author) => author === props.data);
                
                        if (errorIndex !== -1) {
                            newErrors[errorIndex] = {
                                authorError: e.target.value.trim() === "" ? "" : "",
                            };
                        }
                        props?.setAuthorErrors(newErrors); 
                    }}  
                    onBlur={updateAuthorData} readOnly={isReadOnly}
                />
                <input type="text" placeholder='Website URL (optional)'  value={url} onChange={e => {setURL(e.target.value) ;props?.setContentChange(true)}}  onBlur={updateAuthorData} readOnly={isReadOnly}/>
                {
                    (props?.authors.length > 1 && !isReadOnly) &&
                    <div className='autherTrashIcon' onClick={handleDelete}>
                        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.8333 5.00002V4.33335C11.8333 3.39993 11.8333 2.93322 11.6517 2.5767C11.4919 2.2631 11.2369 2.00813 10.9233 1.84834C10.5668 1.66669 10.1001 1.66669 9.16667 1.66669H7.83333C6.89991 1.66669 6.4332 1.66669 6.07668 1.84834C5.76308 2.00813 5.50811 2.2631 5.34832 2.5767C5.16667 2.93322 5.16667 3.39993 5.16667 4.33335V5.00002M6.83333 9.58335V13.75M10.1667 9.58335V13.75M1 5.00002H16M14.3333 5.00002V14.3334C14.3333 15.7335 14.3333 16.4336 14.0608 16.9683C13.8212 17.4387 13.4387 17.8212 12.9683 18.0609C12.4335 18.3334 11.7335 18.3334 10.3333 18.3334H6.66667C5.26654 18.3334 4.56647 18.3334 4.03169 18.0609C3.56129 17.8212 3.17883 17.4387 2.93915 16.9683C2.66667 16.4336 2.66667 15.7335 2.66667 14.3334V5.00002" stroke="#475467" stroke-width="1.66667" strokeLinecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                }
            </div>
            {props?.authorError[props?.errorKey]?.authorError && (
                <p className="validateError">{props?.authorError[props?.errorKey]?.authorError}</p>
            )}
            
        </div>
    );
}

export default AuthorBlock; 