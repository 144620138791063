import "../.././App.css";

import React, { useEffect, useRef, useState } from "react";
import featured from "../.././images/featured.svg";
import avatar from "../.././images/profile-user.svg";
import Loader from "../../Components/Loader/Loader";
import coverImg from "../.././images/picture.svg";
import ImageEditor from "../ImageEditor/ImageEditor";
import userAvatar from '../../images/user-avatar.svg'
import BgFormAnimate from "../../Pages/BgFormAnimate";
import RegStatus from "./RegStatus";

import TheamLight from "../../../src/images/white-regular.svg"
import TheamDark from "../../../src/images/dark-regular-publication.svg"
import logo from "../../images/register-logo.svg"

function Step4(props) {
  const fileInputRef = useRef(null);
  const profileFileInputRef = useRef(null);
  const [featureImage, setFeatureImage] = useState(null);
  const [saveFeatureImage, setSaveFeatureImage] = useState(null);
  const [oldImage, setOldImage] = useState(null);
  const [loadingFeatureImg, setloadingFeatureImg] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [featuredImageModal, setFeaturedImageModal] = useState(false);
  const modalRef = useRef(null);
  const [zoom, setZoom] = useState(1);
  const [oldZoom, setOldZoom] = useState(1);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [replcaeImageZoom, setReplcaeImageZoom] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [newUploadedImage, setNewUploadedImage] = useState(null);

  const profileModalRef = useRef(null);
  const [newProfileUploadedImage, setNewProfileUploadedImage] = useState(null);
  const [profileIsDragging, setProfileIsDragging] = useState(false);
  const [profileCoverModal, setProfileCoverModal] = useState(false);
  const [loadingProfileImg, setloadingProfileImg] = useState(false);
  const [uploadedProfileImage, setUploadedProfileImage] = useState(null);
  const [saveProfileImage, setSaveProfileImage] = useState(null);
  const [oldProfileImage, setOldProfileImage] = useState(null);
  const [profileZoom, setProfileZoom] = useState(1);
  const [oldProfileZoom, setOldProfileZoom] = useState(1);
  const [profilePosition, setProfilePosition] = useState({ x: 0.5, y: 0.5 });
  const [replcaeProfileImageZoom, setReplcaeProfileImageZoom] = useState(false);

  const [image, setImage] = useState(null);
  const [isError, setError] = useState("");
  const [isFeatureError, setFeatureError] = useState("");
  const [profileImage, setProfileImage] = useState();
  const [coverImage, setCoverImage] = useState();
  const [validateProfile, setValidateProfile] = useState(false)
  const [validateCover, setValidateCover] = useState(false)
  const [fileList, setFileList] = useState({ '^image/': 'Images' });



  const [profileImageSrc, setprofileImageSrc] = useState(userAvatar)
  const [CoverImageSrc, setCoverImageSrc] = useState(coverImg)

  const [theme, setTheme] = useState("dark")
  const [validateTheme, setValidateTheme] = useState(false)
  const maxSizeInBytes = 128 * 1024 * 1024; // 128MB
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        (!modalRef.current.contains(event.target) &&
          featuredImageModal === false) ||
        event.target.className === "overlayModal" ||
        event.target.className === "register" ||
        event.target.className === "RegStatus_content" ||
        event.target.className === "RegStatus_bottom"
      ) {
        setFeaturedImageModal(false);
        setZoom(oldZoom);
        setOldImage(newUploadedImage);
      }
    };

    if (featuredImageModal) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [featuredImageModal]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        (!profileModalRef.current.contains(event.target) &&
          profileCoverModal === false) ||
        event.target.className === "profileOverlayModal" ||
        event.target.className === "register" ||
        event.target.className === "RegStatus_content" ||
        event.target.className === "RegStatus_bottom"
      ) {
        setProfileCoverModal(false);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.remove("profile-uploaded");
        setProfileZoom(oldProfileZoom);
        setOldProfileImage(newProfileUploadedImage);
      }
    };

    if (profileCoverModal) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [profileCoverModal]);

  useEffect(() => {
    var data = props.data[3].data[0]

    var profileData = props.data[3]?.data[0] || ''
    if (profileData) {
      setprofileImageSrc(profileData.profileImageSrc);
      setCoverImageSrc(profileData.coverImageSrc);
    }

    if (data !== undefined && data != []) {

      setProfileImage(data.profileImage)
      setCoverImage(data.coverImage)

      setSaveProfileImage(data.profileImageSrc);
      setImage(data.profileImageSrc);
      setProfilePosition(data.profilePosition);
      setOldProfileImage(data.oldProfileImage);
      setNewProfileUploadedImage(data.oldProfileImage)
      setProfileZoom(data.profileZoom);
      setOldProfileZoom(data.profileZoom);
      setTheme(data.theme)

      setFeatureImage(data.coverImageSrc);
      setPosition(data.coverImagePosition);
      setSaveFeatureImage(data.coverImageSrc);
      setOldImage(data.oldCoverImage);
      setZoom(data.coverImageZoom);
      setOldZoom(data.coverImageZoom);
    }
  }, [featuredImageModal, profileCoverModal])

  useEffect(() => {
    if (props?.billingDetails?.subscriptions?.plan !== '') {
      setFileList({
        '^image/': 'Images',
      })
    }
  }, [props?.billingDetails]);

  props.step(4);

  // ************************************* Profile **************************************

  function handleDragOver(e) {
    e.preventDefault();
    if (!profileIsDragging) {
      setProfileIsDragging(true);
    }
  }

  function handleDrop(e) {
    var divElement = document.getElementById("profile-picturebox");
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const reader = new FileReader();
    const isAllowedFileType = Object.keys(fileList).some((pattern) => new RegExp(pattern).test(file.type));
    if (file) {
      if (!isAllowedFileType) {
        setError(`File type ${file?.type} is not allowed`);
        return;
      } else {
        setError("");
      }
      if (file?.size > maxSizeInBytes) {
        setError(`File ${file.name} exceeds the maximum size of 128MB`);
        return;
      } else {
        setError("");
      }
      divElement.classList.remove("profile-uploaded");
      setloadingProfileImg(true);
      var divElement = document.getElementById("profile-picturebox");
      divElement.classList.add("profile-border");
      var TempReplcaeProfileImageZoom = replcaeProfileImageZoom

      if (image) {
        setReplcaeProfileImageZoom(true);

        TempReplcaeProfileImageZoom = true;
      }

      if (TempReplcaeProfileImageZoom === true) {
        setOldProfileZoom(1);
        setProfileZoom(1);
      } else {
        setProfileZoom(oldProfileZoom);
      }

      reader.onload = () => {
        TempReplcaeProfileImageZoom !== true && setImage(reader.result);
        setOldProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
      setUploadedProfileImage(null);
      setProfileImage(null);
      setTimeout(() => {
        reader.readAsDataURL(file);
        setloadingProfileImg(false);
        setUploadedProfileImage(file);
        setProfileImage(file);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.add("profile-uploaded");
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.remove("profile-border");
        setProfileCoverModal(true);
        setReplcaeProfileImageZoom(false);
      }, 4000);

      if (file?.size > maxSizeInBytes) {
        setError(`File ${file.name} exceeds the maximum size of 128MB.`)
      } else {
        setError("")
      }

    } else {
      setloadingProfileImg(false);
    }
    setProfileIsDragging(false);
  }

  const handleProfileClick = () => {
    setProfileCoverModal(!profileCoverModal);
  };

  function handleFileUpload(e) {
    setloadingProfileImg(true);
    var divElement = document.getElementById("profile-picturebox");
    divElement.classList.add("profile-border");
    if (replcaeProfileImageZoom === true) {
      setOldProfileZoom(1);
      setProfileZoom(1);
    } else {
      setProfileZoom(oldProfileZoom);
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    const isAllowedFileType = Object.keys(fileList).some((pattern) => new RegExp(pattern).test(file.type));
    if (!isAllowedFileType) {
      setError(`File type ${file?.type} is not allowed.`);
      return;
    } else {
      setError("");
    }
    if (file?.size > maxSizeInBytes) {
      setError(`File ${file.name} exceeds the maximum size of 128MB.`);
      return;
    } else {
      setError("");
    }
    reader.onload = () => {
      replcaeProfileImageZoom !== true && setImage(reader.result);
      setOldProfileImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setUploadedProfileImage(null);
      setProfileImage(null);
      setTimeout(() => {
        reader.readAsDataURL(file);
        setloadingProfileImg(false);
        setUploadedProfileImage(file);
        setProfileImage(file);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.add("profile-uploaded");
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.remove("profile-border");
        setProfileCoverModal(true);
        setReplcaeProfileImageZoom(false);
      }, 4000);
    } else {
      setloadingProfileImg(false);
    }
  }

  const handleProfileImageLoad = () => {
    const img = new Image();
    img.src = image;
    img.onload = () => { };
  };

  const handleReplaceProfileImg = () => {
    document.getElementById("upload-img").click();
    setReplcaeProfileImageZoom(true);
  };

  useEffect(() => {
    setZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    setProfileZoom(profileZoom);
  }, [profileZoom]);

  const handleProfileZoom = (profileZoom) => {
    setProfileZoom(profileZoom);
  };

  const handleProfilePosition = (newProfilePosition) => {
    setProfilePosition(newProfilePosition);
  };

  const handleSaveProfilePicture = async () => {
    let click = document.getElementById("clickFunction");
    click.click();
    setImage(image);
    setNewProfileUploadedImage(oldProfileImage)
    setSaveProfileImage(image);
    setProfileCoverModal(false);
    var divElement = document.getElementById("profile-picturebox");
    divElement.classList.add("profile-uploaded");
    setOldProfileZoom(profileZoom);
  };

  const handleProfileCancel = () => {
    if (saveProfileImage !== null) {
      var divElement = document.getElementById("profile-picturebox");
      divElement.classList.add("profile-uploaded");
    } else {
      var divElement = document.getElementById("profile-picturebox");
      divElement.classList.remove("profile-uploaded");
    }

    if (profileImage) {
      setProfileImage(profileImage);
    } else {
      setValidateProfile(true)
      setProfileImage('')
    }

    setOldProfileImage(newProfileUploadedImage)
    setProfileCoverModal(false);
    setProfileZoom(oldProfileZoom);

    if (profileFileInputRef.current) {
      profileFileInputRef.current.value = null;
      setUploadedProfileImage(null);
    }
  };
  // ************************************* feature box **************************************

  const handlefeatureImageDragOver = (e) => {
    e.preventDefault();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleProfileDragLeave = (e) => {
    e.preventDefault();
    setProfileIsDragging(false);
  };

  const handleClick = () => {
    setFeaturedImageModal(!featuredImageModal);
    var divElement = document.getElementById("newpageImageUpload");
    divElement.classList.add("imageUploaded");
  };

  const handlefeatureImageDrop = (e) => {
    var divElement = document.getElementById("newpageImageUpload");
    divElement.style.height = divElement.clientHeight + "px";
    divElement.style.minHeight = divElement.clientHeight + "px";
    divElement.classList.remove("imageUploaded");
    setloadingFeatureImg(true);

    var TempReplcaeImageZoom = replcaeImageZoom

    if (featureImage) {
      setReplcaeImageZoom(true);
      TempReplcaeImageZoom = true;
    }

    if (TempReplcaeImageZoom === true) {
      setOldZoom(1);
      setZoom(1);
    } else {
      setZoom(oldZoom);
    }

    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const reader = new FileReader();
    const isAllowedFileType = Object.keys(fileList).some((pattern) => new RegExp(pattern).test(file.type));
    if (!isAllowedFileType) {
      setFeatureError(`File type ${file?.type} is not allowed`);
      return;
    } else {
      setFeatureError("");
    }
    if (file?.size > maxSizeInBytes) {
      setFeatureError(`File ${file.name} exceeds the maximum size of 128MB`);
      return;
    } else {
      setFeatureError("");
    }
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        if (file) {
          setUploadedImage(file);
          setCoverImage(file);
          TempReplcaeImageZoom !== true && setFeatureImage(reader.result);
          setOldImage(reader.result);
        }
      };
    };
    if (file) {
      setTimeout(() => {
        reader.readAsDataURL(file);
        setUploadedImage(file);
        setCoverImage(file);
        setloadingFeatureImg(false);
        var divElement = document.getElementById("newpageImageUpload");
        divElement.classList.add("imageUploaded");
        divElement.classList.add("imageUploaded-wrap");
        setFeaturedImageModal(true);
        setOldImage(file);
        setReplcaeImageZoom(false);
        divElement.style.height = 'auto';
      }, 4000);
    } else {
      setloadingFeatureImg(false);
      divElement.style.height = 'auto';
    }
    setIsDragging(false);
  };
  const handlefeatureImageFileUpload = (e) => {
    var divElement = document.getElementById("newpageImageUpload");
    divElement.style.height = divElement.clientHeight + "px";
    divElement.style.minHeight = divElement.clientHeight + "px";
    divElement.classList.remove("imageUploaded");
    setloadingFeatureImg(true);
    if (replcaeImageZoom === true) {
      setOldZoom(1);
      setZoom(1);
    } else {
      setZoom(oldZoom);
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    const isAllowedFileType = Object.keys(fileList).some((pattern) => new RegExp(pattern).test(file?.type));
    if (!isAllowedFileType) {
      setFeatureError(`File type ${file?.type} is not allowed.`);
      return;
    } else {
      setFeatureError("");
    }
    if (file?.size > maxSizeInBytes) {
      setFeatureError(`File ${file.name} exceeds the maximum size of 128MB.`);
      return;
    } else {
      setFeatureError("");
    }
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;
      image.onload = () => {
        replcaeImageZoom !== true && setFeatureImage(reader.result);
        setOldImage(reader.result);
      };
    };
    if (file) {
      reader.readAsDataURL(file);
      setUploadedImage(null);
      setCoverImage(null);
      setTimeout(() => {
        reader.readAsDataURL(file);
        setloadingFeatureImg(false);
        setUploadedImage(file);
        setCoverImage(file)
        var divElement = document.getElementById("newpageImageUpload");
        divElement.classList.add("imageUploaded");
        divElement.classList.add("imageUploaded-wrap");
        setFeaturedImageModal(true);
        setReplcaeImageZoom(false);
        divElement.style.height = 'auto';
      }, 4000);
    } else {
      setloadingFeatureImg(false);
      divElement.style.height = 'auto';
    }
  };

  const handleImageLoad = () => {
    const img = new Image();
    img.src = featureImage;
    img.onload = () => { };
  };

  const handleReplaceImg = () => {
    document.getElementById("feature-img").click();
    setReplcaeImageZoom(true);
  };

  const handleZoom = (zoom) => {
    setZoom(zoom);
  };

  const handlePosition = (newPosition) => {
    setPosition(newPosition);
  };

  const handleSavePicture = async () => {
    let click = document.getElementById("clickFunction");
    click.click();
    setFeatureImage(featureImage);
    setNewUploadedImage(oldImage);
    setSaveFeatureImage(featureImage);
    setFeaturedImageModal(false);
    setOldZoom(zoom);
  };

  const handleMouseDown = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.add("slider-cursor");
  };

  const handleMouseUp = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.remove("slider-cursor");
  };

  const handleTouchStart = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.add("slider-cursor");
  };

  const handleTouchEnd = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.remove("slider-cursor");
  };

  const handleCancel = () => {
    if (!saveFeatureImage) {
      var divElement = document.getElementById("newpageImageUpload");
      featureImage && divElement.classList.remove("imageUploaded");
    } else {
      var divElement = document.getElementById("newpageImageUpload");
      featureImage && divElement.classList.add("imageUploaded");
    }
    if (coverImage) {
      setCoverImage(coverImage)
    } else {
      setCoverImage('');
      setValidateCover(true)
    }
    // setOldImage(newUploadedImage);
    setFeaturedImageModal(false);
    setZoom(oldZoom);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
      setUploadedImage(null);
    }
  };

  return (
    <>
      <BgFormAnimate />
      <div>
        <div className="step register-content">
          <div className="regLogo">
            <img src={logo} alt="logo" />
          </div>
          <div className="step_content step-profile-picture">
            <div className="step-user-avatar">
              {/* <div className='user-avatar-wrapper'>
                  <img src={userAvatar} alt="userAvatar" />
                {saveProfileImage && <img src={image} alt="userAvatar" className="user-avatar" />}
              </div> */}
              <h2 className='step_title'>
                Pictures and Style
              </h2>
              <p className='step_contentSubtitle'>
                Upload a profile picture and a cover photo to personalize your profile and give it a unique visual identity.
              </p>
            </div>
            <div className='step_content_wrapp'>
              <div className="avatarBlock"
                onDragOver={handleDragOver}
                onDragLeave={handleProfileDragLeave}
                onDrop={handleDrop}
              >
                <div className='avatarUpload'>
                  {saveProfileImage ? (
                    <div
                      id="profile-picturebox"
                      className="registerProfile-block"
                      onClick={handleProfileClick}
                    >
                      {(loadingProfileImg === true && profileCoverModal == false) ? (
                        <div id="profileImg-loader" className="registerProfileImg-loader">
                          <Loader />
                        </div>
                      ) : (
                        <>
                          <div onClick={handleProfileClick}>
                            <img
                              src={image}
                              className="newpage_ProfileImg"
                              alt="Uploaded"
                              onLoad={handleProfileImageLoad}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div
                      id="profile-picturebox"
                      className="registerProfile-block"
                      onClick={() => document.getElementById("upload-img").click()}
                    >
                      {(loadingProfileImg === true && profileCoverModal == false) ? (
                        <div id="profileImg-loader" className="registerProfileImg-loader">
                          <Loader />
                        </div>
                      ) : (
                        <>
                          <img className="avatarUpload-img" src={avatar} />
                        </>
                      )}
                    </div>
                  )}
                  <input
                    type="file"
                    ref={profileFileInputRef}
                    id="upload-img"
                    onChange={(e) => {
                      handleFileUpload(e);
                      setValidateProfile(false);
                    }}
                    accept="image/*"
                    hidden

                  />
                </div>
                <div className="newPageRightRow" onClick={() => document.getElementById("upload-img").click()}>
                  <div className="fileUploadProfile-box">
                    <p className="newPageImageUploadText">
                      <span className="newPageImageText">Profile picture</span> <br />
                      <p className="pageImageSize-text">Min 195x195px, PNG or JPEG <br /></p>
                      <span className="btnUpload">
                        {saveProfileImage ? 'Replace' : 'Upload'}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              {
                isError ? <p className="validateError">{isError}</p> : ""
              }
              {/* {
                (validateProfile === true) ? <p className='validateError'>The field is not filled</p> : ''
              } */}

              <p className="label-text">Cover image</p>
              <div
                className="newPageRightRow fileUploader_width"
                onDragOver={handlefeatureImageDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handlefeatureImageDrop}
              >
                {saveFeatureImage ? (
                  <div
                    id="newpageImageUpload"
                    className={
                      isDragging
                        ? "draging newpageImageUpload"
                        : "newpageImageUpload"
                    }
                    onClick={handleClick}
                  >
                    {(loadingFeatureImg === true && featuredImageModal == false) ? (
                      <div className="featureImg-loader">
                        <Loader />
                      </div>
                    ) : (
                      <div className="featureImgMiddleContent">
                        <div onClick={handleClick}>
                          <img
                            className="newpage_CoverImg"
                            src={saveFeatureImage ? featureImage : CoverImageSrc}
                            onLoad={handleImageLoad}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    id="newpageImageUpload"
                    className={
                      isDragging
                        ? "draging newpageImageUpload"
                        : "newpageImageUpload"
                    }
                    onClick={() => document.getElementById("feature-img").click()}
                  >
                    {(loadingFeatureImg === true && featuredImageModal == false) ? (
                      <div className="featureImg-loader">
                        <Loader />
                      </div>
                    ) : (
                      <div className="featureImgMiddleContent">
                        <img
                          className="fileUploadCover_Img"
                          src={featured}
                          alt="Featured Image"
                        />
                        <p className="newPageImageUploadText">
                          <span>Click to upload</span> or drag and drop <br />
                          JPG or PNG (max. 440x215)
                        </p>
                      </div>
                    )}
                  </div>
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  id="feature-img"
                  onChange={(e) => {
                    handlefeatureImageFileUpload(e);
                    setValidateCover(false);
                  }}
                  accept="image/*"
                  hidden
                />
              </div>
              {
                isFeatureError ? <p className="validateError">{isFeatureError}</p> : ""
              }
              {/* {
                (validateCover === true) ? <p className='validateError'>The field is not filled</p> : ''
              } */}
              {saveFeatureImage &&
                <div className="themeLayout">
                  <div className="chooseTheme">
                    <p className="themeTitle">Style</p>
                    <p className="selectTheme">Choose between light and dark themes for your web profile.</p>
                  </div>
                  <div className="theme">
                    <div className={`theamLight ${theme == 'light' ? 'active' : ''}`}>
                      <input type="hidden" value='light' />
                      <img src={featureImage} alt='' className="coverimg coverimg-light" />
                      <img src={TheamLight} alt="TheamLight" onClick={() => setTheme('light')} className="themetype" />
                    </div>
                    <div className={`theamDark ${theme == 'dark' ? 'active' : ''}`}>
                      <input type="hidden" value='dark' />
                      <img src={featureImage} alt='' className="coverimg coverimg-dark" />
                      <img src={TheamDark} alt="TheamDark" onClick={() => setTheme('dark')} className="themetype" />
                    </div>
                  </div>
                </div>
              }
              <div className="regButtons regBack">
                <button onClick={(e) => props.step(3)} className="backButton create-button secondary-button flex minwidthAuto button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M9.2045 9.99956L12.917 13.7121L11.8565 14.7726L7.0835 9.99956L11.8565 5.22656L12.917 6.28706L9.2045 9.99956Z" fill="#525866" />
                  </svg>
                  Back
                </button>
                <button
                  className={`btn_toStep create-button primary-button ${( saveFeatureImage === null || saveProfileImage === null || validateProfile || validateCover || !theme ) ? 'disabledBtn' : 'button'}`}
                  onClick={(e) => {
                    if (profileImage && coverImage && theme) {
                      if (validateProfile === false && validateCover === false) {
                        var copy = [...props.data];

                        const profileImageIndex = copy[4].data.findIndex(item => item.hasOwnProperty('profileImage'));

                        if (profileImageIndex !== -1) {
                          copy[3].data[profileImageIndex] = {
                            profileImage: profileImage,
                            profileImageSrc: image,
                            profileZoom: profileZoom,
                            profilePosition: profilePosition,
                            oldProfileImage: oldProfileImage,
                            coverImage: coverImage,
                            coverImageSrc: featureImage,
                            coverImagePosition: position,
                            coverImageZoom: zoom,
                            oldCoverImage: oldImage,
                            theme: theme
                          };
                        } else {
                          copy[3].data.push({
                            profileImage: profileImage,
                            profileImageSrc: image,
                            profileZoom: profileZoom,
                            profilePosition: profilePosition,
                            oldProfileImage: oldProfileImage,
                            coverImage: coverImage,
                            coverImageSrc: featureImage,
                            coverImagePosition: position,
                            coverImageZoom: zoom,
                            oldCoverImage: oldImage,
                            theme: theme
                          })
                        }

                        const BillingIndex = copy[4]?.data?.findIndex((item) => item.hasOwnProperty('plan'));

                        if (BillingIndex == -1) {
                          copy[4].data.push({
                            "isProfileAssistant": false,
                            "planType": "month",
                            "plan": "free_plan",
                            "cardName": "",
                            "expiry": "",
                            "cardNumber": "",
                            "cvv": "",
                            "status": "done"
                          });
                          copy[4].status = "done";
                        }

                        copy[3].status = "done";
                        props.addData(copy);
                        props.step(6);
                      }
                    } else {
                      setValidateProfile(!profileImage);
                      setValidateCover(!coverImage);
                    }
                  }}
                >
                  Continue
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z" fill="white" />
                  </svg>
                </button>
              </div>
              <div className='register_nav'>
                <RegStatus statusReg={props.data} setStatusReg={props.addData} activeStep={props.activeStep} setActiveStep={props.step} />
              </div>
              {/* <div className='regContact'>
                <p>
                  Need help? Feel free to <Link to={"/contact"}> Contact us </Link>
                </p>
              </div> */}
            </div>

          </div>

          {featuredImageModal && (
            <>
              <div className="imagCropModal">
                <div className="featuredImageModal featurboxModal" ref={modalRef}>
                  <div className="modal-content">
                    {uploadedImage === null ? (
                      <div
                        className="newPageRightRow"
                        onDragOver={handlefeatureImageDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handlefeatureImageDrop}
                      >
                        {((saveFeatureImage && saveFeatureImage === oldImage) ||
                          saveFeatureImage !== featureImage) &&
                          (saveFeatureImage ? (
                            <div
                              id="newpageImageUpload"
                              className={
                                isDragging
                                  ? "draging newpageImageUpload"
                                  : "newpageImageUpload"
                              }
                            >
                              {loadingFeatureImg === true ? (
                                <div className="featureImg-loader">
                                  <Loader />
                                </div>
                              ) : (
                                <>
                                  <div>
                                    <div className="crop-container">
                                      <ImageEditor
                                        type="featureImg"
                                        image={featureImage}
                                        oldImage={oldImage}
                                        setOldImage={setOldImage}
                                        setFeatureImage={setFeatureImage}
                                        setUploadedImage={setUploadedImage}
                                        replaceImage={handlefeatureImageFileUpload}
                                        zoom={zoom}
                                        position={position}
                                        setZoom={handleZoom}
                                        setPosition={handlePosition}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              id="newpageImageUpload"
                              className={
                                isDragging
                                  ? "draging newpageImageUpload"
                                  : "newpageImageUpload"
                              }
                              onClick={() =>
                                document.getElementById("feature-img").click()
                              }
                            >
                              {loadingFeatureImg === true ? (
                                <div className="featureImg-loader">
                                  <Loader />
                                </div>
                              ) : (
                                <>
                                  <img
                                    className="fileUploadCover_Img"
                                    src={featured}
                                    alt="Featured Image"
                                  />
                                  <p className="newPageImageUploadText">
                                    <span>Click to upload</span> or drag and drop <br />
                                    JPG or PNG (max. 800x400)
                                  </p>
                                </>
                              )}
                            </div>
                          ))}

                        {featureImage &&
                          saveFeatureImage === featureImage &&
                          (featureImage ? (
                            <div
                              id="newpageImageUpload"
                              className={
                                isDragging
                                  ? "draging newpageImageUpload"
                                  : "newpageImageUpload"
                              }
                            >
                              {loadingFeatureImg === true ? (
                                <div className="featureImg-loader">
                                  <Loader />
                                </div>
                              ) : (
                                <>
                                  <div>
                                    <div className="crop-container">
                                      <ImageEditor
                                        type="featureImg"
                                        image={featureImage}
                                        oldImage={oldImage}
                                        setOldImage={setOldImage}
                                        setFeatureImage={setFeatureImage}
                                        setUploadedImage={setUploadedImage}
                                        replaceImage={handlefeatureImageFileUpload}
                                        zoom={zoom}
                                        position={position}
                                        setZoom={handleZoom}
                                        setPosition={handlePosition}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              id="newpageImageUpload"
                              className={
                                isDragging
                                  ? "draging newpageImageUpload"
                                  : "newpageImageUpload"
                              }
                              onClick={() =>
                                document.getElementById("feature-img").click()
                              }
                            >
                              {loadingFeatureImg === true ? (
                                <div className="featureImg-loader">
                                  <Loader />
                                </div>
                              ) : (
                                <>
                                  <img
                                    className="fileUploadCover_Img"
                                    src={featured}
                                    alt="Featured Image"
                                  />
                                  <p className="newPageImageUploadText">
                                    <span>Click to upload</span> or drag and drop <br />
                                    JPG or PNG (max. 800x400)
                                  </p>
                                </>
                              )}
                            </div>
                          ))}
                        <input
                          type="file"
                          ref={fileInputRef}
                          id="feature-img"
                          onChange={handlefeatureImageFileUpload}
                          accept="image/*"
                          hidden
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="crop-container">
                          {featureImage && oldImage && (
                            <ImageEditor
                              type="featureImg"
                              image={featureImage}
                              oldImage={oldImage}
                              setFeatureImage={setFeatureImage}
                              setUploadedImage={setUploadedImage}
                              replaceImage={handlefeatureImageFileUpload}
                              zoom={zoom}
                              position={position}
                              setZoom={handleZoom}
                              setPosition={handlePosition}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    <div className="replaceImageRange">
                      <div className="replaceImgBtn">
                        <button
                          className="uploadImage button"
                          type="button"
                          onClick={() => handleReplaceImg()}
                        >
                          <span>Replace image</span>
                        </button>
                      </div>
                      <div className="imgRange">
                        <img className="coverImgLeft" src={coverImg} />
                        <input
                          id="zoom-range"
                          className="zoom-range"
                          type="range"
                          onMouseDown={handleMouseDown}
                          onMouseUp={handleMouseUp}
                          onTouchStart={handleTouchStart}
                          onTouchEnd={handleTouchEnd}
                          value={zoom}
                          min={1}
                          max={3}
                          step={0.1}
                          aspect={384 / 200}
                          aria-labelledby="Zoom"
                          style={{
                            background: `linear-gradient(to right, #344054 ${((zoom - 1) / 2) * 100
                              }%, #B7BCC2 0%)`,
                          }}
                          onChange={(e) => {
                            handleZoom(e.target.value);
                          }}
                        />
                        <img className="coverImgRight" src={coverImg} />
                      </div>
                    </div>
                    <div className="featuredImageModalbtn">
                      <button
                        className="cancel button"
                        type="button"
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </button>
                      <button
                        className="savePicture primary-button button"
                        type="button"
                        onClick={() => handleSavePicture()}
                      >
                        Save picture
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {featuredImageModal && <div className="overlayModal"> </div>}
            </>
          )}

          {profileCoverModal && (
            <>
              <div className="profile-img-model imagCropModal">
                <div
                  className="featuredImageModal featurboxModal"
                  ref={profileModalRef}
                >
                  <div className="modal-content">
                    {uploadedProfileImage === null ? (
                      <div
                        className="newPageRightRow"
                        onDragOver={handleDragOver}
                        onDragLeave={handleProfileDragLeave}
                        onDrop={handleDrop}
                      >
                        {((saveProfileImage &&
                          saveProfileImage === oldProfileImage) ||
                          saveProfileImage !== image) &&
                          (saveProfileImage ? (
                            <div
                              id="newpageImageUpload"
                              className={
                                isDragging
                                  ? "draging newpageImageUpload"
                                  : "newpageImageUpload"
                              }
                            >
                              {loadingProfileImg === true ? (
                                <div className="featureImg-loader">
                                  <Loader />
                                </div>
                              ) : (
                                <>
                                  <div>
                                    <div className="crop-container">
                                      <ImageEditor
                                        type="profileImg"
                                        image={image}
                                        oldImage={oldProfileImage}
                                        setOldImage={setOldProfileImage}
                                        setFeatureImage={setImage}
                                        setUploadedImage={setUploadedProfileImage}
                                        replaceImage={handleFileUpload}
                                        zoom={profileZoom}
                                        position={profilePosition}
                                        setZoom={handleProfileZoom}
                                        setPosition={handleProfilePosition}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              id="newpageImageUpload"
                              className={
                                isDragging
                                  ? "draging newpageImageUpload"
                                  : "newpageImageUpload"
                              }
                              onClick={() =>
                                document.getElementById("upload-img").click()
                              }
                            >
                              {loadingProfileImg === true ? (
                                <div className="featureImg-loader">
                                  <Loader />
                                </div>
                              ) : (
                                <>
                                  <img className="profile-cover" src={avatar} />
                                  <p className="uploadimgs-text">
                                    Drag and drop an image or click to upload
                                  </p>
                                </>
                              )}
                            </div>
                          ))}

                        {image &&
                          saveProfileImage === image &&
                          (image ? (
                            <div
                              id="newpageImageUpload"
                              className={
                                isDragging
                                  ? "draging newpageImageUpload"
                                  : "newpageImageUpload"
                              }
                            >
                              {loadingProfileImg === true ? (
                                <div className="featureImg-loader">
                                  <Loader />
                                </div>
                              ) : (
                                <>
                                  <div>
                                    <div className="crop-container">
                                      <ImageEditor
                                        type="profileImg"
                                        image={image}
                                        oldImage={oldProfileImage}
                                        setOldImage={setOldProfileImage}
                                        setFeatureImage={setImage}
                                        setUploadedImage={setUploadedProfileImage}
                                        replaceImage={handleFileUpload}
                                        zoom={profileZoom}
                                        position={profilePosition}
                                        setZoom={handleProfileZoom}
                                        setPosition={handleProfilePosition}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              id="newpageImageUpload"
                              className={
                                isDragging
                                  ? "draging newpageImageUpload"
                                  : "newpageImageUpload"
                              }
                              onClick={() =>
                                document.getElementById("upload-img").click()
                              }
                            >
                              {loadingProfileImg === true ? (
                                <div className="featureImg-loader">
                                  <Loader />
                                </div>
                              ) : (
                                <>
                                  <img className="profile-cover" src={avatar} />
                                  <p className="uploadimgs-text">
                                    Drag and drop an image or click to upload
                                  </p>
                                </>
                              )}
                            </div>
                          ))}
                        <input
                          type="file"
                          ref={profileFileInputRef}
                          id="upload-img"
                          onChange={handleFileUpload}
                          accept="image/*"
                          hidden
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="crop-container">
                          {image && oldProfileImage && (
                            <ImageEditor
                              type="profileImg"
                              image={image}
                              oldImage={oldProfileImage}
                              setOldImage={setOldProfileImage}
                              setFeatureImage={setImage}
                              setUploadedImage={setUploadedProfileImage}
                              replaceImage={handleFileUpload}
                              zoom={profileZoom}
                              position={profilePosition}
                              setZoom={handleProfileZoom}
                              setPosition={handleProfilePosition}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    <div className="replaceImageRange">
                      <div className="replaceImgBtn">
                        <button
                          className="uploadImage button"
                          type="button"
                          onClick={() => handleReplaceProfileImg()}
                        >
                          <span>Replace image</span>
                        </button>
                      </div>
                      <div className="imgRange">
                        <img className="coverImgLeft" src={coverImg} />
                        <input
                          id="zoom-range"
                          className="zoom-range"
                          type="range"
                          onMouseDown={handleMouseDown}
                          onMouseUp={handleMouseUp}
                          onTouchStart={handleTouchStart}
                          onTouchEnd={handleTouchEnd}
                          value={profileZoom}
                          min={1}
                          max={3}
                          step={0.1}
                          aspect={384 / 200}
                          aria-labelledby="Zoom"
                          style={{
                            background: `linear-gradient(to right, #344054 ${((profileZoom - 1) / 2) * 100
                              }%, #B7BCC2 0%)`,
                          }}
                          onChange={(e) => {
                            handleProfileZoom(e.target.value);
                          }}
                        />
                        <img className="coverImgRight" src={coverImg} />
                      </div>
                    </div>
                    <div className="featuredImageModalbtn">
                      <button
                        className="cancel button"
                        type="button"
                        onClick={() => handleProfileCancel()}
                      >
                        Cancel
                      </button>
                      <button
                        className="savePicture primary-button button"
                        type="button"
                        onClick={() => handleSaveProfilePicture()}
                      >
                        Save picture
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {profileCoverModal && <div className="profileOverlayModal"> </div>}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Step4;
