import { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import axios from "axios";

import CheckEmail from "../Components/CheckEmail";
import BgFormAnimate from './BgFormAnimate';

import back from "../images/right-long-arrow.svg";

import '../css/loginPage.css'

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [resetDone, setResetDone] = useState(false);
  const [error, setError] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email.length > 0) {
      setValidateEmail(false);
    } else {
      setValidateEmail(true);
    }

    if (emailPattern.test(email)) {
      setValidateEmail(false);
    } else {
      setValidateEmail(true)
    }
    if (emailPattern.test(email)) {
      userSendmail();
    }
  };

  const userSendmail = () => {
    setIsLoading(true);
    const data = {
      email: email,
    };
    axios
      .put(`${process.env.REACT_APP_API_URL}/user/emailsend`, data)
      .then((response) => {
        setError(false);
        setResetDone(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
        setResetDone(false);
      });
  };


  return (
    <>
      <Helmet>
          <title>Forgot Password? | Persone</title>
      </Helmet>
      <BgFormAnimate />
      <form className='form-design' onSubmit={handleSubmit}>
        <section className="login">
          {resetDone === false || error === true ? (
            <div className="loginWindow">
              <p className="loginTitle login-page-title">Forgot password?</p>
              <p className="loginSubtitle">Please enter your email address</p>
              <div className="loginRow">
                <p>Email</p>
                <input
                  type="text"
                  name="email"
                  placeholder="Enter your email"
                  value={email}
                  autoFocus={true}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (e.target.value.length > 0) {
                      setValidateEmail(false);
                      setError(false);
                    }
                  }}
                  className={validateEmail === true ? "inputError" : error === true ? "inputError" : " "}
                />
                {validateEmail === true ? (
                  <p className="validateError forgot-password-validation">Please enter a valid email address</p>
                ) : (
                  ""
                )}
                {error === true ? <p className="validateError">{"Incorrect code, please try again"}</p> : ""}
              </div>
              <button className={`loginButton ${isLoading ? 'btn_loading' : 'button'}`} type="submit">
                {isLoading ?
                  <div className="settingPage-loader">
                    <div className="ring-loader"></div>
                  </div>
                  : "Send reset email"}
              </button>
              <p className="loginBottom resetBottom">
                <Link to={"/sign-in"}>
                  <img src={back} alt="Back" className="back-arrow"/>
                  Back to Sign In
                </Link>
              </p>
            </div>
          ) : (
            <CheckEmail email={email} />
          )}

          <div className="pvivacy_mobile">
            <p>
              © Persone {new Date().getFullYear()}. All Rights Reserved. <span className="login-footer-link">Terms of Service</span> &{" "}<span className="login-footer-link">Privacy Policy</span>.
            </p>
          </div>
        </section>
      </form>
    </>
  );
}

export default ForgotPassword;