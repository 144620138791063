import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from 'axios';

import BgFormAnimate from './BgFormAnimate';
import Notification from "../Components/Notification";

import login from '../images/persone-logo.png'
import '../App.css';

function RegisterNotification() {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [firstName, setFirstName] = useState('Hello There');
    const [openNotify, setOpenNotify] = useState(false);

    const toConvertUppercase = (string) => {
        const words = string.split(" ");

        const capitalizedWords = words.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1)
        );
        const capitalizedString = capitalizedWords.join(" ");
        return capitalizedString;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                var userinfo = localStorage.getItem("userinfo");
                var userId = localStorage.getItem("currentUserId");
                localStorage.setItem("isInvited", "true");
                if (userinfo != undefined) {
                    userId = JSON.parse(localStorage.getItem("userinfo"))?._id;
                }
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/user/${userId}`,
                )
                const userData = response?.data?.result?.user;

                setFirstName(toConvertUppercase(userData?.firstName))

            } catch (error) {
                console.log(error, "error");
            }
        }
        fetchData();
    }, []);

    const handleResed = async () => {

        var userinfo = localStorage.getItem("userinfo");
        var userId = localStorage.getItem("currentUserId");
        if (userinfo != undefined) {
            userId = JSON.parse(localStorage.getItem("userinfo"))?._id;
        }

        await axios.get(
            `${process.env.REACT_APP_API_URL}/user/resendwelcomemail/${userId}`,
        );

        setOpenNotify(true);
    }

    return (
        <>
            <BgFormAnimate />

            <div className="form-design">
                <div className="register-notification">
                    <div className="register-content check-email-content">
                        <div className="logotype">
                            <img src={login} alt="Logotype" className='person-logo'/>
                        </div>

                        <h1 className="title"><span className="user-firstName title">{firstName}</span>, please check your email</h1>
                        <p>We have sent you an email containing a unique link. This link will take you to a page where you can provide essential information about yourself to create your web profile.</p>
                        <p>Please check your inbox for an email from us. If you don't see it in your inbox, don’t forget to check your spam folder, just in case.</p>
                        <p>Didn’t receive an email? <a href="#" onClick={(e) => { e.preventDefault(); handleResed(); }} >Resend email.</a></p>
                        <p>Do you have a question or need assistance? Please don't hesitate to reach out to our support team at <a href="mailto:support@persone.me">support@persone.me.</a></p>
                    </div>
                    <div className="register-footer">
                        <p>© Persone {new Date().getFullYear()}. All Rights Reserved. <span className='login-footer'>Terms of Service</span> & <span className='login-footer'>Privacy Policy</span>.</p>
                    </div>
                </div>
            </div>

            <Notification
                title={"Successfully sent email"}
                text={"Please take a moment to check your inbox for an email from us."}
                type={"success"}
                setOpenNotify={setOpenNotify}
                openNotify={openNotify}
            />
        </>
    )
}

export default RegisterNotification;