import { useState, useEffect } from 'react';
import axios from 'axios';
import '.././App.css';
import calculatePercentage from './Function';
import { Link } from "react-router-dom"
import linkExternal from ".././images/link-external.svg";
import arrow from ".././images/icon-arrow-view.svg";
import viewAll from ".././images/right-long-arrow.svg";
import edit from ".././images/icon-edit.svg";
import NoResults from './NoResults';

function InfoList({ analyticsData, isLoading, filtersData }) {

    
    const [topPages, setTopPages] = useState(analyticsData.topPages);
    const [filters, setFilters] = useState();
    const [pages, setPages] = useState([])


    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/page/list/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )
            const result = response?.data?.combinedResults;
            const draftPages = result.filter(page => page.status === "Draft");
            setPages(draftPages);
        } catch (error) {
            console.log(error, "error");
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const [currentState, setCurrentState] = useState("all");


    const handleElementClick =  (itemName, tabName, itemTitle) => {
        let filterData = filters;

        if (tabName === 'page' && !filters.includes('visit:page')) {
            filterData += `;event:page==${itemName}`;
            filtersData[tabName] = { title: 'Page', value: itemTitle, filterName: `;event:page==${itemName}`, operation: 'is' };
          } else if (tabName === 'source' && !filters.includes('visit:source')) {
            filterData += `;visit:source==${itemName}`;
            filtersData[tabName] = { title: 'Source', value: itemTitle, filterName: `;visit:source==${itemName}`, operation: 'is' };
          } 
        
      };
      useEffect(() => {
        if (currentState == 'publications') {
          setTopPages(analyticsData.topPages.filter(page => page.pageType === 'publication'));
        } else if (currentState == 'pages') {
          setTopPages(analyticsData.topPages.filter(page => ['home', 'page', 'contact'].includes(page.pageType)));
        } else {
          setTopPages(analyticsData.topPages);
        }
      }, [currentState, analyticsData]);

      const handleDetailsClick = () => {
        
    
      };
      

    return (
        <div className="infoBoxes">
            <div className="infoTitle infobox-content">
                <div className="infoTitleHeader">
                    <p className='homePageInfoboxTitle'>Top content & sources</p>
                    <div className="infoTabBtnAnalytics">
                        <button className={currentState === "all" ? "nav-link tabActive" : "nav-link"} onClick={() => setCurrentState("all")} >
                            All
                        </button>
                        <button className={currentState === "pages" ? "nav-link tabActive" : "nav-link"} onClick={() => setCurrentState("pages")} >
                            Pages
                        </button>
                        <button className={currentState === "publications" ? "nav-link tabActive " : "nav-link"} onClick={() => setCurrentState("publications")} >
                            Publications
                        </button>
                        <button className={currentState === "newsletters" ? "nav-link tabActive " : "nav-link"} onClick={() => setCurrentState("newsletters")} >
                            Newsletters
                        </button>
                        <button className={currentState === "sources" ? "nav-link tabActive " : "nav-link"} onClick={() => setCurrentState("sources")} >
                        Sources
                        </button>
                    </div>
                </div>

                <div className="infoTitleGrid infoTitleGridAnalytics">
                    <div className="infoReferrersBlock infoTitles">
                        <div> Source </div>
                        <div> Visitors </div>
                    </div>
                    <div className="infoRefWrap">
                        {!isLoading ? (
                            topPages && topPages.length > 0 ? (
                                topPages.map((item, index) => (
                                    <div key={index} className="infoReferrersBlock">
                                        <div className="title">
                                            <div className="infoRefWrap-color" style={{ width: calculatePercentage(item?.visitors || 0, topPages.reduce((max, item) => { return item.visitors > max ? item.visitors : max; }, 0)) }}></div>
                                            <p onClick={() => handleElementClick(item?.page || "/", "page", item?.page || "Home")}>{item?.page || "/"} </p>
                                            <Link to={`https://jourmal-next-front.vercel.app${item?.page}`} target="blank"><img src={linkExternal} alt={item?.page || "/"} /></Link>
                                        </div>
                                        <div>
                                            <p className="graphNumber">{item?.visitors || 0}</p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="no-data">No data yet</p>
                            )
                        ) : (
                            <>
                                <div className="loader-container">
                                    <div className="loader"></div>
                                </div>
                            </>
                        )}
                    </div>
                    {!isLoading && topPages && topPages.length > 0 && (
                        <div
                            className="viewAll"
                            onClick={() => {
                                if (currentState === "all") {
                                    handleDetailsClick("topPages", "Top Content");
                                } else if (currentState === "pages") {
                                    handleDetailsClick("topPages", "Top Pages");
                                } else if (currentState === "publications") {
                                    handleDetailsClick("topPages", "Top Publications");
                                } else {
                                    handleDetailsClick("topPages", "Top Newsletters");
                                }
                            }}
                        >
                            <Link to="#">
                                View All
                                <img src={arrow} alt="Logo" />
                            </Link>
                        </div>
                    )}
                </div>
            </div>

            <div className="infoReferrers infoDraft">
                <div className="infoTitleHeader">
                    <p className='homePageInfoboxTitle'>Drafts</p>
                </div>
                <div>
                    {
                        pages?.length > 0 ?

                        <div className={`infoTitleGrid ${pages?.length > 10 && 'infoTitleGridTable'}`}>
                            <div className='infoReferrersBlock referrersBlock referrersTitle'>
                                <div>Title</div>
                                <div>Type</div>
                                <div>Edit</div>
                            </div>
                            <div className='infoItemsWrap'>
                                {
                                    pages.map((item, index) => (
                                        <div key={index} className='infoReferrersBlock referrersBlock'>
                                            <div>
                                                <Link className='titleLogo' target="_blank" to={`/dashboard/${item?.page_type ? item.page_type : 'newsLetter'}/${item?._id}`}>
                                                    {item?.page_type == 'page' || item?.page_type == 'publication' ? item?.title : item?.subject }
                                                </Link>
                                            </div>
                                            <div>
                                                <Link className='titleLogo' target="_blank" to={`/dashboard/${item?.page_type ? item.page_type : 'newsLetter'}/${item?._id}`}>
                                                {item?.page_type === 'page' ? 'Page' 
                                                    : item?.page_type === 'publication' 
                                                        ? item?.publication_type === 'Regular' 
                                                            ? 'Regular Publication' 
                                                            : item?.publication_type === 'Video' 
                                                                ? 'Video Publication' 
                                                                : item?.publication_type === 'Link' 
                                                                    ? 'Link Publication' 
                                                                    : item?.publication_type === 'Images' 
                                                                        ? 'Images Publication' 
                                                                        : item?.publication_type === 'File' 
                                                                            ? 'File Publication' 
                                                                            : ''
                                                        : 'Newsletter'}
                                                </Link>
                                            </div>
                                            <div className='draft-pen-icon'>
                                                <Link target="_blank" to={`/dashboard/${item?.page_type ? item.page_type : 'newsLetter'}/${item?._id}`}>
                                                    <p className='graphNumber'><img src={edit} alt="Logo" /></p>
                                                </Link>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        :
                        <NoResults type="draft" name="draft" status="all" />
                    }
                </div>

            </div>
        </div>
    );
}

export default InfoList;
