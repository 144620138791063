import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

import PagesListItem from '../../Components/PagesListItem';
import Pagination from '../../Components/Pagination';
import NoResults from '../../Components/NoResults';
import MultipleDeletePages from '../../Components/DeleteModal/MultipleDeletePages';
import SingleDeletePage from '../../Components/DeleteModal/SingleDeletePage';
import NotificationMessage from '../../Components/NotificationMessage';
import { DataTableList } from '../../Components/DataTable/DataTable';
import PageActionDropdown from '../../Components/PageActionDropdown';

import search from '../.././images/search.svg'
import clear from '../.././images/delete.svg'
import down from '../.././images/down_arrow.svg'
import top from '../.././images/arrow_top.svg'
import more from '../../images/more.svg'

import '../.././App.css';

function DeletedPages(props) {

    props.setActivePage('Deleted Pages')
    const [activeFilter, setActiveFilter] = useState('All')
    const [searchInput, setSearchInput] = useState('')
    const [pages, setPages] = useState([])
    const [originalData, setOriginalData] = useState([]);
    const [isPopupOpenDeleteAccount, setIsPopupOpenDeleteAccount] = useState(false);
    const [isPopupSingleDeleteAccount, setIsPopupSingleDeleteAccount] = useState(false);
    const [openPopupIndex, setOpenPopupIndex] = useState(null);
    const [showMultiNotify, setShowMultiNotify] = useState(false)
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPageStatus, setSelectedPageStatus] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [showNotification, setShowNotification] = useState(false);
    const [selectedPubLenght, setSelectedPubLenght ] = useState(0)
    const [isOpenPopupIndex, setIsOpenIndexPopup] = useState(false);

    const [activateEdit, setActivateEdit] = useState(false)
    const [columnWidths, setColumnWidths] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);

    const [toggleSelect, setToggleSelect] = useState(false);

    const ref = useRef();

    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id

    const homagepage_id = btoa(`homepage-${user_id}`);
    const contactpage_id = btoa(`contactpage-${user_id}`);

    const data = async () => {
        const search = searchInput.toLowerCase();
        setIsLoading(false)
        try {
            setIsLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/pages/pageslist/${user_id}?page=${currentPage}&search=${search}&status=Deleted`,
            )
            const result = response?.data?.result;

            setOriginalData(result);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error, "error");
        }
    }
    useEffect (()=>{
        data();
    },[user_id,currentPage, searchInput, selectedPageStatus])

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedPageStatus]);
    useEffect(() => {
        searchPage()
    }, [searchInput])

    const togglePopup = (index) => {
        if (index === openPopupIndex) {
            setOpenPopupIndex(null);
            setIsOpenIndexPopup(false)
        } else {
            setOpenPopupIndex(index);
            setIsOpenIndexPopup(true)
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openPopupIndex &&  (event.target.closest('.pagesTableAction') === null && event.target.closest('.dropdown') === null) && !document.body.classList.contains('hidden-message')) {
                togglePopup(null) // Close the dropdown
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openPopupIndex,togglePopup]);

    function searchPage() {

        var result = [];

        var input = searchInput.toLowerCase()
        originalData.forEach(element => {
            var title = element.title.toLowerCase();

            if (title.startsWith(input)) {
                result.push(element);
            }
        });

        setPages(result);

        if (result != []) {
            setPages(result)
        } else if (input === '') {
            setPages(pages)
            setActiveFilter('All')
        } else {
            setPages([])
        }
    }

    const fetchData = async () => {
        setIsLoading(false)
        try {
            setIsLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/pages/pageslist/${JSON.parse(localStorage.getItem("userinfo"))?._id}?page=${currentPage}&status=Deleted`,
            )
            setOriginalData(response?.data?.result);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error, "error");
        }
    }


    useEffect(() => {
        fetchData();
    }, [selectedPageStatus]);


    useEffect(() => {
        filterByStatus(activeFilter);
    }, [activeFilter, originalData]);

    function filterByStatus(status) {
        if (status === 'All') {
            setPages(originalData);
        } else {
            const filteredData = originalData.filter(item => item.status === status);
            setPages(filteredData);
        }
    }

    function updateOriginalData(updatedData) {
        //setOriginalData(updatedData);  
        fetchData()
    }
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
          if (e.target != null && isPopupOpenDeleteAccount && (e.target.classList.contains('welcomeOverlay'))) {
            setIsPopupOpenDeleteAccount(false);
          }
          if (e.target != null && isPopupSingleDeleteAccount && (e.target.classList.contains('welcomeOverlay'))) {
            setIsPopupSingleDeleteAccount(false);
        }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
    
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside);
        };
      }, [isPopupOpenDeleteAccount, isPopupSingleDeleteAccount]);


    const handleDelete = async () => {
        if (selectedRows?.length === 1 && (selectedRows?.status !== "Deleted" || selectedRows?.status === "Deleted")) {
            setIsPopupSingleDeleteAccount(true);
            document.body.classList.add('hidden-message');
        }

        if (selectedRows?.length > 1 && (selectedRows?.status !== "Deleted" || selectedRows?.status === "Deleted")) {
            setIsPopupOpenDeleteAccount(true);
            document.body.classList.add('hidden-message');
        }
    };

    const closeSingleAccountDeletedPopup = () => {
        //setSelectedItems([]);
        document.body.classList.remove('hidden-message');
        setIsPopupSingleDeleteAccount(false);
    }

    const closeAccountDeletedPopup = () => {
        //setSelectedItems([]);
        document.body.classList.remove('hidden-message');
        setIsPopupOpenDeleteAccount(false)
    }

    const updateStatus = async (itemId, newStatus) => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/pages/updatepage/${itemId}`,
                {
                    status: newStatus
                });
            fetchData();
        } catch (error) {
            console.log(error, "error");
        }
    };

    useEffect(() => {
        if (originalData.length > 0) {
            calculateColumnWidths();
        }
    }, [originalData]);

    function formateDate(date) {
        var createdAtDate = new Date(date);

        var options = { year: 'numeric', month: 'short', day: 'numeric' };

        var formattedDate = createdAtDate.toLocaleDateString('en-US', options);
        return formattedDate
    }

    const calculateColumnWidths = () => {
        const tempWidths = pages?.reduce((acc, row) => {
            Object.keys(row).forEach((key) => {
                const dataValue = row[key].toString();
                const title = row[key];

                // Compare title length with data content length and take the max

                let maxContentLength = 0;

                if (key === 'categories') {
                    const categories = row[key];

                    if (categories.length > 2) {
                        let tempCatLength = 0;

                        for (let i = 0; i < 2; i++) {
                            tempCatLength = tempCatLength + categories[i].length;
                        }

                        maxContentLength = Math.max(tempCatLength + 4, title.length);

                    } else {
                        if (categories.length == 0) {
                            maxContentLength = Math.max(dataValue.length + 5);
                        } else {
                            maxContentLength = Math.max(dataValue.length, title.length);
                        }
                    }

                } if (key === 'status') {
                    if(row?.status === 'Deleted'){
                        maxContentLength = Math.max(dataValue.length + 3);
                    }
                } else {
                    maxContentLength = Math.max(dataValue.length, title.length);
                }

                if (!acc[key] || maxContentLength > acc[key]) {
                    acc[key] = maxContentLength;
                }
            });
            return acc;
        }, {});
        if(tempWidths !== undefined){
            const widthMap = Object.keys(tempWidths).reduce((acc, key) => {
                if (key === 'createdAt' || key === 'updatedAt') {
                } else if (key === 'categories') {
                    pages.forEach((row) => {
                        const categoryWidth = tempWidths[key] * 10 + 50;
                        acc[key] = `${categoryWidth}px`;
                    });
                } else {
                    acc[key] = `${tempWidths[key] * 10 + 40}px`;
                }
                return acc;
            }, {});
            setColumnWidths(widthMap); // Set the calculated column widths
        }
    
    };

    const handleMasterCheckboxChange = () => {
        if (isAllSelected) {
            setSelectedRows([]);
        } else {
            setSelectedRows(data.map((row) => row._id)); // Select all rows
        }
        setIsAllSelected(!isAllSelected); // Toggle master checkbox
    };

    function updateActivateEdit(activateEdit) {
        setActivateEdit(activateEdit)
    }

    const changeStatus = (newStatus) => {
        data.status = newStatus;
        updateStatus(data._id, newStatus);
    };

    const customTitleSort = (rowA, rowB) => {
        const titleA = rowA?.title.toString().toLowerCase();
        const titleB = rowB?.title.toString().toLowerCase();
    
        // Natural sort comparison for mixed numbers and letters
        return titleA.localeCompare(titleB, undefined, { numeric: true, sensitivity: 'base' });
    };

    const columns = [
        {
            name: 'Title',
            selector: (row) => row?.title,
            cell: (row) => {
                return (
                    <span className='editTitle'>{row?.title}</span>
                )
            },
            sortable: true,
            minWidth: columnWidths['title'] && parseInt(columnWidths['title']) > 300 ? '300px' : columnWidths['title'],
            sortFunction: customTitleSort,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Status',
            selector: (row) => row?.status, // Ensure this returns the status value
            cell: (row) => {
                return (
                    <div className='statusTab'><span className={(row?.status === 'Published') ? 'greenStatus' : (row?.status === 'Draft') ? 'draftStatus' : (row?.status === 'Schedule') ? 'scheduleStatus' : 'deletedStatus'}> {row?.status}</span></div>
                )
            },
            width: columnWidths['status'] || '150px',
            style: { width: columnWidths['status'] || '150px', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
            sortable: true,
        },
        {
            name: 'Delete Date',
            selector: row => formateDate(row?.updatedAt),
            sortable: true,
            paddingLeft: 0,
            width: '100px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", minWidth: '100px', fontSize: "14px" },
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <div className='pagesTableAction publicationAction' onClick={() => togglePopup(row)}>
                        <img src={more} alt="More" />
                    </div>
                    {
                        (isOpenPopupIndex && openPopupIndex?._id === row?._id) && <PageActionDropdown togglePopup={togglePopup} changeStatus={changeStatus} type={"page"} data={row} updateActivateEdit={updateActivateEdit} updateOriginalData={updateOriginalData} index={row._id} setShowNotification={setShowNotification} />
                    }
                </>
            ),
            justifyContent: 'flex-end',
            allowOverflow: true,
            button: true,
            // minWidth: "55px",
            paddingRight: '16px',
            width: '80px',
            style: { textAlign: 'right', minWidth: '80px', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px" }, // Aligning to the right
            headerStyle: { textAlign: 'right' },
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };

    return (
        <section className='dashboard'>
            <Helmet>
                <title>Deleted Pages | Dashboard | Persone</title>
            </Helmet>
            <div className="container">
                <div className="dashboardTop">
                    <div className="dashboardTopText">
                        <div className='dashboardTopLeft'>
                            <p>Deleted Pages</p>
                            <p className="pageSubTitle">Review and restore deleted pages or permanently remove them from your web profile.</p>
                        </div>
                        <div className="pagesSorting pageTypeSorting">
                            <div className='pagesSearch'>
                                <div className="pageSearchInput">
                                    <input type="text" name="pages_search" id="pages_search" placeholder={'Search deleted pages...'} value={searchInput} onChange={e => setSearchInput(e.target.value)} />
                                    <img src={search} alt="Search" />
                                </div>

                                <div className='deleteIcon page-deleteIcon' onClick={() => handleDelete()}>
                                    <img className='deleteIconImage' src={clear} alt="Clear Input" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="pagesTableWrapper pages-list">
                    <div className="pagesTable dataTable-list">
                        <DataTableList
                            columns={columns}
                            data={pages}
                            onSelectedRowsChange={({ selectedRows }) => {
                                const selectedIds = selectedRows.map((row) => row._id);
                                setSelectedRows(selectedIds);
                                setIsAllSelected(selectedIds.length === data.length);
                            }}
                            progressPending={isLoading}
                            progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                            onChangePage={setCurrentPage}
                            paginationTotalRows={totalPage}
                            currentPage={currentPage}
                            customStyles={customStyles}
                            selectableRowsComponent="input"
                            selectAllRows={isAllSelected}
                            onSelectAllChange={handleMasterCheckboxChange}
                            type="page" 
                            name="pages" 
                            status="deleted"
                            selectableRows={true}
                        />
                    </div>
                </div>
            </div>

            {(isPopupOpenDeleteAccount || isPopupSingleDeleteAccount) && <div className="welcomeOverlay"></div>}

            {<SingleDeletePage isOpen={isPopupSingleDeleteAccount} onClose={closeSingleAccountDeletedPopup} data={pages} type='page' updateOriginalData={updateOriginalData} index={selectedRows} activeFilter={"Deleted"} setShowNotification={setShowNotification} setSelectedItems={setSelectedRows} setToggleSelect={setToggleSelect} toggleSelect={toggleSelect}/>}
            {<MultipleDeletePages isOpen={isPopupOpenDeleteAccount} onClose={closeAccountDeletedPopup} customKey="pagesId" type='page' name="pages" selectedItems={selectedRows} updateOriginalData={updateOriginalData} activeFilter={"Deleted"} setShowMultiNotify={setShowMultiNotify} setSelectedPubLenght={setSelectedPubLenght} setSelectedItems={setSelectedRows} setToggleSelect={setToggleSelect} toggleSelect={toggleSelect}/>}
            {showNotification && (
                <NotificationMessage  openNotify={showNotification} setOpenNotify={setShowNotification} type={'success'} title={`The page was successfully deleted!`} />
            )}
            {showMultiNotify && (
                <NotificationMessage  openNotify={showMultiNotify} setOpenNotify={setShowMultiNotify} type={'success'} title={`${selectedPubLenght} pages were successfully deleted!`} />
            )}
        </section>
    );
}

export default DeletedPages;