import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactPlayer from 'react-player/lazy'

import Loader from "../Components/Loader/Loader";
import NoResults from '../Components/NoResults';

import filePdfIcon from "../images/file-pdf-icon.svg";
import fileDocIcon from ".././images/file-doc-icon.svg";
import fileDocxIcon from ".././images/file-docx-icon.svg";
import filePptIcon from ".././images/file-ppt-icon.svg";
import filePptmIcon from ".././images/file-pptm-icon.svg";
import filePptxIcon from ".././images/file-pptx-icon.svg";
import fileXlsIcon from ".././images/file-xls-icon.svg";
import fileXlsmIcon from ".././images/file-xlsm-icon.svg";
import fileXlsxIcon from ".././images/file-xlsx-icon.svg";
import fileVideoIcon from ".././images/file-video-icon.svg";
import fileAudioIcon from ".././images/file-audio-icon.svg";
import search from ".././images/search.svg";
import copyLink from '.././images/icon-copy.svg'
import deleteIcon from '.././images/delete.svg'
import linkExternal from '.././images/icon-link-external.svg'
import downicon from "../images/Settings/select_down.svg"
import downloadIcon from "../images/download.svg"
import close from '../images/Settings/close.svg'

import dateFormatter from '.././utils/dateFormatter';
import { formatFileSize, getImageDimensions } from '../utils/functions';


const UploadedPopupMedia = ({ dateList, setUserMedia, userMedia, fetchData, searchInput, selectedMeidaType, selectedDateList, setSearchInput, setSelectedMeidaType, setSelectedDateList, setUploadNewmedia, uploadNewmedia, selectedItems, setSelectedItems, isLoading, setShowUploadMediaPopup, setSelectedProfileImage, selectMediaLoading, uploadMediaPopupFor }) => {

    const [selectedItemsData, setSelectedItemsData] = useState([]);
    const [tooltipShow, setTooltipShow] = useState(false);
    const [readFields, setReadFields] = useState(true);
    const [dimensions, setDimensions] = useState('');

    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [isDelLoading, setIsDelIsLoading] = useState(false);
    const [mediaTypeSelection, setMediaTypeSelection] = useState(false);
    const [dateSelect, setDateSelect] = useState(false);
    const [isDownLoading, setIsDownLoading] = useState(false);
    const [isImageDragging, setIsImageDragging] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [downloadLink, setDownloadLink] = useState('');
    const [isVideoReady, setIsVideoReady] = useState(false);
    const [itemPreviewUrl, setItemPreviewUrl] = useState('');
    const [selectButtonText, setSelectButtonText] = useState('Select File');
    const [previewMeida, setPreviewMeida] = useState('');
    const [errorMessages, setErrorMessages] = useState([])
    const userfileRef = useRef();
    const dateRef = useRef()
    const fileInputRef = useRef(null);

    const [fileList, setFileList] = useState({
        '^image/': 'Images',
        '^video/': 'Videos',
        'application/pdf': 'PDF',
        'application/doc': 'DOC',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
        'application/vnd.ms-powerpoint': 'PPT',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
        'application/vnd.ms-powerpoint.presentation.macroenabled.12': 'PPTM',
        'application/vnd.ms-excel': 'XLS',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
        'application/vnd.ms-excel.sheet.macroenabled.12': 'XLSM'
    });

    const mediaIconSet = {
        'application/pdf': filePdfIcon,
        'application/doc': fileDocIcon,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': fileDocxIcon,
        'application/vnd.ms-powerpoint': filePptIcon,
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': filePptxIcon,
        'application/vnd.ms-powerpoint.presentation.macroenabled.12': filePptmIcon,
        'application/vnd.ms-excel': fileXlsIcon,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': fileXlsxIcon,
        'application/vnd.ms-excel.sheet.macroenabled.12': fileXlsmIcon,
        'video/mp4': fileVideoIcon,
        'video/mpeg': fileVideoIcon,
        'video/ogg': fileVideoIcon,
        'video/webm': fileVideoIcon,
        'video/3gpp': fileVideoIcon,
        'video/3gpp2': fileVideoIcon,
        'video/x-msvideo': fileVideoIcon,
        'video/quicktime': fileVideoIcon,
        'audio/aac': fileAudioIcon,
        'audio/midi': fileAudioIcon,
        'audio/mpeg': fileAudioIcon,
        'audio/ogg': fileAudioIcon,
        'audio/wav': fileAudioIcon,
        'audio/webm': fileAudioIcon,
        'audio/3gpp': fileAudioIcon,
    }

    useEffect(() => {
        setSelectedItemsData(userMedia.filter(media => selectedItems.includes(media._id)));
    }, [selectedItems]);

    const handleFileDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) {
            setIsImageDragging(true);
        }
    }

    const handleFileDragLeave = (e) => {
        e.preventDefault();
        setIsImageDragging(false);
    };

    useEffect(() => {
        if (uploadMediaPopupFor === 'video-file') {
            setFileList({
                '^video/': 'Videos',
            });
        } else if (uploadMediaPopupFor === 'docfiles-selection') {
            setFileList({
                'application/pdf': 'PDF',
                'application/doc': 'DOC',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
                'application/vnd.ms-powerpoint': 'PPT',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
                'application/vnd.ms-powerpoint.presentation.macroenabled.12': 'PPTM',
                'application/vnd.ms-excel': 'XLS',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
                'application/vnd.ms-excel.sheet.macroenabled.12': 'XLSM'
            });
        } else {
            setFileList({
                '^image/': 'Images',
            });
        }
    }, [uploadMediaPopupFor]);

    useEffect(() => {
        if (previewMeida) {
            if ((previewMeida.type).includes('image')) {
                getImageDimensions(previewMeida.url)
                    .then(dimensions => {
                        setDimensions(dimensions);
                    })
                    .catch(error => {
                        console.error('Error getting image dimensions:', error);
                    });
            }
            setItemPreviewUrl(mediaIconSet[previewMeida.type] ? mediaIconSet[previewMeida.type] : previewMeida.url)

            if ((previewMeida.type).includes('image')) {
                setSelectButtonText('Select Image')
            }
            if ((previewMeida.type).includes('video')) {
                setSelectButtonText('Select Video')
            }
            if (uploadMediaPopupFor === 'images-file') {
                let btnText = 'Select Image'
                if (selectedItemsData && selectedItemsData.length > 1) {
                    btnText = `Select ${selectedItemsData.length} Images`
                }
                setSelectButtonText(btnText)
            }
            if(uploadMediaPopupFor === 'editor-image'){
                setSelectButtonText('Select Image')
            }
        }
    }, [selectedItemsData, previewMeida]);

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (!prevSelectedItems.includes(itemId)) {
                if (uploadMediaPopupFor === 'images-file') {
                    return [itemId, ...prevSelectedItems];
                } else {
                    return [itemId];
                }
            }
            return prevSelectedItems; // No removal logic
        });

        const selectedMediaObject = userMedia.find(item => item._id === itemId);
        setPreviewMeida(selectedMediaObject);
    };

    const handleUnCheckboxChange = (itemId) => {
        let updatedSelectedItems;
        if (selectedItems?.includes(itemId)) {
            updatedSelectedItems = selectedItems?.filter((_id) => _id !== itemId);
            setPreviewMeida(null);
        } else {
            if (uploadMediaPopupFor === 'images-file') {
                updatedSelectedItems = [itemId, ...selectedItems];
            } else {
                updatedSelectedItems = [itemId];
            }
            const selectedMediaObject = userMedia?.find(item => item._id === itemId);
            setPreviewMeida(selectedMediaObject);
        }
        setSelectedItems(updatedSelectedItems);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSelectBtn();
        }
    };

    const updateMedia = async (mediaId, name, description, alt) => {
        try {
          const mediaData = { name, description, alt };
          await axios.post(
            `${process.env.REACT_APP_API_URL}/user/updateMedia/${mediaId}`,
            mediaData
          );
        } catch (error) {
          console.error(`Error updating media: ${mediaId}`, error);
        }
    };

    const handleSelectBtn = () => {
        if (uploadMediaPopupFor === 'images-file') {
          const updatedSelectedItemsData = selectedItemsData.map((item) => {
            return {
              ...item,
              name: previewMeida.name,
              description: previewMeida.description,
              alt: previewMeida.alt,
            };
          });
        setSelectedProfileImage(updatedSelectedItemsData);
      
          updatedSelectedItemsData.forEach((item) => {
            updateMedia(item._id, item.name, item.description, item.alt);
          });
        } else {
          const updatedSelectedItemData = {
            ...selectedItemsData[0],
            name: previewMeida.name,
            description: previewMeida.description,
            alt: previewMeida.alt,
          };
          setSelectedProfileImage(updatedSelectedItemData);
      
          updateMedia(updatedSelectedItemData._id, updatedSelectedItemData.name, updatedSelectedItemData.description, updatedSelectedItemData.alt);
        }
    };
    
    const handleNameChange = (e) => {
        setPreviewMeida({ ...previewMeida, name: e.target.value });
    };
    
    const handleDescriptionChange = (e) => {
        setPreviewMeida({ ...previewMeida, description: e.target.value });
    };
    
    const handleAltChange = (e) => {
        setPreviewMeida({ ...previewMeida, alt: e.target.value });
    };
    const clickCopyUrl = () => {
        navigator.clipboard.writeText(previewMeida.url);
        setTooltipShow(true);
        setTimeout(() => {
            setTooltipShow(false);
        }, 2000);
    };

    useEffect(() => {
        if (deleteConfirmation) {
            const checkIfClickedOutside = (e) => {
                if (e.target != null && deleteConfirmation && (e.target.classList.contains('mediaModalOverlay'))) {
                    setDeleteConfirmation(false);
                }
            };
            document.addEventListener("mousedown", checkIfClickedOutside);

            return () => {
                document.removeEventListener("mousedown", checkIfClickedOutside);
            };
        }
    }, [deleteConfirmation]);
    

    const handleDeleteButton = async () => {
        try {
            setIsDelIsLoading(true);
            await axios.delete(`${process.env.REACT_APP_API_URL}/user/deleteMedia/${selectedItems}`);

            const updatedData = userMedia.filter(media => media._id !== selectedItems);
            setUserMedia(updatedData);

            setIsDelIsLoading(false);
            setDeleteConfirmation(false);
            setSelectedItemsData([]);
            fetchData();
            setPreviewMeida('')
        } catch (error) {
            setIsDelIsLoading(false);
            console.log(error)
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (userfileRef.current && !userfileRef.current.contains(event.target)) {
                setMediaTypeSelection(false)
            }
            if (dateRef.current && !dateRef.current.contains(event.target)) {
                setDateSelect(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleDownloadButton = async () => {
        if (isDownLoading == false) {
            try {
                setIsDownLoading(true);
                let downloadUrl = downloadLink;

                if (downloadLink === '') {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}/user/getDownloadLink/${JSON.parse(localStorage.getItem("userinfo"))?._id}?mediaId=${selectedItems}`,
                    )

                    if (response.status === 200) {
                        downloadUrl = response.data.downloadLink;
                        setDownloadLink(response.data.downloadLink);
                    }
                }

                if (downloadUrl) {
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', selectedItems.object_id); // specify the filename you want to save as
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                }

                setIsDownLoading(false);

            } catch (error) {
                setIsDownLoading(false);
                console.error('Error while trying to download the file:', error);
            }
        }
    }

    const maxSizeInBytes = 128 * 1024 * 1024;

    const handleFileDrop = async (e) => {
        e.preventDefault();

        const files = e.dataTransfer.files;
        await handleMeidaUpload(files);

        setIsImageDragging(false);
    };

    const handleImageGalleryUpload = async (e) => {
        const files = e.target.files;
        await handleMeidaUpload(files);
    }

    const handleMeidaUpload = async (files) => {
        let newErrorMessages = [];
        setErrorMessages([]);
        setIsImageDragging(false);
        if (files) {
            for (const file of files) {
                const isAllowedFileType = Object.keys(fileList).some((pattern) => new RegExp(pattern).test(file.type));

                if (!isAllowedFileType) {
                    const errorMessage = 'This file type is not supported';
                    newErrorMessages.push(errorMessage);
                    continue; // Skip this file
                }

                if (file.size > maxSizeInBytes) {
                    const errorMessage = `File ${file.name} exceeds the maximum size of 128MB.`;
                    newErrorMessages.push(errorMessage);
                    continue; // Skip this file
                }

                const formData = new FormData();
                formData.append('files', file); // Append each file individually

                const uploadMedia = {
                    _id: Math.floor(Math.random() * (999999999999999 - 111111111111111 + 1)) + 111111111111111,
                    name: file.name,
                    description: '',
                    alt: '',
                    type: file.type,
                    size: file.size,
                    object_id: "",
                    key: "",
                    url: "",
                    createdAt: "",
                    isLoading: true
                }
                
                setUserMedia((prevUserMedia) => [uploadMedia, ...prevUserMedia]);

                try {
                    const res = await axios.post(
                        `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    );
                    // Update the uploadMedia object with the response data
                    setUserMedia((prevUserMedia) =>
                        prevUserMedia.map((media) =>
                            media._id === uploadMedia._id ? { ...media, ...res.data.result[0], isLoading: false } : media
                        )
                    );
                } catch (error) {
                    const errorMessage = `Failed to upload file ${file.name}.`;
                    newErrorMessages.push(errorMessage);
                    console.log(error);
                }
                setErrorMessages(newErrorMessages);
            }
        } else {
            setIsImageDragging(false);
        }
        setErrorMessages(newErrorMessages);
    }

    const handleVideoReady = () => {
        setIsVideoReady(true);
    };
   
    return (
        <div className={isImageDragging ? `draging poppContentWrapper` : `poppContentWrapper`} onDragOver={handleFileDragOver} onDragLeave={handleFileDragLeave} onDrop={handleFileDrop} >
            <input type="file" ref={fileInputRef} id="pulication-file" multiple hidden onChange={handleImageGalleryUpload} />
            <div className={`grid-container media-grid-layout ${(previewMeida) && 'show-wrapper'}`}>
                <div className="pagesSearch">
                    <div className="pageSearchTop">
                        <div className="pageSearchInput">
                            <input type="text" name="pages_search" id="pages_search" className="inputFields" placeholder="Search" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} autocomplete="off" readOnly={readFields} onFocus={() => setReadFields(false)} onBlur={() => setReadFields(true)} />
                            <img src={search} alt="Search" className='pageSearchIcon' />
                        </div>

                        {uploadMediaPopupFor === 'docfiles-selection' &&
                            <div ref={userfileRef} className="select-file dropdown-wrapp">
                                <button className="selectFields" onClick={(e) => setMediaTypeSelection(!mediaTypeSelection)}>
                                    All Files {" "}
                                    {selectedMeidaType.length > 0 ? `(${selectedMeidaType.length})` : ""}
                                    <img src={downicon} alt="" className='selectFieldsIcon' />
                                </button>
                                {
                                    mediaTypeSelection === true &&
                                    <div className="dropdown addDropdown countryDropdown">
                                        <ul>
                                            {Object.entries(fileList).map(([key, value]) => (
                                                <li className="list-search" key={key} onClick={() => {
                                                    const result = selectedMeidaType.includes(key)
                                                        ? selectedMeidaType.filter(selectfile => selectfile !== key)
                                                        : [...selectedMeidaType, key];
                                                    setSelectedMeidaType(result);
                                                }}>
                                                    <input
                                                        className="fileSelect"
                                                        type="checkbox"
                                                        checked={selectedMeidaType.includes(key)}
                                                        onChange={() => {
                                                            const result = selectedMeidaType.includes(key)
                                                                ? selectedMeidaType.filter(selectfile => selectfile !== key)
                                                                : [...selectedMeidaType, key];
                                                            setSelectedMeidaType(result);
                                                        }}
                                                    />
                                                    <span className="file-name">{value}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                            </div>
                        }

                        <div ref={dateRef} className="mediadate-dropdown dropdown-wrapp">
                            <button className="selectFields" onClick={(e) => setDateSelect(!dateSelect)}>
                                All Dates{" "}
                                {selectedDateList.length > 0 ? `(${selectedDateList.length})` : ""}
                                <img src={downicon} alt="Media Date" />
                            </button>
                            {dateSelect === true &&
                                <div className="dropdown addDropdown dateDropdown">
                                    <ul>
                                        {dateList && dateList.length > 0 &&
                                            dateList.map((date) => (
                                                <li className="list-search" key={date} onClick={() => {
                                                    const dates = selectedDateList.includes(date)
                                                        ? selectedDateList.filter(
                                                            (selectdate) =>
                                                                selectdate !== date
                                                        )
                                                        : [...selectedDateList, date];
                                                    setSelectedDateList(dates);
                                                }}>
                                                    <input className="dateSelect" type="checkbox" checked={selectedDateList.includes(date)} />
                                                    <span className="date-name">{date}</span>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                    {(selectedItems && selectedItems.length > 0) && (
                        <div className="pagesSearchIcon a">
                            <div className='deleteIcon' onClick={() => { setDeleteConfirmation(true) }}>
                                <img className='deleteIconImage' src={deleteIcon} alt="Clear Input" />
                            </div>
                        </div>
                    )}
                </div>
                {isLoading ?
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                    :
                    <>
                        {(userMedia && userMedia.length > 0) ?
                            <div className="gridIitem">
                                {userMedia?.map((media) => (
                                    <div className={`gridImg ${(previewMeida && previewMeida._id === media._id) && 'preview-acive'} ${(media.type).includes('image') ? 'type-image' : 'type-doc'} ${selectedItems.includes(media._id) ? 'selected' : ''}`} onClick={() => handleCheckboxChange(media._id)} key={media._id}>
                                        {media?.isLoading ?
                                            <div className="uploadPicture">
                                                <Loader />
                                            </div>
                                            :
                                            <>
                                                <img src={mediaIconSet[media.type] ? mediaIconSet[media.type] : media.url} alt={media.name} className='item' />

                                                {(!(media.type).includes('image')) && <div className='itemName'> {media.name} </div>}

                                                <input type="checkbox" checked={selectedItems.includes(media._id)} onChange={() => { handleUnCheckboxChange(media?._id) }} />
                                            </>
                                        }                                        
                                    </div>
                                ))}
                            </div>
                            :
                            <NoResults type="media-gallery" name="Media Library" mediaPopup='popup' onclickhandle={() => setUploadNewmedia(!uploadNewmedia)} status="all" />
                        }
                    </>
                }

                {deleteConfirmation &&
                    <>
                        <div className="welcomeOverlay mediaModalOverlay"></div>
                        <div className='welcomeModal mediaDeleteModal'>
                            <div className="popupTop popupTopClose closePlan">
                                <img src={close} onClick={e => { setDeleteConfirmation(false) }} alt="Icon" />
                            </div>
                            <h3 className='popupTitle'>Delete Media</h3>
                            <p className='popupDesc'>Are you sure you want to delete {(selectedItemsData && selectedItemsData.length > 1) ? "these" : "this"} media? This action cannot be undone.</p>
                            <div className="btnGroupLink btnGroupWidth">
                                <button className="secondary-button button" type="button" onClick={() => setDeleteConfirmation(false)}>Cancel</button>
                                <button className="primary-button button" type="button" onClick={() => { handleDeleteButton() }} >
                                    {isDelLoading ?
                                        <div className="settingPage-loader">
                                            <div className="ring-loader"></div>
                                        </div>
                                        : "Delete"
                                    }
                                </button>
                            </div>
                        </div>
                    </>
                }

            </div>
            <div className={`file-wrapper ${(previewMeida) && 'show-wrapper'}`}>
                <>
                    {(previewMeida) &&
                        <>
                            <div className="file buttonHideShow" id="deleteMedia">
                                <div className="modalHeader">
                                    {(previewMeida.type).includes('video') ?
                                        <div className='video-box'>
                                            <div className='video-box-group'>
                                                {!isVideoReady &&
                                                    <div className="skeleton-video-wrapper"><div className="skeleton-video"></div></div>
                                                }
                                                <div className={(isVideoReady) ? 'player-wrapper player-ready' : 'player-wrapper'}>
                                                    <ReactPlayer
                                                        className='react-player'
                                                        url={previewMeida.url}
                                                        width='100%'
                                                        height='100%'
                                                        controls
                                                        onReady={handleVideoReady}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className={`modalImage ${(previewMeida.type).includes('image') ? 'type-image' : 'type-doc'}`}>
                                            <img src={itemPreviewUrl} alt={previewMeida.name} />
                                        </div>
                                    }
                                    <p className='filename'>{previewMeida.name}</p>
                                </div>
                                <div className="modalContent">
                                    <div className="contentrow">
                                        <div className="modaldate">
                                            <span className="title">Date:</span>
                                            <span className='subtext'>{dateFormatter(previewMeida.date, 'date', '', false, true)}</span>
                                        </div>
                                        <div className="modaltype">
                                            <span className="title">Type:</span>
                                            <span className='subtext'>{(previewMeida.object_id).split('.')[1].toUpperCase()}</span>
                                        </div>
                                        <div className="modalsize">
                                            <span className="title">Size:</span>
                                            <span className='subtext'>{formatFileSize(previewMeida.size)}</span>
                                        </div>
                                        {dimensions &&
                                            <div className="modaldimensions">
                                                <span className="title">Dimensions:</span>
                                                <span className='subtext'>{dimensions}</span>
                                            </div>
                                        }
                                    </div>
                                    <form className='modalContentform'>
                                        <div className="formContent">
                                            <label for="fileName">File Name:</label>
                                            <input type="text" className='modalInput' placeholder="Enter a file name" value={previewMeida.name} onKeyPress={(e) => { handleKeyPress(e) }} onChange={handleNameChange} />
                                        </div>
                                        <div className="formContent fromDesc">
                                            <label for="description">Caption:</label>
                                            <textarea placeholder='Write a short description of the image...' className='modalInput' value={previewMeida.description} onKeyPress={(e) => { handleKeyPress(e) }} onChange={handleDescriptionChange} />
                                        </div>
                                        <div className="formContent">
                                            <label for="fileName">Alt</label>
                                            <input type="text" placeholder="Enter alt text for accessibility" className='modalInput' value={previewMeida.alt} onKeyPress={(e) => { handleKeyPress(e) }} onChange={handleAltChange} />
                                        </div>
                                        <div className="formContent">
                                            <label for="url">URL:</label>
                                            <div className="formURL">
                                                <input type="url" placeholder={previewMeida.url} value={previewMeida.url} readOnly className='modalInput' onKeyPress={(e) => { handleKeyPress(e) }} />
                                                {tooltipShow && <p className="tooltip-text">URL Copied!</p>}
                                                <div className="formUrlImg">
                                                    <Link to="#">
                                                        <img src={copyLink} alt="copyLink" onClick={clickCopyUrl} />
                                                    </Link>
                                                    <Link to={previewMeida.url} target="_blank">
                                                        <img src={linkExternal} alt="linkExternal" />
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='modalActionsButtons'>
                                            <div className="modalDelete" onClick={() => { setDeleteConfirmation(true) }}>
                                                <img src={deleteIcon} alt="deleteIcon" />
                                                <p className='formContentbtn uploadedMedia-Button'>Delete</p>
                                            </div>
                                            <div className="modalDelete" onClick={() => { handleDownloadButton() }}>
                                                {isDownLoading ?
                                                    <div className="settingPage-loader">
                                                        <div className="ring-loader"></div>
                                                    </div>
                                                    :
                                                    <img src={downloadIcon} alt="downloadIcon" />
                                                }
                                                <p className='formContentbtn uploadedMedia-Button'>Download</p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modalActions">
                                <button className="cancelButton button" onClick={(e) => setShowUploadMediaPopup(false)}>Cancel</button>
                                <button className='saveButton primary-button button' onClick={(e) => handleSelectBtn()} >
                                    {selectMediaLoading ?
                                        <div className="settingPage-loader">
                                            <div className="ring-loader"></div>
                                        </div>
                                        :
                                        <>{selectButtonText}</>
                                    }
                                </button>
                            </div>
                        </>
                    }
                </>
            </div>
            <div className={`file-wrapper show-wrapper`}>
                <div className="modalActionsForMobile">
                    <button className="cancelButton" onClick={(e) => setShowUploadMediaPopup(false)}>Cancel</button>
                    <button className='saveButton' onClick={(e) => handleSelectBtn()} disabled={!previewMeida}>
                        {selectMediaLoading ?
                            <div className="settingPage-loader">
                                <div className="ring-loader"></div>
                            </div>
                            :
                            <>{selectButtonText}</>
                        }
                    </button>
                </div>

            </div>
        </div>
    );
};


export default UploadedPopupMedia;