import '../.././App.css';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import userAvatar from '../../images/user-avatar.svg'
import Nestable from 'react-nestable';
import SocialItemList from '../../Components/Settings/SocialItemList';
import plusIcon from '../../images/plus.svg';
import 'react-nestable/dist/styles/index.css';
import BgFormAnimate from '../../Pages/BgFormAnimate';
import RegStatus from './RegStatus';
import logo from "../../images/register-logo.svg"

function Step3(props) {

    const [publicAddress, setPublicAddress] = useState('')
    const [publicPhone, setPublicPhone] = useState('')
    const [publicEmail, setPublicEmail] = useState('')
    const [validateEmail, setValidateEmail] = useState(false);
    const [validatePhone, setValidatePhone] = useState(false)
    const [profileImageSrc, setprofileImageSrc] = useState('')

    useEffect(() => {
        var data = props.data[2].data[0]
        if (data !== undefined && data != []) {
            setPublicAddress(data?.address || '')
            setPublicPhone(data?.phone || '')
            setPublicEmail(data?.email || '')
            setSocialArray(data?.socialArray || [])
        }

        var profileData = props.data[3]?.data[0] || ''
        if (profileData) {
            setprofileImageSrc(profileData.profileImageSrc);
        }

    }, [])

    props.step(3)

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9()+\-#]{10,15}$/;

    // State declarations
    const [socialArray, setSocialArray] = useState([]);

    // Function to delete an item from socialArray
    function deleteItem(id) {
        const updatedData = socialArray.filter(item => item.id !== id);
        updatedData.forEach((item, index) => {
            item.id = index + 1;
        });
        setSocialArray(updatedData);
    }


    // Function to update the socialArray state
    const updateSocialArray = (itemId, newData) => {
        const updatedArray = socialArray.map(item => {
            if (item.id === itemId) {
                return {
                    ...item,
                    ...newData
                };
            }
            return item;
        });
        setSocialArray(updatedArray);
    };
    const handleSubmit = () => {

        if (publicEmail === '' || publicEmail === undefined) {
            setValidateEmail(false);
        } else if (publicEmail !== '' && !emailPattern.test(publicEmail)) {
            setValidateEmail(true);
        } else if (publicEmail !== '' && emailPattern.test(publicEmail)) {
            setValidateEmail(false);
        }

        if (publicPhone === '' || publicPhone === undefined) {
            setValidatePhone(false);
        } else if (publicPhone !== '' && !phonePattern.test(publicPhone)) {
            setValidatePhone(true);
        } else if (publicPhone !== '' && phonePattern.test(publicPhone)) {
            setValidatePhone(false);
        }

        if (!(validatePhone || validateEmail)) {

            var copy = [...props.data]

            const emailIndex = copy[2].data.findIndex(item => item.hasOwnProperty('email'));

            if (emailIndex !== -1) {
                copy[2].data[emailIndex] = {
                    address: publicAddress,
                    phone: publicPhone,
                    email: publicEmail,
                    socialArray: socialArray
                };
            } else {
                copy[2].data.push({
                    address: publicAddress,
                    phone: publicPhone,
                    email: publicEmail,
                    socialArray: socialArray
                });
            }
            copy[2].status = 'done'
            props.addData(copy)
            props.step(4)
        }

    }

    const handlePhoneKeyPress = (e) => {
        if (e?.key === "Enter") {
            e.preventDefault(); // Prevent default form submission behavior
            handleSubmit(); // Call your submit function
        }
        const allowedKeys = /[0-9+()\-\b#]/; 
        if (!allowedKeys.test(e.key)) {
            e.preventDefault();
        }
    }

    const handleKeyPress = (e) => {
        if (e?.key === "Enter") {
            e.preventDefault(); // Prevent default form submission behavior
            handleSubmit(); // Call your submit function
        }
    };
    return (
        <>
            <BgFormAnimate />
            <div>
                <div className='step register-content'>
                    <div className="regLogo">
                        <img src={logo} alt="logo" />
                    </div>
                    <div className='step_content step-contact-details'>
                        <div className="step-user-avatar">
                            {/* <div className='user-avatar-wrapper'>
                                <img src={userAvatar} alt="userAvatar" />
                                {profileImageSrc && <img src={profileImageSrc} alt="userAvatar" className="user-avatar" />}
                            </div> */}
                            <h2 className='step_title'>
                                Your Contact Details
                            </h2>
                            <p className='step_contentSubtitle'>
                                Provide your public contact information so visitors can easily reach you for professional inquiries or opportunities.
                            </p>
                        </div>
                        <div className='step_content_wrapp'>
                            <div className='user_email'>
                                <p className='label-text'>Public address (optional)</p>
                                <input autoFocus={true} value={publicAddress} onChange={e => setPublicAddress(e.target.value)} onKeyPress={(e) => { handleKeyPress(e) }} placeholder='Enter your address' className='last_name icon-public-address' type="text" />
                            </div>

                            <div className='user_email'>
                                <p className='label-text'>Public phone number (optional)</p>
                                <input value={publicPhone} 
                                    onChange={(e) => {
                                        const phoneValue = e.target.value;
                                        setPublicPhone(phoneValue);
                                        if (phoneValue === '' || phonePattern.test(phoneValue)) {
                                            setValidatePhone(false);
                                        } else {
                                            setValidatePhone(true);
                                        }
                                }} 
                                onKeyPress={(e) => { handlePhoneKeyPress(e) }}
                                placeholder='Enter your phone number' className='last_name icon-public-number' type="text" />
                                
                                {validatePhone == true && (publicPhone.length > 0) && (
                                    <p className="validateError">
                                        Please enter a valid phone number
                                    </p>
                                )}
                            </div>

                            <div className='user_email'>
                                <p className='label-text'>Public email (optional)</p>
                                <input value={publicEmail}
                                    onChange={(e) => {
                                        const emailValue = e.target.value;
                                        setPublicEmail(emailValue);
                                        if ((emailValue === '' || emailPattern.test(emailValue))) {
                                            setValidateEmail(false);
                                        } else {
                                            setValidateEmail(true);
                                        }
                                    }}
                                    onKeyPress={(e) => { handleKeyPress(e) }}
                                    placeholder='Enter your email address' className='last_name icon-phone-number' type="text" />

                                {validateEmail === true && (publicEmail.length > 0) && (
                                    <p className="validateError">
                                        Please enter a valid email address
                                    </p>
                                )}
                            </div>

                            <div className="registrationSocialBlock">
                                <div className="registrationListWrap">
                                    <div className="registrationSocialBlock">
                                        <div className='user_email'>
                                            <p className='label-text'>My social networks</p>
                                        </div>
                                        <div className="registrationSocialRight">
                                            <div className="registrationSocialItem">
                                                <Nestable items={socialArray}
                                                    renderItem={({ item }) => <SocialItemList socialArray={socialArray} updateSocialArray={updateSocialArray} deleteItem={deleteItem} data={item} name={item.title} link={item.url} />}
                                                    maxDepth={0}
                                                    onChange={(newData) => {
                                                        newData.items.map((item, index) => (item.id = index + 1));
                                                        setSocialArray(newData.items);
                                                    }}
                                                />

                                                <button onClick={e => {
                                                    var data = socialArray;

                                                    setSocialArray([...socialArray, {
                                                        id: data.length + 1,
                                                        title: null,
                                                        url: null,
                                                        children: []
                                                    }])
                                                }} className='addToMenu addToMenuRegistration'><img src={plusIcon} alt="Add new social network" /><p>Add a new social network</p></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="regButtons regBack">
                                <button onClick={e => props.step(2)} className='backButton create-button secondary-button flex minwidthAuto button'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M9.2045 9.99956L12.917 13.7121L11.8565 14.7726L7.0835 9.99956L11.8565 5.22656L12.917 6.28706L9.2045 9.99956Z" fill="#525866" />
                                    </svg>
                                    Back</button>
                                <button className={`btn_toStep create-button primary-button ${(validatePhone || validateEmail) ? 'disabledBtn' : 'button'}`} onClick={() => { handleSubmit() }}>
                                    Continue
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z" fill="white" />
                                    </svg>
                                </button>
                            </div>
                            <div className='register_nav'>
                                <RegStatus statusReg={props.data} setStatusReg={props.addData} activeStep={props.activeStep} setActiveStep={props.step} />
                            </div>
                            {/* <div className='regContact'>
                                <p>
                                    Need help? Feel free to <Link to={"/contact"}> Contact us </Link>
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Step3;