import '.././App.css';
import { Link } from "react-router-dom"
function Footer({menuActive}) {

    return (
        <footer>
            <div className={`container footer stickyBar ${menuActive ? 'fullStickyBar' : ''}`}>
                <div className="footerLeft">
                    <p>© {new Date().getFullYear()} Persone. All rights reserved.</p>
                </div>
                <ul>
                    <li><Link>Terms of Service</Link></li>
                    <li><Link>Privacy Policy</Link></li>
                </ul>
            </div>
        </footer>
    );
}

export default Footer;
