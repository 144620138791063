import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { format } from "date-fns";
import axios from 'axios';

import '.././App.css';

import Boxes from '../Components/Boxes';
import Analytics from '../Components/Analytics';
import InfoList from '../Components/InfoList';
import LatesInfoBox from '../Components/LatesInfoBox';
import BusinessPlan_Step1 from '../Components/BusinessPlanModal/BusinessPlan_Step1';
import BasicPlanModal from '../Components/BusinessPlanModal/BasicPlanModal';
import AnalyticsOnlineVisitors from "../Components/AnalyticsOnlineVisitors"
import DatePicker from '../Components/DatePicker';
import Notification from '../Components/Notification';
import DashBoardModal from '../Components/WelcomeModal/DashBoardModal';

import down from ".././images/Settings/down.svg";
import avatar from '.././images/profile-user.svg'


import dateFormatter, { formatISO, getCompareDate, today, currentMonth, currentYear, getDateRangeOfMonth, getDateRangeOfYear, getNextDate, getPrevDate, firstDateOfLastMonth, lastDateOfLastMonth } from "../utils/dateFormatter";
import PageModal from '../Components/WelcomeModal/PageModal';
import NotificationMessage from '../Components/NotificationMessage';


function Home(props) {
    var startDate = format(new Date(), "MMMM d, yyyy");
    var endDate = format(new Date(), "MMMM d, yyyy");
    const history = useNavigate();
    const [DateValue, setDateValue] = useState(startDate + " - " + endDate);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    props.setActivePage('Home')

    const [planDetails, setPlanDetails] = useState();
    const [paymentMethods, setPaymentMethods] = useState('');
    const [isStipeConnected, setIsStipeConnected] = useState(false);

    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [openNotify, setOpenNotify] = useState(false);
    const [emailverify, setEmailVerify] = useState(false)
    const [modal, setmodal] = useState(false);
    const [businessPlanModal, setBusinessPlanModal] = useState(false);
    const [basicPlanModal, setBasicPlanModal] = useState(false);
    const [onlineVisitors, setOnlineVisitors] = useState(0)
    const [openRate, setOpenRate] = useState(0)
    const [latestNewsLatterDeliveredCount, setLatestNewsLatterDeliveredCount] = useState(0);
    const [latestNewsLatterOpenedCount, setLatestNewsLatterOpenedCount] = useState(0);
    const [latestPublicationPageviews, setPublicationPageviews] = useState(0);
    const [latestPublicationVisitors, setPublicationVisitors] = useState(0);
    const [totalPayoutRate, setTotalPayoutRate] = useState(0);
    const [totalPayoutRateChange, setTotalPayoutRateChange] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);
    const [analyticsData, setAnalyticsData] = useState([]);
    const [apiFilter, setapiFilter] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [customDate, setCustomDate] = useState('');
    const [customDates, setCustomDates] = useState({ startDate: '', endDate: '' });
    const [showPeriods, setShowPeriods] = useState(false);
    const [selectedPeriodLbl, setSelectedPeriodLbl] = useState('Last 30 Days');

    const [nextDateDisable, setNextDateDisable] = useState(true);
    const [prevDateDisable, setPrevDateDisable] = useState(false);
    const [selectedDate, setSelectedDate] = useState(today);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [previousDate, setPreviousDate] = useState(getCompareDate(today, '30d', 'previous_period'));
    const [stripeBalance, setStripeBalance] = useState({})
    const [profileSlug, setProfileSlug] = useState("");
    const [avatarImage, setAvatarImage] = useState(avatar);
    const [pageModal, setpageModal] = useState(false);
    const [tooltipShow, setTooltipShow] = useState(false);
    const [getTheme,setGetTheme] = useState()
    const [getCoverImage,setCoverImage]= useState()
    const [redirectPage, setRedirectPage] = useState()
    const [subscriberCount, setSubscriberCount]= useState(0)
    const [subscriberNotify, setSubscriberNotify] = useState(false);
    
    const comparePeriod = [
        { value: "disable", label: "Disable comparison" },
        { value: "previous_period", label: "Previous period" },
        { value: "year_over_year", label: "Year over year" },
        { value: "custom_period", label: "Custom period" }
    ];

    const [compareCustomDates, setCompareCustomDates] = useState({ startDate: '', endDate: '' });
    const [filtersData, setFiltersData] = useState({ page: '', browser: '', browserVersion: '', os: '', osVersion: '', size: '', country: '', region: '', city: '' });
    const [selectedComaprePeriod, setSelectedComaprePeriod] = useState(comparePeriod[1]);
    const [selectedComapreLbl, setSelectedComapreLbl] = useState('Previous period');
    const [showCompareDatePicker, setShowCompareDatePicker] = useState(false);


    const periodsRef = useRef(null);
    

    // Close when clicking outside of it
    useEffect(() => {
        function handleClickOutside(event) {

            if (periodsRef.current && !periodsRef.current.contains(event.target)) {
                setShowPeriods(false);
                setShowDatePicker(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [periodsRef]);  

    const periods = [
        { value: "day", label: "Today" },
        { value: "7d", label: "Last 7 Days" },
        { value: "30d", label: "Last 30 Days" },
        { value: "month", label: "Month to Date" },
        { value: "last_month", label: "Last Month" },
        { value: "year", label: "Year to Date" },
        { value: "12mo", label: "Last 12 Months" },
        { value: "all", label: "All Time" },
        { value: "custom", label: "Custom Range" },
    ];

    const ref = useRef();
    const [selectedPeriod, setSelectedPeriod] = useState(periods[2]);
    const [tempPeriod, setTempPeriod] = useState(periods[2]);
    const [showCompare, setShowCompare] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (datePickerOpen && ref.current && !ref.current.contains(e.target)) {
                setDatePickerOpen(false);

            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [datePickerOpen]);

    const datevalue = (data) => {
        setDateValue(data)
    }

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Access specific query parameters
    const value = queryParams.get('success');

    useEffect(() => {
        if (value === 'true') {
            axios.post(`${process.env.REACT_APP_API_URL}/user/userverifyemail/${JSON.parse(localStorage.getItem("userinfo"))?._id}?success=${value}`)
                .then((response) => {
                    setEmailVerify(true)
                })
                .catch((err) => {
                    if (err?.response?.status == 400) {
                        console.log(err);
                    }
                })
        }
    }, [])

    const [userData, setuserData] = useState({});

    const toConvertUppercase = (string) => {
        const words = string?.split(" ");

        const capitalizedWords = words?.map(
            (word) => word?.charAt(0).toUpperCase() + word?.slice(1)
        );
        const capitalizedString = capitalizedWords?.join(" ");
        return capitalizedString;
    };

    const ChartData = async (filterData = '', filtersData = {}) => {
        try {
            setIsLoading(true);
            let setPeriod = selectedPeriod?.value || '30d';
    
            if (setPeriod === 'custom' && (customDates.startDate == '' || customDates.endDate == '')) {
                return false;
            }
        
            if (setPeriod === 'compare') {
                setPeriod = tempPeriod?.value || '30d';
            }
        
            let date = today;
            let compareDate = getCompareDate(today, setPeriod, selectedComaprePeriod.value);
        
            if (showCompare) {
                if ( selectedComaprePeriod.value === 'custom_period' && (compareCustomDates.startDate == '' || compareCustomDates.endDate == '')) {
                return false;
                }
                
                setPeriod = tempPeriod?.value || '30d';
            }
        
            const createdAtDate = formatISO(JSON.parse(localStorage.getItem("userinfo"))?.createdAt || today);
            compareDate = getCompareDate(today, setPeriod, selectedComaprePeriod.value, compareCustomDates);
        
            if (setPeriod === 'day') {
                setPeriod = 'day';
                date = selectedDate;
                compareDate = previousDate || getCompareDate(selectedDate, setPeriod, selectedComaprePeriod.value);
            } else if (setPeriod === 'month') {
                date = `${customDates.startDate},${customDates.endDate}`;
                compareDate = getCompareDate(customDates.startDate, setPeriod, selectedComaprePeriod.value);
                setPeriod = 'custom';
            } else if (setPeriod === 'last_month') {
                date = `${firstDateOfLastMonth},${lastDateOfLastMonth}`;
                compareDate = getCompareDate(firstDateOfLastMonth, setPeriod, selectedComaprePeriod.value);
                setPeriod = 'custom';
            } else if (setPeriod === 'year') {
                date = `${customDates.startDate},${customDates.endDate}`;
                compareDate = getCompareDate(customDates.startDate, setPeriod, selectedComaprePeriod.value);
                setPeriod = 'custom';
            } else if (setPeriod === 'all') {
                setPeriod = 'custom';
                date = `${createdAtDate},${today}`;
            } else if (setPeriod === 'custom') {
                setPeriod = 'custom';
                date = `${formatISO(customDates?.startDate)},${formatISO(customDates?.endDate)}`;
                compareDate = getCompareDate(`${formatISO(customDates?.startDate)},${formatISO(customDates?.endDate)}`, setPeriod, selectedComaprePeriod.value,compareCustomDates);
            }
        
            const period = setPeriod;
        
            setapiFilter(filterData);
            setFiltersData(filtersData);
            
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/getAnalyticsDataDashboard/${JSON.parse(localStorage.getItem("userinfo"))?._id}?filters=${filterData}&period=${period}&date=${date}&compare=${showCompare}&compareDate=${compareDate}`,
            )

            const result = response?.data?.result;
            setAnalyticsData(result);
            setOnlineVisitors(result?.realtimeVisitors || 0);
            setOpenRate(result?.subscriber?.aggregateData?.openRate?.value)
            setTotalPayoutRate(result?.subscriber?.aggregateData?.totalPayout?.value)
            setTotalPayoutRateChange(result?.subscriber?.aggregateData?.totalPayout?.change)
            setLatestNewsLatterDeliveredCount(result?.subscriber?.aggregateData?.deliveredCount?.value)
            setLatestNewsLatterOpenedCount(result?.subscriber?.aggregateData?.openedCount?.value)
            setPublicationVisitors(result?.subscriber?.aggregateData?.latestPublication?.value?.visitors)
            setPublicationPageviews(result?.subscriber?.aggregateData?.latestPublication?.value?.pageviews)
            
            setIsLoading(false);
        
            //setAllFiltered(response?.data?.result);
        } catch (error) {
          setIsLoading(false);
          console.log(error, "error");
        }
    }




    

    useEffect(() => {
        const createdAtDate = formatISO(JSON.parse(localStorage.getItem("userinfo"))?.createdAt || today);
        const createdAtYear = formatISO(JSON.parse(localStorage.getItem("userinfo"))?.createdAt || today, 'year');
    
        if (selectedComaprePeriod.value == 'custom_period' && compareCustomDates.startDate !== '' && compareCustomDates.endDate !== '') {
          setSelectedComapreLbl(`${dateFormatter(compareCustomDates.startDate, 'date', '', false)} - ${dateFormatter(compareCustomDates.endDate, 'date', '', false)}`)
        } else if( selectedComaprePeriod.value === 'disable'){
          setSelectedComapreLbl('Previous period')
        } else{
            setSelectedComapreLbl(selectedComaprePeriod.label)
        }
        if (selectedPeriod.value === 'day') {
          setCustomDate(selectedDate);
          setNextDateDisable(false);
          setPrevDateDisable(false);
    
          setSelectedPeriodLbl(dateFormatter(selectedDate, 'date', '', true));
    
          if (createdAtDate === selectedDate) {
            setPrevDateDisable(true);
          }
    
          if (today === selectedDate) {
            setNextDateDisable(true);
          }
        } else if (selectedPeriod.value === 'month') {
    
        } else if (selectedPeriod.value === 'year') {
          setNextDateDisable(false);
          setPrevDateDisable(false);
    
          if (selectedYear == currentYear) {
            setNextDateDisable(true);
          }
          if (selectedYear == createdAtYear) {
            setPrevDateDisable(true);
          }
          if (createdAtYear === currentYear) {
            setNextDateDisable(true);
            setPrevDateDisable(true);
          }
          setSelectedPeriodLbl(`Year of ${selectedYear}`);
        } else if (selectedPeriod.value === 'custom' && customDates.startDate !== '' && customDates.endDate !== '') {
          setSelectedPeriodLbl(`${dateFormatter(customDates.startDate, 'date', '', false)} - ${dateFormatter(customDates.endDate, 'date', '', false)}`)
        } else {
          if (selectedPeriod.value !== 'compare') {
            setSelectedPeriodLbl(selectedPeriod.label)
          }
        }
        if (showDatePicker === false && showCompareDatePicker === false) {
            ChartData(apiFilter, filtersData);
        }

    }, [selectedPeriod, selectedDate, customDates, showCompare, selectedComaprePeriod, compareCustomDates]);

    const userId= `${JSON.parse(localStorage.getItem("userinfo"))?._id}`
    const monetizationData = async () =>{
        const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/subscriber/getMonetizationData/${userId}`,
        )

        setStripeBalance(response?.data?.data?.stripeBalance || {})
    }

    useEffect(() =>{
        monetizationData()
    },[userId])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}?notification=${true}`,
                )
                const userData = response?.data?.result?.user;
                const userMediaData = response?.data?.result?.userMediaData;
                const userMeta = response?.data?.result?.userMeta;
                const firstName = toConvertUppercase(userData?.firstName);
                const lastName = toConvertUppercase(userData?.lastName);
                setFirstName(firstName);
                setLastName(lastName);
                setuserData(userData);
                setProfileSlug(userMeta.profileSlug);
                setRedirectPage(response?.data?.result?.redireactAboutPage)
                setGetTheme(userMeta)
                setCoverImage(response?.data?.result?.profileMediaUrl)
                setSubscriberCount(response?.data?.subscriberCount)

                if(userMeta?.profileMedia?.cropedImage) {
                    setAvatarImage(userMediaData.profileMeida.cropedMedia.url)
                }


                if (userMeta.introductionStatus === true) {
                    setmodal(false);
                } else {
                    setmodal(true);
                    if (modal === true) {
                        document.body.classList.add('hidden-message');
                    }
                    return () => {
                        document.body.classList.remove('hidden-message');
                    };
                }

                if (userMeta.status === "deactivate" && userMeta.plan === "business_plan") {
                    setBusinessPlanModal(true);
                    if (businessPlanModal === true) {
                        document.body.classList.add('hidden-message');
                    }
                    return () => {
                        document.body.classList.remove('hidden-message');
                    };
                }else if(userMeta.status === "active"){
                    setBusinessPlanModal(false);
                }
                if (userMeta.status === "deactivate" && userMeta.plan === "free_plan") {
                    setBasicPlanModal(true);
                    if (basicPlanModal === true) {
                        document.body.classList.add('hidden-message');
                    } 
                    return () => {
                        document.body.classList.remove('hidden-message');
                    };
                }else if(userMeta.status === "active"){
                    setBasicPlanModal(false);
                }
                if(response?.data?.subscriberCount > 0){
                    setSubscriberNotify(true)
                }

            } catch (error) {
                console.log(error, "error");
            }
        } 
        fetchData();
    }, [businessPlanModal,basicPlanModal]);

    const openPageModal = () => {
        setpageModal(true);
        setmodal(false);
    };
    const clickCopyUrl = () => {
        navigator.clipboard.writeText(`https://jourmal-next-front.vercel.app/${getTheme?.profileSlug}`);
        setTooltipShow(true); 
        setTimeout(() => {
            setTooltipShow(false); 
        }, 2000);
    };

    useEffect(() => {
        const token = JSON.parse(localStorage?.getItem("userinfo"))?.token;
        if (!token) {
            history("/sign-in");
        }
    }, []);

    useEffect(() => {
        fetchBillingData();
    }, []);

    const fetchBillingData = async () => {
        try {
          const billingDetailsResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/getBillingDetails/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
          );

          setPlanDetails(billingDetailsResponse.data.result.subscriptions);
          setPaymentMethods(billingDetailsResponse.data.result.paymentMethods);
          setIsStipeConnected(billingDetailsResponse.data.result.stripeConnected);
          
        } catch (error) {
          console.log(error, "error");
        }
      }

    const handleChangePeriod = (period) => {
        setShowPeriods(false);
        setSelectedPeriod(period);
        setCustomDate('');
        setSelectedPeriodLbl(period.label);

        setNextDateDisable(true);
        setPrevDateDisable(true);

        if (period.value === 'custom') {
            setShowDatePicker(true)
        } else {
            setSelectedPeriod(period);
        }

        if (period.value === 'day') {
            setCustomDate(today)
            setSelectedDate(today);
            setNextDateDisable(true);
            setPrevDateDisable(false);
            setSelectedPeriodLbl(dateFormatter(today, 'date', '', true))
        }

        if (period.value === 'month') {
            const monthRange = getDateRangeOfMonth(currentMonth);

            setSelectedPeriodLbl(currentMonth);
            setCustomDates(monthRange);
            setNextDateDisable(true);
            setPrevDateDisable(false);
        }

        if (period.value === 'year') {
            const yearRange = getDateRangeOfYear(`Year of ${currentYear}`);

            setSelectedYear(currentYear);
            setCustomDates(yearRange);

            setNextDateDisable(true);
            setPrevDateDisable(false);
        }

        if (period.value === 'all') {
            setShowCompare(false);
        }
    }

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const handlePrevDate = () => {
        if (!prevDateDisable) {
    
          if (selectedPeriod.value == 'day') {
            const prevDate = getPrevDate(selectedDate);
    
            setSelectedDate(prevDate);
            setCustomDate(prevDate);
          }
    
          if (selectedPeriod.value === 'month') {
            const createdMonth = formatISO(JSON.parse(localStorage.getItem("userinfo"))?.createdAt || today, 'month');
            const prevMonth = getPrevDate(selectedPeriodLbl, 'month');
            const monthRange = getDateRangeOfMonth(prevMonth);
    
            setCustomDates(monthRange);
            setSelectedPeriodLbl(prevMonth);
            setNextDateDisable(false);
    
            if (prevMonth === createdMonth) {
              setPrevDateDisable(true);
            }
          }
    
          if (selectedPeriod.value === 'year') {
            const prevYear = getPrevDate(selectedPeriodLbl, 'year');
            const yearRange = getDateRangeOfYear(`Year of ${prevYear}`);
    
            setSelectedYear(prevYear);
            setCustomDates(yearRange);
          }
        }
    };
    
      const handleNextDate = () => {
        if (!nextDateDisable) {
          if (selectedPeriod.value == 'day') {
            const nextDate = getNextDate(selectedDate);
            setSelectedDate(nextDate);
            setCustomDate(nextDate);
            if (showCompare) {
              const comNextDate = getNextDate(previousDate);
              setPreviousDate(comNextDate);
            }
          }
    
          if (selectedPeriod.value === 'month') {
            const nextMonth = getNextDate(selectedPeriodLbl, 'month');
            const monthRange = getDateRangeOfMonth(nextMonth);
    
            setCustomDates(monthRange);
            setSelectedPeriodLbl(nextMonth);
            setPrevDateDisable(false);
    
            if (nextMonth === currentMonth) {
              setNextDateDisable(true);
            }
          }
    
          if (selectedPeriod.value === 'year') {
            const nextYear = getNextDate(selectedPeriodLbl, 'year');
            const yearRange = getDateRangeOfYear(`Year of ${nextYear}`);
    
            setSelectedYear(nextYear)
            setCustomDates(yearRange);
          }
        }
    }
   
    const openPublicationModal = async () => {
        try {

            let response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/welcome/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                { introductionStatus: true }
            );
            document.body.classList.remove('hidden-message');
        } catch (error) {
            console.log(error, "error");
        }
        setpageModal(false)
        setmodal(false)

    };
    return (
        <section className='dashboard analytics'>
            <Helmet>
                <title>Dashboard | Persone</title>
            </Helmet>
            <div className="container">
                <div className="dashboardTop">
                    <div className="dashboardTopText">
                        <div className='dashboardTopLeft'>
                            <p >Hello, {toConvertUppercase(userData?.firstName)}</p>
                            <p className="pageSubTitle">Welcome to your dashboard! Here, you can manage your profile, settings, and content all in one place.</p>
                        </div>
                    </div>
                </div>

                <div className="analyticsContent">
                    <div className="online-visitors" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <AnalyticsOnlineVisitors onlineVisitors={onlineVisitors} showTooltip={showTooltip} />
                    </div>
                    <div className="analyticsSelect">
                        {/* Arrow-section Show condition */}
                            {selectedPeriod &&
                            (selectedPeriod.value === "day" || selectedPeriod.value === "month" || selectedPeriod.value === "year") && (
                            <div className="arrow-section">
                            <button className={prevDateDisable && 'disable'} onClick={handlePrevDate}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="15 18 9 12 15 6"></polyline></svg>
                            </button>
                            <button className={nextDateDisable && 'disable'} onClick={handleNextDate}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
                            </button>
                            </div>
                        )}
                    <div className="period-selections datepickerButton" ref={periodsRef}>
                        <div className="selected-period homepage-selectBox" onClick={() => setShowPeriods(!showPeriods)} >{selectedPeriodLbl} <img src={down} alt={selectedPeriod.label} /></div>
                        <div className="periods-options selections-dropdown">
                            {showPeriods && periods.map((period) => (
                                <div
                                    key={period.value}
                                    className={((selectedPeriod.value !== 'day' && selectedPeriod.value !== 'month' && selectedPeriod.value !== 'year') && ((showCompare && tempPeriod.value === period.value) || (!showCompare && ((selectedPeriod.value !== 'compare' && selectedPeriod.value === period.value) || selectedPeriod.value === 'compare' && tempPeriod.value === period.value)))) && `selected`}
                                    onClick={() => { handleChangePeriod(period) }}
                                >
                                    {period.value === 'compare' && showCompare ? 'Disable comparison' : period.label}
                                </div>
                            ))}

                            {
                                showDatePicker && <DatePicker datevalue={datevalue} setShowDatePicker={setShowDatePicker} setCustomDates={setCustomDates} />
                            }

                        </div>
                    </div>
                </div>
                </div>

                <Boxes setOpenNotify={setOpenNotify} planDetails={planDetails} paymentMethods={paymentMethods} isStipeConnected={isStipeConnected} fetchBillingData={fetchBillingData} stripeBalance={stripeBalance} totalPayoutRate={totalPayoutRate} totalPayoutRateChange={totalPayoutRateChange}/>
                    <Analytics analytics={analyticsData?.analytics} subscriber={analyticsData?.subscriber} isLoading={isLoading} analyticsGraphData={analyticsData?.analytics?.graphData}  subscriberGraphData={analyticsData?.subscriber?.graphData} selectedPeriod={selectedPeriod} />
                <LatesInfoBox openRate={openRate} latestNewsLatterDeliveredCount={latestNewsLatterDeliveredCount} latestNewsLatterOpenedCount={latestNewsLatterOpenedCount} latestPublicationPageviews={latestPublicationPageviews} latestPublicationVisitors={latestPublicationVisitors}/>
                <InfoList analyticsData={analyticsData} isLoading={isLoading} />


                <Notification openNotify={openNotify} setOpenNotify={setOpenNotify} type={'success'} title={'Successfully updated profile'} text={'Your changes have been saved and your profile is live. Your team can make edits.'} />
                <Notification openNotify={emailverify} setOpenNotify={setEmailVerify} type={'success'} title={'Email has been successfully verified!'} text={'Thank you for taking the time to verify your email address with us'} />
                {modal && <DashBoardModal dashboardModal={modal} setdashboardModal={setmodal} openPageModal={openPageModal} clickCopyUrl={clickCopyUrl} getTheme={getTheme} getCoverImage={getCoverImage}  tooltipShow={tooltipShow} openPublicationModal={openPublicationModal}/>}
                {pageModal && <PageModal redirectPage={redirectPage} setdashboardModal={setmodal} pageModal={pageModal} setpageModal={setpageModal} openPublicationModal={openPublicationModal}/>}
                {businessPlanModal && <div className='welcomeOverlay'></div>}
                {businessPlanModal && <BusinessPlan_Step1 name={firstName} />}
                {basicPlanModal && <div className='welcomeOverlay'></div>}
                {basicPlanModal && <BasicPlanModal />}
                {/* {(subscriberNotify && subscriberCount > 0) && (
                    <NotificationMessage  openNotify={subscriberNotify} setOpenNotify={setSubscriberNotify} type={'success'} title={`${(subscriberCount === 1) ? `A subscriber was successfully added!`: `${subscriberCount} subscribers were successfully added!`}`} />
                )} */}

                
            </div>
        </section>
    );
}

export default Home;
