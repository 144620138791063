import { Routes, Route, Link } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import General from './Settings/General';
import MenuManagement from './Settings/MenuManagement';
import Social from './Social';
import Billing from './Settings/Billing';
import Notification from './Settings/Notification';
import Password from './Settings/Password';
import ErrorPage from './Error';
import '.././App.css';
import TwoFactor from './Settings/TwoFactor';
import Monetization from './Settings/Monetization';
import iconLock from '../images/locked.svg'
import ChangePlan from '../Components/Settings/ChangePlan';
import { ChangePlanSuccessModel } from '../Components/Modal/ChangePlanSuccessModel';
import { Domain } from './Settings/Domain';

function Settings(props) {

    // Set active page and initial props
    props.setActivePage('Settings');

    // State declarations
    const [activePage, setActivePage] = useState('General');
    const [navMenuOpen, setNavMenuOpen] = useState(false);
    const [selectedLink, setSelectedLink] = useState('General');
    const [isChangePlanOpen, setIsChangePlanOpen] = useState(false);
    const [openSuccessPlanPopup, setOpenSuccessPlanPopup] = useState(false)

    const ref = useRef();

    useEffect(() => {
        props.setActivePage("General");
    }, []);

    // Close navigation menu if clicked outside
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (navMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setNavMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [navMenuOpen]);

    // Logout functionality
    const handleLogout = () => {
        localStorage.removeItem("userinfo");
        localStorage.removeItem("isCompleted");
        localStorage.removeItem("userProfileSlug");
        sessionStorage.removeItem("userId");
        Cookies.remove('rememberMe');
        window.location.href = "/sign-in";
    }

    return (
        <>
        
            <Helmet>
                <title>Settings | Dashboard | Persone</title>
            </Helmet>
            <section className='settings'>

                <div className="container">
                    <h1>Settings</h1>

                    <div className="mobileNavigation">
                        <div className="select" ref={ref}>
                            <div className="selectMain" onClick={e => setNavMenuOpen(!navMenuOpen)}>
                                <p>{activePage}</p>
                            </div>
                            {
                                (navMenuOpen === true)
                                    ? <div className="selectListOption">

                                        <Link className="selectOption" onClick={e => { setSelectedLink('General'); setNavMenuOpen(false) }} to="/dashboard/settings/general">General</Link>
                                        <Link className="selectOption" onClick={e => { setSelectedLink('Navigation menu'); setNavMenuOpen(false) }} to="/dashboard/settings/menu">Navigation menu</Link>
                                        <Link className="selectOption" onClick={e => { setSelectedLink('Social networks'); setNavMenuOpen(false) }} to="/dashboard/settings/social">Social networks</Link>
                                        <Link className="selectOption" onClick={e => { setSelectedLink('Billing'); setNavMenuOpen(false) }} to="/dashboard/settings/billing">Billing</Link>
                                        <Link className="selectOption" onClick={e => { setSelectedLink('Notifications'); setNavMenuOpen(false) }} to="/dashboard/settings/notifications">Notifications</Link>
                                        <Link className="selectOption" onClick={e => { setSelectedLink('2FA'); setNavMenuOpen(false) }} to="/dashboard/settings/twofactor">2FA</Link>
                                        {(props?.billingDetails?.subscriptions?.plan === "free_plan") ? 
                                            <>
                                                <div className='menuOverlay' onClick={()=>{setIsChangePlanOpen(true)}} >
                                                    <div className='overlayLock-publications-type'></div>
                                                    <div className='selectOption lock setting-menu'>
                                                        <Link className='menulink' onClick={e => { setSelectedLink('Monetization'); setNavMenuOpen(false) }} to="/dashboard/settings/monetization">Monetization</Link>
                                                        <div className='overlayLock lock-icon'>
                                                            <img  src={iconLock} alt="lock-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <Link className="selectOption" onClick={e => { setSelectedLink('Monetization'); setNavMenuOpen(false) }} to="/dashboard/settings/monetization">Monetization</Link>
                                        }
                                        {(props?.billingDetails?.subscriptions?.plan === "free_plan") ? 
                                            <>
                                                <div className='menuOverlay' onClick={()=>{setIsChangePlanOpen(true)}} >
                                                    <div className='overlayLock-publications-type'></div>
                                                    <div className='selectOption lock setting-menu'>
                                                    <Link className="menulink" onClick={e => { setSelectedLink('Domain'); setNavMenuOpen(false) }} >Domain</Link>
                                                        <div className='overlayLock lock-icon'>
                                                            <img  src={iconLock} alt="lock-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <Link className="selectOption" onClick={e => { setSelectedLink('Domain'); setNavMenuOpen(false) }} to="/dashboard/settings/domain">Domain</Link>
                                        }
                                        <Link className="selectOption" onClick={e => { setSelectedLink('Password'); setNavMenuOpen(false) }} to="/dashboard/settings/password">Password</Link>
                                        <Link className="selectOption" onClick={e => { setSelectedLink('Navigation menu'); setNavMenuOpen(false); handleLogout(); }} to="">Sign out</Link>
                                    </div>
                                    : ''
                            }

                        </div>
                    </div>
                    <div className='settingsPage'>
                        <div className="settingsMenu">
                            <Link className={(activePage === 'General') ? 'settingMenu active' : 'settingMenu'} to="/dashboard/settings/general">General</Link>
                            <Link className={(activePage === 'Navigation menu') ? 'settingMenu active' : 'settingMenu'} to="/dashboard/settings/menu">Navigation menu</Link>
                            <Link className={(activePage === 'Social networks') ? 'settingMenu active' : 'settingMenu'} to="/dashboard/settings/social">Social networks</Link>
                            <Link className={(activePage === 'Billing') ? 'settingMenu active' : 'settingMenu'} to="/dashboard/settings/billing">Billing</Link>
                            <Link className={(activePage === 'Notifications') ? 'settingMenu active' : 'settingMenu'} to="/dashboard/settings/notifications">Notifications</Link>
                            <Link className={(activePage === '2FA') ? 'settingMenu active' : 'settingMenu'} to="/dashboard/settings/twofactor">2FA</Link>
                            {(props?.billingDetails?.subscriptions?.plan === "free_plan") ? 
                                <>
                                    <div className='overlayMenu' onClick={()=>{setIsChangePlanOpen(true)}} >
                                        <div className='overlayLock overlayLock-menu'>
                                            <img  src={iconLock} alt="lock-icon" />
                                        </div>
                                        <Link className={(activePage === 'Monetization') ? 'settingMenu active' : 'settingMenu'} >Monetization</Link>
                                    </div>
                                   
                                </>
                                :
                                <Link className={(activePage === 'Monetization') ? 'settingMenu active' : 'settingMenu'} to="/dashboard/settings/monetization">Monetization</Link>
                            }
                            {(props?.billingDetails?.subscriptions?.plan === "free_plan") ? 
                                <>
                                    <div className='overlayMenu' onClick={()=>{setIsChangePlanOpen(true)}} >
                                        <div className='overlayLock overlayLock-menu'>
                                            <img  src={iconLock} alt="lock-icon" />
                                        </div>
                                        <Link className={(activePage === 'Domain') ? 'settingMenu active' : 'settingMenu'} >Domain</Link>
                                    </div>
                                   
                                </>
                                :
                                <Link className={(activePage === 'Domain') ? 'settingMenu active' : 'settingMenu'} to="/dashboard/settings/domain">Domain</Link>
                            }
                            <Link className={(activePage === 'Password') ? 'settingMenu active' : 'settingMenu'} to="/dashboard/settings/password">Password</Link>
                            <Link className='settingMenu' to=" " onClick={handleLogout}>Sign out</Link>
                        </div>

                        <div className="settingsContent">
                            <Routes basename="/settings">
                                <Route path='/' element={<General setActivePage={setActivePage} />}></Route>
                                <Route path='/general' element={<General setActivePage={setActivePage} />}></Route>
                                <Route path='/menu' element={<MenuManagement setActivePage={setActivePage} />}></Route>
                                <Route path='/social' element={<Social setActivePage={setActivePage} />}></Route>
                                <Route path='/billing' element={<Billing setActivePage={setActivePage} />}></Route>
                                <Route path='/notifications' element={<Notification setActivePage={setActivePage} />}></Route>
                                <Route path='/twofactor' element={<TwoFactor setActivePage={setActivePage} />}></Route>                                <Route path='/monetization' element={props?.billingDetails?.subscriptions?.plan === 'free_plan' ? (<General setActivePage={setActivePage} />) : (<Monetization setActivePage={setActivePage} billingDetails={props?.billingDetails}/>)}></Route>
                                <Route path='/domain' element={props?.billingDetails?.subscriptions?.plan === 'free_plan' ? (<General setActivePage={setActivePage} />) : (<Domain setActivePage={setActivePage} />)}></Route>
                                <Route path='/password' element={<Password setActivePage={setActivePage} />}></Route>
                                <Route path='*' element={<ErrorPage setActivePage={setActivePage} menuDisabled={true} />}></Route>
                            </Routes>
                        </div>
                    </div>
                </div>
            </section>
            {isChangePlanOpen && 
                <ChangePlan setIsChangePlanOpen={setIsChangePlanOpen}  planDetails={props?.planDetails} paymentMethods={props?.paymentMethods} isStipeConnected={props?.isStipeConnected || false} updateData={props?.updateData} isExpired={props?.isExpired} setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup} isChangePlanOpen={isChangePlanOpen}/>
            }
            {
                openSuccessPlanPopup && 
                <ChangePlanSuccessModel setOpenSuccessPlanPopup={setOpenSuccessPlanPopup} openSuccessPlanPopup={openSuccessPlanPopup} plan={props?.planDetails?.plan}/>
            }
        </>
    );
}

export default Settings;
