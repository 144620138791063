import React, { useEffect, useState } from 'react'
import axios from 'axios';

import logo from "../../images/register-logo.svg"
import avatar from '../../images/profile-user.svg'

const InvitedUSerFirstStep = ({setShowInvitation, invitedFrom}) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [avatarImage, setAvatarImage] = useState(avatar);
    
    const toConvertUppercase = (string) => {
        const words = string.split(" ");

        const capitalizedWords = words.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1)
        );
        const capitalizedString = capitalizedWords.join(" ");
        return capitalizedString;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/user/${invitedFrom}`,
                )

                const userData = response?.data?.result?.user;
                const userMeta = response?.data?.result?.userMeta;
                const userMediaData = response?.data?.result?.userMediaData;

                const firstName = toConvertUppercase(userData?.firstName);
                const lastName = toConvertUppercase(userData?.lastName);

                if(userMeta?.profileMedia?.cropedImage) {
                    setAvatarImage(userMediaData.profileMeida.cropedMedia.url)
                }

                setFirstName(firstName);
                setLastName(lastName);

            } catch (error) {
                console.log(error, "error");
            }
        }
        fetchData();
    }, []);

    return (
        <div>
            <div className='welcomeModal inviteModal'>
            <div className='popupBlock-wrapper'>
                <div className="regLogo">
                    <img src={logo} alt="logo" />
                </div>
                <div className='invited-user-avatar'>
                    <img className='avatar' src={avatarImage} alt="logo" />
                </div>
                <p className='popupTitle text-center'>{firstName} {lastName} has invited you to Persone!</p>
                <p className='popupDesc text-center'>Invited people enjoy exclusive discounts on our paid plans, experiencing a personal website without any limitations:</p>
                <div className='step_content'>
                    <div className="invite-box-popup">
                        <div className="giftbox">
                            <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 4.5V16.5M9 4.5H6.34821C5.95749 4.5 5.58278 4.34196 5.30649 4.06066C5.03021 3.77936 4.875 3.39782 4.875 3C4.875 2.60218 5.03021 2.22064 5.30649 1.93934C5.58278 1.65804 5.95749 1.5 6.34821 1.5C8.41071 1.5 9 4.5 9 4.5ZM9 4.5H11.6518C12.0425 4.5 12.4172 4.34196 12.6935 4.06066C12.9698 3.77936 13.125 3.39782 13.125 3C13.125 2.60218 12.9698 2.22064 12.6935 1.93934C12.4172 1.65804 12.0425 1.5 11.6518 1.5C9.58929 1.5 9 4.5 9 4.5ZM15 8.25V14.1C15 14.9401 15 15.3601 14.8365 15.681C14.6927 15.9632 14.4632 16.1927 14.181 16.3365C13.8601 16.5 13.4401 16.5 12.6 16.5L5.4 16.5C4.55992 16.5 4.13988 16.5 3.81901 16.3365C3.53677 16.1927 3.3073 15.9632 3.16349 15.681C3 15.3601 3 14.9401 3 14.1V8.25M1.5 5.7L1.5 7.05C1.5 7.47004 1.5 7.68006 1.58175 7.84049C1.65365 7.98161 1.76839 8.09635 1.90951 8.16825C2.06994 8.25 2.27996 8.25 2.7 8.25L15.3 8.25C15.72 8.25 15.9301 8.25 16.0905 8.16825C16.2316 8.09635 16.3463 7.98161 16.4183 7.84049C16.5 7.68006 16.5 7.47004 16.5 7.05V5.7C16.5 5.27996 16.5 5.06994 16.4183 4.90951C16.3463 4.76839 16.2316 4.65365 16.0905 4.58175C15.9301 4.5 15.72 4.5 15.3 4.5L2.7 4.5C2.27996 4.5 2.06994 4.5 1.90951 4.58175C1.76839 4.65365 1.65365 4.76839 1.58175 4.90951C1.5 5.06994 1.5 5.27996 1.5 5.7Z" stroke="#0A2540" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>


                            <div className="LatesInfoContent">
                                <p className='LatesInfoTitel'>
                                    30 days free
                                </p>
                                <p className='popupDesc'>Unlimited plan for 30 days, no credit card required.</p>
                            </div>
                        </div>
                        <div className="giftbox">
                            <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.75 6.75H6.7575M11.25 11.25H11.2575M12 6L6 12M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9ZM7.125 6.75C7.125 6.95711 6.95711 7.125 6.75 7.125C6.54289 7.125 6.375 6.95711 6.375 6.75C6.375 6.54289 6.54289 6.375 6.75 6.375C6.95711 6.375 7.125 6.54289 7.125 6.75ZM11.625 11.25C11.625 11.4571 11.4571 11.625 11.25 11.625C11.0429 11.625 10.875 11.4571 10.875 11.25C10.875 11.0429 11.0429 10.875 11.25 10.875C11.4571 10.875 11.625 11.0429 11.625 11.25Z" stroke="#0A2540" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>


                            <div className="LatesInfoContent">
                                <p className='LatesInfoTitel'>
                                    25% off
                                </p>
                                <p className='popupDesc'>Special discount on upgrade for the first 3 months.</p>
                            </div>
                        </div>
                    </div>
                    </div>
                <div className="popupButtons">
                <button className='invitedUserButton primary-button button' onClick={() => setShowInvitation(false)} >
                    Create my personal web profile 
                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ rotate: "180deg", marginLeft: "5px", marginTop: '1px' }}>
                        <path d="M14.3335 6.5L1.00016 6.5M1.00016 6.5L6.00016 11.5M1.00016 6.5L6.00016 1.5" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
            </div>
            </div>
        </div>
    )
}

export default InvitedUSerFirstStep
