import { all } from 'axios';
import '.././App.css';
import noResults from '.././images/noResults.svg';
import plus from '.././images/plus.svg';
import { Link } from 'react-router-dom';

function NoResults(props) {    
    const clickEventHandler = props?.onclickhandle || null;

    const descriptions = {
        page: {
            draft: {
                title: "No draft pages yet",
                desctription: "It looks like you haven’t started any draft pages. Begin creating your informational pages to showcase who you are and what you do.",
            },
            deleted: {
                title: "No deleted pages",
                desctription: "It looks like you haven't deleted any pages yet. If you remove a page, you'll find it here for recovery or final removal.",
            },
            buttonText: "Create new page",
        },
        publication: {
            all: {
                title: "No publications yet",
                desctription: "It looks like you haven’t created any publications yet. Start sharing your content to engage your audience and showcase your work.",
            },
            published: {
                title: "No published publications",
                desctription: "It looks like you haven't published anything yet. Once you do, they'll appear here to keep your audience engaged.",
            },
            draft: {
                title: "No draft publications",
                desctription: "It looks like you haven’t started any draft publications yet. Begin crafting your content to share with your audience.",
            },
            deleted: {
                title: "No deleted publications",
                desctription: "It looks like you haven’t deleted any publications. If you do, you’ll find them here to restore or permanently remove.",
            },
            buttonText: "Create new publication",
        },
        newsletter: {
            all: {
                title: "No newsletters yet",
                desctription: "It looks like you haven’t created any newsletters yet. Start crafting your content and keep your subscribers and informed and updated.",
            },
            sent: {
                title: "No sent newsletters",
                desctription: "It looks like you haven’t sent any newsletters yet. You haven’t sent any newsletters yet. Once you do, they’ll appear here for easy access and reference.",
            },
            draft: {
                title: "No draft newsletters",
                desctription: "It looks like you haven’t started any draft newsletters yet. Begin writing your next newsletter to share valuable content with your subscribers.",

            },
            deleted: {
                title: "No deleted newsletters",
                desctription: "It looks like you haven’t deleted any newsletters. If you do, they’ll appear here for you to recover or remove permanently."
            },
            buttonText: "Create new newsletter",
        },
        'media-gallery': {
            all: {
                title: "Your media library is empty",
                desctription: "You haven’t added any media yet. Start uploading images, videos, and files to enrich your content and bring your ideas to life.",
            },
            buttonText: "Upload media",
        },
        draft: {
            all: {
                title: "No drafts yet",
                desctription: "You haven’t started any drafts yet. Begin crafting your ideas today and save them as drafts to refine later.",
            }
        },
        categories: {
            all: {
                title: "No categories yet",
                desctription: "It looks like you haven’t created any categories yet. Organize your content into categories to keep everything structured and easy to manage.",
            },
            buttonText: "Add new category",
        }
    }

    let title = `No ${props?.name} yet`;
    let desctription = "Your search “Landing page design” did not match any projects. Please try again.";
    let buttonText = `Create new ${props?.name}`;
    let buttonTo = props.type === 'categories' ? '#' : `/dashboard/${props?.type}`;

    if ( descriptions[props?.type] ) {
        if( descriptions[props?.type][props?.status] ) {
            title = descriptions[props?.type][props?.status].title;
            desctription = descriptions[props?.type][props?.status].desctription;
        }
        if( descriptions[props?.type]['buttonText'] ) {
            buttonText = descriptions[props?.type]['buttonText'];
        }

        switch (props?.type) {
            case 'publication'
                : buttonTo = '/dashboard/new-publication/';
                break;
            case 'page'
                : buttonTo = '/dashboard/new-page/';
                break;
            case 'newsletter'
                : buttonTo = '/dashboard/new-newsletter/';
                break;
        }
    }


    return (
        <div className='NoResults'>
            <div className="noResultsBody">
                { props?.hideIcon !== false &&
                    <img src={noResults} alt="No results" />
                }                
                <p className="noResTitle">{title}</p>
                <p className='noResDesc'>{desctription}</p>
                { props?.name !== "draft" &&
                    <button className='noResButton secondary-button flex button'>
                        <img src={plus} alt="Plus" />
                            <Link className='editTitle' to={buttonTo} onClick={(e) => {
                                if(props.name === 'Media Library') {
                                    e.preventDefault();
                                }
                                if(props.mediaPopup) {
                                    document.getElementById("pulication-file").click();
                                }
                                if(clickEventHandler) clickEventHandler();
                            }}>
                                {buttonText}
                            </Link>
                    </button>
                }
            </div>
        </div>
    );
}

export default NoResults;
