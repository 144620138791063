import '../.././App.css';
import React, { useState, useEffect } from "react";
import userAvatar from '../../images/user-avatar.svg';
import Loader from '../Loader/Loader';
import axios from 'axios';
import Cookies from 'js-cookie';

function Done(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [profileImageSrc, setprofileImageSrc] = useState('')

    const step2Data = props.data[1].data[0];
    let displayName = step2Data?.displayName || '';
    let title = step2Data?.title || '';
    let desc = step2Data?.desc || '';

    const step3Data = props.data[2].data[0];
    let address = step3Data?.address || '';
    let phone = step3Data?.phone || '';
    let email = step3Data?.email || '';
    let socialNetwork = step3Data?.socialArray || [];
    const step4Data = props.data[3].data[0];
    let profilePictureData = {
        profilePosition: step4Data?.profilePosition,
        profileZoom: step4Data?.profileZoom,
        position: step4Data?.coverImagePosition,
        coverImageZoom: step4Data?.coverImageZoom
    }
    const step5Data = props.data[4].data;
    let plan = '';
    let cardName = '';
    let expiry = '';
    let cardNumber = '';
    let cvv = '';
    let planType = '';
    let isProfileAssistant = false;
    step5Data.map((item) => {
        return (
            plan = item?.plan || '',
            cardName = item?.cardName || '',
            expiry = item?.expiry || '',
            cardNumber = item?.cardNumber || '',
            cvv = item?.cvv || '',
            planType = item?.planType || '',
            isProfileAssistant = item?.isProfileAssistant || ''
        )
    });

    const step6Data = props.data[5].data[0];
    let profileSlug = step6Data?.profileSlug || '';

    useEffect(() => {
        var profileData = props.data[3]?.data[0] || ''
        if (profileData) {
            setprofileImageSrc(profileData?.profileImageSrc);
        }
        setIsLoading(true);

        addData();
    }, []);

    const addData = async () => {
        const registerData = {
            displayName: displayName,
            socialNetwork: socialNetwork,
            job_title: title,
            bio: desc,
            address: address,
            phone: phone,
            email: email,
            profileSlug: profileSlug,
            cardDetail: {
                cardName: cardName,
                expiry: expiry,
                cardNumber: cardNumber,
                cvv: cvv
            },
            profileMedia: {
                selectedImage: step6Data?.imageId?.profileImageId || '',
                cropedImage: step6Data?.imageId?.profileCroppedId || '',
                profilePosition: profilePictureData?.profilePosition || '',
                profileZoom: profilePictureData?.profileZoom || 0
            },
            coverMedia: {
                selectedImage: step6Data?.imageId?.coverImageId || "",
                cropedImage: step6Data?.imageId?.coverCroppedId || "",
                profilePosition: profilePictureData?.position || "",
                profileZoom: profilePictureData?.coverImageZoom || 0
            },
            theme: step4Data?.theme || 'dark'
        };

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/plan/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            registerData
        );
        if (response.status == 200) {
            localStorage.setItem("isCompleted", "true");
            props.setIsCompleted(true);
            Cookies.set('rememberMe', true);

            setTimeout(() => {
                window.location.href = '/dashboard';
            }, 1000);
        }
    }

    return (
        <div className='step done'>
            <div className='step_content'>
                <div className="step-user-avatar">
                    <div className='user-avatar-wrapper'>
                        <img src={userAvatar} alt="userAvatar" />
                        {profileImageSrc && <img src={profileImageSrc} alt="userAvatar" className="user-avatar" />}
                    </div>
                    <h2 className='step_title profile-done-title'>Your profile is almost ready!</h2>
                    <p className='step_contentSubtitle'>
                        We are creating your web profile, it might take a few minutes.
                    </p>
                </div>
                <div className='loading-content'>
                    {isLoading === true && <Loader />}
                    <p className='step_contentSubtitle step_contentAlignment'>
                        When you’re web profile is ready you’ll be forwarded to your dashboard, and you will receive an email with all the information you need to continue.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Done;