import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import Pagination from '../Components/Pagination';
import NoResults from '../Components/NoResults';
import CategoriesPageListItem from '../Components/CategoriesPageListItem';
import AddCategoryPage from "../Components/Modal/AddCategoryPage";
import DeleteCategoryModel from "../Components/Modal/DeleteCategoryModel";
import NotificationMessage from "../Components/NotificationMessage";
import { DataTableList } from '../Components/DataTable/DataTable';
import PageActionDropdown from '../Components/PageActionDropdown';

import search from '.././images/search.svg'
import down from '.././images/down_arrow.svg'
import top from '.././images/arrow_top.svg'
import clear from '.././images/delete.svg'
import plus from '.././images/plus-icon.svg'
import edit from '.././images/pen.svg'
import more from '../images/more.svg'

import '.././App.css';
import CategoryActionDropdown from '../Components/CategoryActionDropdown';

function Categories(props) {

    props.setActivePage('Categories');

    const [activeFilter, setActiveFilter] = useState('All')
    const [searchInput, setSearchInput] = useState('')

    const [originalData, setOriginalData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([])

    const [selectedCatsLength, setSelectedCatsLength] = useState(0)

    const [openPopupIndex, setOpenPopupIndex] = useState(null);

    const [showCategoryField, setShowCategoryField] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false)
    const [openNotify, setOpenNotify] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [showMultiNotify, setShowMultiNotify] = useState(false)

    const [toggleSelect, setToggleSelect] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [columnWidths, setColumnWidths] = useState([]);

    const ref = useRef();

    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id

    const fetchData = async () => {
        const search = searchInput.toLowerCase();
        setIsLoading(false)
        try {
            setIsLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/categories/list/${JSON.parse(localStorage.getItem("userinfo"))?._id}?page=${currentPage}&search=${search}&types=all`,
            )
            setOriginalData(response?.data?.result);
            setCategoriesData(response?.data?.result);
            setTotalPage(response.data.totalPages || 1);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error, "error");
        }
    }
    useEffect(() => {
        fetchData();
    }, [currentPage, searchInput, activeFilter]);

    const updateCategoriesData = (categoryId, categoryName, action) => {
        if (action == 'add') {
            const categoriesDatas = categoriesData;

            const foundData = categoriesDatas.find(obj => obj.name === categoryName);

            if (foundData) {
                return "This category already exists.";
            }
        }

        if (action == 'update') {
            const categoriesDatas = categoriesData;
            categoriesDatas.push(categoryId);

            setOriginalData(categoriesDatas);
            setCategoriesData(categoriesDatas);
        }

        if (action == 'edit') {
            const categoriesDatas = categoriesData;

            const categoryFound = categoriesDatas.find(obj => obj._id !== categoryId && obj.name === categoryName);

            if (categoryFound !== undefined) {
                return "This category already exists.";
            }

            const foundData = categoriesDatas.find(obj => obj._id === categoryId);

            foundData.name = categoryName;

            const indexId = categoriesDatas.findIndex(item => item._id === foundData._id);

            if (indexId !== -1) {
                categoriesDatas[indexId] = foundData;
            }

            setOriginalData(categoriesDatas);
            setCategoriesData(categoriesDatas);
        }

        if (action == 'delete') {
            const categoriesDatas = categoriesData.filter(item => !categoryId.includes(item._id));

            setOriginalData(categoriesDatas);
            setCategoriesData(categoriesDatas);
        }

        if (action == 'move') {
            const categoriesDatas = categoriesData;

            const foundData = categoriesDatas.find(obj => obj._id === categoryName);
            const foundDeletedData = categoriesDatas.filter(obj => categoryId.includes(obj._id));

            const page_ids = Array.from(new Set([...foundData.page_ids, ...[...new Set(foundDeletedData.flatMap(obj => obj.page_ids))]]));
            const newslatter_ids = Array.from(new Set([...foundData.newslatter_ids, ...[...new Set(foundDeletedData.flatMap(obj => obj.newslatter_ids))]]));
            const publication_ids = Array.from(new Set([...foundData.publication_ids, ...[...new Set(foundDeletedData.flatMap(obj => obj.publication_ids))]]));

            foundData.page_ids = page_ids;
            foundData.newslatter_ids = newslatter_ids;
            foundData.publication_ids = publication_ids;

            foundData.page_count = page_ids.length;
            foundData.newslatter_count = newslatter_ids.length;
            foundData.publication_count = publication_ids.length;

            const indexId = categoriesDatas.findIndex(item => item._id === foundData._id);

            if (indexId !== -1) {
                categoriesDatas[indexId] = foundData;
            }

            setOriginalData(categoriesDatas);
            setCategoriesData(categoriesDatas);

            return foundData;
        }

        return true;
    }

    const togglePopup = (index) => {
        if (index === openPopupIndex) {
            setOpenPopupIndex(null);
        } else {
            setOpenPopupIndex(index);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openPopupIndex &&  (event.target.closest('.pagesTableAction') === null && event.target.closest('.dropdown') === null) && !document.body.classList.contains('hidden-message')) {
                togglePopup(null) // Close the dropdown
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openPopupIndex,togglePopup]);

    const handleNewCategoryClick = () => {
        setShowCategoryField(true);
        document.body.classList.add('hidden-message');
    }

    const closeCategoryField = (action) => {
        setShowCategoryField(false);
        document.body.classList.remove('hidden-message');
    }

    const handleDelete = () => {
        if (selectedRows.length > 0) {
            setShowDeleteModel(true);
            document.body.classList.add('hidden-message');
        }
    }

    const closeDeleteModel = () => {
        setShowDeleteModel(false);
        document.body.classList.remove('hidden-message');
    }

    

    const handleEditAction = () => {
        togglePopup();
    }

    const handleDeleteAction = () => {
        togglePopup();
    }

    const handleMasterCheckboxChange = () => {
        if (isAllSelected) {
            setSelectedRows([]);
        } else {
            setSelectedRows(fetchData.map((row) => row._id)); // Select all rows
        }
        setIsAllSelected(!isAllSelected); // Toggle master checkbox
    };

    const customTitleSort = (rowA, rowB) => {
        const titleA = rowA?.subject.toString().toLowerCase();
        const titleB = rowB?.subject.toString().toLowerCase();
    
        // Natural sort comparison for mixed numbers and letters
        return titleA.localeCompare(titleB, undefined, { numeric: true, sensitivity: 'base' });
    };

    const calculateColumnWidths = () => {
        const tempWidths = categoriesData?.reduce((acc, row) => {
            Object.keys(row).forEach((key) => {
                const dataValue = row[key].toString();
                const title = row[key];

                // Compare title length with data content length and take the max

                let maxContentLength = Math.max(dataValue.length, title.length);
                

                if (!acc[key] || maxContentLength > acc[key]) {
                    acc[key] = maxContentLength;
                }
            });
            return acc;
        }, {});
        if (tempWidths !== undefined) {
            const widthMap = Object.keys(tempWidths).reduce((acc, key) => {
                    acc[key] = `${tempWidths[key] * 10}px`;
                return acc;
            }, {});

            setColumnWidths(widthMap); // Set the calculated column widths
        }
    };

    useEffect(() => {
            if (originalData.length > 0) {
                calculateColumnWidths();
            }
        }, [originalData]);

    const columns = [
        {
            name: 'Name',
            selector: (row) => row?.name,
            cell: (row) => {
                return (
                    <span className='editTitle'>{row?.name}</span>
                )
            },
            sortable: true,
            minWidth: columnWidths['name'] && parseInt(columnWidths['name']) > 300 ? '300px' : columnWidths['name'] && parseInt(columnWidths['name']) <= 130 ? '130px' : columnWidths['name'],
            sortFunction: customTitleSort,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "20px", paddingBottom: "20px", fontSize: "14px" },
        },
        {
            name: 'Pages',
            selector: (row) => row?.page_count,
            cell: (row) => {
                return (
                    <div className='page-count cat-count'>{row?.page_count}</div>
                )
            },
            width: '95px',
            style: { width: '95px', paddingLeft: 0, paddingTop: "20px", paddingBottom: "20px", fontSize: "14px",textAlign: 'right' },
            sortable: true,
            headerStyle: { textAlign: 'right' },
            justifyContent: 'flex-end',
        },
        {
            name: 'Publications',
            selector: (row) => row?.publication_count,
            cell: (row) => {
                return (
                    <div className='publication-count cat-count'>{row?.publication_count}</div>
                )
            },
            sortable: true,
            width: '135px',
            style: { paddingLeft: 0,  paddingTop: "20px", paddingBottom: "20px", minWidth: '135px', fontSize: "14px",textAlign: 'right' },
            headerStyle: { textAlign: 'right' },
            justifyContent: 'flex-end',
        },
        {
            name: 'Newsletters',
            selector: (row) => row?.newslatter_count,
            cell: (row) => {
                return (
                    <div className='newslatter-count cat-count'>{row?.newslatter_count}</div>
                )
            },
            sortable: true,
            width: '130px',
            style: { paddingLeft: 0, paddingTop: "20px", paddingBottom: "20px", minWidth: '130px', fontSize: "14px",textAlign: 'right' },
            headerStyle: { textAlign: 'right' },
            justifyContent: 'flex-end',
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <div className='pagesTableAction publicationAction' onClick={() => togglePopup(row)} ref={ref}>
                        <img src={more} alt="More" />
                    </div>
                    {(openPopupIndex?._id === row?._id) &&
                        <CategoryActionDropdown togglePopup={togglePopup} data={row} updateCategoriesData={updateCategoriesData} categoriesData={categoriesData} setShowNotification={setShowNotification} />
                    }
                </>
            ),
            justifyContent: 'flex-end',
            allowOverflow: true,
            button: true,
            paddingRight: '16px',
            width: '100px',
            style: { textAlign: 'right', minWidth: '100px', paddingLeft: 0, paddingRight: "16px", paddingTop: "20px", paddingBottom: "20px" }, // Aligning to the right
            headerStyle: { textAlign: 'right' },
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };

    return (
        <section className='dashboard categories-page'>            
            <Helmet>
                <title>Categories | Dashboard | Persone</title>
            </Helmet>
            <div className="container">
                <div className="dashboardTop">
                    <div className="dashboardTopText publicationTopText">
                        <div className='dashboardTopLeft'>
                            <p>Categories</p>
                            <p className="pageSubTitle">Organize your content with categories to manage it more effectively.</p>
                        </div>

                        <div className="pagesSorting pageTypeSorting">
                            <div className='pagesSearch'>
                                <div className="pageSearchInput">
                                    <input type="text" id="pages_search" name="pages_search" placeholder={'Search categories...'} value={searchInput} onChange={e => setSearchInput(e.target.value)} />
                                    <img src={search} alt="Search" />
                                </div>
                                <div className='sortingButton'>
                                    <div className='addIcon publicationsCategoryButton'>
                                        <button className="primary-button flex minwidthAuto button" onClick={handleNewCategoryClick}>
                                            <img src={plus} alt="Icon" /> <span>New Category</span>
                                        </button>
                                    </div>

                                    <div className='deleteIcon' onClick={() => handleDelete()}>
                                        <img className='deleteIconImage' src={clear} alt="Clear Input" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pagesTableWrapper categoriespage-list">

                    <div className="pagesTable dataTable-list">
                        <DataTableList
                            columns={columns}
                            data={categoriesData}
                            onSelectedRowsChange={({ selectedRows }) => {
                                const selectedIds = selectedRows.map((row) => row._id);
                                setSelectedRows(selectedIds);
                                setIsAllSelected(selectedIds.length === fetchData.length);
                            }}
                            progressPending={isLoading}
                            progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                            onChangePage={setCurrentPage}
                            paginationTotalRows={totalPage}
                            currentPage={currentPage}
                            customStyles={customStyles}
                            selectableRowsComponent="input"
                            selectAllRows={isAllSelected}
                            onSelectAllChange={handleMasterCheckboxChange}
                            clearSelectedRows={toggleSelect}
                            type="categories" 
                            name="categories"
                            status="all"
                            selectableRows={true}
                        />
                    </div>
                </div>
            </div>

            {(showCategoryField || showDeleteModel) && <div className="welcomeOverlay"></div>}
            {showCategoryField && <AddCategoryPage isOpen={showCategoryField} onClose={closeCategoryField} updateCategoriesData={updateCategoriesData} setOpenNotify={setOpenNotify} toggleSelect={toggleSelect} setToggleSelect={setToggleSelect}/>}
            {showDeleteModel && <DeleteCategoryModel isOpen={showDeleteModel} onClose={closeDeleteModel} categoryIds={selectedRows} updateCategoriesData={updateCategoriesData} categoriesData={categoriesData} setShowNotification={setShowNotification} setShowMultiNotify={setShowMultiNotify} setSelectedItems={setSelectedRows} setSelectedCatsLength={setSelectedCatsLength} toggleSelect={toggleSelect} setToggleSelect={setToggleSelect} />}
            {showNotification && (
                <NotificationMessage  openNotify={showNotification} setOpenNotify={setShowNotification} type={'success'} title={`The category was successfully deleted!`} />
            )}
            {showMultiNotify && (
                <NotificationMessage  openNotify={showMultiNotify} setOpenNotify={setShowMultiNotify} type={'success'} title={`${selectedCatsLength} categories were successfully deleted!`} />
            )}
            {openNotify && 
              <NotificationMessage  openNotify={openNotify} setOpenNotify={setOpenNotify} type={'success'} title={`Category has been successfully added!`} />
            }

        </section>
    );
}

export default Categories;
