import { useState } from "react";

import NotificationMessage from './NotificationMessage';
import EditCategoryModel from "./Modal/EditCategoryModel";
import DeleteCategoryModel from "./Modal/DeleteCategoryModel";

import more from '.././images/more.svg'
import clear from '.././images/delete.svg'
import edit from '.././images/pen.svg'

function CategoryActionDropdown(props) {

    let data = props.data;
    let togglePopup = props.togglePopup;

    const [showCategoryField, setShowCategoryField] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);

    const [showNotification, setShowNotification] = useState(false);

    const handleEdit = () => {
        // togglePopup();

        document.body.classList.add('hidden-message')
        const dropdown = document.querySelector('.dropdown');
        dropdown.style.display = 'none';
        
        setShowCategoryField(true);
        document.body.classList.add('hidden-message');
    }

    const closeCategoryField = (action = 'cancel') => {
        setShowCategoryField(false);
        document.body.classList.remove('hidden-message');

        if (action === 'update') {
            setShowNotification(true);
        }
    }

    const handleDelete = () => {
        // togglePopup();

        document.body.classList.add('hidden-message')
        const dropdown = document.querySelector('.dropdown');
        dropdown.style.display = 'none';

        setShowDeleteModel(true);
        document.body.classList.add('hidden-message');
    }

    const closeDeleteModel = () => {
        setShowDeleteModel(false);
        document.body.classList.remove('hidden-message');
    }

    return (
        <>
            <div className='dropdown addDropdown dropdown-field'>
                <ul>
                    <li key={`edit-${data._id}`}>
                        <a href="#" onClick={(e) => { e.preventDefault(); handleEdit() }}>
                            <img src={edit} alt="Icon" />
                            Edit
                        </a>
                    </li>
                    <li key={`delete-${data._id}`}>
                        <a href="#" onClick={(e) => { e.preventDefault(); handleDelete() }}>
                            <img src={clear} alt="Icon" />
                            Delete
                        </a>
                    </li>
                </ul>
            </div>

            
            {(showCategoryField || showDeleteModel) && <div className="welcomeOverlay"></div>}
            {showCategoryField && <EditCategoryModel isOpen={showCategoryField} onClose={closeCategoryField} categoryId={data._id} categoryName={data.name} updateCategoriesData={props.updateCategoriesData} />}
            {showDeleteModel && <DeleteCategoryModel isOpen={showDeleteModel} onClose={closeDeleteModel} categoryIds={[data._id]} categoryName={data.name} updateCategoriesData={props.updateCategoriesData} categoriesData={props.categoriesData} setShowNotification={props.setShowNotification}/>}


            {showNotification && (
                <NotificationMessage  openNotify={showNotification} setOpenNotify={setShowNotification} type={'success'} title={`Category has been successfully edited!`} />
            )}
        </>
    )

}


export default CategoryActionDropdown;