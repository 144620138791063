import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';

import close from '../../images/Settings/close.svg'

export const MonetizationModal = ({isDeletePlan, isDisconnectStripe, isPriceChange, setPlanOneOpen, setMonetization, monetization, handleSaveData, setDeletePlan, setIsPriceChanged, setDisconnectStripe, isShouldCreateNew, handleAgreeBtn}) => {
    const ref = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleAgree = async () => {
        if(isLoading) {
            return;
        }

        setErrorMessage('');

        try {
            if (isDeletePlan) {
                setIsLoading(true);
                handleAgreeBtn('deletePlan');
                setIsLoading(false);
            }

            if (isDisconnectStripe) {
                setIsLoading(true);

                const response = handleAgreeBtn('disconnectStipe');

                if( response ) {
                    setErrorMessage(response);
                }
  
                setIsLoading(false);
            }

            if (isPriceChange) {
                setIsLoading(true);
                await handleSaveData();
                setIsLoading(false);
            }
        } catch {
            setIsLoading(false);
        }
    }

    const handleCancel = () => {
        if (isDeletePlan) {
            setDeletePlan(false)
        }
        if (isDisconnectStripe) {
            setDisconnectStripe(false)
        }
        if (isPriceChange) {
            setIsPriceChanged(false)
        }
    }
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if(isLoading) {
                return false;
            }
            if (isDeletePlan && ref.current && !ref.current.contains(e.target)) {
                setDeletePlan(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isDeletePlan, isLoading]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isDisconnectStripe && ref.current && !ref.current.contains(e.target)) {
                setDisconnectStripe(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isDisconnectStripe]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isPriceChange && ref.current && !ref.current.contains(e.target)) {
                setIsPriceChanged(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isPriceChange]);
    
    return (
        <div className="detailsPopup stripeApiModal">
            <div className="detailsPopup-inner" ref={ref}>
                <div className="popup__content">
                    <div className='popupTitle'>{isDeletePlan ? "Delete Plan" : isDisconnectStripe ? "Disconnect Stripe" : `Price change for ${monetization?.planNameOne || 'plan'}`}</div>
                    <div>
                        
                        <div className="step_content">
                            {
                                ((isDeletePlan || isPriceChange) && !isDisconnectStripe) &&
                                <div>
                                    {isDeletePlan && <>
                                        <p className='popupDesc'>You are about to delete a subscription plan that has active subscribers. Please note that all current subscribers will be notified about the cancellation of this subscription plan, will no longer be charged, and will be transferred to your free plan. They will also receive a refund for the remaining subscription period.</p>
                                    </>}
                                    {isPriceChange && <>
                                        <p className='popupDesc'>You are about to change the price of a subscription plan that has active subscribers. Please note that only new subscribers will be subject to the new rate once the price change has taken effect. Your current subscribers will continue with the plan they initially subscribed to.</p>
                                    </>}
                                </div>
                            }
                            {
                                (isDisconnectStripe && (!isDeletePlan && !isPriceChange)) &&
                                <>
                                    <p className='popupDesc'>You are about to disconnect your Stripe account.</p>
                                    <p className='popupDesc'>
                                        Please note that by doing so, you will no longer be able to earn revenue from paid subscriptions on your personal website. All active subscriptions will be refunded for the remaining subscription period, canceled, and transfered to your free plan. This will immediately impact your monetization.
                                    </p>
                                </>
                            }
                        </div>
                        <div className="step_content">
                            <p className='popupDesc'>
                            { isDeletePlan ? "Are you sure you want to proceed with deleting this subscription plan?" : isDisconnectStripe ? "Are you sure you want to proceed with disconnecting your Stripe account?  " : "Are you sure you want to proceed with changing the price of this subscription plan?" }
                            </p>                            
                            {errorMessage && 
                                <p className="error-message">{errorMessage}</p>
                            }
                        </div>
                    </div>
                    <div className='btnGroupLink btnGroupWidth'>
                        <input type="reset" value="Cancel" className="secondary-button button minwidthAuto" onClick={() => { handleCancel() }} />
                        <button className="primary-button button minwidthAuto" onClick={handleAgree}>
                            {isLoading ?
                                <div className="settingPage-loader">
                                    <div className="ring-loader"></div>
                                </div>
                                : "I agree"
                            }
                        </button>
                    </div>
                </div>
                <span className="detailsClose" onClick={() => { handleCancel() }}>
                    <img className="popupClose" src={close} alt="close" />
                </span>
            </div>
        </div>
    )
}
