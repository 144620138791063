import { Link } from 'react-router-dom';

import done from '../images/done.svg'

import '../css/loginPage.css'

function PassReset() {
    return (
        <div className="loginWindow checkEmail">
            <img className='passResetImg' src={done} alt="Login" />

            <p className='loginTitle'>Password reset</p>
            <p className='loginSubtitle'>Your password has been successfully reset. Click below to log in magically.</p>

            <Link to={'/sign-in'}><button className='blueButton loginButton passResetButton'>Log in to your account</button></Link>
        </div>
    );
}

export default PassReset;
