import '.././App.css';
import { useState, useEffect, useRef } from 'react';
import up from '../images/up-image.svg'
import down from '../images/down-image.svg'
import Chart from 'chart.js/auto'
import dateFormatter , {formatTime} from '.././utils/dateFormatter'

function Analytics({subscriber, analytics, analyticsGraphData, subscriberGraphData, selectedPeriod, isLoading, showCompare}) {

    const analyticschartRef = useRef(null)
    const subscriberchartRef = useRef(null)
    const [totalVisits, setTotalVisits] = useState(0)
    const [pageviews, setPageviews] = useState(0)
    const [totalVisitRate, setTotalVisitRate] = useState(0)
    const [pageviewRate, setPageviewRate] = useState(0)

    const [totalSubscriberCount,setTotalSubscriberCount] = useState(0)
    const [totalFreeSubscriberCount,setTotalFreeSubscriberCount] = useState(0)
    const [totalPaidSubscriberCount,setTotalPaidSubscriberCount] = useState(0)
    const [totalSubscriberRate, setTotalSubscriberRate] = useState(0)
    const [totalFreeSubscribersRate, setTotalFreeSubscribersRate] = useState(0)
    const [totalPaidSubscribersRate, setTotalPaidSubscribersRate] = useState(0)
    const [analyticsActive, setAnalyticsActive] = useState('visitors')
    const [analyticsAllActive, setAnalyticsAllActive] = useState("subscribers")
    
    const analyticsFilter = {
        visitors: {
          title: 'Visitors'
        },
        pageviews: {
          title: 'Page Views'
        }
    }
    const subscriberFilter = {
        subscribers: {
          title: 'Subscriber'
        },
        freeSubscribers: {
          title: 'Free Subscriber'
        },
        paidSubscribers: {
          title: 'Paid Subscriber'
        }
    }

    useEffect(() => {
        setTotalSubscriberCount(subscriber?.aggregateData?.totalSubscribers?.value || 0);
        setTotalFreeSubscriberCount(subscriber?.aggregateData?.freeSubscribers?.value || 0);
        setTotalPaidSubscriberCount(subscriber?.aggregateData?.paidSubscribers?.value || 0);
        
        setTotalSubscriberRate(subscriber?.aggregateData?.totalSubscribers?.change || 0)
        setTotalFreeSubscribersRate(subscriber?.aggregateData?.freeSubscribers?.change || 0)
        setTotalPaidSubscribersRate(subscriber?.aggregateData?.paidSubscribers?.change || 0)

        setTotalVisits(analytics?.aggregateData?.visitors?.value || 0)
        setPageviews(analytics?.aggregateData?.pageviews?.value || 0)

        setTotalVisitRate(analytics?.aggregateData?.visitors?.change || 0)
        setPageviewRate(analytics?.aggregateData?.pageviews?.change || 0)

    }, [subscriber, analytics]);

    useEffect(() => {
        if (analyticsGraphData) {

          const ctx = analyticschartRef?.current?.getContext('2d');
    
          var gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, 'rgba(101,116,205, 0.2)');
          gradient.addColorStop(1, 'rgba(101,116,205, 0)');
    
          const datasets = [{
            label: analyticsFilter[analyticsActive]['title'],
            data: analyticsGraphData?.map(data => data[analyticsActive]),
            borderWidth: 3,
            pointHoverRadius: 4,
            fill: true,
            backgroundColor: gradient,
            borderColor: 'rgba(101,116,205)',
            pointBorderColor: "transparent",
            pointBackgroundColor: 'rgba(101,116,205)',
            pointHoverBackgroundColor: 'rgba(71, 87, 193)',
            yAxisID: 'y',
        }]

        // Dummy data
        const data = {
            labels: analyticsGraphData?.map(data => dateFormatter(data.date, selectedPeriod.value === 'day' ? 'hour' : 'date', '', false)),
            datasets: datasets
        };
    
          // Create the chart
        const myChart = new Chart(ctx, {
            type: 'line',
            data: data,
            options: {
                animation: false,
                plugins: {
                    legend: { display: false },
                    tooltip: {
                        enabled: false,
                        mode: 'index',
                        intersect: false,
                        position: 'average',
                        external: GraphTooltip()
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                elements: { line: { tension: 0 }, point: { radius: 0 } },
                scale: {
                    ticks: { precision: 0, maxTicksLimit: 8 }
                },
                scales: {
                    y: {
                        min: 0,
                        suggestedMax: calculateMaximumY(datasets),
                        ticks: {
                            callback: function (value, index, values) {
                            return value;
                            }
                        },
                        border: {display: false},
                        grid: {
                            zeroLineColor: 'transparent',
                            drawBorder: false,
                        }
                    },
                    yComparison: {
                        min: 0,
                        display: false,
                        suggestedMax: calculateMaximumY(datasets),
                        grid: { display: false },
                    },
                    x: {
                        grid: { display: false },
                    }
                },
                interaction: {
                    mode: 'index',
                    intersect: false,
                }
            },
          });
    
            window.addEventListener('mousemove', repositionTooltip);
        
            return () => {
                // Cleanup chart on unmount
                myChart.destroy();
            };
        }
    }, [analyticsGraphData, analyticsActive]);


    useEffect(() => {
        if (subscriberGraphData) {
            const ctx = subscriberchartRef.current.getContext('2d');
        
            var gradient = ctx.createLinearGradient(0, 0, 0, 300);
            gradient.addColorStop(0, 'rgba(101,116,205, 0.2)');
            gradient.addColorStop(1, 'rgba(101,116,205, 0)');
        
            const datasets = [{
                label: subscriberFilter[analyticsAllActive]?.['title'],
                data: subscriberGraphData?.map(data => data[analyticsAllActive]),
                borderWidth: 3,
                pointHoverRadius: 4,
                fill: true,
                backgroundColor: gradient,
                borderColor: 'rgba(101,116,205)',
                pointBorderColor: "transparent",
                pointBackgroundColor: 'rgba(101,116,205)',
                pointHoverBackgroundColor: 'rgba(71, 87, 193)',
                yAxisID: 'y',
            }]

        // Dummy data
        const data = {
            labels: subscriberGraphData?.map(data => dateFormatter(data.date, selectedPeriod.value === 'day' ? 'hour' : 'date', '', false)),
            datasets: datasets
        };

        // Create the chart
        const myChart = new Chart(ctx, {
            type: 'line',
            data: data,
            options: {
                animation: false,
                plugins: {
                    legend: { display: false },
                    tooltip: {
                        enabled: false,
                        mode: 'index',
                        intersect: false,
                        position: 'average',
                        external: GraphTooltip()
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                elements: { line: { tension: 0 }, point: { radius: 0 } },
                scale: {
                    ticks: { precision: 0, maxTicksLimit: 8 }
                },
                scales: {
                    y: {
                        min: 0,
                        suggestedMax: calculateMaximumY(datasets),
                        ticks: {
                            callback: function (value, index, values) {
                            return value;
                            }
                        },
                        border: {display: false},
                        grid: {
                            zeroLineColor: 'transparent',
                            drawBorder: false,
                        }
                    },
                    yComparison: {
                        min: 0,
                        display: false,
                        suggestedMax: calculateMaximumY(datasets),
                        grid: { display: false },
                    },
                    x: {grid: { display: false },}
                },
                interaction: {
                    mode: 'index',
                    intersect: false,
                }
            },
        });
    
          window.addEventListener('mousemove', repositionTooltip);
    
          return () => {
            // Cleanup chart on unmount
            myChart.destroy();
          };
    
        }
    
    }, [subscriberGraphData, analyticsAllActive]);

    const calculateMaximumY = function (dataset) {
        const yAxisValues = dataset
            .flatMap((item) => item.data)
            .map((item) => item || 0)

        if (yAxisValues) {
            return Math.max(...yAxisValues)
        } else {
            return 1
        }
    }
    const repositionTooltip = (e) => {
        const tooltipEl = document.getElementById('chartjs-tooltip');
        if (tooltipEl && window.innerWidth >= 768) {
          if (e.clientX > 0.66 * window.innerWidth) {
            tooltipEl.style.right = (window.innerWidth - e.clientX) + window.pageXOffset + 'px';
            tooltipEl.style.left = null;
          } else {
            tooltipEl.style.right = null;
            tooltipEl.style.left = e.clientX + window.pageXOffset + 'px';
          }
          tooltipEl.style.top = e.clientY + window.pageYOffset + 'px';
          tooltipEl.style.opacity = 1;
        }
    };

    const GraphTooltip = () => {

        return (context) => {
          const tooltipModel = context.tooltip;
          const offset = document.getElementById("main-graph-canvas").getBoundingClientRect();
          let tooltipEl = document.getElementById('chartjs-tooltip');
    
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.display = 'none';
            tooltipEl.style.opacity = 0;
            document.body.appendChild(tooltipEl);
          }
    
          if (tooltipEl && offset && window.innerWidth < 768) {
            tooltipEl.style.top = offset.y + offset.height + window.scrollY + 15 + 'px'
            tooltipEl.style.left = offset.x + 'px'
            tooltipEl.style.right = null
            tooltipEl.style.opacity = 1
          }
    
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.display = 'none';
            return;
          }
    
          const data = tooltipModel.dataPoints.find((dataPoint) => dataPoint.dataset.yAxisID == "y");
          const value = data?.raw || 0
          
          const updatedData = subscriberGraphData[data.dataIndex];
          const formatedDate = dateFormatter(updatedData?.date, 'date', '');
          const formatedTime = dateFormatter(updatedData?.date, 'hour', '');
          const date = `${formatedDate}${selectedPeriod.value === 'day' ? `, ${formatedTime}` : ''}`;
    
          if (tooltipModel.body) {
            tooltipEl.innerHTML = `
            <div class="grapph-tooltip">
              <div class="tooltip-title">${data?.dataset?.label}</div>
              <div class="tooltip-subtitle"> <div class="tooltip-mark"></div>${date} <div class="tooltip-value">${(analyticsActive === 'visit_duration') ? formatTime(value) : value}</div> </div>
            </div>
          `;
          }
          tooltipEl.style.display = null;
        };
    };
    

    return (
        <div className='dashboardGraph'>
            {isLoading ? 
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
                :
                <div className='analyticsOverview'>
                    <div className="dashboardGraphCotnent">
                        <p className='dashUserStatus'>Analytics overview</p>
                        <p className="dashboardRegularText">An overview of your profile’s performance, with visitor data and page views in a graph.</p>
                        <div className="analyticsButtons">
                            <div className={(analyticsActive === 'visitors') ? 'graphButton greenPercent dashboardGraphActiveButton' : 'graphButton'} onClick={e => setAnalyticsActive('visitors')}>
                                <p className='analyticsButtonTitle'>Visitors</p>
                                <div>
                                    <p className='graphNumber'>{totalVisits}</p>
                                        <span className={`graphPercent ${totalVisitRate !== 0 && (totalVisitRate >= 0 ? 'greenPercent' : 'redPercent') }`}>
                                            {totalVisitRate !== 0 ? (
                                            totalVisitRate >= 0 ? (
                                                <img src={up} alt="Up" className="svg-icon" />
                                            ) : (
                                                <img src={down} alt="Down" className="svg-icon" />
                                            )) : (
                                                "〰"
                                            )}
                                            {Math.abs(totalVisitRate)}%
                                        </span>
                                </div>
                            </div>
                            <div className={(analyticsActive === 'pageviews') ? 'graphButton greenPercent dashboardGraphActiveButton' : 'graphButton'} onClick={e => setAnalyticsActive('pageviews')}>
                                <p className='analyticsButtonTitle'>Pageviews</p>
                                <div>
                                    <p className='graphNumber'>{pageviews}</p>
                                        <span className={`graphPercent ${pageviewRate !== 0 && (pageviewRate >= 0 ? 'greenPercent' : 'redPercent') }`}>
                                            {pageviewRate !== 0 ? (
                                            pageviewRate >= 0 ? (
                                                <img src={up} alt="Up" className="svg-icon" />
                                            ) : (
                                                <img src={down} alt="Down" className="svg-icon" />
                                            )) : (
                                                "〰"
                                            )}
                                            {Math.abs(pageviewRate)}%
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboardChart">
                        <div className='visitor-grreapoh'>
                            <canvas id="main-graph-canvas" ref={analyticschartRef} width="100%" height="250px"></canvas>
                        </div>
                    </div>
                </div>
            }
            {isLoading ? 
                <div className="loader-container">
                    <div className="loader"></div>
                </div> 
                :
                <>
                    <div>
                        <div className="dashboardGraphCotnent dashboardGraphSubscribers">
                            <p className='dashUserStatus'>Subscribers overview</p>
                            <p className="dashboardRegularText">An overview of your subscribers, with a graph showing numbers by plan.</p>

                            <div className="analyticsButtons">
                                <div className={(analyticsAllActive === 'subscribers') ? 'graphButton greenPercent dashboardGraphActiveButton' : 'graphButton'} onClick={e => setAnalyticsAllActive('subscribers')}>
                                    <p className='analyticsButtonTitle'>All</p>
                                    <div>
                                    <p className='graphNumber'>{totalSubscriberCount}</p>
                                        <span className={`graphPercent ${totalSubscriberRate !== 0 && (totalSubscriberRate >= 0 ? 'greenPercent' : 'redPercent') }`}>
                                        {totalSubscriberRate !== 0 ? (
                                            totalSubscriberRate >= 0 ? (
                                                <img src={up} alt="Up" className="svg-icon" />
                                            ) : (
                                                <img src={down} alt="Down" className="svg-icon" />
                                            )) : (
                                                "〰"
                                            )}
                                            {Math.abs(totalSubscriberRate)}%
                                        </span>
                                    </div>
                                </div>
                                <div className={(analyticsAllActive === 'freeSubscribers') ? 'graphButton greenPercent dashboardGraphActiveButton' : 'graphButton'} onClick={e => setAnalyticsAllActive('freeSubscribers')}>
                                    <p className='analyticsButtonTitle'>Free</p>
                                    <div>
                                    <p className='graphNumber'>{totalFreeSubscriberCount}</p>
                                        <span className={`graphPercent ${totalFreeSubscribersRate !== 0 && (totalFreeSubscribersRate >= 0 ? 'greenPercent' : 'redPercent') }`}>
                                            {totalFreeSubscribersRate !== 0 ? (
                                            totalFreeSubscribersRate >= 0 ? (
                                                <img src={up} alt="Up" className="svg-icon" />
                                            ) : (
                                                <img src={down} alt="Down" className="svg-icon" />
                                            )) : (
                                                "〰"
                                            )}
                                            {Math.abs(totalFreeSubscribersRate)}%
                                        </span>
                                    </div>
                                </div>
                                <div className={(analyticsAllActive === 'paidSubscribers') ? 'graphButton greenPercent dashboardGraphActiveButton' : 'graphButton'} onClick={e => setAnalyticsAllActive('paidSubscribers')}>
                                    <p className='analyticsButtonTitle'>Paid</p>
                                    <div>
                                        <p className='graphNumber'>{totalPaidSubscriberCount}</p>
                                        <span className={`graphPercent ${totalPaidSubscribersRate !== 0 && (totalPaidSubscribersRate >= 0 ? 'greenPercent' : 'redPercent') }`}>
                                            {totalPaidSubscribersRate !== 0 ? (
                                            totalPaidSubscribersRate >= 0 ? (
                                                <img src={up} alt="Up" className="svg-icon" />
                                            ) : (
                                                <img src={down} alt="Down" className="svg-icon" />
                                            )) : (
                                                "〰"
                                            )}
                                            {Math.abs(totalPaidSubscribersRate)}%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboardChart">
                            <div className='visitor-grreapoh'>
                                <canvas id="main-graph-canvas" ref={subscriberchartRef} width="100%" height="250px"></canvas>
                            </div>
                        </div>
                    </div>                    
                </>
            }
        </div>
    );
}

export default Analytics;
