import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import NewsletterListItem from '../../Components/NewsletterListItem';
import Pagination from '../../Components/Pagination';
import NoResults from '../../Components/NoResults';
import MultipleDeletePages from '../../Components/DeleteModal/MultipleDeletePages';
import SingleDeletePage from '../../Components/DeleteModal/SingleDeletePage';
import NotificationMessage from '../../Components/NotificationMessage';
import { DataTableList } from '../../Components/DataTable/DataTable';
import PageActionDropdown from '../../Components/PageActionDropdown';

import search from '../.././images/search.svg'
import clear from '../.././images/delete.svg'
import down from '../.././images/down_arrow.svg'
import top from '../.././images/arrow_top.svg'
import more from '../.././images/more.svg'

import '../.././App.css';


function DraftsNewsletter(props) {

    props.setActivePage('Drafts Newsletters')
    const [activeFilter, setActiveFilter] = useState('All')
    const [searchInput, setSearchInput] = useState('')
    const [pages, setPages] = useState([])
    const [allFiltered, setAllFiltered] = useState(pages)
    const [itemToDelete, setItemToDelete] = useState('')
    const [isPopupOpenDeleteAccount, setIsPopupOpenDeleteAccount] = useState(false);
    const [isPopupSingleDeleteAccount, setIsPopupSingleDeleteAccount] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPageStatus, setSelectedPageStatus] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [showNotification, setShowNotification] = useState(false);
    const [showMultiNotify, setShowMultiNotify] = useState(false)
    const [selectedPubLenght, setSelectedPubLenght ] = useState(0)

    const [toggleSelect, setToggleSelect] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [activateEdit, setActivateEdit] = useState(false)
    const [columnWidths, setColumnWidths] = useState([]);

    const ref = useRef();

    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id

    useEffect(() => {
        searchPage()
    }, [searchInput])

    useEffect(() => {
        var id = itemToDelete;

        var result = [];

        pages.forEach(element => {
            if (element.id === id) {
                return false
            } else {
                result.push(element)
            }
        });

        setPages(result)
        setAllFiltered(result)

    }, [itemToDelete])

    const data = async () => {
        const search = searchInput.toLowerCase();
        setIsLoading(false)
        try {
            setIsLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/newsLetter/list/${user_id}?page=${currentPage}&search=${search}&status=Draft`,
            )
            const result = response?.data?.result;

            setOriginalData(result);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error, "error");
        }
    }
    useEffect (()=>{
        data();
    },[user_id,currentPage, searchInput, selectedPageStatus])


    useEffect(() => {
        setCurrentPage(1);
    }, [selectedPageStatus]);
    function searchPage() {

        var result = [];
        var input = searchInput.toLowerCase()
        originalData.forEach(element => {
            var title = element?.title?.toLowerCase();
            if (title?.startsWith(input)) {
                result.push(element);
            }
        });

        setPages(result);
        setAllFiltered(result);

        if (result != []) {
            setPages(result)
            setAllFiltered(result)
        } else if (input === '') {
            setPages(originalData)
            setAllFiltered(originalData)
            setActiveFilter('All')
        } else {
            setPages([])
            setAllFiltered([])
        }
    }

    /* SORTING */
    const [originalData, setOriginalData] = useState([]);

    function updateOriginalData(updatedData) {
        setOriginalData(updatedData);
        fetchData()
    }

    const fetchData = async () => {
        setIsLoading(false)
        try {
            setIsLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/newsLetter/list/${JSON.parse(localStorage.getItem("userinfo"))?._id}?page=${currentPage}&status=Draft`,
            )
            setOriginalData(response?.data?.result);
            setTotalPage(response?.data?.totalPages || 1);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error, "error");
        }
    }
    

    useEffect(() => {
        fetchData();
    }, [selectedPageStatus]);

    useEffect(() => {
        filterByStatus(activeFilter);
    }, [activeFilter, originalData]);

    function filterByStatus(status) {
        if (status === 'All') {
            setPages(originalData);
        } else {
            const filteredData = originalData.filter(item => item.status === status);
            setPages(filteredData);
        }
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
          if (e.target != null && isPopupOpenDeleteAccount && (e.target.classList.contains('welcomeOverlay'))) {
            setIsPopupOpenDeleteAccount(false);
          }
          if (e.target != null && isPopupSingleDeleteAccount && (e.target.classList.contains('welcomeOverlay'))) {
            setIsPopupSingleDeleteAccount(false);
        }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
    
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside);
        };
      }, [isPopupOpenDeleteAccount, isPopupSingleDeleteAccount]);

    const handleDelete = async () => {
        if (selectedRows.length === 1) {
            setIsPopupSingleDeleteAccount(true);
            document.body.classList.add('hidden-message');
        }

        if (selectedRows.length > 1 ) {
            setIsPopupOpenDeleteAccount(true);
            document.body.classList.add('hidden-message');
        }
    };

    const closeSingleAccountDeletedPopup = () => {
        document.body.classList.remove('hidden-message');
        setIsPopupSingleDeleteAccount(false);
    }

    const closeAccountDeletedPopup = () => {
        document.body.classList.remove('hidden-message');
        setIsPopupOpenDeleteAccount(false)
    };

    useEffect(() => {
        setSelectAll(false); 
        filterByStatus(activeFilter);
    }, [activeFilter, originalData]);

    const [openPopupIndex, setOpenPopupIndex] = useState(null);

    const togglePopup = (index) => {
        if (index === openPopupIndex) {
            setOpenPopupIndex(null);
        } else {
            setOpenPopupIndex(index);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openPopupIndex &&  (event.target.closest('.pagesTableAction') === null && event.target.closest('.dropdown') === null) && !document.body.classList.contains('hidden-message')) {
                togglePopup(null) // Close the dropdown
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openPopupIndex,togglePopup]);

    const updateStatus = async (itemId, newStatus) => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/newsLetter/update/${itemId}`,
                {
                    status: newStatus
                });
            fetchData();
        } catch (error) {
            console.log(error, "error");
        }
    };

    useEffect(() => {
        if (originalData.length > 0) {
            calculateColumnWidths();
        }
    }, [originalData]);

    function formateDate(date) {
        var createdAtDate = new Date(date);

        var options = { year: 'numeric', month: 'short', day: 'numeric' };

        var formattedDate = createdAtDate.toLocaleDateString('en-US', options);
        return formattedDate
    }

    const calculateColumnWidths = () => {
        const tempWidths = pages?.reduce((acc, row) => {
            Object.keys(row).forEach((key) => {
                const dataValue = row[key].toString();
                const title = row[key];

                // Compare title length with data content length and take the max

                let maxContentLength = Math.max(dataValue.length, title.length);;

                if (!acc[key] || maxContentLength > acc[key]) {
                    acc[key] = maxContentLength;
                }
            });
            return acc;
        }, {});

        if(tempWidths !== undefined){
            const widthMap = Object.keys(tempWidths).reduce((acc, key) => {
                if (key === 'createdAt' || key === 'updatedAt') {
                } else {
                    acc[key] = `${tempWidths[key] * 10 + 40}px`;
                }
                return acc;
            }, {});
            setColumnWidths(widthMap); // Set the calculated column widths
        }
    
    };

    const handleMasterCheckboxChange = () => {
        if (isAllSelected) {
            setSelectedRows([]);
        } else {
            setSelectedRows(data.map((row) => row._id)); // Select all rows
        }
        setIsAllSelected(!isAllSelected); // Toggle master checkbox
    };

    function updateActivateEdit(activateEdit) {
        setActivateEdit(activateEdit)
    }

    const changeStatus = (newStatus) => {
        data.status = newStatus;
        updateStatus(data._id, newStatus);
    };

    const customTitleSort = (rowA, rowB) => {
        const titleA = rowA?.subject.toString().toLowerCase();
        const titleB = rowB?.subject.toString().toLowerCase();
    
        // Natural sort comparison for mixed numbers and letters
        return titleA.localeCompare(titleB, undefined, { numeric: true, sensitivity: 'base' });
    };

    const columns = [
        {
            name: 'Subject',
            selector: (row) => row?.subject,
            cell: (row) => {
                return (
                    <Link className='editTitle' to={`/dashboard/newsletter/${row?._id}`}>{row?.subject}</Link>
                )
            },
            sortable: true,
            minWidth: columnWidths['title'] && parseInt(columnWidths['title']) > 300 ? '300px' : columnWidths['title'],
            sortFunction: customTitleSort,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Status',
            selector: (row) => row?.status, // Ensure this returns the status value
            cell: (row) => {
                return (
                    <div className='statusTab'><span className={(row?.status === 'Send') ? 'greenStatus' : (row?.status === 'Draft') ? 'draftStatus' : (row?.status === 'Schedule') ? 'scheduleStatus' : 'deletedStatus'}> {row?.status}</span></div>
                )
            },
            width: columnWidths['status'] || '150px',
            style: { width: columnWidths['status'] || '150px', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
            sortable: true,
        },
        {
            name: 'Recipients',
            selector: row => `${row?.sendTo?.plan === "All Subscribers" || row?.sendTo?.plan === "" || row?.sendTo?.plan === undefined ? "All Subscribers" : row?.sendTo?.plan }`,
            cell: (row) => {
                return (
                    <span className={`toSubject ${row?.sendTo?.slug === 'planOne' ? `pagePaidPlanLabel month` : row?.sendTo?.slug === "freePlan" ? 'pageFreePlanLabel' : "pageAllSubscriberLabel" }`}>{`${row?.sendTo?.plan === "All Subscribers" || row?.sendTo?.plan === "" || row?.sendTo?.plan === undefined ? "All Subscribers" : row?.sendTo?.plan }`}</span>
                )
            },
            sortable: true,
            paddingLeft: 0,
            width: '140px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", minWidth: '140px', fontSize: "14px" },
        },
        {
            name: 'Last Updated',
            selector: row => formateDate(row?.updatedAt),
            sortable: true,
            paddingLeft: 0,
            width: '140px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", minWidth: '140px', fontSize: "14px" },
        },
        {
            name: 'Link',
            cell: (row) => {
                let pageUrl;
                if (row?.status === 'Send') {
                    pageUrl = row?.link;
                } else {
                    pageUrl = `https://reactdashboard.codeptsolutions.com/dashboard/previewnewsletter/${row?._id}`
                }
                return (
                    <div className='pagesTableLink'><Link target={'_blank'} to={`${pageUrl}`}><button className='pagesLinkButton'>
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.88252 9.44272L7.11748 10.2078C6.06116 11.2641 4.34855 11.2641 3.29223 10.2078C2.23592 9.15146 2.23592 7.43884 3.29223 6.38252L4.05728 5.61748M10.9427 6.38252L11.7078 5.61748C12.7641 4.56116 12.7641 2.84855 11.7078 1.79223C10.6515 0.735922 8.93884 0.735922 7.88252 1.79223L7.11748 2.55728M5.6066 7.89339L9.3934 4.10659" stroke="#344054" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round" />
                        </svg>
                        {row?.status === 'Send' ? 'Link' : 'Preview'}
                    </button></Link></div>
                )
            },
            paddingLeft: 0,
            width: '110px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", width: '110px', fontSize: "14px" },
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <div className='pagesTableAction publicationAction' onClick={() => togglePopup(row)} ref={ref}>
                        <img src={more} alt="More" />
                    </div>
                    {
                        (openPopupIndex?._id === row?._id) && <PageActionDropdown togglePopup={togglePopup} changeStatus={changeStatus} type={"newsletter"} data={row} updateActivateEdit={updateActivateEdit} updateOriginalData={updateOriginalData} index={row._id} setShowNotification={setShowNotification} />
                    }
                </>
            ),
            justifyContent: 'flex-end',
            allowOverflow: true,
            button: true,
            // minWidth: "55px",
            paddingRight: '16px',
            width: '80px',
            style: { textAlign: 'right', minWidth: '80px', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px" }, // Aligning to the right
            headerStyle: { textAlign: 'right' },
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };


    return (
        <section className='dashboard'>
            <Helmet>
                <title>Drafts Newsletters | Dashboard | Persone</title>
            </Helmet>
            <div className="container">
                <div className="dashboardTop">
                    <div className="dashboardTopText">
                        <div className='dashboardTopLeft'>
                            <p>Drafts Newsletters</p>
                            <p className="pageSubTitle">Edit and finalize your draft newsletters to prepare them for sending.</p>
                        </div>
                        <div className="pagesSorting pageTypeSorting">
                            <div className='pagesSearch'>
                                <div className="pageSearchInput">
                                    <input type="text" name="pages_search" id="pages_search" placeholder={'Search drafts newsletters...'} value={searchInput} onChange={e => setSearchInput(e.target.value)} />

                                    <img src={search} alt="Search" />

                                </div>

                                <div className='deleteIcon newsletter-deleteIcon' onClick={() => handleDelete()}>
                                    <img className='deleteIconImage' src={clear} alt="Clear Input" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="pagesTableWrapper newsletters-list">

                    <div className="pagesTable dataTable-list">
                        <DataTableList
                            columns={columns}
                            data={pages}
                            onSelectedRowsChange={({ selectedRows }) => {
                                const selectedIds = selectedRows.map((row) => row._id);
                                setSelectedRows(selectedIds);
                                setIsAllSelected(selectedIds.length === data.length);
                            }}
                            progressPending={isLoading}
                            progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                            onChangePage={setCurrentPage}
                            paginationTotalRows={totalPage}
                            currentPage={currentPage}
                            customStyles={customStyles}
                            selectableRowsComponent="input"
                            selectAllRows={isAllSelected}
                            onSelectAllChange={handleMasterCheckboxChange}
                            clearSelectedRows={toggleSelect}
                            type="newsletter" 
                            name="Newsletters" 
                            status="draft"
                            selectableRows={true}
                        />
                    </div>
                </div>                
            </div>

            {(isPopupOpenDeleteAccount || isPopupSingleDeleteAccount) && <div className="welcomeOverlay"></div>}
            
            { <SingleDeletePage isOpen={isPopupSingleDeleteAccount} onClose={closeSingleAccountDeletedPopup} data={pages} type='newsLetter' updateOriginalData={updateOriginalData} index={selectedRows} activeFilter={"Draft"} setShowNotification={setShowNotification} setSelectedItems={setSelectedRows} toggleSelect={toggleSelect} setToggleSelect={setToggleSelect} /> }
            { <MultipleDeletePages isOpen={isPopupOpenDeleteAccount} onClose={closeAccountDeletedPopup} customKey="newsLetterId" type='newsLetter' name="newsLetters" selectedItems={selectedRows} updateOriginalData={updateOriginalData} activeFilter={"Draft"} setShowMultiNotify={setShowMultiNotify} setSelectedPubLenght={setSelectedPubLenght} setSelectedItems={setSelectedRows} toggleSelect={toggleSelect} setToggleSelect={setToggleSelect}/> }
            {showNotification && (
                <NotificationMessage  openNotify={showNotification} setOpenNotify={setShowNotification} type={'success'} title={`The newsletter was successfully deleted!`} />
            )}
            {showMultiNotify && (
                <NotificationMessage  openNotify={showMultiNotify} setOpenNotify={setShowMultiNotify} type={'success'} title={`${selectedPubLenght} newsletters were successfully deleted!`} />
            )}
        </section>
    );
}

export default DraftsNewsletter;
