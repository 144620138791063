import React, { useEffect, useRef } from 'react'
import CategoriesDropdown from '../../Components/CategoriesDropdown';
import search from '../.././images/search.svg';
import clear from '../.././images/delete.svg';
import category from '../.././images/category.svg'
import PlusIcon from '../../images/plus-icon.svg'

export const SubscriberHeader = (props) => {

    const dropdownref = useRef(null);
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (props?.planSelect && dropdownref.current && !dropdownref.current.contains(e.target)) {
                props?.setPlanSelect(false); 
            }
        };
    
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [props?.planSelect]);

    return (
        <div className="pagesSorting subscriberSorting">
            <div className='pagesSearch'>
                <div className="pageSearchInput">
                    <input type="text" name="pages_search" id="pages_search" placeholder={`${props?.name === "freeSubscriber" ? 'Search free subscriber...' : props?.name === "paidSubscriber" ? 'Search paid subscriber...' : props?.name === "trialSubscriber" ? 'Search trial subscriber...' : 'Search subscribers...'}`} value={props?.searchInput} onChange={e => props?.setSearchInput(e.target.value)} />
                    <img src={search} alt="Search" />
                </div>
                <div className='sortingButton'>
                {
                    props?.name !== "freeSubscriber" &&
                    <div className="publicationsCategoryButton" ref={dropdownref}>
                        <button className='secondary-button minwidthAuto' onClick={e => props?.setPlanSelect(!props?.planSelect)}><img src={category} alt="Category" />Plans {(props?.selectedPlan.length > 0) ? `(${props?.selectedPlan.length})` : ''}</button>

                        {
                            (props?.planSelect === true) ? <CategoriesDropdown categories={props?.planList} selected={props?.selectedPlan} changeCategory={props?.setSelectedPlan} /> : ''
                        }
                    </div>
                }

                <button className="pagesbtn add-subcriber-btn primary-button flex button" onClick={() => props?.handleOpenAddSubcriberPopup()}>
                    <img src={PlusIcon}  className="plusIcon" alt='Add subscribers'  />
                    <span className="pagesbtnTitel">Add subscribers</span>
                </button>

                <div className='deleteIcon' onClick={() => props?.handleDelete()}>
                    <img className='deleteIconImage' src={clear} alt="Clear Input" />
                </div>
                </div>

            </div>
        </div>
    )
}
