import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import close from '../../images/Settings/close.svg'

function SingleDeletePage(props) {

    const { activeFilter, selectedItems, type, index, updateOriginalData, setPages, isOpen, onClose, name ,data, setShowNotification, setSelectedItems, action, setContentChange, setToggleSelect, toggleSelect} = props;
    const history = useNavigate()
    const inputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [userPassword, setUserPassword] = useState('');

    useEffect(() => {
        if (isOpen && inputRef.current) {
            inputRef?.current.focus();
        }
        if (isOpen) {
            const handleKeyDown = (e) => {
                const deleteMediaElement = document.getElementById('deleteMedia');
                if (e?.key === 'Enter' && isOpen && deleteMediaElement?.children?.length > 0) {
                    deletePages()
                }else if(e?.key === "Escape"){
                    onClose()
                }
            };
            document.addEventListener("keydown", handleKeyDown);
            return () => {
                document.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [isOpen]);
    
    if (!isOpen) {
        return null;
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            deletePages(index);
        }
    };


    if( activeFilter && selectedItems ) {
        data = data.find(item => item._id === selectedItems[0]);
    }

    const deletePages = async (id) => {
        try {
            
            let currentData;
            if(action === 'action'){
                currentData = data;
            } else {
                currentData = data?.find((item) => item._id === id[0]);
            }
            
            if (currentData?.status !== "Deleted"){
                setIsLoading(true);

                let updateAPI = `${process.env.REACT_APP_API_URL}/${type}/update/${id}`;

                if(type == 'page') {
                    updateAPI = `${process.env.REACT_APP_API_URL}/pages/updatepage/${id}`;

                }
                if(type == 'publication') {
                    updateAPI = `${process.env.REACT_APP_API_URL}/pages/updatepublication/${id}`;

                }

                const response = await axios.post(
                    updateAPI,
                    {
                        status: "Deleted",
                        previous_status: data.status,
                        member_id: JSON.parse(localStorage.getItem("userinfo"))?._id,
                        password: userPassword 
                    }
                );
                if(response?.status === 200){
                    
                    setShowNotification(true)

                    if (typeof setContentChange === 'function') {
                        setContentChange(false);
                    }
                    
                    if(action !== 'action'){
                        setSelectedItems([])
                        setToggleSelect(!toggleSelect)
                    }
                }

                if(name !== "pages" && name !== "publications" && name !== "newsLetters"){
                    { 
                        name && history(`/dashboard/${name}`) 
                    }
                    document.body.classList.remove('hidden-message');  
                }

                if(name === 'pages') {
                    history(`/dashboard/all-pages`);
                }
                if(name === "publications") {
                    history(`/dashboard/all-publications`);
                }
                if(name ==="newsletters") {
                    history(`/dashboard/all-newsletters`);
                }

                updateOriginalData((prevData) => {
                    const updatedData = prevData.filter((item) => item._id !== index);
                    return updatedData;
                });
                if (setPages) {
                    setPages((prevPages) => prevPages.filter((item) => item._id !== index));
                }

                onClose();
                setIsLoading(false);

            }else {
                setIsLoading(true);

                let deleteAPI = `${process.env.REACT_APP_API_URL}/${type}/remove/${id}`;

                if(type == 'page') {
                    deleteAPI = `${process.env.REACT_APP_API_URL}/pages/removepage/${id}`;
                }
                if(type == 'publication') {
                    deleteAPI = `${process.env.REACT_APP_API_URL}/pages/removepublication/${id}`;
                }

                await axios.delete(deleteAPI);
                if(name !== "pages" && name !== "publications" && name !== "newsLetters"){
                    { name && history(`/dashboard/${name}`) }
                  }
                updateOriginalData((prevData) => {
                    const updatedData = prevData.filter((item) => item._id !== index);
                    return updatedData;
                });
                if (setPages) {
                    setPages((prevPages) => prevPages.filter((item) => item._id !== index));
                }
                onClose();
                setContentChange(false)
                setIsLoading(false);
                if(action !== 'action'){
                    setSelectedItems([])
                    setToggleSelect(!toggleSelect)
                }
            }

        } catch (error) {
            console.log({error:error})
            setIsLoading(false);
        }
        
    };

    return (
        <>
            {isOpen && (<div className='welcomeModal singleDeletePage'>
                <div className="popupTop popupTopClose closePlan">
                    <img src={close} onClick={e => { onClose() }} alt="Icon" />
                </div>
                <h3 className='popupTitle'>Delete {type.toLowerCase()}</h3>
                <p className='popupDesc'>Are you sure you want to delete this {type.toLowerCase()}? This action cannot be undone.</p>
                <div className="btnGroupLink" id='deleteMedia'>
                    <button className="secondary-button button" type="button" onClick={onClose}>Cancel</button>
                    <button className="primary-button button" type="button" onClick={() => deletePages(index)} >
                        {isLoading ?
                            <div className="settingPage-loader">
                                <div className="ring-loader"></div>
                            </div>
                            : "Delete"}
                    </button>
                </div>
            </div>)}

        </>
    )
}

export default SingleDeletePage