import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

import PublicationListItem from '../../Components/PublicationListItem';
import Pagination from '../../Components/Pagination';
import CategoriesDropdown from '../../Components/CategoriesDropdown';
import MultipleDeletePages from '../../Components/DeleteModal/MultipleDeletePages';
import NoResults from '../../Components/NoResults';
import SingleDeletePage from '../../Components/DeleteModal/SingleDeletePage';
import NotificationMessage from '../../Components/NotificationMessage';
import { DataTableList } from '../../Components/DataTable/DataTable';
import PageActionDropdown from '../../Components/PageActionDropdown';

import '../.././App.css';

import search from '../.././images/search.svg'
import clear from '../.././images/delete.svg'
import down from '../.././images/down_arrow.svg'
import top from '../.././images/arrow_top.svg'
import category from '../.././images/category.svg'
import more from '../../images/more.svg'


function DraftsPublications(props) {

    props.setActivePage('Draft Publications')
    const [activeFilter, setActiveFilter] = useState('All')
    const [searchInput, setSearchInput] = useState('')

    const [pageData, setPageData] = useState([])
    const [allFiltered, setAllFiltered] = useState(pageData)
    const [itemToDelete, setItemToDelete] = useState('')
    const [selectAll, setSelectAll] = useState(false);
    const [isPopupOpenDeleteAccount, setIsPopupOpenDeleteAccount] = useState(false);
    const [isPopupSingleDeleteAccount, setIsPopupSingleDeleteAccount] = useState(false)
    const [showNotification, setShowNotification] = useState(false);
    const [showMultiNotify, setShowMultiNotify] = useState(false)
    const [selectedPubLenght, setSelectedPubLenght ] = useState(0)


    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState([])
    const [categoryList, setCategoryList] = useState([])

    const [toggleSelect, setToggleSelect] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [activateEdit, setActivateEdit] = useState(false)
    const [columnWidths, setColumnWidths] = useState([]);

    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id
    const profileSlug = localStorage.getItem('userProfileSlug')

    useEffect(() => {
        searchPage()
    }, [searchInput])

    useEffect(() => {
        var id = itemToDelete;

        var result = [];

        pageData.forEach(element => {
            if (element.id === id) {
                return false
            } else {
                result.push(element)
            }
        });

        setPageData(result)
        setAllFiltered(result)

    }, [itemToDelete])

    const data = async () => {
        const search = searchInput.toLowerCase();
        setIsLoading(false)
        try {
            setIsLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/pages/publicationlist/${user_id}?page=${currentPage}&search=${search}&status=Draft&categories=${selectedCategory}`,
            )
            const result = response?.data?.result;
            setOriginalData(result);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error, "error");
        }
    }
    useEffect (()=>{
        data();
    },[user_id,currentPage, searchInput,selectedCategory])

    useEffect(() => {
        if(originalData?.length <= 20){
            setCurrentPage(1);
        }
    }, [selectedCategory]);
    function searchPage() {

        var result = [];
        var input = searchInput.toLowerCase()
        originalData.forEach(element => {
            var title = element.title.toLowerCase();

            if (title.startsWith(input)) {
                result.push(element);
            }
        });

        setPageData(result);
        setAllFiltered(result);

        if (result != []) {
            setPageData(result)
            setAllFiltered(result)
        } else if (input === '') {
            setPageData(result)
            setAllFiltered(originalData)
            setActiveFilter('All')
        } else {
            setPageData(result)
            setAllFiltered([])
        }
    }

    /* SORT BY CATEGORY */

    const categoryRef = useRef();
    const ref = useRef();

    const [categorySelect, setCategorySelect] = useState(false)

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (categorySelect && categoryRef.current && !categoryRef.current.contains(e.target)) {
                setCategorySelect(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [categorySelect]);


    useEffect(() => {
        if (selectedCategory.length !== 0) {
            var arr = [];

            selectedCategory.forEach(element => {
                var result = pageData.filter((item) => item.categories.indexOf(element) !== -1)
                result.forEach(item => {
                    arr.push(item)
                });
            });

            let unique = [...new Set(arr)];
            setPageData(unique)
        } else {
            setPageData()
        }

    }, [selectedCategory])

    const [originalData, setOriginalData] = useState([]);

    const fetchData = async () => {
        setIsLoading(false)
        try {
            setIsLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/pages/publicationlist/${JSON.parse(localStorage.getItem("userinfo"))?._id}?page=${currentPage}&status=Draft&categories=${selectedCategory}`,
            )
            setOriginalData(response?.data?.result);
            setTotalPage(response?.data?.totalPages || 1)
        setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error, "error");
        }
    }
    
    useEffect(() => {
        fetchData();
    }, [selectedCategory]);

    useEffect(() => {
        filterByStatus(activeFilter);
    }, [activeFilter, originalData]);

    function filterByStatus(status) {
        if (status === 'All') {
            setPageData(originalData);
        } else {
            const filteredData = originalData.filter(item => item.status === status);
            setPageData(filteredData);
        }
    }
    function updateOriginalData(updatedData) {
        setOriginalData(updatedData);
        // setSelectedItems([])
        fetchData()
    }

    const handleDelete = async () => {
        if (selectedRows.length === 1) {
            setIsPopupSingleDeleteAccount(true);
            document.body.classList.add('hidden-message');
        }

        if (selectedRows.length > 1) {
            setIsPopupOpenDeleteAccount(true);
            document.body.classList.add('hidden-message');
        }
    };

    const closeSingleAccountDeletedPopup = () => {
        document.body.classList.remove('hidden-message');
        setIsPopupSingleDeleteAccount(false);
    }

    const closeAccountDeletedPopup = () => {
        document.body.classList.remove('hidden-message');
        setIsPopupOpenDeleteAccount(false)
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
          if (e.target != null && isPopupOpenDeleteAccount && (e.target.classList.contains('welcomeOverlay'))) {
            setIsPopupOpenDeleteAccount(false);
          }
          if (e.target != null && isPopupSingleDeleteAccount && (e.target.classList.contains('welcomeOverlay'))) {
            setIsPopupSingleDeleteAccount(false);
        }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
    
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside);
        };
      }, [isPopupOpenDeleteAccount, isPopupSingleDeleteAccount]);
    useEffect(() => {
        setSelectAll(false);
        filterByStatus(activeFilter);
    }, [activeFilter, originalData]);

    const updateStatus = async (itemId, newStatus) => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/pages/updatepublication/${itemId}`,
                {
                    status: newStatus
                });
            fetchData();
            setSelectedRows([]);
        } catch (error) {
            console.log(error, "error");
        }
    };


    useEffect(() => {
        if (originalData.length > 0) {
            calculateColumnWidths();
        }
    }, [originalData]);
    const [openPopupIndex, setOpenPopupIndex] = useState(null);

    const togglePopup = (index) => {
        if (index === openPopupIndex) {
            setOpenPopupIndex(null);
        } else {
            setOpenPopupIndex(index);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openPopupIndex &&  (event.target.closest('.pagesTableAction') === null && event.target.closest('.dropdown') === null) && !document.body.classList.contains('hidden-message')) {
                togglePopup(null) // Close the dropdown
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openPopupIndex,togglePopup]);
    

    useEffect(() => {
        categoriesListData()
    }, [])

    const categoriesListData = async () => {
        setIsLoading(false);
        try {
            setIsLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/categories/list/${JSON.parse(localStorage.getItem("userinfo"))?._id}?publicationList=true`,
            );
            
            // Extract category names from the response
            const categoryNames = response?.data?.result?.map(item => item.name);
            
            setCategoryList(categoryNames);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error, "error");
        }
    }

    function formateDate(date) {
        var createdAtDate = new Date(date);

        var options = { year: 'numeric', month: 'short', day: 'numeric' };

        var formattedDate = createdAtDate.toLocaleDateString('en-US', options);
        return formattedDate
    }

    const calculateColumnWidths = () => {
        const tempWidths = pageData?.reduce((acc, row) => {
            Object.keys(row).forEach((key) => {
                const dataValue = row[key].toString();
                const title = row[key];

                // Compare title length with data content length and take the max

                let maxContentLength = 0;

                if (key === 'categories') {
                    const categories = row[key];

                    if (categories.length > 2) {
                        let tempCatLength = 0;

                        for (let i = 0; i < 2; i++) {
                            tempCatLength = tempCatLength + categories[i].length;
                        }

                        maxContentLength = Math.max(tempCatLength + 5.5, title.length);

                    } else {
                        if (categories.length == 0) {
                            maxContentLength = Math.max(dataValue.length + 5);
                        } else {
                            maxContentLength = Math.max(dataValue.length + 3, title.length);
                        }
                    }

                } else if (key === 'status') {
                    if(row?.status === 'Draft'){
                        maxContentLength = Math.max(dataValue.length + 3);
                    } 
                } else {
                    maxContentLength = Math.max(dataValue.length, title.length);
                }

                if (!acc[key] || maxContentLength > acc[key]) {
                    acc[key] = maxContentLength;
                }
            });
            return acc;
        }, {});
        if(tempWidths !== undefined){
            const widthMap = Object.keys(tempWidths).reduce((acc, key) => {
                if (key === 'createdAt' || key === 'updatedAt') {
                } else if (key === 'categories') {
                    pageData.forEach((row) => {
                        const categoryWidth = tempWidths[key] * 10 + 50;
                        acc[key] = `${categoryWidth}px`;
                    });
                } else {
                    acc[key] = `${tempWidths[key] * 10 + 40}px`;
                }
                return acc;
            }, {});
            setColumnWidths(widthMap); // Set the calculated column widths
        }    
    };

    const handleMasterCheckboxChange = () => {
        if (isAllSelected) {
            setSelectedRows([]);
        } else {
            setSelectedRows(data.map((row) => row._id)); // Select all rows
        }
        setIsAllSelected(!isAllSelected); // Toggle master checkbox
    };

    function updateActivateEdit(activateEdit) {
        setActivateEdit(activateEdit)
    }

    const changeStatus = (newStatus) => {
        data.status = newStatus;
        updateStatus(data._id, newStatus);
    };

    const customTitleSort = (rowA, rowB) => {
        const titleA = rowA?.title.toString().toLowerCase();
        const titleB = rowB?.title.toString().toLowerCase();
    
        // Natural sort comparison for mixed numbers and letters
        return titleA.localeCompare(titleB, undefined, { numeric: true, sensitivity: 'base' });
    };

    const columns = [
        {
            name: 'Title',
            selector: (row) => row?.title,
            cell: (row) => {
                return (
                    <Link className='editTitle' to={`/dashboard/publication/${row?._id}`}>{row?.title}</Link>
                )
            },
            sortable: true,
            minWidth: columnWidths['title'] && parseInt(columnWidths['title']) > 300 ? '300px' : columnWidths['title'],
            sortFunction: customTitleSort,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Status',
            selector: (row) => row?.status, // Ensure this returns the status value
            cell: (row) => {
                return (
                    <div className='statusTab'><span className={(row?.status === 'Published') ? 'greenStatus' : (row?.status === 'Draft') ? 'draftStatus' : (row?.status === 'Schedule') ? 'scheduleStatus' : 'deletedStatus'}> {row?.status}</span></div>
                )
            },
            width: columnWidths['status'] || '150px',
            style: { width: columnWidths['status'] || '150px', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
            sortable: true,
        },
        {
            name: 'Categories',
            cell: (row) => {
                var category = row?.categories.slice(0, 2)
                var categoryFull = row?.categories.slice(2)
                return (<div className='publicationsCategory'>
                    <div className={row?.categories?.length > 2 ? 'categories-large' : 'categories-small'}>
                        {category?.map((category, index) => (
                            <span key={index}>{category}</span>
                        ))}

                        {(row.categories.length > 2) &&
                            <div className='categoryList'>
                                <span>+{row.categories.length - 2}</span>
                                <div className="categoryTooltip">
                                    {categoryFull.map((item) => (
                                        <p> {item}</p>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                </div>)
            },
            paddingLeft: 0,
            minWidth: '130px',
            width: columnWidths['categories'] || '200px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", width: columnWidths['categories'] || '200px', fontSize: "14px", minWidth: '130px' },
        },
        {
            name: 'Last Updated',
            selector: row => formateDate(row?.updatedAt),
            sortable: true,
            paddingLeft: 0,
            width: '150px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", minWidth: '150px', fontSize: "14px" },
        },
        {
            name: 'Link',
            cell: (row) => {
                let pageUrl;
                if (row?.status === 'Published') {
                    if (row?.publication_type === 'Video' || row?.publication_type === 'Images' || row?.publication_type === 'File') {
                        pageUrl = `https://jourmal-next-front.vercel.app/${profileSlug}?pid=${row?._id}`;
                    } else if (row?.publication_type === 'Link') {
                        pageUrl = row.share_url;
                    } else {
                        pageUrl = `https://jourmal-next-front.vercel.app/${profileSlug}/${row.url_address}`;
                    }
                } else {
                    if (row.status === 'Draft' && row?.publication_type === 'Regular') {
                        pageUrl = `https://reactdashboard.codeptsolutions.com/dashboard/previewpublication/${row?._id}`
                    }
                }
                return (
                    <div className='pagesTableLink'><Link target={'_blank'} to={`${pageUrl}`}><button className='pagesLinkButton'>
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.88252 9.44272L7.11748 10.2078C6.06116 11.2641 4.34855 11.2641 3.29223 10.2078C2.23592 9.15146 2.23592 7.43884 3.29223 6.38252L4.05728 5.61748M10.9427 6.38252L11.7078 5.61748C12.7641 4.56116 12.7641 2.84855 11.7078 1.79223C10.6515 0.735922 8.93884 0.735922 7.88252 1.79223L7.11748 2.55728M5.6066 7.89339L9.3934 4.10659" stroke="#344054" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round" />
                        </svg>
                        {row?.status === 'Published' ? 'Link' : 'Preview'}
                    </button></Link></div>
                )
            },
            paddingLeft: 0,
            width: (columnWidths['status']  && parseInt(columnWidths['status'])) ? `${parseInt(columnWidths['status']) - 10}px`  : '150px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <div className='pagesTableAction publicationAction' onClick={() => togglePopup(row)} ref={ref}>
                        <img src={more} alt="More" />
                    </div>
                    {
                        (openPopupIndex?._id === row?._id) && <PageActionDropdown togglePopup={togglePopup} changeStatus={changeStatus} type={"publication"} data={row} updateActivateEdit={updateActivateEdit} updateOriginalData={updateOriginalData} index={row._id} setShowNotification={setShowNotification} />
                    }
                </>
            ),
            justifyContent: 'flex-end',
            allowOverflow: true,
            button: true,
            // minWidth: "55px",
            paddingRight: '16px',
            width: '80px',
            style: { textAlign: 'right', minWidth: '80px', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px" }, // Aligning to the right
            headerStyle: { textAlign: 'right' },
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };

    return (
        <section className='dashboard'>
            <Helmet>
                <title>Drafts Publications | Dashboard | Persone</title>
            </Helmet>
            <div className="container">
                <div className="dashboardTop">
                    <div className="dashboardTopText publicationTopText">
                        <div className='dashboardTopLeft'>
                            <p>Drafts Publications</p>
                            <p className="pageSubTitle">Edit and finalize your draft publications to prepare them for publishing and sharing.</p>
                        </div>
                        <div className="pagesSorting pageTypeSorting">
                            <div className='pagesSearch'>
                                <div className="pageSearchInput">
                                    <input type="text" name="pages_search" id="pages_search" placeholder={'Search drafts publications...'} value={searchInput} onChange={e => setSearchInput(e.target.value)} />
                                    <img src={search} alt="Search" />
                                </div>
                                <div className='sortingButton'>
                                    <div className="publicationsCategoryButton" ref={categoryRef}>
                                        <button className='secondary-button' onClick={e => setCategorySelect(!categorySelect)}><img src={category} alt="Category" />Categories {(selectedCategory.length > 0) ? `(${selectedCategory.length})` : ''}</button>

                                        {
                                            (categorySelect === true) ? <CategoriesDropdown categories={categoryList} selected={selectedCategory} changeCategory={setSelectedCategory} /> : ''
                                        }
                                    </div>

                                    <div className='deleteIcon' onClick={() => handleDelete()}>
                                        <img className='deleteIconImage' src={clear} alt="Clear Input" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pagesTableWrapper publications-list">
                    <div className="pagesTable dataTable-list">
                        <DataTableList
                            columns={columns}
                            data={pageData}
                            onSelectedRowsChange={({ selectedRows }) => {
                                const selectedIds = selectedRows.map((row) => row._id);
                                setSelectedRows(selectedIds);
                                setIsAllSelected(selectedIds.length === data.length);
                            }}
                            progressPending={isLoading}
                            progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                            onChangePage={setCurrentPage}
                            paginationTotalRows={totalPage}
                            currentPage={currentPage}
                            customStyles={customStyles}
                            selectableRowsComponent="input"
                            selectAllRows={isAllSelected}
                            onSelectAllChange={handleMasterCheckboxChange}
                            clearSelectedRows={toggleSelect}
                            type="publication" 
                            name="publication" 
                            status="draft"
                            selectableRows={true}
                        />
                    </div>
                </div>
            </div>

            {(isPopupOpenDeleteAccount || isPopupSingleDeleteAccount) && <div className="welcomeOverlay"></div>}

            {<SingleDeletePage isOpen={isPopupSingleDeleteAccount} onClose={closeSingleAccountDeletedPopup} data={pageData} type='publication' updateOriginalData={updateOriginalData} index={selectedRows} activeFilter={"Draft"} setShowNotification={setShowNotification} setSelectedItems={setSelectedRows} toggleSelect={toggleSelect} setToggleSelect={setToggleSelect} />}
            {<MultipleDeletePages isOpen={isPopupOpenDeleteAccount} onClose={closeAccountDeletedPopup} customKey="publicationId" type='publication' name="publications" selectedItems={selectedRows} updateOriginalData={updateOriginalData} activeFilter={"Draft"} setShowMultiNotify={setShowMultiNotify} setShowNotification={setShowNotification} setSelectedPubLenght={setSelectedPubLenght} setSelectedItems={setSelectedRows} toggleSelect={toggleSelect} setToggleSelect={setToggleSelect}/>}
            {showNotification && (
                <NotificationMessage  openNotify={showNotification} setOpenNotify={setShowNotification} type={'success'} title={`The publication was successfully deleted!`} />
            )}
            {showMultiNotify && (
                <NotificationMessage  openNotify={showMultiNotify} setOpenNotify={setShowMultiNotify} type={'success'} title={`${selectedPubLenght} publications were successfully deleted!`} />
            )}
        </section>
    );
}

export default DraftsPublications;
