import React, { useEffect, useRef, useState } from 'react'
import Select, { components } from "react-select";

import selectDown from "../../images/Settings/select_down.svg";
import close from '../../images/Settings/close.svg'
import axios from 'axios';

const AddSubscriberModel = ({ setShowSubcriberPopup, showSubcriberPopup, errorMessage, setErrorMessage, existEmail, setExistEmail, validateEmail, setValidateMail, isAddSubscriberLoading, setAddSubscriberIsLoading,userMetaData, setSubscriberNotify, setMultiSubscriberNotify, setNewMails, newMails, selectedCategory, setSelectedCategory, setSelectedCategoryPlan, setVerifySubscriberNotify}) => {

  const [showSubcriptionPeriod, setShowSubcriptionPeriod] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState({ value: '1-week', label: '1 week' });
  const [periodTypeOpen, setPeriodTypeOpen] = useState("");
  const [planTypeOpen, setPlanTypeOpen] = useState("");
  const popupRef = useRef();
  const trialRef = useRef();
  const planRef = useRef();
  const emailAddressesRef = useRef();

  let options = [
    { value: 'freePlan', label: 'Free subscription' }
  ];
  if ((userMetaData?.subsPlans?.planOne?.planNameOne !== "" && userMetaData?.subsPlans?.planOne?.planNameOne !== undefined) && (userMetaData?.stripeAccountData?.stripeAccountid !== "" && userMetaData?.stripeAccountData?.stripeAccountid !== undefined)) {
    options.push({ value: 'planOne', label: 'Trial subscription' });
  }
  const period_options = [
    { value: '1-week', label: '1 week' },
    { value: '2-weeks', label: '2 weeks' },
    { value: '3-weeks', label: '3 weeks' },
    { value: '1-month', label: '1 month' },
    { value: '2-months', label: '2 months' },
    { value: '3-months', label: '3 months' },
    { value: '4-months', label: '4 months' },
    { value: '5-months', label: '5 months' },
    { value: '6-months', label: '6 months' },
    { value: '1-year', label: '1 year' },
    { value: '2-years', label: '2 years' },
    { value: '3-years', label: '3 years' },
    { value: '4-years', label: '4 years' },
    { value: '5-years', label: '5 years' },
    { value: '6-years', label: '6 years' },
  ];

  const getInterval = (period) => {
    switch (period) {
      case '1-week':
      case '2-weeks':
      case '3-weeks':
      case '1-month':
      case '2-months':
      case '3-months':
      case '4-months':
      case '5-months':
      case '6-months':
        return 'month';
      case '1-year':
      case '2-years':
      case '3-years':
      case '4-years':
      case '5-years':
      case '6-years':
        return 'year';
      default:
        return null;
    }
  };

  const colourStyles = {
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f9f9f9" : "transparent",
        fontSize: "14px",
        cursor: "pointer",
        color: '#101828',
        ':active': {
          backgroundColor: "#f9f9f9"
        }
      };
    },
    input: (provided, state) => ({
      ...provided,
      height: "34px",
      padding: 0,
      margin: 0,
      borderRadius: "8px",
      fontSize: "14px",
      cursor: "auto",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      color: "#101828",
    }),
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderRadius: "8px",
      width: "100%",
    }),
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none !important",
      borderRadius: "8px",
      marginRTop: "2px",
    }),
    container: (provided, state) => ({
      ...provided,
      paddingLeft: "0px",
    }),
    placeholder: (styles, { isFocused }) => {
      return {
        ...styles,
        marginBottom: isFocused ? "0px" : "0px",
        fontSize: "14px",
        marginLeft: "0",
        color: "#667085",
      };
    },
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      outline: "none",
      marginRight: "5px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: "15px",
      paddingRight: "15px",
      marginTop: "1px",
      height: "42px"
    }),
  };


  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    setEmailAddresses([inputValue]);
    setErrorMessage("")
    setExistEmail([])
    setValidateMail("")
  };

  const validateEmailAddresses = (emailAddresses) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const invalidEmails = emailAddresses.filter((email) => !emailRegex.test(email.trim()));

    if (invalidEmails.length > 0) {
      setValidateMail(`Invalid email address: ${invalidEmails.join(", ")}`);
      setErrorMessage("")
      setExistEmail([])
      return false;
    }

    return true;
  };
  const handleAddSubscribers = async () => {
    const emailAddresses = emailAddressesRef.current.value.split(",").map(email => email.trim()); // Trim each email
    if (!validateEmailAddresses(emailAddresses)) {
      return
    };
    setAddSubscriberIsLoading(false)
    try {
      setAddSubscriberIsLoading(true)
      const payload = {
        email: emailAddresses,
        plan: selectedCategory,
        period: selectedCategory?.value === "planOne" ? selectedPeriod : undefined,
        interval: selectedCategory?.value === "planOne" ? getInterval(selectedPeriod?.value) : undefined,
        publicationNotification: true,
        emailVerification: false,
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/subscriber/addSubscriber/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
        payload
      );
      setNewMails(response?.data?.newEmails)
      if(response?.data?.newEmails.length > 0){
        setVerifySubscriberNotify(true) 
      }
      if (response?.data?.email.length > 0 && response?.data?.message !== "Subscriber Add Successfully") {
        setErrorMessage(response?.data?.message)
        setExistEmail(response?.data?.email)
        setValidateMail("")
        setAddSubscriberIsLoading(false)
      } else {
        setErrorMessage("")
        setExistEmail([])
        setValidateMail("")
        setAddSubscriberIsLoading(false)
        setShowSubcriberPopup(false)
        setSelectedCategoryPlan({ value: 'freePlan', label: 'Free subscription' })
      }
      setAddSubscriberIsLoading(false)
    } catch (error) {
      setAddSubscriberIsLoading(false)
      console.error(error, "error");
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showSubcriberPopup && setShowSubcriberPopup && popupRef?.current && !popupRef?.current.contains(e.target)) {
        setShowSubcriberPopup(false)
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showSubcriberPopup]);


  useEffect(() => {
      const checkIfClickedOutside = (e) => {
        if (
          periodTypeOpen &&
          trialRef.current &&
          !trialRef.current.contains(e.target)
        ) {
          setPeriodTypeOpen(false);
        }
      };
  
      document.addEventListener("mousedown", checkIfClickedOutside);
  
      return () => {
        document.removeEventListener("mousedown", checkIfClickedOutside);
      };
    }, [periodTypeOpen]);
    
  useEffect(() => {
      const checkIfClickedOutside = (e) => {
        if (
          planTypeOpen &&
          planRef.current &&
          !planRef.current.contains(e.target)
        ) {
          setPlanTypeOpen(false);
        }
      };
  
      document.addEventListener("mousedown", checkIfClickedOutside);
  
      return () => {
        document.removeEventListener("mousedown", checkIfClickedOutside);
      };
    }, [planTypeOpen]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddSubscribers();
    }
  };
  return (
    <>
      <div className='welcomeModal addSubcriberModel' ref={popupRef}>
        <div className="popupTop popupTopClose closePlan">
          <img src={close} onClick={e => { setShowSubcriberPopup(false) }} alt="Icon" />
        </div>
        <h3 className='popupTitle'>Add subscribers by email address</h3>
        <p className='popupDesc'>An email with a confirmation link will be sent to each subscriber.</p>

        <div className='popupFields'>
          <label className='fieldsLabel'>Emails</label>
          <textarea type="text" name='subscriber-email' className='inputFields subscriberMailBox' placeholder='Type emails here (seperated by commas)' value={emailAddresses?.join(",")}
            onChange={handleEmailChange} ref={emailAddressesRef} onKeyPress={(e) => { handleKeyPress(e) }} />
          {errorMessage && <div className='error-message'>{existEmail?.join(", ")}{errorMessage}</div>}
          {validateEmail && <div className='error-message'>{validateEmail}</div>}
        </div>
        <div className="newPageRightRow popupFields" ref={planRef}>
            <p className="newPageRightTitle iconDropWrap">
              <span>Subscription type</span>
            </p>
            <div className={`contentDrop`}>
              <div>
                <div className="select planTypeSelect">
                  <div className="selectMain" onClick={(e) => { setPlanTypeOpen(!planTypeOpen)}}>
                    <p>{selectedCategory?.label}</p>
                  </div>
                  {planTypeOpen === true && (
                    <div className="selectListOption">
                      { options?.map((item) => {
                          return (
                            <div
                              className="selectOption"
                              onClick={() => {
                                setSelectedCategory(item)
                                if (item?.value === 'freePlan') {
                                  setShowSubcriptionPeriod(false);
                                } else {
                                  setShowSubcriptionPeriod(true);
                                }
                                setPlanTypeOpen(false);
                              }}
                            >
                              <p>{item?.label}</p>
                            </div>
                          )
                        })
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        {showSubcriptionPeriod &&
          <div className="" ref={trialRef}>
            <p className="newPageRightTitle iconDropWrap">
              <span>
                Trial period
              </span>
            </p>
            <div className={`contentDrop`}>
              <div>

                <div className="select planTypeSelect">
                  <div
                    className="selectMain"
                    onClick={(e) => {
                      setPeriodTypeOpen(!periodTypeOpen)
                    }}
                  >

                    <p>{selectedPeriod?.label}</p>
                  </div>
                  {periodTypeOpen === true ? (
                    <div className="selectListOption selectPlanOption">
                      {
                        period_options?.map((item) => {
                          return (
                            <div
                              className="selectOption"
                              onClick={() => {
                                setSelectedPeriod({ value: item?.value, label: item?.label })
                                setPeriodTypeOpen(false);
                              }}
                            >
                              <p>{item?.label}</p>
                            </div>
                          )
                        })
                      }
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        }
        <div className="btnGroupLink btnGroupWidth" id='deleteMedia'>
          <button className="secondary-button button" type="button" onClick={() => setShowSubcriberPopup(false)}>Cancel</button>
          <button className="primary-button button" type="button" onClick={handleAddSubscribers}>
            {isAddSubscriberLoading ?
              <div className="settingPage-loader">
                <div className="ring-loader"></div>
              </div>
              :"Add subscribers" }
          </button>
        </div>
      </div>
    </>
  )
}

export default AddSubscriberModel
