
import '.././App.css';
import notify_ok from '.././images/notification_ok.svg'
import notify_close from '.././images/notify_close.svg'

function NotificationMessage(props) {
    if(props?.name !== 'liveNotification'){
        setTimeout(() => {
            props?.setOpenNotify(false)
        }, 10000);
    }

    return (
        <div className={(props?.openNotify === true) ? 'notification notificationMessage notificationActive' : 'notification notificationMessage'}>
            {
                props?.type === 'success' ?
                    <img className='notifyStatus notifyImg' src={(props?.type === 'success') ? notify_ok : ''} alt="Notify Status" />
                    :
                    <svg className='notifyStatus notifyImg error-img' fill="#ff0000"
                        version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 478.125 478.125">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <g>
                                <g>
                                    <g>
                                        <circle cx="239.904" cy="314.721" r="35.878">
                                        </circle>
                                        <path d="M256.657,127.525h-31.9c-10.557,0-19.125,8.645-19.125,19.125v101.975c0,10.48,8.645,19.125,19.125,19.125h31.9 c10.48,0,19.125-8.645,19.125-19.125V146.65C275.782,136.17,267.138,127.525,256.657,127.525z"></path> <path d="M239.062,0C106.947,0,0,106.947,0,239.062s106.947,239.062,239.062,239.062c132.115,0,239.062-106.947,239.062-239.062 S371.178,0,239.062,0z M239.292,409.734c-94.171,0-170.595-76.348-170.595-170.596c0-94.248,76.347-170.595,170.595-170.595 s170.595,76.347,170.595,170.595C409.887,333.387,333.464,409.734,239.292,409.734z"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
            }
            <p className="notificationTitle">{props?.title}</p>
            <img 
                onClick={e => 
                    {
                        props?.setOpenNotify(false); 
                        if(props?.name === 'liveNotification'){
                            props?.setOldSubscriberCount(0)
                            props?.setNotificationMessages([])
                        }
                    }
                } 
                className='notifyClose' src={notify_close} alt="Close Notify" />
        </div>
    );
}

export default NotificationMessage;