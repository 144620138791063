import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import DomainDeleteModel from '../../Components/Modal/DomainDeleteModel';

import Notification from '../../Components/Notification';

export const Domain = (props) => {
    props.setActivePage("Domain");
    const [isLoading, setIsLoading] = useState(false);
    const [domainName, setDomainName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [domainAdded, setDomainAdded] = useState(false);
    const [domainVerified, setDomainVerified] = useState(false);
    const [domainVerifictionToken, setDomainVerifictionToken] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [isRoot, setIsRoot] = useState(false);
    const [connectedNotification, setConnectedNotification] = useState(false);
    const [disConnectedNotification, setDisConnectedNotification] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        document.body.classList.toggle("hidden-message", showPopup);
        return () => document.body.classList.remove("hidden-message");
    }, [showPopup]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (showPopup && e.target.classList.contains('welcomeOverlay')) {
                setShowPopup(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [showPopup]);

    const isRootDomain = (domain) => {
        // Remove 'www.' if it exists
        const strippedDomain = domain.replace(/^www\./, '');
    
        // Common two-level TLDs
        const twoLevelTLDs = ['co.uk', 'com.au', 'org.uk', 'gov.uk', 'net.au', 'co.in', 'co.at'];
        
        // Pattern to check if domain is root with either one or two levels in TLD
        const rootDomainPattern = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
        
        if (rootDomainPattern.test(strippedDomain)) {
            // Split domain by dots
            const parts = strippedDomain.split('.');
    
            // Check if the domain has a two-level TLD
            const tld = parts.slice(-2).join('.'); // Get the last two parts, e.g., "co.uk"
            const isTwoLevelTLD = twoLevelTLDs.includes(tld);
    
            // If there are exactly two parts (example.com) or a known two-level TLD (example.co.uk)
            return parts.length === 2 || (parts.length === 3 && isTwoLevelTLD);
        }
    
        return false; // If it doesn't match the root domain pattern
    }

    const isValidDomain = (name) => {
        const validStartRegex = /^[a-zA-Z0-9]/; 
        const validAfterDotRegex = /\.[a-zA-Z0-9]{2,6}$/;
        
        if (name === "") {
            setErrorMessage('Please enter a valid domain');
            return false;
        }
      
        if (!validStartRegex.test(name)) {
            setErrorMessage('URL must start with digits or characters');
            return false;
        }
        if (!validAfterDotRegex.test(name)) {
            setErrorMessage('Please enter a valid domain');
            return false;
        }
        return true;
    };

    const addDomain = async() => {
        setErrorMessage('');

        const strippedDomain = domainName
            .replace(/^(https?:\/\/)/, '') // Remove 'http://', 'https://', and 'www.'
            .replace(/\/$/, ''); // Remove trailing slash
        
        if (!isValidDomain(strippedDomain)) {
            return; 
        }
        
        try {
            setIsLoading(true);

            const data = {
                domain: strippedDomain,
                isRoot: isRootDomain(strippedDomain)
            };
    
            
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/addDomain/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                data
            );

            if(response.status === 200) {
                setDomainVerifictionToken(response.data.token);
                setConnectedNotification(true)
            } else {
                setErrorMessage(response.data.message);
                setIsLoading(false);
            }
            
            setDomainAdded(true);
            setDomainVerified(false);            
            setIsRoot(isRootDomain(strippedDomain));
    
            setIsLoading(false);
        } catch (error) {
            setDomainAdded(false);
            setIsLoading(false);
            
            if (error.response) {
                if (error.response.status === 409) {
                    setErrorMessage(error.response.message);
                }
            }
        }
    }

    const verifyDomain = async() => {
        setErrorMessage('');

        try {
            setIsLoading(true);

            const response = await axios.post( `${process.env.REACT_APP_API_URL}/user/verifyDomain/${JSON.parse(localStorage.getItem("userinfo"))?._id}`, );

            if(response.status === 200) {
                setDomainVerified(true);   
            } else {
                setErrorMessage(response.data.message);
                setIsLoading(false);
            }    
            setIsLoading(false);
        } catch (error) {
            setDomainVerified(false);
            setIsLoading(false);

            console.log(error)

            setErrorMessage('DNS record not found. Please ensure you’ve created a CNAME record with the specified values, saved your changes, and allowed up to 48 hours for propagation.');
            
            if (error.response) {
                // setErrorMessage(error.response.data.message);
            }
        }
    }

    const handleBlur = () => {
        const strippedDomain = domainName
            .replace(/^(https?:\/\/)/, '') // Remove 'http://', 'https://', and 'www.'
            .replace(/\/$/, ''); // Remove trailing slash
        setDomainName(strippedDomain);
    };

    const handleKeyPress = (e) =>{
        if (e?.key === "Enter") {
            addDomain();
        }
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`
            );
            console.log({ response: response?.data })
            
            const userMeta = response?.data?.result?.userMeta;
            if (userMeta?.customDomain?.domain !== '' && userMeta?.customDomain?.domain !== undefined) {
                setDomainName(userMeta?.customDomain?.domain || '');
                setDomainVerifictionToken(userMeta?.customDomain?.token || '');
                setDomainVerified(userMeta?.customDomain?.verified || false);
                setDomainAdded(userMeta?.customDomain?.domain ? true : false);
            } else {
                setDomainName('');
                setDomainVerifictionToken('');
                setDomainVerified(false);
                setDomainAdded(false);
            }
        } catch (error) {
            console.log(error, "error");
        }
    };

    
    const handleNameChange = (e) => {
        setErrorMessage('');
        setDomainName(e.target.value); // Update domainName state on change
    };

    return (
        <>
        <Helmet>
          <title>Domain | Dashboard | Persone</title>
        </Helmet>
        <div className="general domain">
            <div className="generalTop">
                <div className="generalTopLeft">
                    <p className="generalTopLeftTitle">Domain</p>
                    <p className="generalTopLeftDesc">
                        Connect and manage your personal domain to give your profile a professional and personalized web address.
                    </p>
                </div>
            </div>

            <div className="generalRow domain-content">
                <div className="generalRowDesc">
                    <p>Connect a domain</p>
                    <p className='generalTopLeftDesc'>Enter your personal domain name and verify it using the provided DNS settings. Please note that the verification process may take up to 48 hours to complete.</p>
                </div>

                <div className="generalBlockItems">
                    <div>
                        <p className="field-label">My domain</p>
                        <div className='domain-wrapper'>
                            <input
                                type="text"
                                value={domainName}
                                readOnly={domainAdded ? true : false} // Conditionally set readonly
                                onChange={handleNameChange}
                                onBlur={handleBlur}
                                placeholder='for example: yourdomain.com...'
                                onKeyPress={(e) => handleKeyPress(e)}
                            />

                            { (! domainVerified && domainAdded) &&
                                <div className='instruction-wrapp'>
                                    <h4>Create a DNS record</h4>
                                    <p>Go to your DNS provider and create a record using these values:</p>
                                    <div className='instructions'>
                                        {isRoot ? 
                                            <>
                                                <p><b>Type:</b> <code>A</code></p>
                                                <p><b>Name:</b> <code>@</code></p>
                                                <p><b>Value:</b> <code>35.93.90.59</code></p>
                                            </>
                                            :
                                            <>
                                                <p><b>Type:</b> <code>CNAME</code></p>
                                                <p><b>Name:</b> <code>&#123;your_subdomain&#125;</code></p>
                                                <p><b>Value:</b> <code>demo4commerce.com</code></p>
                                            </>
                                        }                                        
                                    </div>
                                </div>
                            }
                            
                            
                            { errorMessage && 
                                <p className='error-message'>{errorMessage}</p>
                            }

                            <div className='domain-btn-wrapper'>
                                { ! domainAdded && 
                                    <button className='addDomainbtn primary-button flex button' onClick={addDomain}>
                                        {isLoading ?
                                            <div className="settingPage-loader">
                                                <div className="ring-loader"></div>
                                            </div>
                                            :
                                            <>Connect domain</>
                                        }
                                    </button>
                                }

                                {(! domainVerified && domainAdded) && 
                                <div className='verifySection flex'>
                                    <button className='secondary-button minwidthAuto button' onClick={() => setDomainAdded(false)}>
                                        Change domain
                                    </button>
                                    <button className='verifyDomainbtn primary-button button' onClick={verifyDomain}>
                                        {isLoading ?
                                            <div className="settingPage-loader">
                                                <div className="ring-loader"></div>
                                            </div>
                                            :
                                            <>Verify domain</>
                                            
                                        }
                                    </button>
                                </div>
                                }

                                {(domainVerified && domainAdded) && 
                                    <button className='primary-button minwidthAuto' onClick={() => setShowPopup(true)}>
                                        Delete
                                    </button>
                                }

                                {showPopup && 
                                    <>
                                        <div className="welcomeOverlay"></div>
                                        <DomainDeleteModel setShowPopup={setShowPopup} setDomainName={setDomainName} setDomainVerifictionToken={setDomainVerifictionToken} setDomainVerified={setDomainVerified} setDomainAdded={setDomainAdded} setDisConnectedNotification={setDisConnectedNotification}/>
                                    </>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {connectedNotification && (
                <Notification
                title={"Your domain has been successfully connected!"}
                text={"It may take up to 48 hours for the changes to update."}
                link= {""}
                to={``}
                setOpenNotify={setConnectedNotification}
                type={"success"}
                openNotify={connectedNotification}
              />
            )}
            {disConnectedNotification && (
                <Notification
                title={"Your domain has been successfully disconnected!"}
                text={"It may take up to 48 hours for the changes to update."}
                link= {""}
                to={``}
                setOpenNotify={setDisConnectedNotification}
                type={"success"}
                openNotify={disConnectedNotification}
              />
            )}
        </div>
        </>
    )
}
