import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../.././App.css';
import axios from 'axios';
import NotificationMessage from '../../Components/NotificationMessage';

function Password(props) {

    props.setActivePage('Password')

    const [currentPass, setCurrentPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [confirmNewPass, setConfirmNewPass] = useState('')
    const [matchPassword, setMatchPassword] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [validateCurrentPassword, setvalidateCurrentPassword] = useState(false);
    const [validatePassword, setValidatePassword] = useState(false);
    const [validateConfirmPass, setValidateConfirmPass] = useState(false);


    const onPasswordChange = e => {
        setCurrentPass(e.target.value);
        setError(false);
        if (e.target.value.length > 0) {
            setvalidateCurrentPassword(false);
        } else {
            setvalidateCurrentPassword(true);
        }
        // const hiddenPassValue = passHidden;
        // let showLength = 1;
        // let offset = e.target.value.length - hiddenPassValue.length;
        // if (offset > 0) {
        //     setPassHidden(hiddenPassValue + e.target.value.substring(hiddenPassValue.length, hiddenPassValue.length + offset))
        // } else if (offset < 0) {
        //     setPassHidden(hiddenPassValue.substring(0, hiddenPassValue.length + offset))
        // }
        // setCurrentPass(e.target.value.substring(0, e.target.value.length - showLength).replace(/./g, "•") + e.target.value.substring(e.target.value.length - showLength, e.target.value.length))
        // clearTimeout(hideAll);
        // setHideAll(setTimeout(() => setCurrentPass(e.target.value.replace(/./g, "•")), 600));
    };

    const onNewPasswordChange = e => {
        setNewPass(e.target.value);

        if(e.target.value.length > 0) {
            if( e.target.value.length >= 8 && (hasNumber && hasSpecialChar && isLowercase && isUppercase ) ) {
              setBox(false);
            } else {
              setBox(true);
            }
            setValidatePassword(false);
        } else {
            setValidatePassword(true);
        }

        // const hiddenPassValue = passHidden;
        // let showLength = 1;
        // let offset = e.target.value.length - hiddenPassValue.length;
        // if (offset > 0) {
        //     setPassHidden(hiddenPassValue + e.target.value.substring(hiddenPassValue.length, hiddenPassValue.length + offset))
        // } else if (offset < 0) {
        //     setPassHidden(hiddenPassValue.substring(0, hiddenPassValue.length + offset))
        // }
        // setNewPass(e.target.value.substring(0, e.target.value.length - showLength).replace(/./g, "•") + e.target.value.substring(e.target.value.length - showLength, e.target.value.length))
        // clearTimeout(hideAll);
        // setHideAll(setTimeout(() => setNewPass(e.target.value.replace(/./g, "•")), 600));
    };


    // useEffect(() => {
    //     const passwordsMatch = confirmNewPass === newPass;
    //     setMatchPassword(passwordsMatch);
    // }, [confirmNewPass, newPass]);

    const onConfirmPasswordChange = e => {
        setConfirmNewPass(e.target.value);
        if (e.target.value.length > 0) {
            setValidateConfirmPass(false);
        }

        if (e.target.value !== newPass) {
            setMatchPassword(false);
        } else {
            setMatchPassword(true);
        }
        // const hiddenPassValue = passHidden;
        // let showLength = 1;
        // let offset = e.target.value.length - hiddenPassValue.length;
        // if (offset > 0) {
        //     setPassHidden(hiddenPassValue + e.target.value.substring(hiddenPassValue.length, hiddenPassValue.length + offset))
        // } else if (offset < 0) {
        //     setPassHidden(hiddenPassValue.substring(0, hiddenPassValue.length + offset))
        // }
        // setConfirmNewPass(e.target.value.substring(0, e.target.value.length - showLength).replace(/./g, "•") + e.target.value.substring(e.target.value.length - showLength, e.target.value.length))
        // clearTimeout(hideAll);
        // setHideAll(setTimeout(() => setConfirmNewPass(e.target.value.replace(/./g, "•")), 600));
    };
    const isUppercase = /[A-Z]/.test(newPass);
    const isLowercase = /[a-z]/.test(newPass);
    const hasNumber = /[0-9]/.test(newPass);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPass); // Only `[` and `]` need to be escaped
    const isMinLength = newPass.length >= 8;

    const [box, setBox] = useState(false);

    const fulfilledRequirementsCount = [
        isUppercase,
        isLowercase,
        hasSpecialChar,
        hasNumber,
    ].filter(Boolean).length;

    function clearAll() {
        setCurrentPass('')
        setNewPass('')
        setConfirmNewPass('')
    }

    const addPasswordData = async () => {
        try {
            setIsLoading(true);
            const passwordData = {
                currentPassword: currentPass,
                newPassword: newPass,
                confirmPassword: confirmNewPass
            }
            
            await axios.post(
                `${process.env.REACT_APP_API_URL}/user/changePassword/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                passwordData,
            )
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                setError(true);
            } else {
                console.log(error, "Data not add");
            }
            return false;
        }
    }
    
    return (
        <>
        <Helmet>
          <title>Password | Dashboard | Persone</title>
        </Helmet>
        <div className="general">
            <div className="generalTop">
                <div className="generalTopLeft">
                    <p className='generalTopLeftTitle'>Password</p>
                    <p className='generalTopLeftDesc'>Update your current password to ensure your account remains secure.</p>
                </div>
            </div>

            <div className="billingContent billingData">
                <div className="billingRow">
                    <div className="billingText">
                        <p className='settingsMenuBlockTitle'>Change password</p>
                        <p className='generalTopLeftDesc'>Enter a new password and make sure it is strong to enhance your account's security.</p>
                    </div>
                    <div className='settingPassword'>
                        <div className="passwordInputs">
                            <div className="rowPass">
                                <p>Current password</p>
                                <input
                                    value={currentPass}
                                    onChange={onPasswordChange}
                                    placeholder='Enter your password'
                                    type="password"
                                    autoComplete="new-password"
                                    aria-autocomplete="none"
                                    autoCorrect="off"
                                    autoCapitalize="off" />
                                {(validateCurrentPassword === true) ? <p className="validateError">The field is not filled</p> : ''}
                                {(error === true) ? <p className='validateError'>Incorrect password, please try again</p> : ''}
                            </div>

                            <div className="rowPass">
                                <p>New password</p>
                                <input
                                    type="password"
                                    value={newPass}
                                    placeholder='Enter your new password'
                                    autoComplete="new-password"
                                    aria-autocomplete="none"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    onChange={onNewPasswordChange} />
                                    
                                {validatePassword === true && (
                                    <p className="validateError">Password doesn't meet criteria</p>
                                )}
                            </div>
                            {(onNewPasswordChange === true) ? <p className="validateError">The field is not filled</p> : ''}
                            {box && (
                                <div className="requirements-box">
                                    <p>Your password must contain:</p>
                                    <ul className="requirement-list">
                                        <li className={isMinLength ? "fulfilled" : ""}>
                                            <p>At least 8 characters</p>
                                        </li>
                                        <li
                                            className={
                                                fulfilledRequirementsCount >= 4 ? "fulfilled" : ""
                                            }
                                        >
                                            <p>And the Following:</p>
                                            <ul>
                                                <li className={isLowercase ? "fulfilled" : ""}>
                                                    <p>Lower case letters (a - z)</p>
                                                </li>
                                                <li className={isUppercase ? "fulfilled" : ""}>
                                                    <p>Upper case letters (A - Z)</p>
                                                </li>
                                                <li className={hasNumber ? "fulfilled" : ""}>
                                                    <p>Numbers (0 - 9)</p>
                                                </li>
                                                <li className={hasSpecialChar ? "fulfilled" : ""}>
                                                    <p>Special characters (e.g. !@#$%^&*)</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            )}

                            <div className="rowPass">
                                <p>Confirm new password</p>
                                <input
                                    type="password"
                                    value={confirmNewPass}
                                    onChange={onConfirmPasswordChange}
                                    placeholder='Re-enter your new password'                                    
                                    autoComplete="new-password"
                                    aria-autocomplete="none"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    onBlur={(e) => {
                                        if (e.target.value.length === 0) {
                                            setValidateConfirmPass(true);
                                        }
                                    }} />
                                    
                                {validateConfirmPass === true && (
                                    <p className="validateError">The field is not filled</p>
                                )}
                                {!matchPassword && (
                                    <p className="validateError">Passwords do not match</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="saveButtons">
                <button className='settingsWhite secondary-button button' onClick={e => clearAll()}>Cancel</button>
                <button className={`settingsBlue primary-button button ${isLoading ? 'btn_loading' : ''}`} onClick={(e) => {
                    if (
                        currentPass.length > 0 &&
                        newPass.length > 0 &&
                        confirmNewPass.length > 0 &&
                        matchPassword === true
                    ) {
                        addPasswordData();
                    } else {
                        if (currentPass.length === 0) {                            
                            setvalidateCurrentPassword(true);
                        }
                        if( newPass.length === 0 ) {
                            setValidatePassword(true);
                        }
                        if( confirmNewPass.length === 0 ) {
                            setValidateConfirmPass(true);
                        }
                    }
                }}>
                    {isLoading ?
                        <div className="settingPage-loader">
                            <div className="ring-loader"></div>
                        </div>
                        : "Save"}
                </button>
            </div>
        </div>
        </>
    );
}

export default Password;
