import '../.././App.css';
import drag from '../../images/Settings/drag.svg'
import down from '../../images/Settings/down.svg'
import clear from '../../images/delete.svg'
import { useState, useEffect } from 'react';

function NavItemEditable(props) {

  const [isOpen, setisOpen] = useState(false);
  const [title, setTitle] = useState(props.name);
  const [url, setUrl] = useState(props.data.url_address || '');



  useEffect(() => {
    setTitle(props.name);
    setUrl(props.data.url_address || '');
  }, [props.name, props.data.url_address]);


  return (

    <div className="navItem" draggable>
      <div className="navEditableVisible" onClick={(e) => setisOpen(!isOpen)} >
        <div>
          <img src={drag} alt="Drag" />
          <p>{title}</p>
        </div>
        <img
          className={isOpen === true ? "downRotated" : ""}
          src={down}
          alt="Down"
        />
      </div>

      {isOpen === true ? (
        <div className="customLinkRow">
          <p>Title</p>
          <div className="navLinkRow">
            <input
              type="text"
              placeholder="Enter a title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                props.updateTitle(props.data.id, e.target.value)
                props.data.name = e.target.value;
              }}
            />
          </div>
          {props.data.type === "customLink" &&
            <>
              <p>Url</p>
              <div className="navLinkRow">
                <input
                  type="text"
                  placeholder={props.data.url_address !== '' ? props.data.url_address : 'Enter URL'}
                  value={url}
                  onChange={(e) => {
                    setUrl(e.target.value);
                    props.updateUrl(props.data.id, e.target.value);
                    props.data.url = e.target.value;
                  }}
                />
              </div>

            </>}

          <div className='remove deleteRow'>
            <button className='remove-navitem' onClick={() => props.removeNavItem(props.data)}><img src={clear} alt="Clear" /> Delete </button>
          </div>

        </div>
      ) : (
        ""
      )}
    </div>

  );
}

export default NavItemEditable;
