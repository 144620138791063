import React, { useState } from "react";
import { DateRangePicker } from 'react-date-range';
import { format } from "date-fns";
import { defaultStaticRanges } from "./DefaultRanges"
import PropTypes from "prop-types";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import '.././App.css';

function DatePicker({ ranges = defaultStaticRanges, onChange, datevalue, onSubmit, setShowDatePicker, setCustomDates, setDatePickerOpen, ...rest }) {
    var startDate = format(new Date(), 'MMMM d, yyyy');
    var endDate = format(new Date(), 'MMMM d, yyyy');
    var StartDate = format(new Date(), 'MMMM d, yyyy');
    var EndDate = format(new Date(), 'MMMM d, yyyy');

    const [selectedDateRange, setSelectedDateRange] = useState({ startDate: new Date(), endDate: new Date(), key: "selection" });
    const [myDateRange, setDate] = useState(startDate + " - " + endDate);
    const [StartDATE, setStartDate] = useState(format(new Date(), 'MMMM d, yyyy'));
    const [EndDATE, setEndDate] = useState(format(new Date(), 'MMMM d, yyyy'));
    const minDate = new Date(JSON.parse(localStorage.getItem("userinfo"))?.createdAt);
    const maxDate  = new Date();
    
    const handleSelect = (ranges) => {
        setSelectedDateRange(ranges.selection);
        startDate = format(ranges.selection.startDate, 'MMMM d, yyyy');
        endDate = format(ranges.selection.endDate, 'MMMM d, yyyy');
        StartDate = format(ranges.selection.startDate, 'MMMM d, yyyy');
        EndDate = format(ranges.selection.endDate, 'MMMM d, yyyy');
        setStartDate(startDate)
        setEndDate(endDate)
        setDate(StartDate + " - " + EndDate)
        datevalue(StartDate + " - " + EndDate)
    };

    const onClickApply = () => {
        // document.getElementById("date").style.visibility = "hidden";
        if (typeof setShowDatePicker === 'function') {
            setShowDatePicker(false);
        }
        if (typeof setDatePickerOpen === 'function') {
            setDatePickerOpen(false);
        }
        datevalue(myDateRange)
        setSelectedDateRange({ startDate: new Date(), endDate: new Date(), key: "selection" });

        if (typeof setCustomDates === 'function') {
            setCustomDates({
                startDate: StartDATE,
                endDate: EndDATE
            })
        }
    };

    const onClickClear = () => {
        if (typeof setShowDatePicker === 'function') {
            setShowDatePicker(false);
        }
        if (typeof setDatePickerOpen === 'function') {
            setDatePickerOpen(false);
        }  
    };


    return (
        <div className='date' id="date">
            <div className="shadow d-inline-block">
                <DateRangePicker
                    colors={[
                        { date: new Date(), cellCssClass: 'check-in' },
                        { date: new Date(), cellCssClass: 'check-out' },
                        { start: new Date(), end: new Date(), background: '#007aff' }
                    ]}                    
                    arrowIcon={<button className="my-prev-button-class"></button>}
                    inputRanges={[{ label: 'Custom Range' }]}
                    staticRanges={ranges}
                    onChange={handleSelect}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={[selectedDateRange]}
                    direction="horizontal"
                    minDate={minDate}
                    maxDate={maxDate} />

                <div className="text-right position-relative rdr-buttons-position mt-2 mr-3 daterange">
                <div className="daterange-update">
                    <div className="daterange-start">{StartDATE}</div>
                        <span>-</span>
                        <div className="daterange-end">{EndDATE}</div>
                    </div>
                    <div className="daterange-btn">
                        <button className="daterange-btn-cancel secondary-button minwidthAuto button" type="button" onClick={onClickClear}>Cancel</button>
                        <button className="daterange-btn-apply primary-button minwidthAuto button" type="button" onClick={onClickApply}>Apply</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

// DatePicker.defaultProps = { ranges: defaultStaticRanges };
DatePicker.propTypes = { onSubmit: PropTypes.func };

export default DatePicker;
